import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import { SecondaryNavigationContext } from './SecondaryNavigationContext';

export const ThemeContext = createContext<ThemeContextInterface>({
  isDark: false,
  setIsDark(darkState) {
    this.isDark = darkState;
  },
});

const ThemeContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;
  const [isDark, setIsDark] = useState(false);
  const { activeSubNav } = useContext(SecondaryNavigationContext);

  useEffect(() => {
    setIsDark(activeSubNav?.name?.includes('stress-test'));
  }, [activeSubNav]);

  const value = useMemo(() => ({ isDark, setIsDark }), [isDark]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

interface ThemeContextInterface {
  isDark: boolean;
  setIsDark: (a: boolean) => void;
}

export default ThemeContextProvider;
