import { getToken } from '@api/config';
import { createHeaders } from '@api/httpHeaderConfig';
import { ApiResponse, CompanyLoginMethodsInterface, ErrorDetail } from '@interfaces/index';
import { CompanyDetails } from 'custom';

export const getCompanyDetailsByUrl = async (companyURL: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/company-details-by-url/${companyURL}`,
    {
      method: 'GET',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (response.status === 404) {
    return null;
  }

  const result: CompanyDetails = await response.json();

  return result;
};

export const getCompanyLoginMethodsByUrl = async (companyURL: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-company-login-methods/${companyURL}`,
    {
      method: 'GET',
      headers: createHeaders(),
    },
  );

  const responseData: ApiResponse<CompanyLoginMethodsInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getCompanyDetailsById = async (id: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/company-details/${id}`, {
    method: 'GET',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 404) {
    return null;
  }

  const result: CompanyDetails = await response.json();

  return result;
};

export const getCompanyDataByDomain = async (domain: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/company-details-by-domain/${domain}`,
    {
      method: 'GET',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (response.status === 404) {
    return null;
  }

  const result: CompanyDetails = await response.json();

  return result;
};

export const getCompanyDetailsByCompanyCode = async (companyCode: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/company-details-by-code/${companyCode}`,
    {
      method: 'GET',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (response.status === 404) {
    return null;
  }

  const result: CompanyDetails = await response.json();

  return result;
};

export const getCompanySignUpFields = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/company-fields`, {
    method: 'GET',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 404) {
    return null;
  }

  const result: any = await response.json();

  return result;
};
