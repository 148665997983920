import React, { useCallback, useEffect, useRef, useState } from 'react';
import EChartsReact from 'echarts-for-react';
import { formatDate } from '@utils/date.util';
import { generateStar } from '@utils/star.util';
import { WingDetails } from '@components/Star';
import useWindowDimensions from '@hooks/useWindowDimensions';
import dayjs from 'dayjs';
import { SET_GRAPH_DATA, useGlobalState } from '@context/GlobalStore';
import Img from '@components/Atoms/Img';
import styles from './index.module.scss';
import leftArrow from '../../../assets/images/leftArow.svg';

const generalHappiness = 'General Happiness';

const Area: React.FunctionComponent<AreaProps> = (props) => {
  const {
    values,
    labels,
    stars,
    nextPage,
    prevPage,
    currentPage,
    totalPages,
    setIndexGraph,
    selectedIndex,
    setSelectedIndex,
  } = props;
  const { isDesktop } = useWindowDimensions();
  const chartRef = useRef() as any;
  const [_, dispatch] = useGlobalState();

  const modifiedValues = values.map((i) => ({
    score: i.score,
    assessmentId: i.assessment_id,
    date: dayjs(i.created_at).format('DD/MM/YYYY'),
  }));

  const modifiedLabels = values.map((i) => dayjs(i.created_at).format('DD MMM YY'));

  if (values.length === 1) {
    modifiedValues.unshift(modifiedValues[0]);
    modifiedLabels.unshift('');
  }

  const maxValue = isDesktop ? 10 : 5;
  let modifiedStars = [...stars];
  modifiedStars = new Array(maxValue - stars.length).fill([]);
  modifiedStars.push(...stars);
  modifiedStars.push([]);
  // }

  const data = modifiedValues.map((value, index) => ({
    score: Math.round(value.score * 100) / 100,
    assessmentId: value.assessmentId,
    date: value.date,
    index,
  }));

  const labelData = modifiedLabels;

  const series = data.map((value, index) => {
    if (labelData[index] === '') {
      return {
        value: value.score,
        assessmentId: value.assessmentId,
        index,
        date: value.date,
        label: { show: false },
        symbol: 'none',
        symbolSize: 0,
      };
    }

    if (index === selectedIndex) {
      return {
        value: value.score,
        assessmentId: value.assessmentId,
        index,
        date: value.date,
        symbolSize: 67,
        label: {
          fontSize: 20,
        },
      };
    }

    return {
      value: value.score,
      assessmentId: value.assessmentId,
      index,
      date: value.date,
      label: { show: true },
    };
  });

  const richData: RichDataObject = {
    value: {
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 30,
      align: 'center',
    },
    selected: {
      height: 4,
      backgroundColor: '#FCD300',
      width: 60,
      lineHeight: 15,
    },
  };

  const getDataGraph = useCallback((dataGraph: any) => {
    dispatch({
      type: SET_GRAPH_DATA,
      payload: {
        assesmentId: dataGraph?.data?.assessmentId,
        date: dataGraph?.data?.date,
        index: dataGraph?.data?.index,
      },
    });
    setIndexGraph(dataGraph?.data?.index);
    setSelectedIndex(dataGraph?.data?.index);
  }, []);

  labelData.forEach((item) => {
    if (item !== '') {
      richData[8] = {
        height: 30,
        backgroundColor: {
          image: generateStar(372, 428, [
            {
              color: '#019DF2',
              name: generalHappiness,
              value: [10, 10, 6, 6],
            },
            {
              color: '#BEAAF3',
              name: generalHappiness,
              value: [10, 10, 6, 6],
            },
            {
              color: '#FCD300',
              name: generalHappiness,
              value: [10, 10, 6, 6],
            },
            {
              color: '#8EEA36',
              name: generalHappiness,
              value: [10, 10, 6, 6],
            },
            {
              color: '#06BF97',
              name: generalHappiness,
              value: [10, 10, 6, 6],
            },
            {
              color: '#FF8A4A',
              name: generalHappiness,
              value: [10, 10, 6, 6],
            },
          ]),
        },
      };
    }
  });

  useEffect(() => {
    const instance = chartRef?.current?.getEchartsInstance();
    instance.on('click', (params: any) => {
      dispatch({
        type: SET_GRAPH_DATA,
        payload: {
          assesmentId: series[params?.dataIndex]?.assessmentId,
          date: series[params?.dataIndex]?.date,
          index: series[params?.dataIndex]?.index,
        },
      });
      setIndexGraph(series[params?.dataIndex]?.index);
      setSelectedIndex(series[params?.dataIndex]?.index);
    });
  }, [series]);

  const option = {
    grid: { right: 40, left: 40 },
    // tooltip: {
    //   show: true,
    //   trigger: 'axis',
    //   formatter: (params: any) => {
    //     if (params[0]?.data?.assessmentId) {
    //       getDataGraph(params[0]);
    //     }
    //   },
    // },

    xAxis: {
      type: 'category',
      boundaryGap: false,
      triggerEvent: true,
      data: labelData,
      axisPointer: {
        show: true,
        type: 'shadow',
        triggerEvent: false,
        trigger: 'item',
      },
      axisLabel: {
        margin: 30,
        align: 'center',
        rotate: 20,
        triggerEvent: true,
        trigger: 'axis',
        interval: 0,
        formatter: (value: string, index: number) =>
          [
            `{${value !== '' ? 8 : 0}|}`,
            `${index === modifiedValues.length - 1 && currentPage === 1 ? 'Current' : value}`,
            `{${
              values.length < 2
                ? index - 1 + currentPage === selectedIndex + currentPage && 'selected'
                : index + currentPage === selectedIndex + currentPage && 'selected'
            }|}`,
          ].join('\n'),
        fontWeight: 'bold',
        rich: richData,
      },
    },
    yAxis: {
      type: 'value',
      interval: 10,
      max: 100,
      triggerEvent: true,
      trigger: 'axis',
      axisLine: {
        show: true,
      },
      splitLine: {
        show: true,
      },
    },
    series: [
      {
        data: series,
        type: 'line',
        symbolSize: 44,
        smooth: true,

        label: {
          show: true,
          position: 'inside',
          triggerEvent: true,
          fontSize: 16,
          fontFamily: 'Titillium Web',
          color: '#505050',
        },
        itemStyle: {
          color: '#069B7B',
        },
        lineStyle: {
          color: '#06BF97',
        },
        areaStyle: {
          color: '#06BF97',
          opacity: 0.9,
        },
      },
      {
        data: series,
        type: 'line',
        symbolSize: 44,
        smooth: true,

        label: {
          show: true,
          position: 'inside',
          triggerEvent: true,
          fontSize: 16,
          fontFamily: 'Titillium Web',
          color: '#505050',
        },
        itemStyle: {
          color: '#069B7B',
        },
        lineStyle: {
          color: '#06BF97',
        },
        areaStyle: {
          color: '#06BF97',
          opacity: 0.9,
        },
      },
    ],
  };

  return (
    <div className={styles.hsAreaChart} style={{ maxWidth: '100%' }}>
      {currentPage < totalPages && (
        <div onClick={prevPage}>
          <Img src={leftArrow} alt="Left Accow" className={styles.arrowContLeft} />
        </div>
      )}
      <EChartsReact ref={chartRef} option={option} className={styles.eChart} />
      {currentPage !== 1 && (
        <div onClick={nextPage}>
          <Img src={leftArrow} alt="Right Accow" className={styles.arrowContRight} />
        </div>
      )}
    </div>
  );
};

interface RichDataObject {
  [key: string]: string | number | object;
}

interface AreaProps {
  values: any[];
  labels: any[];
  nextPage: any;
  stars: WingDetails[][];
  prevPage: any;
  totalPages: number;
  currentPage: number;
  setIndexGraph: (index: number) => void;
  setSelectedIndex: any;
  selectedIndex: number;
}

export default Area;
