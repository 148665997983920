import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const FormTextarea = React.forwardRef<HTMLTextAreaElement, FormTextareaProps>((props, ref) => {
  const { name, isError, title, placeholder, ...rest } = props;

  return (
    <fieldset className={styles.root}>
      <div className={styles.row}>
        <label htmlFor={name}>{title}</label>
      </div>

      <textarea
        id={name}
        className={classNames([styles.input, isError && styles.error])}
        name={name}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    </fieldset>
  );
});

FormTextarea.displayName = 'FormTextarea';

FormTextarea.defaultProps = {
  isError: false,
};

interface FormTextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
  isError?: boolean;
}

export default FormTextarea;
