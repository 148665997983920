import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from '../Button';

import styles from './index.module.scss';

const ModalContent: React.FunctionComponent<ModalContentProps> = (props) => {
  const { title, subtitle, onClose, hasBackground, children } = props;
  const [modalClasses, setModalClasses] = useState(styles.hsModal);

  useEffect(() => {
    setModalClasses(classNames([styles.hsModal, hasBackground && styles.hsModalBackground]));
  }, [hasBackground]);

  return (
    <>
      <div className={modalClasses}>
        <div className={styles.hsModalContent}>
          <div className={styles.hsModalHeader}>
            <div className={styles.hsModalClose}>
              <Button isButton type="close" onClick={onClose}>
                Close
                <i className="icon icon-x" />
              </Button>
            </div>
            <h4 className={styles.hsModalTitle}>{title}</h4>
            {subtitle && <p className={styles.hsModalSubtitle}>{subtitle}</p>}
          </div>
          <div className={styles.hsModalBody}>{children}</div>
        </div>
      </div>
    </>
  );
};

interface ModalContentProps {
  title: string | JSX.Element;
  subtitle?: string;
  onClose: () => void;
  hasBackground?: boolean;
  children: React.ReactNode;
}

ModalContent.defaultProps = {
  subtitle: '',
  hasBackground: false,
};

export default ModalContent;
