import React from 'react';
import { useHistory } from 'react-router';
import FormButtonNew from '@components/FormButtonNew/FormButtonNew';
import LabelInput from '@components/Molecules/LabelInput';
import useGoNative from '@hooks/useGoNative';
import { LoginTypes, loginUser } from '@api/auth/login';
import { toast } from 'react-toastify';
import useInput, { getInputValue } from '@hooks/useInput';
import { emailValidation } from '@helpers/validation.helper';
import styles from './index.module.scss';

const LoginForm: React.FunctionComponent<LoginFormProps> = (props) => {
  const { organizationId, companyURL } = props;
  const history = useHistory();
  const isMobileApp = useGoNative();

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput(emailValidation, getInputValue, '');

  const handleCreateUser = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    let type = LoginTypes.LINK;
    if (isMobileApp) {
      type = LoginTypes.TOKEN;
    }

    if (emailValue) {
      const response = await loginUser({
        email: emailValue.toLowerCase(),
        type,
        organizationId,
      });

      if (response.ok) {
        history.push(
          `/login/check-your-inbox/${emailValue}${companyURL ? `/${companyURL}` : ''}${
            isMobileApp ? '/?isMobileApp=true' : ''
          }`,
        );
      } else if (response.status === 400 || response.status === 403) {
        toast(
          'We couldn’t recognise this email address. If you are new to Hapstar, click ‘Sign Up’ below to create your account.',
        );
      } else {
        toast('Login unsuccessful. Please contact admin team');
      }
    } else {
      toast('Please enter a valid email');
    }

    emailReset();
  };

  return (
    <form onSubmit={handleCreateUser}>
      <div className={styles.inputHolder}>
        <LabelInput
          id="email"
          label="Email *"
          type="text"
          name="email"
          placeholder="Add your email here..."
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          value={emailValue || ''}
          isError={emailHasError}
        />
        {emailHasError && <p className={styles.hsError}>Please enter a valid email address</p>}
      </div>
      <div className={styles.buttonHolder}>
        <FormButtonNew
          onClick={handleCreateUser}
          disabled={!emailIsValid && !isMobileApp}
          color="primary"
          type="submit"
        >
          Login
        </FormButtonNew>
      </div>
    </form>
  );
};

LoginForm.displayName = 'LoginForm';

LoginForm.defaultProps = {
  organizationId: 0,
  companyURL: undefined,
};

interface LoginFormProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  organizationId?: number;
  companyURL?: string;
}

export default LoginForm;
