import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

interface UploadResponse {
  message: string;
  imageUrl: string;
  bucketUploadedPath: string;
}

export const uploadImage = async (formData: FormData) => {
  const headers = createHeaders({ Authorization: `Bearer ${getToken()}` }) as Record<
    string,
    string
  >;

  delete headers['Content-Type'];

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/upload-image`, {
    method: 'POST',
    headers: headers as HeadersInit,
    body: formData,
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    const error = new Error(errorMessage || `Failed to upload image`);
    throw error;
  }

  const result: UploadResponse = await response.json();

  return result;
};
