import Card from '@components/Atoms/Card';
import DeepDownItem from '@components/Molecules/DeepDownItem';
import { Grid, Skeleton } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './index.module.scss';

const DeepDives: FunctionComponent<DeepDivesProps> = (props) => {
  const { className, data, loading } = props;
  const [selectedImproves, setSelectedImproves] = useState<number[]>([]);

  useEffect(() => {
    setSelectedImproves(
      data.filter((button) => button.latestAssessmentScore > 0).map((button) => button.questionId),
    );
  }, [data]);

  const handleButtonClick = (questionId: number) => {
    setSelectedImproves((prevSelected) => {
      if (prevSelected.includes(questionId)) {
        return prevSelected.filter((id) => id !== questionId);
      }

      return [...prevSelected, questionId];
    });
  };

  return (
    <Card className={className}>
      <div className={styles.topSection}>
        {loading ? (
          <Skeleton variant="text" width="50%" height={40} />
        ) : (
          <h5>Your wellbeing deep dives</h5>
        )}

        {loading ? (
          <Skeleton variant="text" width="30%" height={20} className={styles.subLoad} />
        ) : (
          <p>Unlock all core wellbeing values</p>
        )}
      </div>

      {loading ? (
        <Grid container spacing={2} className={styles.focusSelectionGrid}>
          {[0, 1, 2, 3, 4, 5].map((index) => (
            <Grid item xs={6} md={4} key={index}>
              <Skeleton variant="rounded" width="90%" height={165} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2} className={styles.focusSelectionGrid}>
          {data?.map((item, index) => (
            <Grid item xs={6} md={4} key={index} className={styles.gridItem}>
              <DeepDownItem
                id={item.questionId}
                colorTheme={item.colorTheme}
                selected={selectedImproves.includes(item.questionId)}
                onClick={() => null}
                score={item.latestAssessmentScore}
                title={item.questionText}
                icon={item.icon}
                progressSectionEnabled
                progress={item.latestAssessmentScore}
                deepDiveId={item.deepDiveId}
                deepDiveName={item.deepDiveName}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Card>
  );
};

DeepDives.displayName = 'DeepDives';

DeepDives.defaultProps = {
  className: styles.deepDiveContainer,
};

interface DeepDivesProps {
  className?: string;
  data: DeepDive[];
  loading: boolean;
}
interface DeepDive {
  questionId: number;
  questionText: string;
  colorTheme: string;
  deepDiveId: number;
  latestAssessmentScore: number;
  icon: string;
  deepDiveName: string;
}

export default DeepDives;
