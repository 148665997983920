import { getShortStarQuestionsData } from '@api/shortStar';
import { AppDispatch } from '@hooks/useAppDispatch';
import { ShortStarQuestionsInterface } from 'custom.d';
import { shortStarActions } from 'store/Slices/ShortStarSlice';

export const getShortStarQuestions = () => async (dispatch: AppDispatch) => {
  dispatch(shortStarActions.setLoading({ loading: true }));
  try {
    const data: ShortStarQuestionsInterface[] = await getShortStarQuestionsData();
    if (data) {
      dispatch(shortStarActions.updateShortStarQuestionsData(data));
    } else {
      dispatch(shortStarActions.setError({ error: 'Loading short star questions failed' }));
    }
  } catch (error) {
    dispatch(shortStarActions.setError({ error: 'Loading short star questions failed' }));
  }
};
