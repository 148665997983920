import {
  ApiResponse,
  BookingCancellationResponseInterface,
  BookingSessionHistoryInterface,
  BookingSessionInterface,
  ErrorDetail,
  ExpertBooking,
  ExpertBookingOptionsData,
  ExpertInterface,
  ExpertTopicInterface,
  ExpertsMainDataInterface,
  ExpertsResultsResponse,
} from '@interfaces/index';
import { ExpertBookingTypes, SessionDurations } from 'custom.d';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getAllExperts = async (
  expertise: number | null,
  questionId: number | null,
  page: number | null,
  perPage: number | null,
  timeRange: {
    start: string;
    end: string;
  } | null,
) => {
  const formattedReq = {
    expertise,
    questionId,
    page,
    perPage,
    timeRange,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-filtered-experts`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<ExpertsResultsResponse> | ApiResponse<ErrorDetail> =
    await response.json();

  // const data = castResponse<ExpertsResultsResponse>(response);

  return responseData;
};

export const getExpertById = async (id: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-expert-by-id/${id}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const responseData: ApiResponse<ExpertInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getExpertAvailability = async (
  id: number,
  startDate: string,
  endDate: string,
  timeZone: string,
) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/expert-availability/${id}?timezone=${timeZone}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const responseData: ApiResponse<any> | ApiResponse<ErrorDetail> = await response.json();

  return responseData;
};

export const addBooking = async (
  bookingDate: string | Date,
  expertId: number,
  typeId: number,
  durationId: number,
  timeZone: string,
) => {
  const formattedReq = {
    bookingDate,
    type: typeId,
    duration: durationId,
    expertId,
    timeZone,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/expert-booking?statusCode=201`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<ExpertBooking> | ApiResponse<ErrorDetail> = await response.json();

  return responseData;
};

export const expertsPageLog = async (
  type: string,
  ipAddress: string | null,
  os: string | null,
  isNative: boolean | null,
) => {
  const formattedReq = {
    type,
    ipAddress,
    os,
    isNative,
  };

  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/create-log`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  const responseData: ApiResponse<ExpertBooking> | ApiResponse<ErrorDetail> = await response.json();

  return responseData;
};

export const getExpertsTopics = async () => {
  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/experts-topics`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  // const result: ExpertTopicInterface[] = await response.json();
  const responseData = await response.json();

  return responseData;
};

export const fetchAllExpertsToDB = async () => {
  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/fetch-all-experts`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  const responseData = await response.json();

  return responseData;
};

export const updateAllExpertsDetails = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/update-all-experts-data`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const responseData: ApiResponse<{ updatedExpertDetails: number }> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getExpertAssignedQuestions = async (expertId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/get-questions-for-expert/${expertId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const responseData = await response.json();

  return responseData;
};

export const updateExpertQuestionData = async (expertId: number, questionIds: number[]) => {
  const formattedReq = {
    expertId,
    questionIds,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/update-expert-question-data`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<{ updatedExpertDetails: number }> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getExpertBookingOptions = async (expertId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/expert-durations-types/${expertId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const responseData: ApiResponse<ExpertBookingOptionsData> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getExpertUpcomingBookings = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/upcoming-expert-bookings`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const responseData: ApiResponse<BookingSessionInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const cancelBookingSession = async (bookingId: number, timeZone: string) => {
  const formattedReq = {
    bookingId,
    timeZone,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/cancel-expert-booking`,
    {
      method: 'PUT',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<BookingCancellationResponseInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};

export const getSessionHistory = async (status: Array<string>) => {
  const formattedReq = {
    status,
  };

  const response = await fetch(
    `${process.env.REACT_APP_ACTION_PLAN_API_URL}/user-booking-history`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  const responseData: ApiResponse<BookingSessionHistoryInterface> | ApiResponse<ErrorDetail> =
    await response.json();

  return responseData;
};
