import { createSlice } from '@reduxjs/toolkit';

export interface UserStatusState {
  loading: boolean;
  isLoaded: boolean;
  error: string | null;
  data: {
    isRegistered: boolean | null;
    isJoined: boolean | null;
    isAgreed: boolean | null;
    isFirstShortStarCompleted: boolean | null;
  };
}

const initialState: UserStatusState = {
  loading: false,
  error: null,
  isLoaded: false,
  data: {
    isRegistered: null,
    isJoined: null,
    isAgreed: null,
    isFirstShortStarCompleted: null,
  },
};

export interface UpdateUserStatusAction {
  type: string;
  payload: {
    isRegistered?: boolean | null;
    isJoined?: boolean | null;
    isAgreed?: boolean | null;
    isFirstShortStarCompleted?: boolean | null;
  };
}

export interface SetLoadingAction {
  type: string;
  payload: {
    loading: boolean;
  };
}

export interface SetErrorAction {
  type: string;
  payload: {
    error: string;
  };
}

export type UserStatusAction = SetLoadingAction | SetErrorAction | UpdateUserStatusAction;

const UserStatusSlice = createSlice({
  name: 'userStatus',
  initialState,
  reducers: {
    setLoading(state: UserStatusState, action: SetLoadingAction): UserStatusState {
      return {
        ...state,
        loading: action.payload.loading,
        isLoaded: false,
        error: null,
        data: {
          isRegistered: null,
          isJoined: null,
          isAgreed: null,
          isFirstShortStarCompleted: null,
        },
      };
    },
    setError(state: UserStatusState, action: SetErrorAction): UserStatusState {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload.error,
        data: {
          isRegistered: null,
          isJoined: null,
          isAgreed: null,
          isFirstShortStarCompleted: null,
        },
      };
    },
    updateUserStatus(state: UserStatusState, action: UpdateUserStatusAction): UserStatusState {
      const isRegisteredUpdate = action.payload.isRegistered;
      const isJoinedUpdate = action.payload.isJoined;
      const isAgreedUpdate = action.payload.isAgreed;
      const isFirstShortStarUpdate = action.payload.isFirstShortStarCompleted;

      return {
        ...state,
        loading: false,
        isLoaded: true,
        error: null,
        data: {
          isRegistered:
            isRegisteredUpdate !== undefined ? isRegisteredUpdate : state.data.isRegistered,
          isJoined: isJoinedUpdate !== undefined ? isJoinedUpdate : state.data.isJoined,
          isAgreed: isAgreedUpdate !== undefined ? isAgreedUpdate : state.data.isAgreed,
          isFirstShortStarCompleted:
            isFirstShortStarUpdate !== undefined
              ? isFirstShortStarUpdate
              : state.data.isFirstShortStarCompleted,
        },
      };
    },
  },
});

export const userStatusActions = UserStatusSlice.actions;

export default UserStatusSlice;
