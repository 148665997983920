import { getLearnings } from '@api/learning';
import BlogPostCard from '@components/Organisms/BlogPostCard';
import MainLayout from '@layouts/MainLayout';
import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.css';

const PostsPage: React.FunctionComponent = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await getLearnings();

        if (response === 401) {
          throw new Error('Unable to fetch posts');
        }

        const data: any = await response.json();

        const filteredPosts = data.filter((p: any) => p.acf?.show_in_employee_portal);

        setPosts(filteredPosts);
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    };

    getPosts();
  }, []);

  return (
    <MainLayout title="Learn" secondaryNavEnabled isEmployerPage>
      {!loading && error && <p>Unable to fetch posts</p>}

      {loading && !error && posts && posts.length === 0 && (
        <div className="container">
          <Skeleton variant="text" width={200} height={60} className="loading-learn-text" />
          <Grid container spacing={3}>
            {[0, 1, 2, 3, 4, 5].map((index) => (
              <Grid item xs={12} sm={12} md={4} key={index}>
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={360}
                  className="loading-post-card"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {!loading && !error && posts && (
        <div>
          <div className="container">
            <div className="heading-wrapper trigger in-view">
              <div className="news-heading">
                <div className="title-heading">
                  <h1>Learn</h1>
                </div>
              </div>
            </div>
            <div className="card-section-v2">
              {posts &&
                posts.length > 0 &&
                posts.map((item: any, key: number) => (
                  <BlogPostCard
                    postId={item.id}
                    key={key}
                    keyValue={key}
                    backgroundImage={item.acf?.background_image?.sizes?.medium}
                    title={item.title.rendered}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default PostsPage;
