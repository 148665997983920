export const TOKEN = localStorage.getItem('token');

export const getToken = () => localStorage.getItem('token');

export const checkLocalStorage = (key: any) =>
  new Promise((resolve, reject) => {
    try {
      const item = localStorage.getItem(key);

      if (item) {
        resolve(item);
      }
    } catch (error) {
      reject(error);
    }
  });
