import { getSpeakUpResponsesForEmployer, getEmployerThoughts } from '@api/EmployerNew';
import { getQuestionnairesNames } from '@api/questionnaires';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import MainLayout from '@layouts/MainLayout';
import { Skeleton } from '@mui/material';
import React, { FunctionComponent, Suspense, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import AnalyticsIcon from '@assets/images/icons/analytics-admin.svg';
import BellRed from '@assets/images/icons/bell-red.svg';
import CommentsIcon from '@assets/images/icons/comments-admin.svg';
import UsersIcon from '@assets/images/icons/users-admin.svg';
import styles from './index.module.scss';

const NotifySection: FunctionComponent<NotifySectionProps> = ({ message, loading }) => {
  const history = useHistory();
  const handleNavigation = (path: string) => {
    history.push(path);
  };

  return (
    <>
      {loading ? (
        <Skeleton
          className={styles.employerNotifyLoadingContainer}
          variant="rectangular"
          width="100%"
          height="100%"
        />
      ) : (
        message?.length > 0 && (
          <div
            className={styles.employerNotifyContainer}
            onClick={() => handleNavigation('/employer/comments')}
          >
            <div className={styles.leftSection}>
              <Img className={styles.notificationBell} src={BellRed} alt="Hs Notification Icon" />
              <p>{message}</p>
            </div>
            <DefaultButton onClick={() => handleNavigation('/employer/comments')}>
              VIEW
            </DefaultButton>
          </div>
        )
      )}
    </>
  );
};

const NavigationSection: FunctionComponent = () => {
  const history = useHistory();

  const handleNavigation = (path: string) => {
    history.push(path);
  };

  const navigationItems = useMemo(
    () => [
      {
        path: '/employer/analytics',
        icon: AnalyticsIcon,
        title: 'View your Hapstar analytics',
        buttonText: 'ANALYTICS',
      },
      {
        path: '/employer/comments',
        icon: CommentsIcon,
        title: 'Respond to speak up & comments',
        buttonText: 'COMMENTS',
      },
      {
        path: '/employer/users',
        icon: UsersIcon,
        title: 'Manage employee data',
        buttonText: 'EMPLOYEES',
      },
    ],
    [],
  );

  return (
    <div className={styles.navigationContainer}>
      {navigationItems.map((item, index) => (
        <div
          key={index}
          className={`${styles.navigationItem} ${styles[`navigationItem${index + 1}`]}`}
          onClick={() => handleNavigation(item.path)}
          role="button"
          tabIndex={0}
          onKeyPress={() => handleNavigation(item.path)}
        >
          <Img src={item.icon} alt={`${item.title} Icon`} className={styles.navigationIcon} />
          <h4>{item.title}</h4>
          <div className={styles.navigationBtn}>
            <DefaultButton>{item.buttonText}</DefaultButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const EmployerLanding: FunctionComponent = () => {
  const [commentsResponses, setCommentsResponses] = useState<{
    respondedSpeakUps: number;
    respondedThoughts: number;
    notRespondedSpeakUps: number;
    notRespondedThoughts: number;
  }>({
    respondedSpeakUps: 0,
    respondedThoughts: 0,
    notRespondedSpeakUps: 0,
    notRespondedThoughts: 0,
  });

  const [questionnaireNames, setQuestionnaireNames] = useState<QuestionnaireNamesInterface[]>([]);
  const [loadingAlerts, setLoadingAlerts] = useState<boolean>(true);

  useEffect(() => {
    getQuestionnairesNames().then((data) => {
      if (Array.isArray(data)) {
        const newObject = { id: 4, name: 'Check-in' };

        data.unshift(newObject);

        setQuestionnaireNames(data);
      } else {
        setQuestionnaireNames([]);
      }
    });
  }, []);

  useEffect(() => {
    if (questionnaireNames?.length > 0) {
      fetchCommentsData();
    }
  }, [questionnaireNames]);

  const fetchCommentsData = async () => {
    const qIds = questionnaireNames.map((q) => q.id);

    try {
      setLoadingAlerts(true);

      const [respondedSpeakUps, notRespondedSpeakUps, respondedThoughts, notRespondedThoughts] =
        await Promise.all([
          getSpeakUpResponsesForEmployer(true, qIds, 1, 1000, 'DESC'),
          getSpeakUpResponsesForEmployer(false, qIds, 1, 1000, 'DESC'),
          getEmployerThoughts(true, qIds, 1, 1000, 'DESC', 'CREATED_AT'),
          getEmployerThoughts(false, qIds, 1, 1000, 'DESC', 'CREATED_AT'),
        ]);

      setCommentsResponses({
        respondedSpeakUps: respondedSpeakUps?.data?.length || 0,
        respondedThoughts: respondedThoughts?.data?.length || 0,
        notRespondedSpeakUps: notRespondedSpeakUps?.data?.length || 0,
        notRespondedThoughts: notRespondedThoughts?.data?.length || 0,
      });
    } catch (error) {
      setCommentsResponses({
        respondedSpeakUps: 0,
        respondedThoughts: 0,
        notRespondedSpeakUps: 0,
        notRespondedThoughts: 0,
      });
    } finally {
      setLoadingAlerts(false);
    }
  };

  const { notRespondedSpeakUps, notRespondedThoughts } = commentsResponses;
  let message = '';
  if (notRespondedSpeakUps > 0 && notRespondedThoughts > 0) {
    // Scenario 4: Pending speak up and pending comments
    message = `You have ${notRespondedSpeakUps} speak up and ${notRespondedThoughts} comments to respond`;
  } else if (notRespondedSpeakUps > 0) {
    // Scenario 2: Pending speak up and 0 pending comments
    message = `You have ${notRespondedSpeakUps} speak up to respond`;
  } else if (notRespondedThoughts > 0) {
    // Scenario 3: 0 Pending speak up and pending comments
    message = `You have ${notRespondedThoughts} comments to respond`;
  }

  return (
    <MainLayout title="Employer Landing" useEmployerNav isEmployerPage secondaryNavEnabled>
      <div className={styles.mainContainer}>
        <Suspense fallback={<LoadingPage />}>
          <h2 className={styles.mainTitle}>Employer portal</h2>
          <NotifySection message={message} loading={loadingAlerts} />
          <NavigationSection />
        </Suspense>
      </div>
    </MainLayout>
  );
};

interface QuestionnaireNamesInterface {
  id: number;
  name: string;
}

interface NotifySectionProps {
  message: string;
  loading: boolean;
}

export default EmployerLanding;
