import { useState } from 'react';

const useInput = (
  validateValue: (value: any) => boolean,
  getValue: (value: any) => any,
  defaultValue: any,
) => {
  const [value, setValue] = useState<any>(defaultValue || undefined);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const validateValueHandler = () => value !== undefined && validateValue(value);

  const valueIsValid = validateValueHandler();
  const hasError = !valueIsValid && isTouched;

  const valueChangeHandler = (changeValue: any) => {
    setValue(getValue(changeValue));
  };

  const inputBlurHandler = (changeValue: any) => {
    setIsTouched(true);
  };

  const reset = () => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    } else {
      setValue(undefined);
    }

    setIsTouched(false);
  };

  return {
    value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useInput;

export const getInputValue: (target: any) => string = (target) => target.target.value;

export const getSelectValue: (target: any) => any = (target) => target;

export const getCheckedValue: (target: any) => boolean = (target) => target.target.checked;
