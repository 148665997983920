import { createCanvas } from 'canvas';
import { CoordinatePosition, RhombusCoordinates, StarConfig } from 'custom.d';
import { innerRhombusConfig, rhombusCoordinates } from '@config/star.config';

/**
 * Calculate coordinates of the rhombus based on value
 */
export const calculatePoints = (
  value: number,
  point1X: number,
  point1Y: number,
  point2X: number,
  point2Y: number,
  centerXPoint: number,
  centerYPoint: number,
  xPlus: boolean,
  yPlus: boolean,
  isSideRhombus: boolean,
  walkXOnRotated = false,
  walkYOnRotated = false,
) => {
  let walkX = ((point2X - centerXPoint) / 10) * value;
  let walkY = ((centerYPoint - point2Y) / 10) * value;

  if (walkXOnRotated) {
    if (walkYOnRotated) {
      walkX -= (38 * value) / 10;
    } else {
      walkY -= (65 * value) / 10;
    }
  }

  if (!isSideRhombus) {
    walkX = ((point1X - centerXPoint) / 10) * value;
    walkY = ((centerYPoint - point1Y) / 10) * value;
  }

  const point = [];
  const pointX = xPlus ? centerXPoint + walkX : centerXPoint - walkX;
  const pointY = yPlus ? centerYPoint + walkY : centerYPoint - walkY;

  point.push(pointX);
  point.push(pointY);

  return point;
};

/**
 * Calculates the center of the rhombus
 */
export const calculateCenterOfGivenCoordinates = (inner: CoordinatePosition) => {
  const { p1, p2, p3, p4 } = inner;

  const centerX = (p1.x + p2.x + p3.x + p4.x) / 4;
  const centerY = (p1.y + p2.y + p3.y + p4.y) / 4;

  return [centerX, centerY];
};

/**
 * Generates star with given values
 */
export const generateStar = (width: number, height: number, wingDetails: StarConfig[]) => {
  const canvas = createCanvas(width, height, 'svg');
  const ctx = canvas.getContext('2d');

  // Draws big rhombuses
  const drawBigRhombuses = ({ p1, p2, p3, p4 }: CoordinatePosition) => {
    ctx.strokeStyle = '#FCFBF7';
    ctx.lineWidth = 1;
    ctx.fillStyle = '#F1EEE6';

    ctx.beginPath();
    ctx.moveTo(p1.x, p1.y);
    ctx.lineTo(p2.x, p2.y);
    ctx.lineTo(p3.x, p3.y);
    ctx.lineTo(p4.x, p4.y);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  };

  // Draws inner colored rhombuses
  const drawInnerRhombuses = (ele: RhombusCoordinates, wingDetail: StarConfig) => {
    const { inner, position } = ele;
    const { p1, p2 } = inner;
    const center = calculateCenterOfGivenCoordinates(inner);
    const { color, value } = wingDetail;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    const innerRhombusConfigByPosition = innerRhombusConfig[position];
    const pointData = innerRhombusConfigByPosition.map((element, index) => {
      const { xPlus, yPlus, isSideRhombus, walkXOnRotated, walkYOnRotated } = element;
      const pointValue = (value[index] + 5) as any;

      return calculatePoints(
        pointValue,
        p1.x,
        p1.y,
        p2.x,
        p2.y,
        center[0],
        center[1],
        xPlus,
        yPlus,
        isSideRhombus,
        walkXOnRotated,
        walkYOnRotated,
      );
    });

    const [point1, point2, point3, point4] = pointData;

    ctx.beginPath();
    ctx.moveTo(point1[0], point1[1]);
    ctx.lineTo(point2[0], point2[1]);
    ctx.lineTo(point3[0], point3[1]);
    ctx.lineTo(point4[0], point4[1]);
    ctx.closePath();
    ctx.fill();
  };

  rhombusCoordinates.forEach((element, index) => {
    if (index < wingDetails.length) {
      drawBigRhombuses(element);
      const wingDetail = wingDetails[index];
      drawInnerRhombuses(element, wingDetail);
    }
  });

  return canvas.toDataURL('image/png');
};
