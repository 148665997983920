import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getHistoricalData = async (questionnaireId = 1, page = 0, perPage = 10) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/historical-data/${questionnaireId}/${page}/${perPage}`,
    {
      method: 'GET',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: any = await response.json();

  return result;
};
