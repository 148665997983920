import { addUserOneSignalId } from '@api/users';
import Median from 'median-js-bridge';

export const updateOneSignalDetails = async () => {
  Median.onReady(async () => {
    console.log('Median Ready');
  });

  const info = await Median.onesignal.info();

  if (info) {
    await addUserOneSignalId(info);
  }
};
