import React from 'react';
import classNames from 'classnames';
import Img from '@components/Atoms/Img';
import chatIcon from '../../assets/images/earlyWarning/chat-icon.svg';
import styles from './index.module.scss';

const ThoughtsAndSpeakupCard: React.FunctionComponent<ActionsCardProps> = (props) => {
  const { focusArea, focusAreaColor, action, score, completed, createdDate, isSpeakup } = props;

  const styleClasses = classNames([
    styles.hsActionsCard,
    focusAreaColor === '#BEAAF3' && styles.calmLilac,
    focusAreaColor === '#019DF2' && styles.skyBlue,
    focusAreaColor === '#FCD300' && styles.sunYellow,
    focusAreaColor === '#8EEA36' && styles.zingyGreen,
    focusAreaColor === '#06BF97' && styles.aquaGreen,
    focusAreaColor === '#FF8A4A' && styles.warmOrange,
  ]);

  return (
    <div className={styleClasses}>
      <div className={styles.hsActionsCardTitle}>
        <p className={styles.focusAreaX}>{focusArea}</p>
        <p>
          {isSpeakup ? (
            <div className={styles.speakupContainerStar}>
              <Img src={chatIcon} alt="Chat Icon" className={styles.chatIcon} />
            </div>
          ) : (
            <span>{score}</span>
          )}
        </p>
      </div>
      <div className={styles.hsActionsCardContent}>{action}</div>
    </div>
  );
};

type ActionsCardProps = {
  focusArea: string;
  focusAreaColor: any;
  action: string;
  score: number;
  completed: boolean;
  createdDate: string;
  isSpeakup: boolean;
};

export default ThoughtsAndSpeakupCard;
