import React, { FormEvent, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from '@components//Button';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { saveAction } from '@api/actions';
import { ADD_ACTIONS, useGlobalState } from '@context/GlobalStore';
import { GetFocusAreaSuggestions } from '@api/focusArea';
import styles from './index.module.scss';

const CreateEditAction: React.FunctionComponent<CreateEditActionProps> = (props) => {
  const {
    focusArea,
    score,
    color,
    suggestions,
    isEdit,
    id,
    closeModal,
    sendActionData,
    actionToEdit = '',
  } = props;
  const [canCreate, setCanCreate] = useState(true);
  const [action, setAction] = useState(actionToEdit);
  const [length, setLength] = useState(0);
  const [focusAreasSuggestions, setFocusAreaSuggestions] = useState([]);
  const [state, dispatch] = useGlobalState();
  const { activeSubNav } = useContext(SecondaryNavigationContext);

  const styleClasses = classNames([
    styles.hsCreateAction,
    color === '#BEAAF3' && styles.calmLilac,
    color === '#019DF2' && styles.skyBlue,
    color === '#FCD300' && styles.sunYellow,
    color === '#8EEA36' && styles.zingyGreen,
    color === '#06BF97' && styles.aquaGreen,
    color === '#FF8A4A' && styles.warmOrange,
  ]);

  const handleActionInput = (e: FormEvent<HTMLTextAreaElement>) => {
    const newAction = e.currentTarget.value;
    if (newAction.length <= 100) {
      setAction(e.currentTarget.value);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setCanCreate(action !== '');
  }, [action]);

  const handleCreateAction = async () => {
    // eslint-disable-next-line no-alert
    sendActionData({ id, action_text: action, name: focusArea });

    closeModal();
  };

  useEffect(() => {
    const getFocusAreaSuggestions = async () => {
      const focusData = await GetFocusAreaSuggestions(id);

      setFocusAreaSuggestions(focusData);
    };

    getFocusAreaSuggestions();
  }, [id]);

  return (
    <div className={styleClasses}>
      <div className={styles.hsCreateActionLabel}>
        <p>{focusArea}</p>
        <p>
          Score <span>{score}</span>
        </p>
      </div>
      <div>
        <textarea placeholder="Your action..." onInput={handleActionInput} value={action} />
        <p className={styles.hsCharCounter}>{action.length}/100 characters</p>
      </div>

      {!isEdit && (
        <div className={styles.hsCreateActionSuggestions}>
          <p className={styles.hsCreateActionSuggestionsTitle}>Add a suggested action</p>
          {focusAreasSuggestions &&
            focusAreasSuggestions.map((suggestion: any, index: number) => (
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const result = `${suggestion.description}`.substring(0, 100);
                  setAction(result);
                }}
                className={styles.hsSuggestion}
                key={index}
              >
                {suggestion.description}
              </div>
            ))}
        </div>
      )}

      <div className={styles.hsButtonContainer}>
        <Button isButton isDisabled={!canCreate} type="primary" onClick={handleCreateAction}>
          Add action
        </Button>
      </div>
    </div>
  );
};

interface CreateEditActionProps {
  isEdit?: boolean;
  actionToEdit?: string;
  focusArea: string;
  id: number;
  score: number;
  color: string;
  suggestions: any;
  sendActionData: any;
  closeModal: () => void;
}

CreateEditAction.defaultProps = {
  isEdit: false,
  actionToEdit: '',
};

export default CreateEditAction;
