import { getSessionHistory } from '@api/experts';
import AudioIcon from '@assets/images/icons/audio-only.svg';
import CalendarIcon from '@assets/images/icons/calendar-check-icon.svg';
import SuccessCheckIcon from '@assets/images/icons/check-circle.svg';
import ClockIcon from '@assets/images/icons/clock-icon.svg';
import PendingIcon from '@assets/images/icons/hourglass_empty.svg';
import InstantMessagingIcon from '@assets/images/icons/instant-messaging.svg';
import DefaultIcon from '@assets/images/icons/phone-call-dark.svg';
import RotateIcon from '@assets/images/icons/rotate-icon.svg';
import VideoIcon from '@assets/images/icons/video-only.svg';
import NotSuccessXIcon from '@assets/images/icons/x-circle.svg';
import Img from '@components/Atoms/Img';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { BookingSessionHistoryInterface } from '@interfaces/index';
import { Skeleton } from '@mui/material';
import { formatDateToDateMonthYear, formatTimeToGMT } from '@utils/date.util';
import classNames from 'classnames';
import { ExpertsBookingStatus, USER_NOT_FOUND_IMAGE, castResponse } from 'custom.d';
import React, { useEffect, useState } from 'react';
import { RootState } from 'store';
import { expertActions } from 'store/Slices/ExpertSlice';
import styles from './index.module.scss';

const ExpertSessionHistory: React.FunctionComponent<ExpertSessionInterface> = (props) => {
  const { isHistoryEnabled, handleHistoryOpen } = props;
  const PENDING_STATUS = 'pending' || 'PENDING' || 'Pending';
  const dispatch = useAppDispatch();
  const expertState = useAppSelector((state: RootState) => state.experts);
  const [bookings, setBookings] = useState<BookingSessionHistoryInterface[] | []>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBookings();
  }, []);

  useEffect(() => {
    if (expertState.refetchSessions) {
      getBookings();
      dispatch(expertActions.updateRefetchSessionsState(false));
    }
  }, [expertState.refetchSessions]);

  const getBookings = async () => {
    try {
      setLoading(true);
      const statuses = [ExpertsBookingStatus.SUCCESS, ExpertsBookingStatus.CANCELLED];
      const response = await getSessionHistory(statuses);
      if (response?.success) {
        const data = castResponse<BookingSessionHistoryInterface[]>(response);
        const sortedData = (data || []).sort(
          (a, b) =>
            new Date(b.bookingDetails.date).getTime() - new Date(a.bookingDetails.date).getTime(),
        );

        setBookings(sortedData);
      } else {
        setBookings([]);
      }
    } catch {
      setBookings([]);
    } finally {
      setLoading(false);
    }
  };

  const getIconForType = (typeName: string | undefined | null) => {
    switch (typeName?.toLowerCase()) {
      case 'video':
        return VideoIcon;
      case 'audio':
        return AudioIcon;
      case 'instant messaging':
      case 'im':
        return InstantMessagingIcon;
      default:
        return DefaultIcon;
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" height={120} className={styles.skeleton} />
      ) : (
        <>
          {!loading && bookings?.length === 0 ? (
            <>
              <div className={styles.sessionHistoryContainer}>
                <div className={styles.historyHeader}>
                  <h5>Your booking history</h5>
                  <div className={styles.btnClose}>
                    <button type="button" onClick={() => handleHistoryOpen(false)}>
                      Close&nbsp;
                      <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                    </button>
                  </div>
                </div>

                <p>
                  You don&apos;t have any expert booking history. Your completed or cancelled expert
                  sessions will be listed here.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className={styles.sessionHistoryContainer}>
                <div className={styles.historyHeader}>
                  <h5>Your booking history</h5>
                  <div className={styles.btnClose}>
                    <button type="button" onClick={() => handleHistoryOpen(false)}>
                      Close&nbsp;
                      <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                    </button>
                  </div>
                </div>

                <div className={styles.sessions}>
                  {bookings.map((dt, index) => (
                    <div key={index}>
                      <div className={styles.sessionInfoContainer}>
                        <div className={styles.left}>
                          <Img
                            src={`https://${dt?.expert?.image}` || USER_NOT_FOUND_IMAGE}
                            alt={`${dt?.expert?.firstName} ${dt?.expert?.lastName}`}
                            className={styles.expertImage}
                          />
                          <span>{`${dt?.expert?.firstName} ${dt?.expert?.lastName}`}</span>
                        </div>

                        <div className={styles.middle}>
                          <div className={styles.bookingContainer}>
                            <div className={styles.row}>
                              <div className={styles.cell}>
                                <Img src={CalendarIcon} alt="Calendar" className={styles.icon} />
                                <span>
                                  {formatDateToDateMonthYear(`${dt?.bookingDetails?.date}`)}
                                </span>
                              </div>
                              <div className={styles.cell}>
                                <Img src={RotateIcon} alt="Rotate" className={styles.icon} />
                                <span>
                                  {dt?.bookingDetails?.duration?.durationName || 0} Minutes
                                </span>
                              </div>
                            </div>
                            <div className={styles.row}>
                              <div className={styles.cell}>
                                <Img src={ClockIcon} alt="Clock" className={styles.icon} />
                                <span>{formatTimeToGMT(`${dt?.bookingDetails?.date}`)}</span>
                              </div>
                              <div className={styles.cell}>
                                <Img
                                  src={getIconForType(dt?.bookingDetails?.type?.typeName)}
                                  alt="Session type"
                                  className={styles.icon}
                                />
                                <span>{dt?.bookingDetails?.type?.typeName || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={styles.right}>
                          {dt.bookingDetails.status === ExpertsBookingStatus.SUCCESS ? (
                            <>
                              <Img
                                src={SuccessCheckIcon}
                                alt="Success Icon"
                                className={styles.icon}
                              />
                              <p className={styles.sessionCompleted}>Session completed</p>
                            </>
                          ) : dt.bookingDetails.status === ExpertsBookingStatus.CANCELLED ? (
                            <>
                              <Img
                                src={NotSuccessXIcon}
                                alt="Failure Icon"
                                className={styles.icon}
                              />
                              <p className={styles.sessionFailure}>Session cancelled</p>
                            </>
                          ) : dt.bookingDetails.status === PENDING_STATUS ? (
                            <>
                              <Img src={PendingIcon} alt="Pending Icon" className={styles.icon} />
                              <p className={styles.sessionPending}>Session pending</p>
                            </>
                          ) : (
                            <>
                              <Img
                                src={NotSuccessXIcon}
                                alt="Failure Icon"
                                className={styles.icon}
                              />
                              <p className={styles.sessionFailure}>Session failed</p>
                            </>
                          )}
                        </div>
                      </div>
                      {index < bookings.length - 1 && <hr className={styles.divider} />}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

ExpertSessionHistory.defaultProps = {
  isHistoryEnabled: false,
};

interface ExpertSessionInterface {
  isHistoryEnabled?: boolean;
  handleHistoryOpen: (arg: boolean) => void;
}

export default ExpertSessionHistory;
