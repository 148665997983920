import BorderedProgressBar from '@components/Atoms/BorderLinearProgressBar';
import Card from '@components/Atoms/Card';
import Checkbox from '@components/Atoms/Checkbox';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Skeleton } from '@mui/material';
import DefaultButton from '@components/Atoms/DefaultButton';
import { updateActionPlan } from '@api/actionPlan';
import { toast } from 'react-toastify';
import { UserImprovePlanActions } from 'custom.d';
import styles from './index.module.scss';

const ImprovePlan: FunctionComponent<ImprovePlanProps> = (props) => {
  const { className, dashboardActions, loading, refetch, userActiveImprovePlans } = props;
  const history = useHistory();
  const [checkboxes, setCheckboxes] = useState(
    dashboardActions.actions.map((item: { done: any }) => item.done),
  );
  const [actionMarkedAsCompleted, setActionMarkedAsCompleted] = useState({
    id: 0,
    completed: false,
  });

  useEffect(() => {
    setCheckboxes(dashboardActions.actions.map((item: { done: any }) => item.done));
  }, []);

  const handleCheckboxChange = (index: number, actionItem: any) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);

    if (actionItem?.details?.actionType !== 'USER_ACTION') {
      handleUpdateAction(
        {
          id: actionItem?.details?.actionId,
          actionType: actionItem?.details?.actionType,
          dueDate: null,
          done: true,
          removed: null,
          start: null,
        },
        'Action completed',
      );
    } else {
      toast.info('User action cannot be marked as completed');
    }
  };

  const handleUpdateAction = async (
    data: {
      id: number;
      actionType: string;
      dueDate: string | null;
      done: boolean | null;
      removed: boolean | null;
      start: boolean | null;
    },
    message?: string,
  ) => {
    try {
      const req = await updateActionPlan(data);
      if (req) {
        setActionMarkedAsCompleted({ id: data.id, completed: true });
      }

      setTimeout(() => {
        refetch();
        setActionMarkedAsCompleted({ id: 0, completed: false });
      }, 1500);

      toast.success(message);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message || `Error. Try again later`);
      } else {
        toast.error(`Error. Try again later`);
      }
    }
  };

  return (
    <Card className={className}>
      <div className={styles.bodyContainer}>
        <div className={styles.actionsContainer}>
          <div className={styles.topSection}>
            {loading ? <Skeleton variant="text" width={200} height={40} /> : <h5>Your plan</h5>}

            <div className={styles.plans}>
              {loading ? (
                <div className={styles.loadPlan}>
                  <Skeleton variant="rounded" width={60} height={40} className={styles.plan} />
                  <Skeleton variant="rounded" width={60} height={40} className={styles.plan} />
                </div>
              ) : (
                userActiveImprovePlans?.map((item: any) => (
                  <div
                    key={item?.id}
                    className={`${styles.planItem} ${styles[item?.question?.color]}`}
                  >
                    {item?.question?.questionText}
                  </div>
                ))
              )}
            </div>
          </div>

          <div className={styles.progressSection}>
            {loading ? (
              <>
                <Skeleton variant="rectangular" width="90%" height={40} className={styles.plan} />
                <Skeleton variant="text" width="10%" height={30} className={styles.loadSpan} />
              </>
            ) : (
              <>
                <BorderedProgressBar
                  value={dashboardActions?.progress}
                  className={styles.progressBar}
                  barColor="#505050"
                  backgroundColor="#ECE7DC"
                />
                <span>{dashboardActions?.progress}%</span>
              </>
            )}
          </div>

          <h5 className={styles.alreadyCompletedText}>
            {loading ? (
              <Skeleton variant="text" width="100%" height={30} className={styles.loadSpan} />
            ) : dashboardActions?.progress === 0 ? (
              `Let's get started by completing your first action 🚀 `
            ) : (
              `You already completed ${dashboardActions?.progress}% actions 😍 Great job, keep going!`
            )}
          </h5>

          <div className={styles.plansContent}>
            {loading ? (
              <div>
                {[0, 1, 2, 3].map((item, index) => (
                  <div key={index} className={styles.loadPlanItems}>
                    <Skeleton
                      variant="circular"
                      width={40}
                      height={40}
                      className={styles.loadSpan}
                    />
                    <Skeleton variant="text" width="70%" height={30} className={styles.loadSpan} />
                    <Skeleton variant="text" width="20%" height={30} className={styles.loadSpan} />
                  </div>
                ))}
              </div>
            ) : (
              <>
                {dashboardActions?.progress === 100 ? (
                  <div className={styles.completedPlansSection}>
                    <div className={styles.body}>
                      <div className={styles.confetti}>
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                        <div className={styles.confettiPiece} />
                      </div>

                      <div className={styles.texts}>
                        <p>
                          Congratulations!
                          <br />
                          You&apos;ve really nailed your action plans. Take a moment to savor this
                          awesome achievement and think about all the hard work you&apos;ve put into
                          it.
                        </p>

                        <div className={styles.button}>
                          <DefaultButton
                            variant="primary"
                            onClick={() => {
                              if (dashboardActions.plans?.length > 1) {
                                history.push('/improve-plans');
                              } else {
                                history.push(
                                  `/improve-plans/?improveAreaId=${dashboardActions.plans[0].id}&improvePlan=${dashboardActions.plans[0]?.improvePlan}`,
                                );
                              }
                            }}
                          >
                            {dashboardActions?.plans?.length > 0
                              ? 'Mark plans as complete'
                              : 'Mark plan as complete'}
                          </DefaultButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {dashboardActions.actions.map((item: any, index: number) => (
                      <div key={index} className={`${styles.planItem} `}>
                        {actionMarkedAsCompleted.completed &&
                        actionMarkedAsCompleted.id === item?.details?.actionId ? (
                          <div className={styles.checkBoxWrapper}>
                            {' '}
                            <svg
                              className={styles.checkmark}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 52 52"
                            >
                              {' '}
                              <circle
                                className={styles.checkmarkCircle}
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                              />{' '}
                              <path
                                className={styles.checkmarkCheck}
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div
                            className={`${styles.checkBoxContainer} ${styles[item.questionColor]}`}
                          >
                            <Checkbox
                              id={`checkbox-${index}`}
                              name=""
                              onChange={() => handleCheckboxChange(index, item)}
                              checked={checkboxes[item.done]}
                              className={styles.checkbox}
                            />
                          </div>
                        )}

                        <div className={styles.planWithOverdue}>
                          <span
                            className={`${styles.improvePlanQuestionName} ${
                              !item?.details?.actionUrl && styles.urlNotEnabled
                            }`}
                            onClick={() => {
                              if (item?.details?.actionUrl) {
                                window.location.href = item?.details?.actionUrl;
                              }
                            }}
                          >
                            {item?.details?.actionTitle}
                          </span>
                          {item?.dueDate && (
                            <span
                              className={`${styles.overdueSection} ${
                                item.improvePlanActionDueDays < 0 ? styles.overdue : styles.ontime
                              }`}
                            >
                              {item.improvePlanActionDueDays < 0
                                ? `${Math.abs(item.improvePlanActionDueDays)} days overdue`
                                : `${item.improvePlanActionDueDays} days to go`}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}{' '}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div>
          {!loading && dashboardActions?.progress !== 100 && (
            <div className={styles.viewAllBtn} onClick={() => history.push('/improve-plans')}>
              view all
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

ImprovePlan.displayName = 'ImprovePlan';

ImprovePlan.defaultProps = {
  className: styles.improveContainer,
};

interface ImprovePlanProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  dashboardActions: { progress: number; actions: any; plans: any };
  loading: boolean;
  refetch: () => void;
  userActiveImprovePlans: UserImprovePlanActions[];
}

export default ImprovePlan;
