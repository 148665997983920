import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const Input: FunctionComponent<InputProps> = (props) => {
  const { isError, className, ...rest } = props;

  return <input className={classNames([className, isError && styles.error])} {...rest} />;
};

Input.displayName = 'Input';

Input.defaultProps = {
  isError: false,
  className: styles.input,
};

interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  isError?: boolean;
  className?: string;
}

export default Input;
