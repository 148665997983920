import React, { FormEvent, useState } from 'react';
import MainLayout from '@layouts/MainLayout';
import Button from '@components/Button';
import { sendContactUs } from '@api/contactUs';
import row1ShapesImage from '@assets/images/whatIsHapstar/Row1.svg';
import useWindowDimensions from '@hooks/useWindowDimensions';
import styles from './index.module.scss';

const FeedbackAndSuggestions: React.FunctionComponent = () => {
  const [name, setName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [comment, setComment] = useState<string>('');
  const [sent, setSent] = useState(false);
  const { isMobile } = useWindowDimensions();

  const nameInput = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const companyInput = (e: FormEvent<HTMLInputElement>) => {
    setCompany(e.currentTarget.value);
  };

  const subjectInput = (e: FormEvent<HTMLSelectElement>) => {
    setSubject(e.currentTarget.value);
  };

  const commentInput = (e: FormEvent<HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
  };

  const handleContactUs = () => {
    sendContactUs({
      name,
      company,
      subject,
      comment,
    });

    setSent(true);
  };

  const isButtonsDisabled = () => {
    let isDisabled = true;

    if (name && company && subject && comment) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <MainLayout title="Feedback and Suggestions" secondaryNavEnabled>
      {sent && (
        <div>
          <h2 style={{ marginBottom: 40 }}>Message sent successfully</h2>

          <p>We will get back to you within 24 hours.</p>
        </div>
      )}

      {!sent && (
        <div style={{ margin: isMobile ? '0 30px' : undefined }}>
          <div className={styles.rowOneImage}>
            <img src={row1ShapesImage} alt="" style={{ height: isMobile ? 300 : undefined }} />
          </div>
          <div>
            <h1>Contact Us</h1>
            <p style={{ marginTop: '26px' }}>
              <p style={{ fontWeight: '600', color: '#505050', width: isMobile ? 160 : undefined }}>
                We’d love to hear from you, and are here to help if you have any issues.
              </p>
            </p>
            <br />
            <p style={{ color: '#505050', width: isMobile ? 250 : undefined }}>
              Fill out the boxes below and we will get back to you within 24 hours
            </p>
            <br />
            <div className={styles.hsContactUsPage}>
              <div
                className={styles.hsContactUsContainer}
                style={{
                  width: isMobile ? '100%' : undefined,
                  padding: isMobile ? '10px 20px 70px 20px' : undefined,
                  marginBottom: '120px',
                }}
              >
                <div>
                  <form>
                    <div>
                      <label>
                        Name
                        <input
                          name="name"
                          type="text"
                          placeholder="Fill name here..."
                          onChange={nameInput}
                          value={name}
                          maxLength={50}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        Organisation
                        <input
                          name="company"
                          type="text"
                          placeholder="Add your organisation name here..."
                          onChange={companyInput}
                          value={company}
                          maxLength={50}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        Subject
                        <select onChange={subjectInput} value={subject}>
                          <option value="" disabled selected>
                            Select the reason for contacting us...
                          </option>
                          <option value="contact_us">Contact Us</option>
                          <option value="feedback">Feedback</option>
                          <option value="technical_support">Technical Support</option>
                          <option value="delete_personal_data">Delete Personal Data</option>
                        </select>
                      </label>
                    </div>
                    <div>
                      <label>
                        Comment
                        <textarea
                          placeholder="Write your comment..."
                          onChange={commentInput}
                          value={comment}
                          rows={10}
                          maxLength={500}
                        />
                      </label>
                      <p style={{ textAlign: 'end', color: '#505050' }}>
                        {comment.length}/500 characters
                      </p>
                      <Button
                        type="primary"
                        isButton
                        onClick={handleContactUs}
                        isDisabled={isButtonsDisabled()}
                      >
                        Send
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default FeedbackAndSuggestions;
