import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  setUser(newUser) {
    this.user = newUser;
  },
});

const AuthContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;
  const [user, setUser] = useState<any>(null);
  const value = useMemo(() => ({ user, setUser }), [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Add when confirmed from API response
interface AuthContextInterface {
  user: any;
  setUser: (a: any) => void;
}

export default AuthContextProvider;
