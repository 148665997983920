import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Registration from '@components/Templates/Registration';
import { getCompanyDetailsByUrl } from '@api/auth/company';
import { Parser } from 'html-to-react';

const RegistrationPage: React.FunctionComponent = () => {
  const { companyURL } = useParams<UrlParamsType>();
  const [companyDetails, setCompanyDetails] = useState<any>(null);
  let content = (
    <>
      <p>Unlock the power of Happiness with Hapstar</p>
    </>
  );
  let organizationLogo;
  let organizationAltText;
  let organizationId;

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetailsByUrl(companyURL);
      setCompanyDetails(data);
    };

    if (companyURL) {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null) {
    content = Parser().parse(companyDetails.sign_up_text);
    organizationLogo = `${process.env.PUBLIC_URL}/images/logos/${companyDetails.company_logo}`;
    organizationAltText = `${companyDetails.company_name} logo`;
    organizationId = companyDetails.id;
  }

  return (
    <Registration
      content={content}
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      organizationId={organizationId}
      companyURL={companyURL}
    />
  );
};

type UrlParamsType = {
  companyURL: string;
};

export default RegistrationPage;
