import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import ViewCommentModal from '@components/ModalContent/ViewComment';
import { newFormatDate } from '@utils/date.util';
import { truncateString } from '@utils/string.util';
import styles from './ResponsesActionCard.module.scss';

const ResponsesActionCard: React.FunctionComponent<ActionsCardProps> = (props) => {
  const {
    focusArea,
    focusAreaColor,
    questionnaireName,
    action,
    score,
    completed,
    createdDate,
    addToCompleted,
    categoryName,
  } = props;
  const [actionsCheckbox, setActionCheckbox] = useState(false);
  const [openViewCommentsModal, setOpenViewCommentsModal] = useState(false);

  const styleClasses = classNames([
    styles.hsActionsCard,
    focusAreaColor === '#BEAAF3' && styles.calmLilac,
    focusAreaColor === '#019DF2' && styles.skyBlue,
    focusAreaColor === '#FCD300' && styles.sunYellow,
    focusAreaColor === '#8EEA36' && styles.zingyGreen,
    focusAreaColor === '#06BF97' && styles.aquaGreen,
    focusAreaColor === '#FF8A4A' && styles.warmOrange,
  ]);

  const markAsDone = () => {
    setActionCheckbox(true);
    addToCompleted();
  };

  const isModalView = action.length >= 75;

  const handleOpenViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(true);
  }, [action]);

  const handleCloseViewCommentModal = useCallback(() => {
    setOpenViewCommentsModal(false);
  }, []);

  return (
    <div className={styleClasses}>
      <div className={styles.hsActionsCardTitle}>
        <div className={styles.dateContainer}>
          <p className={styles.formattedDate}>{newFormatDate(createdDate)}</p>
          <p className={styles.focusArea}>{focusArea}</p>
        </div>

        <p className={styles.categorySection}>
          <p>{categoryName}</p>
          <span>{score}</span>
        </p>
      </div>

      <div className={styles.hsActionsCardTitle}>
        <p className={styles.truncateSection}>
          {truncateString(action, 75)}
          {isModalView && (
            <a className={styles.viewMore} onClick={handleOpenViewCommentModal}>
              View More
            </a>
          )}
        </p>
      </div>

      <ViewCommentModal
        title={focusArea}
        isOpen={openViewCommentsModal}
        onClose={handleCloseViewCommentModal}
      >
        {action}
      </ViewCommentModal>
    </div>
  );
};

type ActionsCardProps = {
  focusArea: string;
  focusAreaColor: any;
  action: string;
  questionnaireName: string;
  score: number;
  completed: boolean;
  createdDate: string;
  addToCompleted: () => void;
  categoryName: string;
};

export default ResponsesActionCard;
