import React, { FunctionComponent } from 'react';
import Input from '@components/Atoms/Input';
import classNames from 'classnames';
import { LabelPositions } from '@enums/LabelPositions';
import Tooltip from '@components/Tooltip';
import styles from './index.module.scss';

const LabelInput: FunctionComponent<LabelInputProps> = (props) => {
  const {
    id,
    name,
    label,
    type,
    value,
    className,
    isError,
    placeholder,
    labelPosition,
    onChange,
    onBlur,
    tip,
  } = props;

  const inputClasses = classNames([
    className,
    labelPosition === LabelPositions.top && styles.positionTop,
    labelPosition === LabelPositions.left && styles.positionLeft,
  ]);

  return (
    <div className={inputClasses}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.inputBlock}>
        {tip && tip.value && (
          <div className={styles.tooltipContainer}>
            <p>{tip.label}</p>
            <Tooltip tip={tip.value} />
          </div>
        )}
        <Input
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          isError={isError}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

LabelInput.displayName = 'LabelInput';

LabelInput.defaultProps = {
  value: undefined,
  className: styles.inputContainer,
  isError: false,
  placeholder: '',
  labelPosition: LabelPositions.top,
  onBlur: undefined,
  tip: undefined,
};

interface LabelInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string;
  name: string;
  label: string;
  type: string;
  onChange: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: any;
  className?: string;
  isError?: boolean;
  placeholder?: string;
  labelPosition?: LabelPositions.top | LabelPositions.left;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void | undefined;
  tip?: { value: string; label?: string };
}

export default LabelInput;
