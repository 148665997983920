import React, { createContext, PropsWithChildren, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from '@hooks/useQuery';

export const SecondaryNavigationContext = createContext<SecondaryNavContextInterface>({
  activeSubNav: { id: 1, name: 'Overall' },
  toggleSubNav(subNav: { id: number; name: string } | any) {
    sessionStorage.setItem('subNav', JSON.stringify(subNav));
    this.activeSubNav = subNav;
  },
});

const SecondaryNavigationContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;
  const query = useQuery();

  const defaultSubNav = { id: 1, name: 'Overall' };
  const tabId = query.get('tabId');
  const tabName = query.get('tabName');

  const history = useHistory();
  const [activeSubNav, setActiveSubNav] = useState(() =>
    tabId
      ? { id: parseInt(tabId, 10), name: tabName }
      : JSON.parse(sessionStorage.getItem('subNav') as any) || defaultSubNav,
  );

  const toggleSubNav = (subNav: { id: number; name: string } | any) => {
    const params = { tabId: subNav.id.toString(), tabName: subNav.name };
    const urlParams = new URLSearchParams(params).toString();
    const currentUrl = history.location.pathname;

    // Set the subNav selection in the session storage
    sessionStorage.setItem('subNav', JSON.stringify(subNav));

    // Add query params to the URL
    history.replace(`${currentUrl}?${urlParams}`);
  };

  const value = useMemo(() => ({ activeSubNav, toggleSubNav } as any), [activeSubNav]);

  // Update the active tab whenever values change
  useEffect(() => {
    if (tabId && tabName) {
      setActiveSubNav({ id: parseInt(tabId, 10), name: tabName });
    }
  }, [tabId, tabName]);

  return (
    <SecondaryNavigationContext.Provider value={value}>
      {children}
    </SecondaryNavigationContext.Provider>
  );
};

interface SecondaryNavContextInterface {
  activeSubNav: { id: number; name: string };
  toggleSubNav: (subNav: { id: number; name: string }) => void;
}

export default SecondaryNavigationContextProvider;
