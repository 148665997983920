import React from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import { RootState } from 'store';
import HelpSpeakup from '@components/Organisms/HelpAndSpeakup';

const HelpAndSpeakup: React.FunctionComponent<HelpAndSpeakupInterface> = ({
  assesmentDataForHelp,
  handleAssessmentData,
  previewInstantHelp,
  handleInstantPreview,
  skipStarStep,
  handleSkipButton,
  handleClose,
  isShortStarModal,
}) => {
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);
  const { showSpeakUp } = companyConfig;

  return (
    <>
      <HelpSpeakup
        speakUpVisible={showSpeakUp}
        assesmentDataForHelp={assesmentDataForHelp}
        handleAssessmentData={handleAssessmentData}
        previewInstantHelp={previewInstantHelp}
        handleInstantPreview={handleInstantPreview}
        skipStarStep={skipStarStep}
        handleSkipButton={handleSkipButton}
        handleClose={handleClose}
        isShortStarModal={isShortStarModal}
      />
    </>
  );
};

HelpAndSpeakup.defaultProps = {
  isShortStarModal: false,
  handleClose: () => null,
};

interface HelpAndSpeakupInterface {
  assesmentDataForHelp: { assessmentId: number; questionnaireId: number };
  handleAssessmentData: (data: { assessmentId: number; questionnaireId: number }) => void;
  previewInstantHelp: boolean;
  handleInstantPreview: (arg0: boolean) => void;
  skipStarStep: (arg0: number) => void;
  handleSkipButton: (arg0: string) => void;
  handleClose?: () => void;
  isShortStarModal?: boolean;
}

export default HelpAndSpeakup;
