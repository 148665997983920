import { useEffect, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isDesktop = windowDimensions.width > 992;
  const isTablet = windowDimensions.width >= 768 && windowDimensions.width < 992;
  const isMobile = windowDimensions.width < 768;

  return {
    isDesktop,
    isTablet,
    isMobile,
    ...windowDimensions,
  };
}
