import React, { useState } from 'react';
import EChartsReact from 'echarts-for-react';
import { WingDetails } from '@components/Star';
import useWindowDimensions from '@hooks/useWindowDimensions';
import dayjs from 'dayjs';
import styles from './index.module.scss';

const EmployerArea: React.FunctionComponent<AreaProps> = (props) => {
  const { values, stars, selectedIndex, selectedTimeframeKey, months, selectedTabs, queColors } =
    props;
  const { isDesktop } = useWindowDimensions();

  const modifiedValues =
    values?.[0]?.data.map((i: any) => ({
      score: i.value,
      assessmentId: i.assessment_id,
      date: dayjs(i.date).format('DD/MM/YYYY'),
    })) ?? [];

  const maxValue = isDesktop ? 10 : 5;
  let modifiedStars = [...stars];
  modifiedStars = new Array(maxValue - stars.length).fill([]);
  modifiedStars.push(...stars);
  modifiedStars.push([]);

  const labelData = values?.[0]?.data.map((i: any) =>
    months > 1 ? dayjs(i.date).format('MMM') : dayjs(i.date).format('DD MMM'),
  );

  const series = Object.values(values).map((n: any) =>
    n.data
      .map((i: any) => ({
        score: i.value,
        assessmentId: i.assessment_id,
        date: dayjs(i.date).format('DD/MM/YYYY'),
        totalResponses: i.totalResponses,
      }))
      .map((value: any, index: any) => {
        if (value.score === 0 && value.totalResponses === 0) {
          return {
            color: queColors.find((code) => code.qId === n.id)?.color,
            questionId: n.id,
            value: null,
            assessmentId: value.assessmentId,
            index,
            date: value.date,
            label: { show: false },
            symbol: 'none',
            symbolSize: 0,
            totalResponses: value.totalResponses || 0,
            questionText: queColors.find((code) => code.qId === n.id)?.qName,
          };
        }

        return {
          color: queColors.find((code) => code.qId === n.id)?.color,
          questionId: n.id,
          value: value.score.toFixed(0),
          decimalScore: value.score.toFixed(0),
          assessmentId: value.assessmentId,
          index,
          date: value.date,
          label: { show: true },
          symbolSize: 40,
          totalResponses: value.totalResponses,
          questionText: queColors.find((code) => code.qId === n.id)?.qName,
        };
      }),
  );

  const richData: RichDataObject = {
    value: {
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 30,
      align: 'center',
    },
    selected: {
      height: 30,
    },
  };

  const series99 = queColors.map((color, index) => ({
    data: series.flatMap((dt) =>
      dt.filter((entry: { questionId: number }) => entry.questionId === color.qId),
    ),
    type: 'line',
    symbolSize: 44,
    connectNulls: true,
    label: {
      show: true,
      position: 'inside',
      triggerEvent: true,
      fontSize: 16,
      fontFamily: 'Titillium Web',
      color: selectedTabs.includes(index) ? color.color : undefined,
    },
    itemStyle: {
      color: color.color,
      opacity: 1,
    },
    lineStyle: {
      color: color.color,
      opacity: 1,
    },
  }));

  const option = {
    grid: { right: 40, left: 40 },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'axis' },
      formatter: (params: any) => {
        const getResponse = (index: number) => {
          const data = params[index]?.data || {};

          return `<strong>${data.questionText || ''}: ${data.decimalScore || 0}</strong>`;
        };

        const responses = params.map((_: any, index: number) => getResponse(index)).join('<br />');

        return `
          Date: ${params[0].data.date}<br />
          <strong>Check-ins: ${params[0]?.data?.totalResponses || 0}</strong><br />
          ${responses}
        `;
      },
    },

    xAxis: {
      type: 'category',
      boundaryGap: false,
      triggerEvent: true,
      data: labelData,
      axisPointer: {
        show: true,
        type: 'shadow',
        triggerEvent: false,
        trigger: 'item',
      },
      axisLabel: {
        triggerEvent: true,
        trigger: 'axis',
        interval: 0,
        formatter: (value: string) => [`{selected|}`, `${value}`].join('\n'),
        fontWeight: 'bold',
        rich: richData,
      },
    },
    yAxis: {
      type: 'value',
      interval: 10,
      min: -100,
      max: 100,
      triggerEvent: true,
      trigger: 'axis',
      axisLine: {
        show: true,
      },
      splitLine: {
        show: true,
      },
    },
    series: series99,
  };

  return (
    <div className={styles.hsAreaChart}>
      <EChartsReact
        option={option}
        style={{
          width: '100%',
          height: '550px',
        }}
      />
    </div>
  );
};

interface RichDataObject {
  [key: string]: string | number | object;
}

EmployerArea.defaultProps = {};

interface AreaProps {
  values: any[];
  stars: WingDetails[][];
  selectedIndex: number;
  months: any;
  selectedTimeframeKey: any;
  selectedTabs: any;
  queColors: { qId: number; qName: string; color: string }[];
}

export default EmployerArea;
