import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserImprovePlanActions } from 'custom';

interface QuestionnaireResponseInterface {
  assessment_id: number;
  questionnaire_id: number;
  user_id: number;
}

interface GeneralHappinessQuestionInterface {
  imagePath: string;
  score: number;
  questionId: number | undefined;
  questionText: string | undefined;
}

interface SliderValues {
  [key: number]: {
    questionId: number;
    questionText: string;
    score: number;
  };
}

interface QuestionsScores {
  questionId: number;
  questionInfo?: string;
  questionText: string;
  colorTheme: string;
  generalHappinessQuestion?: boolean;
  icon: string;
  questionnaireId?: number;
  score: number;
}

interface WizardState {
  questionnaireResponse: QuestionnaireResponseInterface | null;
  wizardContent: string;
  generalHappinessQuestion: GeneralHappinessQuestionInterface | null;
  sliderStates: SliderValues;
  questionsScores: QuestionsScores[] | [];
  selectedImprovementArea: QuestionsScores | null;
  isImproveAreaAlreadySelected: boolean;
  actionPlans: UserImprovePlanActions[] | [];
}

const initialState: WizardState = {
  questionnaireResponse: null,
  wizardContent: 'GENERAL_HAPPINESS_QUESTION_SECTION',
  generalHappinessQuestion: null,
  sliderStates: {},
  questionsScores: [],
  selectedImprovementArea: null,
  isImproveAreaAlreadySelected: false,
  actionPlans: [],
};

const shortStarWizardSlice = createSlice({
  name: 'shortStarWizard',
  initialState,
  reducers: {
    updateWizardContent: (state, action: PayloadAction<string>) => ({
      ...state,
      wizardContent: action.payload,
    }),
    addQuestionnaireResponse: (state, action: PayloadAction<QuestionnaireResponseInterface>) => ({
      ...state,
      questionnaireResponse: action.payload,
    }),
    storeGeneralHappinessQuestion: (
      state,
      action: PayloadAction<GeneralHappinessQuestionInterface>,
    ) => ({
      ...state,
      generalHappinessQuestion: action.payload,
    }),
    storeHappinessSliderValues: (state, action: PayloadAction<SliderValues>) => ({
      ...state,
      sliderStates: action.payload,
    }),
    addQuestionsScores: (state, action: PayloadAction<QuestionsScores[]>) => ({
      ...state,
      questionsScores: action.payload,
    }),
    addImprovementArea: (state, action: PayloadAction<QuestionsScores>) => ({
      ...state,
      selectedImprovementArea: action.payload,
    }),
    updateImrpoveSectionSelected: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isImproveAreaAlreadySelected: action.payload,
    }),
    addActionPlans: (state, action: PayloadAction<UserImprovePlanActions[]>) => ({
      ...state,
      actionPlans: action.payload,
    }),
    resetGeneralHappinessQuestion: (state) => ({
      ...state,
      generalHappinessQuestion: null,
    }),
    resetWizard: () => initialState,
  },
});

export const shortStarWizardActions = shortStarWizardSlice.actions;

export default shortStarWizardSlice;
