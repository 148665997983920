import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const createLog = async (req: {
  type: string;
  ipAddress: string | undefined;
  os: string | undefined;
  isNative: boolean | undefined;
}) => {
  const formattedReq = {
    type: req.type,
    ipAddress: req.ipAddress,
    os: req.os,
    isNative: req.isNative,
  };
  const response = await fetch(`${process.env.REACT_APP_ACTION_PLAN_API_URL}/create-log`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  const result = await response.json();

  return result;
};
