import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  focusAreas: [],
  actions: [],
  allActions: [],
  thougts: [],
  diffCompareData: [],
  graphData: {
    assesmentId: 0,
    date: '',
    index: 0,
  },
  currentTab: {
    id: null,
    name: '',
  },
  historicalData: [],
  isUserOnBoarded: true,
  wizzardStep: localStorage.getItem('wizzardStep') || 0,
  actionsCompleted: false,
};

export const ADD_ACTIONS = 'ADD_ACTIONS';
export const SET_ACTIONS = 'SET_ACTIONS';
export const COMPLETE_ACTIONS = 'COMPLETE_ACTIONS';

export const SET_ALL_ACTIONS = 'SET_ALL_ACTIONS';
export const COMPLETE_ALL_ACTIONS = 'COMPLETE_ALL_ACTIONS';

export const ADD_THOUGTHS = 'ADD_THOUGTHS';
export const SET_THOUGTHS = 'SET_THOUGTHS';

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

export const ADD_FOCUS_AREAS = 'ADD_FOCUS_AREAS';
export const SET_FOCUS_AREAS = 'SET_FOCUS_AREAS';

export const COMPARE_DATA = 'COMPARE_DATA';

export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';
export const SET_GRAPH_INDEX = 'SET_GRAPH_INDEX';
export const SET_WIZZARD_STEP = 'SET_WIZZARD_STEP';

export const SET_HISTORICAL_DATA = 'SET_HISTORICAL_DATA';

export const SET_IS_USER_ONBOARDING = 'SET_IS_USER_ONBOARDING';

export const SET_ACTIONS_COMPLETED = 'SET_ACTIONS_COMPLETED';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ADD_ACTIONS:
      return {
        ...state,
        actions: [...state.actions, action.payload],
      };
    case SET_ACTIONS:
      return {
        ...state,
        actions: action.payload,
      };
    case SET_ALL_ACTIONS:
      return {
        ...state,
        allActions: action.payload,
      };
    case COMPLETE_ACTIONS:
      return {
        ...state,
        actions: state.actions.map((i: any) =>
          i.id === action.payload ? { ...i, complete: !i.complete } : i,
        ),
      };
    case COMPLETE_ALL_ACTIONS:
      return {
        ...state,
        allActions: state.allActions.map((i: any) =>
          i.id === action.payload ? { ...i, complete: !i.complete } : i,
        ),
      };
    case ADD_THOUGTHS:
      return {
        ...state,
        thougths: [...state.thougts, action.payload],
      };
    case SET_THOUGTHS:
      return {
        ...state,
        thougths: action.payload,
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case ADD_FOCUS_AREAS:
      return {
        ...state,
        focusAreas: [...state.focusAreas, action.payload],
      };
    case SET_FOCUS_AREAS:
      return {
        ...state,
        focusAreas: action.payload,
      };
    case COMPARE_DATA:
      return {
        ...state,
        diffCompareData: action.payload,
      };
    case SET_GRAPH_DATA:
      return {
        ...state,
        graphData: action.payload,
      };
    case SET_WIZZARD_STEP:
      localStorage.setItem('wizzardStep', action.payload);

      return {
        ...state,
        wizzardStep: action.payload,
      };
    case SET_HISTORICAL_DATA:
      return {
        ...state,
        historicalData: action.payload,
      };
    case SET_IS_USER_ONBOARDING:
      return {
        ...state,
        isUserOnBoarded: action.payload,
      };
    case SET_ACTIONS_COMPLETED:
      return {
        ...state,
        actionsCompleted: action.payload,
      };
    default:
      return state;
  }
};

const useValue = () => useReducer(reducer, initialState);

const Context = createContext(null);

export const useGlobalState = (): any => {
  const value = useContext(Context);
  if (value === null) throw new Error('Please add GlobalStateProvider');

  return value;
};

export const GlobalStateProvider = ({ children }: any) => (
  <Context.Provider value={useValue() as any}>{children}</Context.Provider>
);
