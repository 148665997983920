import React from 'react';
import { ToastContainer } from 'react-toastify';
import Seo from '@components/SEO';
import useGoNative from '@hooks/useGoNative';
import classNames from 'classnames';
import { TwitterCardType } from 'custom.d';
import Header from './Header';
import Footer from './Footer';
import styles from './index.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const AuthenticationLayout: React.FunctionComponent<AuthenticationLayoutProps> = (props) => {
  const { children, title, description, name, type, organizationLogo, organizationAltText } = props;
  const isMobileApp = useGoNative();

  return (
    <div className={classNames([styles.hsAuthLayout, isMobileApp && styles.hsFooterMobile])}>
      <Seo title={title} description={description} name={name} type={type} />
      <ToastContainer hideProgressBar draggable={false} theme="dark" />
      <Header organizationLogo={organizationLogo} organizationAltText={organizationAltText} />
      <div className={styles.hsAuthLayoutContent}>{children}</div>
      <Footer />
    </div>
  );
};

AuthenticationLayout.defaultProps = {
  description: '',
  name: '',
  type: 'summary',
  organizationLogo: undefined,
  organizationAltText: undefined,
};

interface AuthenticationLayoutProps {
  children: React.ReactNode | undefined;
  title: string;
  description?: string;
  name?: string;
  type?: TwitterCardType;
  organizationAltText?: string;
  organizationLogo?: string | undefined;
}

export default AuthenticationLayout;
