import reviewIcon from '@assets/images/review-Icons.svg';
import Radar from '@components/Charts/Radar';
import CircleScoreCard from '@components/CircleScoreCard';
import ScoreCheckbox from '@components/ScoreCheckbox';
import ScoreFilter from '@components/ScoreFilter';
import { CreateStarContext } from '@context/CreateStarContext';
import { COMPARE_DATA, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CreateStarCategoryInterface } from '@interfaces/index';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { RootState } from 'store';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const NewScorePageContent: React.FunctionComponent<ScoresPageContentProps> = (props) => {
  const { isEditable, data, showButton } = props;
  const { setCategoryQuestion } = useContext(CreateStarContext);
  const [currentData, setCurrentData] = useState<CreateStarCategoryInterface[]>(data);
  const { isMobile } = useWindowDimensions();
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [state, dispatch] = useGlobalState();
  const [diffData, setDiffData] = useState<any>([]);
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);
  const reduxDispatch = useAppDispatch();

  useEffect(() => {
    setCurrentData(data);
  }, [data, activeSubNav.id, state.diffCompareData]);

  useEffect(() => {
    const newData = currentData.map((category) => ({
      ...category,
      questions: category.questions.map((question) => ({ ...question })),
    }));

    function updateNewData() {
      const planQuestionIds = reduxStarScores?.planFocusAreas
        .filter((dt: { active: boolean }) => dt.active)
        .map((area: { questionId: number }) => area.questionId);

      newData.forEach((dt) => {
        const isSelected = dt.questions.some((question) => planQuestionIds.includes(question.id));

        if (isSelected) {
          dt.questions.forEach((question) => {
            if (planQuestionIds.includes(question.id)) {
              // eslint-disable-next-line no-param-reassign
              question.isSelected = true;
            }
          });
        }
      });

      const selectedQuestions = newData.flatMap((dt) =>
        dt.questions.filter((question) => question.isSelected),
      );

      return selectedQuestions;
    }

    const updateSelectedQuestions = () => {
      newData.forEach((dt) => {
        dt.questions.forEach((question) => {
          if (
            reduxStarScores.planFocusAreas.some(
              (area: { questionId: number }) => area.questionId === question.id,
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            question = { ...question, isSelected: true };
          }
        });
      });

      // Return newData with only isSelected true questions
      return newData.map((dt) => ({
        ...dt,
        questions: dt.questions,
      }));
    };

    setCategoryQuestion(updateSelectedQuestions());
    setCurrentData(updateSelectedQuestions());

    reduxDispatch(starScoresSliceActions.setFocusAreas(updateNewData()));
  }, [reduxStarScores.planFocusAreas]);

  const handleSelectFocusAreas = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const [categoryId, questionId] = e.currentTarget.id.split('-');
    const newData = currentData.map((category) => ({
      ...category,
      questions: category.questions.map((question) => ({ ...question })),
    }));
    let selectedFocusAreas = 0;
    newData.forEach((category) => {
      selectedFocusAreas += category.questions.filter((qs) => qs.isSelected).length;
    });
    if (selectedFocusAreas < 3 || !checked) {
      const currentCategory = newData.find((category) => category.id === Number(categoryId));
      const currentQuestion = currentCategory?.questions.find(
        (question) => question.id === Number(questionId),
      );

      if (currentQuestion) {
        currentQuestion.isSelected = checked;
      }

      const selectedQuestions = newData.flatMap((dt) =>
        dt.questions.filter((qu) => qu?.isSelected),
      );
      reduxDispatch(starScoresSliceActions.setFocusAreas(selectedQuestions));

      setCategoryQuestion(newData);
      setCurrentData(newData);
    }
  };

  useEffect(() => () => dispatch({ type: COMPARE_DATA, payload: [] }), []);

  useEffect(() => {
    if (state?.diffCompareData?.oneByOneCompare?.length < 22) {
      setDiffData([
        state?.diffCompareData?.oneByOneCompare?.slice(0, 1),
        state?.diffCompareData?.oneByOneCompare?.slice(1, 5),
        state?.diffCompareData?.oneByOneCompare?.slice(5, 9),
        state?.diffCompareData?.oneByOneCompare?.slice(9, 13),
        state?.diffCompareData?.oneByOneCompare?.slice(13, 17),
        state?.diffCompareData?.oneByOneCompare?.slice(17, 21),
      ]);
    } else {
      setDiffData([
        state?.diffCompareData?.oneByOneCompare?.slice(0, 4),
        state?.diffCompareData?.oneByOneCompare?.slice(4, 8),
        state?.diffCompareData?.oneByOneCompare?.slice(8, 12),
        state?.diffCompareData?.oneByOneCompare?.slice(12, 16),
        state?.diffCompareData?.oneByOneCompare?.slice(16, 20),
        state?.diffCompareData?.oneByOneCompare?.slice(20, 24),
      ]);
    }
  }, [state?.diffCompareData?.oneByOneCompare]);

  return (
    <div>
      <div className="container-fluid">
        {isEditable && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              position: 'relative',
              marginBottom: 80,
            }}
          >
            <div>
              <h2
                style={{
                  fontSize: '40px',
                  fontWeight: '600',
                  color: '#505050',
                  marginBottom: '28px',
                }}
              >
                Select your focus areas
              </h2>
              {reduxStarScores.currentStep === 'NO_ACTIVE_PLANS' ? (
                <div className={styles.topTextSection}>
                  <p>
                    Great job on finishing the scoring! Now, let&apos;s take a deeper dive into your
                    results.
                  </p>
                  <p>Reflect on your scores and think about where you really want to see change.</p>
                  <p>
                    Choose up to three areas that stand out to you, and we&apos;ll create a plan
                    that&apos;s all yours.
                  </p>
                  <p>
                    It&apos;s all about taking those insights and turning them into action, so you
                    can grow and thrive in the ways that matter most to you.
                  </p>
                </div>
              ) : reduxStarScores.currentStep === 'HAS_PLANS_NO_FOCUS_AREAS' ? (
                <div className={styles.topTextSection}>
                  <p>Great job on finishing the scoring!</p>
                  <p>
                    Reflect on your scores and think about where you really want to see change.{' '}
                  </p>
                  <p>
                    Choose up to three areas that stand out to you, and we&apos;ll add them to your
                    plan.
                  </p>
                  <p>
                    As you know, it&apos;s all about taking those insights and turning them into
                    action, so you can grow and thrive in the ways that matter most to you.
                  </p>
                  <p>If you don’t want to add any actions you can skip this stage.</p>
                </div>
              ) : reduxStarScores.currentStep === 'HAS_BOTH_PLANS_AND_FOCUS_AREAS' ? (
                <div className={styles.topTextSection}>
                  <p>Well done for the deep dive!</p>
                  <p>Your current focus areas are already highlighted below.</p>
                  <p>
                    Check out your scores and feel free to change your focus areas, or leave them as
                    they are and continue.
                  </p>
                </div>
              ) : (
                <div className={styles.topTextSection}>
                  <p style={{ fontSize: '18px', color: '#505050' }}>Scoring is only the start!</p>
                  <p style={{ width: isMobile ? '100%' : 849, color: '#505050', fontSize: '18px' }}>
                    Check out your scores and select up to 5 focus areas to take action on.
                  </p>
                </div>
              )}
            </div>
            {!isMobile && (
              <img
                src={reviewIcon}
                alt="review icons"
                style={{
                  position: 'absolute',
                  top: -60,
                  right: 0,
                  zIndex: 999,
                }}
              />
            )}
          </div>
        )}
        <div className="col-12">
          <ScoreFilter />
        </div>
        <div className="row">
          {currentData.map((radar, index) => (
            <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4">
              <Radar {...radar} showOnScorePagea compareData={diffData[index]} />
              <div className={styles.radarBox}>
                {!isEditable &&
                  radar.questions.map((score, scoreIndex) => (
                    <CircleScoreCard
                      index={score.id}
                      key={scoreIndex}
                      text={score.name}
                      color={score.color}
                      score={score.score}
                      isSelected={score.isSelected}
                      scoreDifference={score.scoreDifference}
                    />
                  ))}
                {isEditable &&
                  radar.questions.map((score, scoreIndex) => (
                    <ScoreCheckbox
                      index={score.id}
                      key={scoreIndex}
                      id={`${radar.id}-${score.id}`}
                      text={score.name}
                      color={score.color}
                      score={score.score}
                      isSelected={score.isSelected}
                      scoreDifference={score.scoreDifference}
                      onChange={handleSelectFocusAreas}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface ScoresPageContentProps {
  data: CreateStarCategoryInterface[];
  isEditable?: boolean;
  showButton?: boolean;
}

NewScorePageContent.defaultProps = {
  isEditable: false,
  showButton: false,
};

export default NewScorePageContent;
