import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';
import styles from './index.module.scss';

const BorderLinearProgress = styled(LinearProgress)<{
  barColor?: string;
  backgroundColor?: string;
}>(({ theme, barColor, backgroundColor }) => ({
  height: 15,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      backgroundColor || theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: barColor || '#1a90ff',
  },
}));

const BorderedProgressBar: React.FC<BorderedProgressBarProps> = ({
  value,
  className,
  barColor,
  backgroundColor,
}) => (
  <div className={className}>
    <BorderLinearProgress
      variant="determinate"
      value={value}
      barColor={barColor}
      backgroundColor={backgroundColor}
    />
  </div>
);

BorderedProgressBar.defaultProps = {
  className: styles.progressBar,
  value: 0,
  barColor: '#BEAAF3',
  backgroundColor: '#d4c6f9',
};

interface BorderedProgressBarProps {
  value?: number;
  className?: string;
  barColor?: string;
  backgroundColor?: string;
}

export default BorderedProgressBar;
