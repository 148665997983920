import React, { ReactNode } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import './index.module.scss';
import 'swiper/swiper-bundle.css';

const DefaultSwiper: React.FunctionComponent<DefaultSwiperProps> = ({
  children,
  spaceBetween,
  modes,
  handleChange,
}) => {
  const swiperOptions = {
    modules: modes,
    slidesPerView: 1,
    spaceBetween,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
      pauseOnMouseEnter: true,
      waitForTransition: true,
    },
    loop: true,
    pagination: {
      clickable: true,
      dots: true,
    },
    effect: 'creative',
    parallax: true,
    watchSlidesProgress: true,
  };

  const handleSlideChange = (swiper: any) => {
    if (handleChange) {
      handleChange(swiper);
    }
  };

  return (
    <Swiper {...swiperOptions} onSlideChange={handleSlideChange}>
      {children}
    </Swiper>
  );
};

DefaultSwiper.defaultProps = {
  spaceBetween: 30,
  modes: [Navigation],
  handleChange: () => undefined,
};

interface DefaultSwiperProps {
  children: ReactNode;
  spaceBetween?: number;
  modes?: any;
  handleChange?: (swiper: any) => void;
}

export default DefaultSwiper;
