import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getActions = async (questionnaireId = 1, assessmentId = 0) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/actions/${questionnaireId}/${assessmentId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getAllActions = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/allActions`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  const result: any = await response.json();

  if (response.status === 401) {
    return 401;
  }

  return result;
};

export const saveAction = async (data: any, questionnaireId = 1, assesmentId = 0) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/actions/${questionnaireId}/${assesmentId}`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );

  if (response.status === 400) {
    return 400;
  }

  return response.statusText;
};

export const updateAction = async (data: any, questionnaireId = 1, assesmentId = 0) => {
  await fetch(`${process.env.REACT_APP_API_URL}/actions/${questionnaireId}/${assesmentId}`, {
    method: 'PUT',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });
};
