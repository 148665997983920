import { getIpAddress } from '@api/auth/login';
import { logLearning } from '@api/learning';
import { getAllLearningByTagId, getLearningContentById } from '@api/learningContent';
import ModalContent from '@components/ModalContent';
import CreateImprovePlan from '@components/ModalContent/CreateImprovePlan';
import CarouselSection from '@components/Organisms/Learning/CarouselSection';
import LearningArticleCard from '@components/Organisms/Learning/LearningArticleCard';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { SingleLearningContentInterface } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import learningCard from '../../../assets/images/all-news-bg.png';
import styles from './index.module.scss';
import './style.css';

const LearningArticle: React.FunctionComponent = () => {
  const [data, setData] = useState<any>({});
  const isMobileApp = useGoNative();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isMobile } = useWindowDimensions();
  const { postId } = useParams() as number | any;
  const [navigationPostData, setNavigationPostData] = useState<{
    previous: PreviewPost | null;
    next: PreviewPost | null;
    goBack: PreviewPost | null;
  }>({
    previous: { id: 0, innerImageUrl: '', title: '' },
    next: { id: 0, innerImageUrl: '', title: '' },
    goBack: { id: -999, innerImageUrl: learningCard, title: 'All learning' },
  });
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const catId = urlParams.get('categoryId');
  const catName = urlParams.get('categoryName');

  const [openImprovePlanCreateDialog, setOpenImprovePlanCreateDialog] = useState<{
    open: boolean;
    data: {
      questionId: number;
      actionContent: string;
      questionName: string;
      actionButton: string | null;
      actionUrl: string | null;
    };
  }>({
    open: false,
    data: {
      questionId: 0,
      actionContent: '',
      questionName: '',
      actionButton: null,
      actionUrl: null,
    },
  });

  const [contentData, setContentData] = useState<SingleLearningContentInterface | null>(null);
  const [learningsGroupByTagsLoading, setLearningsGroupedByTagsLoading] = useState<boolean>(true);
  const [learningsGroupByTags, setLearningsGroupedByTags] = useState<
    | {
        id: number;
        innerImageUrl: string;
        title: string;
        tagId?: number;
        tag?: string;
      }[]
    | []
  >([]);

  useEffect(() => {
    if (postId) {
      getContentDetailsById(postId);
    }
  }, [postId]);

  useEffect(() => {
    if (postId && contentData?.title) {
      handleLogLearnPost(postId, contentData?.title);
    }
  }, [postId, contentData]);

  const getContentDetailsById = async (id: number) => {
    try {
      const res = await getLearningContentById({ contentId: id });
      setContentData(res);
      setNavigationPostData({
        previous: res.previousItem
          ? {
              id: res.previousItem.id,
              innerImageUrl: res.previousItem.imageUrl,
              title: res.previousItem.title,
            }
          : null,
        next: res.nextItem
          ? {
              id: res.nextItem.id,
              innerImageUrl: res.nextItem.imageUrl,
              title: res.nextItem.title,
            }
          : null,
        goBack: { id: -999, innerImageUrl: learningCard, title: 'All learning' },
      });
    } catch (err) {
      setError(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleLogLearnPost = async (id: number, postTitle: string) => {
    const ip = await getIpAddress();
    const os = navigator.userAgentData?.platform || '';
    await logLearning('learningItemClicked', ip, os, isMobileApp, id, postTitle);
  };

  const handleClick = (event: any) => {
    event.preventDefault();

    const href = event.target.getAttribute('href');

    if (!href) {
      return;
    }

    const url = new URL(href, window.location.origin);

    const questionId = url.searchParams.get('questionId');
    const actionContent = url.searchParams.get('actionContent');
    const queName = url.searchParams.get('questionName');

    const actionBtn = url.searchParams.get('actionButton');
    const actionUrl = url.searchParams.get('url');

    const qId = Number(questionId);

    if (questionId === null || questionId === '') {
      window.location.href = href;

      return;
    }

    if (qId) {
      setOpenImprovePlanCreateDialog({
        open: true,
        data: {
          questionId: qId,
          actionContent: actionContent || '',
          questionName: queName || '',
          actionButton: actionBtn || null,
          actionUrl: actionUrl || null,
        },
      });
    }
  };

  useEffect(() => {
    if (catId) {
      fetchContentForTags();
    }
  }, [catId]);

  const fetchContentForTags = async () => {
    try {
      setLearningsGroupedByTagsLoading(true);

      const cData = await getAllLearningByTagId({ tagId: Number(catId) });

      if (catId && catName) {
        const modifiedData = cData?.map(({ id, imageUrl, title }) => ({
          id,
          innerImageUrl: imageUrl,
          title,
          tagId: Number(catId),
          tag: catName,
        }));

        setLearningsGroupedByTags(modifiedData);
      } else {
        setLearningsGroupedByTags([]);
      }
    } catch (err) {
      setLearningsGroupedByTags([]);
    } finally {
      setLearningsGroupedByTagsLoading(false);
    }
  };

  const handleClickNavigate = () => {
    history.push(`/posts?type=singleCategoryContent&categoryId=${catId}&categoryName=${catName}`);
  };

  const CONTENT_TYPES = {
    AUDIO: 'audio',
    VIDEO: 'video',
  };

  const isLearningContentTypeVideo =
    contentData?.learningContentType &&
    contentData?.learningContentType?.contentType === CONTENT_TYPES.VIDEO;

  return (
    <>
      <ToastContainer hideProgressBar theme="dark" />
      <MainLayout title="Hapstar Posts" secondaryNavEnabled>
        <div className="container">
          {loading && !error && (
            <>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={474}
                className={styles.blogBannerLoading}
              />

              <div className={styles.contentLoadingContainer}>
                <div>
                  <Skeleton variant="text" width="60%" height={60} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="20%" height={30} />
                </div>
                <div>
                  <Skeleton variant="text" width="40%" height={40} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                </div>
                <div>
                  <Skeleton variant="text" width="70%" height={60} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="60%" height={30} />
                </div>
                <div>
                  <Skeleton variant="text" width="60%" height={60} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="20%" height={30} />
                </div>
                <div>
                  <Skeleton variant="text" width="40%" height={40} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                </div>
                <div>
                  <Skeleton variant="text" width="70%" height={60} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="100%" height={30} />
                  <Skeleton variant="text" width="60%" height={30} />
                </div>
              </div>
            </>
          )}

          {error && !loading && <p>Invalid post</p>}

          {!loading && !error && data && (
            <div>
              {!isLearningContentTypeVideo && (
                <section className="blog-banner banner--transition trigger in-view">
                  <div className="banner-inner inner-wrap">
                    <div className="container">
                      <div className="row">
                        <div className="banner-content banner-content--light news-content">
                          <div
                            className="banner-image main-image"
                            style={{
                              backgroundImage: `url(${contentData?.imageUrl})`,
                            }}
                          />
                          <div className="text-column">
                            <p />
                            {contentData?.title && (
                              <div className="title-cont">
                                <h1 dangerouslySetInnerHTML={{ __html: contentData?.title }} />
                              </div>
                            )}
                          </div>
                          <div className="banner-overlay" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              <div className={isLearningContentTypeVideo ? 'video-content' : ''}>
                <div className="row">
                  <div className="news-intro-content">
                    <div style={{ width: '100%' }} className="news-intro-text trigger in-view">
                      <div className="news-main-wrap">
                        <div>
                          <div
                            style={{
                              color: 'black',
                              fontSize: '18px',
                            }}
                            className="news-main-content twocolumn trigger in-view rowv2"
                          >
                            {contentData?.learningContentExtended && (
                              <div>
                                <div className="content-style-01">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: contentData?.learningContentExtended.description,
                                    }}
                                    onClick={handleClick}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <br />

                        {catId && catName && learningsGroupByTags?.length > 0 ? (
                          <div className="container">
                            <div className="navigation-title">More on {catName?.toLowerCase()}</div>
                            <CarouselSection
                              tagId={Number(catId)}
                              title={catName}
                              data={learningsGroupByTags}
                              loading={learningsGroupByTagsLoading}
                              carouselType="categoryPosts"
                              onClickNavigation={handleClickNavigate}
                              hideTitle
                            />
                          </div>
                        ) : (
                          <div className="container">
                            <div className="navigation-title">Keep your learning going</div>
                            <Grid container spacing={4} className={styles.learningItemsContainer}>
                              {Object.values(navigationPostData)
                                .filter((card) => card && card.id !== 0)
                                .map(
                                  (card, index) =>
                                    card && (
                                      <Grid item xs={12} md={4} key={index}>
                                        <LearningArticleCard
                                          data={card}
                                          sectionType={card.id === -999 ? 'allLearnings' : 'posts'}
                                        />
                                      </Grid>
                                    ),
                                )}
                            </Grid>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </MainLayout>
      <Modal
        isOpen={openImprovePlanCreateDialog.open}
        onRequestClose={() =>
          setOpenImprovePlanCreateDialog({
            open: false,
            data: {
              questionId: 0,
              actionContent: '',
              questionName: '',
              actionButton: null,
              actionUrl: null,
            },
          })
        }
        contentLabel="Improve Action Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() =>
            setOpenImprovePlanCreateDialog({
              open: false,
              data: {
                questionId: 0,
                actionContent: '',
                questionName: '',
                actionButton: null,
                actionUrl: null,
              },
            })
          }
          hasBackground
          title="Create your action"
        >
          <CreateImprovePlan
            closeModal={() =>
              setOpenImprovePlanCreateDialog({
                open: false,
                data: {
                  questionId: 0,
                  actionContent: '',
                  questionName: '',
                  actionButton: null,
                  actionUrl: null,
                },
              })
            }
            improvePlan={`${openImprovePlanCreateDialog?.data?.questionName} wellbeing`}
            id={0}
            questionId={openImprovePlanCreateDialog?.data?.questionId}
            isLearningArticle
            handleRefetch={() => null}
            actionContentData={openImprovePlanCreateDialog?.data?.actionContent}
            otherData={openImprovePlanCreateDialog?.data}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default LearningArticle;

interface PreviewPost {
  id: number;
  innerImageUrl: string;
  title: string;
}
