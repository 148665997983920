import { getIpAddress } from '@api/auth/login';
import { getLearnings, logLearning } from '@api/learning';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import ModalContent from '@components/ModalContent';
import CreateImprovePlan from '@components/ModalContent/CreateImprovePlan';
import BlogPostCard from '@components/Organisms/BlogPostCard';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import MainLayout from '@layouts/MainLayout';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import learningCard from '../../assets/images/all-news-bg.png';
import { WP_BLOG_BASE_URL } from '../../constants';
import './style.css';

const PostPage: React.FunctionComponent = () => {
  const [data, setData] = useState<any>({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isMobile } = useWindowDimensions();
  const { postId } = useParams() as number | any;
  const [navigationPostData, setNavigationPostData] = useState<{
    previous: PreviewPost;
    next: PreviewPost;
    goBack: PreviewPost;
  }>({
    previous: { postId: 0, background: '', title: '' },
    next: { postId: 0, background: '', title: '' },
    goBack: { postId: -999, background: learningCard, title: 'All learning' },
  });
  const isMobileApp = useGoNative();
  const allBlogPostsId = -999;
  const [openImprovePlanCreateDialog, setOpenImprovePlanCreateDialog] = useState<{
    open: boolean;
    data: {
      questionId: number;
      actionContent: string;
      questionName: string;
      actionButton: string | null;
      actionUrl: string | null;
    };
  }>({
    open: false,
    data: {
      questionId: 0,
      actionContent: '',
      questionName: '',
      actionButton: null,
      actionUrl: null,
    },
  });

  useEffect(() => {
    if (postId && data?.title) {
      handleLogLearnPost(postId, data?.title?.rendered);
    }
  }, [postId, data?.title]);

  const handleLogLearnPost = async (id: number, postTitle: string) => {
    const ip = await getIpAddress();
    const os = navigator.userAgentData?.platform || '';
    await logLearning('learningItemClicked', ip, os, isMobileApp, id, postTitle);
  };

  useEffect(() => {
    const getPostsAPI = async () => {
      const response = await fetch(`${WP_BLOG_BASE_URL}/blog/${postId}?acf_format=standard`);
      const result: any = await response.json();

      if (postId && result && result.acf && result.acf.show_in_employee_portal) {
        setData(result);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };

    getPostsAPI();
  }, [postId]);

  useEffect(() => {
    getAllLearnings();
  }, [data]);

  const getAllLearnings = async () => {
    const allPosts = await getLearnings();

    if (allPosts === 401) {
      throw new Error('Unable to fetch posts');
    }

    const postsData: any = await allPosts.json();

    const navigatedPostDetails = postsData.find(
      (post: { id: number }) => post.id === Number(postId),
    );

    const setPreviousData = async () => {
      const response = await getFormattedNavigationPosts(navigatedPostDetails.previous.id);
      setNavigationPostData((prev) => ({
        ...prev,
        previous: response,
      }));
    };

    const setNextData = async () => {
      const response = await getFormattedNavigationPosts(navigatedPostDetails.next.id);
      setNavigationPostData((prev) => ({
        ...prev,
        next: response,
      }));
    };

    if (navigatedPostDetails?.previous?.id) {
      setPreviousData();
    }

    if (navigatedPostDetails?.next?.id) {
      setNextData();
    }
  };

  async function getFormattedNavigationPosts(id: number) {
    const response = await fetch(`${WP_BLOG_BASE_URL}/blog/${id}?acf_format=standard`);
    const result: Post = await response.json();

    if (result?.acf?.show_in_employee_portal) {
      const previewData = {
        postId: id,
        background: result?.acf?.background_image?.sizes?.medium,
        title: result?.title?.rendered,
      };

      return previewData;
    }

    return {
      postId: 0,
      background: '',
      title: '',
    };
  }

  const renderFlexibleContent = (module: string, content: string) => {
    switch (module) {
      case 'content_text':
        return <div className="panel1" dangerouslySetInnerHTML={{ __html: content }} />;

      default:
        return null;
    }
  };

  const filteredContent = data?.acf?.section_selector
    .filter(
      (dt: { acf_fc_layout: string; content: string }) =>
        dt.acf_fc_layout === 'content_text' && dt?.content?.length > 0,
    )
    .map((item: { acf_fc_layout: string; content: string }) => {
      const newItem = { ...item };
      newItem.content = newItem?.content?.replace(/^<p>&nbsp;<\/p>/, '');

      return newItem || item;
    });

  const handleClick = (event: any) => {
    event.preventDefault();

    const href = event.target.getAttribute('href');

    if (!href) {
      return;
    }

    const url = new URL(href, window.location.origin);

    const questionId = url.searchParams.get('questionId');
    const actionContent = url.searchParams.get('actionContent');
    const queName = url.searchParams.get('questionName');

    const actionBtn = url.searchParams.get('actionButton');
    const actionUrl = url.searchParams.get('url');

    const qId = Number(questionId);

    if (questionId === null || questionId === '') {
      window.location.href = href;

      return;
    }

    if (qId) {
      setOpenImprovePlanCreateDialog({
        open: true,
        data: {
          questionId: qId,
          actionContent: actionContent || '',
          questionName: queName || '',
          actionButton: actionBtn || null,
          actionUrl: actionUrl || null,
        },
      });
    }
  };

  return (
    <>
      <ToastContainer hideProgressBar theme="dark" />
      <MainLayout title="Hapstar Posts" secondaryNavEnabled>
        <div className="container">
          {loading && !error && <LoadingPage />}

          {error && !loading && <p>Invalid post</p>}

          {!loading && !error && data && (
            <div>
              <section className="blog-banner banner--transition trigger in-view">
                <div className="banner-inner inner-wrap">
                  <div className="container">
                    <div className="row">
                      <div className="banner-content banner-content--light news-content">
                        <div
                          className="banner-image main-image"
                          style={{
                            backgroundImage: `url(${data.acf?.background_image?.sizes?.large})`,
                          }}
                        />
                        <div className="text-column">
                          <p />
                          <div className="title-cont">
                            <h1 dangerouslySetInnerHTML={{ __html: data.title.rendered }} />
                          </div>
                        </div>
                        <div className="banner-overlay" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <div className="row">
                  <div className="news-intro-content">
                    <div style={{ width: '100%' }} className="news-intro-text trigger in-view">
                      <div className="news-main-wrap">
                        <div>
                          <div
                            style={{
                              color: 'black',
                              fontSize: '18px',
                            }}
                            className="news-main-content twocolumn trigger in-view rowv2"
                          >
                            {data.acf && data.acf.section_selector && (
                              <div>
                                {filteredContent?.length === 1 && filteredContent[0] && (
                                  <div className="content-style-01">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: filteredContent[0]?.content,
                                      }}
                                      onClick={handleClick}
                                    />
                                  </div>
                                )}

                                {filteredContent?.length === 2 && (
                                  <div className="content-style-01">
                                    {filteredContent[0] && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `${filteredContent[0]?.content || ''} ${
                                            filteredContent[1]?.content || ''
                                          }`,
                                        }}
                                        onClick={handleClick}
                                      />
                                    )}
                                  </div>
                                )}

                                {filteredContent?.length === 3 && (
                                  <div className="content-style-01">
                                    {filteredContent[0] && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `${filteredContent[0]?.content || ''} ${
                                            filteredContent[1]?.content || ''
                                          } ${filteredContent[2]?.content || ''}`,
                                        }}
                                        onClick={handleClick}
                                      />
                                    )}
                                  </div>
                                )}

                                {filteredContent?.length === 4 && (
                                  <div className="content-style-base-02">
                                    <div className="flex-col-section1">
                                      {filteredContent[0] && (
                                        <div
                                          className="item-01"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[0]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                      {filteredContent[1] && (
                                        <div
                                          className="item-02"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[1]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                    </div>

                                    <div className="flex-col-section2">
                                      {filteredContent[2] && (
                                        <div
                                          className="item-03"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[2]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                      {filteredContent[3] && (
                                        <div
                                          className="item-03"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[3]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}

                                {filteredContent?.length === 5 && (
                                  <div className="content-style-base">
                                    <div className="flex-col-section">
                                      {filteredContent[0] && (
                                        <div
                                          className="item-01"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[0]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                      {filteredContent[1] && (
                                        <div
                                          className="item-02"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[1]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                      {filteredContent[2] && (
                                        <div
                                          className="item-02"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[2]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                    </div>

                                    <div className="flex-col-section">
                                      {filteredContent[3] && (
                                        <div
                                          className="item-03"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[3]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                      {filteredContent[4] && (
                                        <div
                                          className="item-03"
                                          dangerouslySetInnerHTML={{
                                            __html: filteredContent[4]?.content,
                                          }}
                                          onClick={handleClick}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <br />

                        <div className="container">
                          <div className="navigation-title">Keep your learning going</div>

                          <div className="card-section">
                            {Object.values(navigationPostData)
                              .filter((card) => card.postId !== 0)
                              .map((card, index) => (
                                <BlogPostCard
                                  postId={card.postId}
                                  key={index}
                                  keyValue={index}
                                  backgroundImage={card.background}
                                  title={card.title}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </MainLayout>
      <Modal
        isOpen={openImprovePlanCreateDialog.open}
        onRequestClose={() =>
          setOpenImprovePlanCreateDialog({
            open: false,
            data: {
              questionId: 0,
              actionContent: '',
              questionName: '',
              actionButton: null,
              actionUrl: null,
            },
          })
        }
        contentLabel="Improve Action Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() =>
            setOpenImprovePlanCreateDialog({
              open: false,
              data: {
                questionId: 0,
                actionContent: '',
                questionName: '',
                actionButton: null,
                actionUrl: null,
              },
            })
          }
          hasBackground
          title="Create your action"
        >
          <CreateImprovePlan
            closeModal={() =>
              setOpenImprovePlanCreateDialog({
                open: false,
                data: {
                  questionId: 0,
                  actionContent: '',
                  questionName: '',
                  actionButton: null,
                  actionUrl: null,
                },
              })
            }
            improvePlan={`${openImprovePlanCreateDialog?.data?.questionName} wellbeing`}
            id={0}
            questionId={openImprovePlanCreateDialog?.data?.questionId}
            isLearningArticle
            handleRefetch={() => null}
            actionContentData={openImprovePlanCreateDialog?.data?.actionContent}
            otherData={openImprovePlanCreateDialog?.data}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PostPage;

interface PreviewPost {
  postId: number;
  background: string;
  title: string;
}

interface Post {
  id: number;
  date: string;
  date_gmt: string;
  guid: {
    rendered: string;
  };
  modified: string;
  modified_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: {
    rendered: string;
  };
  parent: number;
  template: string;
  acf: {
    post_layout: string;
    section_selector: {
      acf_fc_layout: string;
      content: string;
    }[];
    background_image: {
      ID: number;
      id: number;
      title: string;
      filename: string;
      filesize: number;
      url: string;
      link: string;
      alt: string;
      author: string;
      description: string;
      caption: string;
      name: string;
      status: string;
      uploaded_to: number;
      date: string;
      modified: string;
      menu_order: number;
      mime_type: string;
      type: string;
      subtype: string;
      icon: string;
      width: number;
      height: number;
      sizes: {
        thumbnail: string;
        thumbnail_width: number;
        thumbnail_height: number;
        medium: string;
        medium_width: number;
        medium_height: number;
        medium_large: string;
        medium_large_width: number;
        medium_large_height: number;
        large: string;
        large_width: number;
        large_height: number;
        '1536x1536': string;
        '1536x1536-width': number;
        '1536x1536-height': number;
        '2048x2048': string;
        '2048x2048-width': number;
        '2048x2048-height': number;
        'full-screen': string;
        'full-screen-width': number;
        'full-screen-height': number;
        'mobile-screen': string;
        'mobile-screen-width': number;
        'mobile-screen-height': number;
        'mobile-actual': string;
        'mobile-actual-width': number;
        'mobile-actual-height': number;
      };
    };
    author: {
      ID: number;
      post_author: string;
      post_date: string;
      post_date_gmt: string;
      post_content: string;
      post_title: string;
      post_excerpt: string;
      post_status: string;
      comment_status: string;
      ping_status: string;
      post_password: string;
      post_name: string;
      to_ping: string;
      pinged: string;
      post_modified: string;
      post_modified_gmt: string;
      post_content_filtered: string;
      post_parent: number;
      guid: string;
      menu_order: number;
      post_type: string;
      post_mime_type: string;
      comment_count: string;
      filter: string;
    }[];
    show_in_employee_portal: boolean;
    show_in_public_website: boolean;
  };
  yoast_head: string;
  yoast_head_json: {
    title: string;
    description: string;
    robots: {
      index: string;
      follow: string;
      'max-snippet': string;
      'max-image-preview': string;
      'max-video-preview': string;
    };
    canonical: string;
    og_locale: string;
    og_type: string;
    og_title: string;
    og_description: string;
    og_url: string;
    og_site_name: string;
    article_modified_time: string;
    twitter_card: string;
    schema: {
      '@context': string;
      '@graph': {
        '@type': string;
        '@id': string;
        url: string;
        name: string;
        isPartOf: {
          '@id': string;
        };
        datePublished: string;
        dateModified: string;
        description: string;
        breadcrumb: {
          '@id': string;
        };
        inLanguage: string;
        potentialAction: {
          '@type': string;
          target: string[];
        }[];
      }[];
    };
  };
  next: {
    id: number;
    slug: string;
  };
  previous: {
    id: number;
    slug: string;
  };
  _links: {
    self: {
      href: string;
    }[];
    collection: {
      href: string;
    }[];
    about: {
      href: string;
    }[];
    'acf:post': {
      embeddable: boolean;
      href: string;
    }[];
    'wp:attachment': {
      href: string;
    }[];
    curies: {
      name: string;
      href: string;
      templated: boolean;
    }[];
  };
}
