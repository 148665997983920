import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const FormInputSelect = React.forwardRef<HTMLSelectElement, InputSelectProps>((props, ref) => {
  const { children, title, isError, name, onChange, isLabelWhite, variant, ...rest } = props;

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <label className={classNames([isLabelWhite && styles.labelWhite])} htmlFor={name}>
          {title}
        </label>
      </div>

      <select
        id={name}
        ref={ref}
        name={name}
        onChange={onChange}
        className={classNames(
          variant === 'primary'
            ? [styles.inputPrimary, isError && styles.error]
            : [styles.inputDefault, isError && styles.error],
        )}
        {...rest}
      >
        {children}
      </select>
    </div>
  );
});

interface InputSelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name: string;
  title: string;
  isError: boolean;
  children: React.ReactNode;
  isLabelWhite?: boolean;
  onChange: (e: any) => void;
  variant?: string;
}

FormInputSelect.defaultProps = {
  isLabelWhite: false,
  variant: 'primary',
};

FormInputSelect.displayName = 'FormInputSelect';

export default FormInputSelect;
