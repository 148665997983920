import React from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import styles from './index.module.scss';

const Hamburger: React.FunctionComponent<HamburgerProps> = (props) => {
  const { isDark } = props;
  const { toggled, toggleSidebar } = useProSidebar();

  const handleClick = () => {
    toggleSidebar(!toggled);
  };

  return (
    <div
      className={`${styles.hmgIcn} ${isDark && styles.hmgIcnDark} ${toggled && styles.opened}`}
      onClick={handleClick}
    >
      <div />
    </div>
  );
};

interface HamburgerProps {
  isDark: boolean;
}

export default Hamburger;
