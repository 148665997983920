import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import FormInputSelect from '@components/FormInputSelect';
import DefaultButton from '@components/Atoms/DefaultButton';
import { User } from '../../custom';
import styles from './index.module.scss';

const UserFilters: React.FunctionComponent<UserFiltersProps> = (props) => {
  const { users, isFiltered, onFilter, onReset, methods } = props;

  const allLocations = users.map((u) => u.location).flat();
  const sortedLocations = orderBy(allLocations, ['asc']);
  const uniqueLocations = uniq(sortedLocations);

  const allTeams = users.map((u) => u.team).flat();
  const sortedTeams = orderBy(allTeams, ['asc']);
  const uniqueTeams = uniq(sortedTeams);

  const allJobTypes = users.map((u) => u.jobType).flat();
  const sortedJobTypes = orderBy(allJobTypes, ['asc']);
  const uniqueJobTypes = uniq(sortedJobTypes);

  const allJobTitles = users.map((u) => u.jobTitle).flat();
  const sortedJobTitles = orderBy(allJobTitles, ['asc']);
  const uniqueJobTitles = uniq(sortedJobTitles);

  const genders = [
    { value: 'non_specified', label: 'Non-Specified' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'non_binary', label: 'Non-binary' },
  ];

  const peopleLedValues = [
    { value: '', label: 'All' },
    { value: 'no', label: 'No' },
    { value: 'yes', label: 'Yes' },
  ];

  const seniorityLevels = [
    { value: 'non_specified', label: 'Non-Specified' },
    { value: 'junior', label: 'Junior' },
    { value: 'mid_level', label: 'Mid-level' },
    { value: 'senior', label: 'Senior' },
    { value: 'c_level', label: 'C-level' },
  ];

  const salaryRanges = [
    { value: 10000, label: '£10-20k+' },
    { value: 20000, label: '£20-30k+' },
    { value: 30000, label: '£30-40k+' },
    { value: 40000, label: '£40-50k+' },
    { value: 50000, label: '£50-60k+' },
    { value: 60000, label: '£60-70k+' },
    { value: 70000, label: '£70-80k+' },
    { value: 80000, label: '£80-90k+' },
    { value: 90000, label: '£90-100k+' },
    { value: 100000, label: '£100k+' },
  ];

  const speakupAlertValues = [
    { value: '', label: 'All' },
    { value: 'yes', label: 'Eligible' },
    { value: 'no', label: 'Not-Eligible' },
  ];

  const {
    formState: { errors },
    handleSubmit,
    register,
    formState,
  } = methods;

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit(onFilter)}>
        <div className={styles.sliceAndDiceFlex}>
          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.location)}
              title="Location"
              {...register('location')}
            >
              <option value="">All</option>
              {uniqueLocations.map(
                (location) =>
                  location && (
                    <option key={location} value={location}>
                      {location}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.team)}
              title="Team"
              {...register('team')}
            >
              <option value="">All</option>
              {uniqueTeams.map(
                (team) =>
                  team && (
                    <option key={team} value={team}>
                      {team}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.gender)}
              title="Gender"
              {...register('gender')}
            >
              <option value="">All</option>
              {genders.map(
                (gender) =>
                  gender && (
                    <option key={gender.value} value={gender.value}>
                      {gender.label}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.seniority)}
              title="Seniority"
              {...register('seniority')}
            >
              <option value="">All</option>
              {seniorityLevels.map(
                (seniority) =>
                  seniority && (
                    <option key={seniority.value} value={seniority.value}>
                      {seniority.label}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.jobType)}
              title="Job Type"
              {...register('jobType')}
            >
              <option value="">All</option>
              {uniqueJobTypes.map(
                (jobTypes) =>
                  jobTypes && (
                    <option key={jobTypes} value={jobTypes}>
                      {jobTypes}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.jobTitle)}
              title="Job Title"
              {...register('jobTitle')}
            >
              <option value="">All</option>
              {uniqueJobTitles.map(
                (jobTitles) =>
                  jobTitles && (
                    <option key={jobTitles} value={jobTitles}>
                      {jobTitles}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.payLevel)}
              title="Pay Level"
              {...register('payLevel')}
            >
              <option value={0}>All</option>
              {salaryRanges.map(
                (salaryRange) =>
                  salaryRange && (
                    <option key={salaryRange.label} value={salaryRange.value}>
                      {salaryRange.label}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.peopleLead)}
              title="People Lead"
              {...register('peopleLead')}
            >
              {peopleLedValues.map(
                (item) =>
                  item && (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>

          <div>
            <FormInputSelect
              isLabelWhite
              isError={Boolean(errors.speakupAlertEligibility)}
              title="Speak Up Alert Eligibility"
              {...register('speakupAlertEligibility')}
            >
              {speakupAlertValues.map(
                (item) =>
                  item && (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ),
              )}
            </FormInputSelect>
          </div>
          <div />
        </div>
        <div className={styles.footer}>
          <div className={styles.buttonGroup}>
            <DefaultButton disabled={!formState.isDirty} type="submit" variant="primary">
              Filter
            </DefaultButton>

            <DefaultButton disabled={!isFiltered} type="button" onClick={onReset} variant="primary">
              Clear Filter
            </DefaultButton>
          </div>
        </div>
      </form>
    </div>
  );
};

interface UserFiltersProps {
  methods: UseFormReturn;
  users: Array<User>;
  isFiltered: boolean;
  onFilter: (data: any) => void;
  onReset: () => void;
}

export default UserFilters;
