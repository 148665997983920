import { getCompanyDetailsByUrl } from '@api/auth/company';
import { getCompanyDetails } from '@helpers/auth.helper';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

const Seo: React.FunctionComponent<SeoProps> = (props) => {
  const { title, description, name, type } = props;
  const { companyURL } = useParams<UrlParamsType>();

  const [companyDetails, setCompanyDetails] = useState<any>(null);
  let organizationName;

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetails();
      if (data) {
        setCompanyDetails(data);
      }
    };

    const getCompanyDataByURL = async () => {
      const data = await getCompanyDetailsByUrl(companyURL);
      setCompanyDetails(data);
    };

    if (companyURL) {
      getCompanyDataByURL();
    } else {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null && companyDetails.id !== 0) {
    organizationName = companyDetails.company_name && companyDetails.company_name;
  }

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{`${title} | Hapstar ${organizationName ? `| ${organizationName}` : ''}`}</title>
      <meta name="description" content={description} />
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

Seo.defaultProps = {
  description: '',
  name: '',
  type: 'summary',
};

interface SeoProps {
  title: string;
  description?: string;
  name?: string;
  type?: 'summary' | 'summary_large_image' | 'app' | 'player';
}

type UrlParamsType = {
  companyURL: string;
};

export default Seo;
