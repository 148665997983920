import React, { useState } from 'react';
import LabelCheckbox from '@components/Molecules/LabelCheckbox';
import LinkText from '@components/Atoms/Link';
import FormButtonNew from '@components/FormButtonNew/FormButtonNew';
import LabelInput from '@components/Molecules/LabelInput';
import useInput, { getInputValue } from '@hooks/useInput';
import { LabelPositions } from '@enums/LabelPositions';
import { emailValidation } from '@helpers/validation.helper';
import styles from './index.module.scss';

const RegistrationForm: React.FunctionComponent<RegistrationFormProps> = (props) => {
  const { onClick } = props;
  const [isAgree, setIsAgree] = useState(false);
  let isFormValid = false;

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useInput(emailValidation, getInputValue, '');

  const handleTermsAndConditions = () => {
    setIsAgree((preState) => !preState);
  };

  if (emailIsValid && isAgree) {
    isFormValid = true;
  }

  const handleCreateUser = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    if (isFormValid) {
      onClick(emailValue, isAgree);
    }

    emailReset();
    setIsAgree(false);
  };

  const termsAndConditionsLabel = (
    <>
      I agree to Hapstar’s{' '}
      <LinkText path="https://hapstar.app/terms/" target="_blank" rel="noopener noreferrer">
        Terms & Conditions
      </LinkText>{' '}
      and{' '}
      <LinkText path="https://hapstar.app/privacy/" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </LinkText>
    </>
  );

  return (
    <form onSubmit={handleCreateUser}>
      <div className={styles.inputHolder}>
        <LabelInput
          id="email"
          label="Email *"
          type="text"
          name="email"
          placeholder="Add your email here..."
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          value={emailValue || ''}
          isError={emailHasError}
        />
        {emailHasError && <p className={styles.hsError}>Please enter a valid email address</p>}
      </div>
      <div className={styles.inputHolder}>
        <LabelCheckbox
          id="termsAndConditions"
          name="termsAndConditions"
          onChange={handleTermsAndConditions}
          checked={isAgree}
          labelPosition={LabelPositions.right}
          label={termsAndConditionsLabel}
        />
      </div>
      <div className={styles.buttonHolder}>
        <FormButtonNew
          onClick={handleCreateUser}
          disabled={!isFormValid}
          color="primary"
          type="submit"
        >
          Continue
        </FormButtonNew>
      </div>
    </form>
  );
};

RegistrationForm.displayName = 'RegistrationForm';

interface RegistrationFormProps {
  onClick: (emailValue: string, isAgreeValue: boolean) => void;
}

export default RegistrationForm;
