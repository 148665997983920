import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getTeams } from '@api/teams';
import { getLocations } from '@api/locations';
import { getJobTitles } from '@api/jobTitles';
import { getJobTypes } from '@api/jobTypes';
import { addJobTitle, addJobType, addLocation, addTeam } from '@api/users';
import Modal from '@components/Atoms/Modal';
import DefaultButton from '@components/Atoms/DefaultButton';
import FormFieldsUser from '@components/FormFieldsUser';
import {
  JobTitleType,
  JobTypeType,
  LocationItemType,
  SelectableOptionType,
  TeamItemType,
  UserFieldValues,
} from '../../../custom';
import styles from './index.module.scss';

const defaultValues = {
  email: '',
  surname: '',
  forename: '',
  seniority: 'non_specified',
  sex: 'non_specified',
  payLevel: 0,
  jobType: null,
  jobTitle: null,
  team: null,
  location: null,
  dob: null,
  joined: null,
  peopleLead: false,
  isAdmin: false,
  speakupAlertEligibility: false,
} as UserFieldValues;

const AddEmployee: React.FunctionComponent<AddEmployeeProps> = (props) => {
  const { title, isOpen, onClose, onSave } = props;
  const [loading, setLoading] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState<boolean>(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [loadingJobTypes, setLoadingJobTypes] = useState(false);

  const [errorJobTitles, setErrorJobTitles] = useState(false);
  const [errorTeams, setErrorTeams] = useState(false);
  const [errorLocations, setErrorLocations] = useState(false);
  const [errorJobType, setErrorJobType] = useState(false);

  const [error, setError] = useState(false);
  const [teams, setTeams] = useState<Array<SelectableOptionType>>([]);
  const [locations, setLocations] = useState<Array<SelectableOptionType>>([]);
  const [jobTitles, setJobTitles] = useState<Array<SelectableOptionType>>([]);
  const [jobTypes, setJobTypes] = useState<Array<SelectableOptionType>>([]);

  const today = new Date();

  const validationSchema = yup.object({
    surname: yup.string().required(),
    forename: yup.string().required(),
    email: yup.string().email().required(),
    jobType: yup
      .object()
      .shape({ label: yup.string(), value: yup.number().integer() })
      .nullable(true),
    jobTitle: yup
      .object()
      .shape({ label: yup.string(), value: yup.number().integer() })
      .nullable(true),
    team: yup.object().shape({ label: yup.string(), value: yup.number().integer() }).nullable(true),
    location: yup
      .object()
      .shape({ label: yup.string(), value: yup.number().integer() })
      .nullable(true),
    seniority: yup.string(),
    sex: yup.string(),
    payLevel: yup.number().integer().min(0),
    peopleLead: yup.boolean(),
    dob: yup.date().max(today, 'DOB must be in the past').nullable(true),
    joined: yup.date().max(today, 'Joined must be in the past').nullable(true),
    isAdmin: yup.boolean().required().default(false),
    speakupAlertEligibility: yup.boolean().default(false),
  });

  useEffect(() => {
    const getData = async () => {
      const teamsResponse = await getTeams();
      const locationsResponse = await getLocations();
      const jobTitlesResponse = await getJobTitles();
      const jobTypesResponse = await getJobTypes();

      const teamsOptions = teamsResponse
        ? teamsResponse.map((team: TeamItemType) => ({
            label: team.name,
            value: team.id,
          }))
        : [];

      const locationOptions = locationsResponse
        ? locationsResponse.map((location: LocationItemType) => ({
            label: location.name,
            value: location.id,
          }))
        : [];

      const jobTitleOption = jobTitlesResponse
        ? jobTitlesResponse.map((jobTitle: JobTitleType) => ({
            label: jobTitle.name,
            value: jobTitle.id,
          }))
        : [];

      const jobTypesOptions = jobTypesResponse
        ? jobTypesResponse.map((jobType: JobTypeType) => ({
            label: jobType.name,
            value: jobType.id,
          }))
        : [];

      setLoading(true);
      setTeams(teamsOptions);
      setLocations(locationOptions);
      setJobTitles(jobTitleOption);
      setJobTypes(jobTypesOptions);
      setLoading(false);
    };

    try {
      getData();
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }, []);

  const methods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleAddTeam = React.useCallback(
    async (name: string) => {
      try {
        setLoadingTeams(true);

        const response = await addTeam(name);

        if (!response.ok) {
          throw new Error('Error fetching data');
        }

        const { id } = await response.json();
        const newOptions = [...teams, { label: name, value: id }] as Array<SelectableOptionType>;

        if (id) {
          setTeams(newOptions);
          methods.setValue('team', { label: name, value: id });
          setLoadingTeams(false);
          toast(`Created new team '${name}`);
        }
      } catch (err) {
        setErrorTeams(true);
        setLoadingTeams(false);
        toast(`Unable to save team '${name}`);
      }
    },
    [teams, methods],
  );

  const handleAddLocation = React.useCallback(
    async (name: string) => {
      try {
        setLoadingLocations(true);

        const response = await addLocation(name);

        if (!response.ok) {
          throw new Error('Error fetching data');
        }

        const { id } = await response.json();
        const newOptions = [
          ...locations,
          { label: name, value: id },
        ] as Array<SelectableOptionType>;

        setLocations(newOptions);
        methods.setValue('location', { label: name, value: id });
        setLoadingLocations(false);
        toast(`Created new location '${name}`);
      } catch (err) {
        setErrorLocations(true);
        setLoadingLocations(false);
        toast(`Unable to save location '${name}`);
      }
    },
    [methods, locations],
  );

  const handleAddJobTitle = React.useCallback(
    async (name: string) => {
      try {
        setLoadingJobTitles(true);

        const response = await addJobTitle(name);

        if (!response.ok) {
          throw new Error('Error fetching data');
        }

        const { id } = await response.json();
        const newOptions = [
          ...jobTitles,
          { label: name, value: id },
        ] as Array<SelectableOptionType>;

        setJobTitles(newOptions);
        methods.setValue('jobTitle', { label: name, value: id });
        setLoadingJobTitles(false);
        toast(`Created new job title '${name}`);
      } catch (err) {
        setErrorJobTitles(true);
        setLoadingJobTitles(false);
        toast(`Unable to save job title '${name}`);
      }
    },
    [methods, jobTitles],
  );

  const handleAddJobType = React.useCallback(
    async (name: string) => {
      try {
        setLoadingJobTypes(true);

        const response = await addJobType(name);

        if (!response.ok) {
          throw new Error('Error fetching data');
        }

        const { id } = await response.json();
        const newOptions = [...jobTypes, { label: name, value: id }] as Array<SelectableOptionType>;

        setJobTypes(newOptions);
        methods.setValue('jobType', { label: name, value: id });
        setLoadingJobTypes(false);
        toast(`Created new job type '${name}`);
      } catch (err) {
        setErrorJobType(true);
        setLoadingJobTypes(false);
        toast(`Unable to save job type '${name}`);
      }
    },
    [methods, jobTypes],
  );

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
  } = methods;

  const onSubmit = (data: any, event: any) => {
    event.preventDefault();

    const mutableData = data;

    if (!mutableData.isAdmin) {
      mutableData.speakupAlertEligibility = false;
    }

    onSave(mutableData, methods);
  };

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <div>
      <div className={styles.overlay}>
        <Modal
          open={isOpen}
          setOpen={handleClose}
          onTapBackgroundClose
          className={styles.modalRoot}
        >
          <div className={styles.modalHeader}>
            <h4>{title}</h4>
            <button type="button" className={styles.btnClose} onClick={handleClose}>
              <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
            </button>

            <hr />
          </div>
          <div className={styles.statusRoot}>
            {loading && !error && (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only" />
              </div>
            )}
            {error && <p className={styles.error}>Error fetching data</p>}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!error && !loading && (
              <FormFieldsUser
                loading={loading}
                loadingTeams={loadingTeams}
                loadingLocations={loadingLocations}
                loadingJobTitles={loadingJobTitles}
                loadingJobTypes={loadingJobTypes}
                onAddTeam={handleAddTeam}
                onAddLocation={handleAddLocation}
                onAddJobTitle={handleAddJobTitle}
                onAddJobType={handleAddJobType}
                control={control}
                register={register}
                errors={errors}
                teams={teams}
                locations={locations}
                jobTitles={jobTitles}
                jobTypes={jobTypes}
                getValues={watch}
                setValue={setValue}
              />
            )}

            <hr className={styles.hrLine} />
            <div className={styles.footerContainer}>
              <DefaultButton disabled={loading} type="submit" variant="primary">
                Confirm
              </DefaultButton>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

interface AddEmployeeProps {
  title: string;
  isOpen: boolean;
  onClose: () => void | undefined;
  onSave: (data: any, methods: any) => Promise<void> | undefined;
}

export default AddEmployee;
