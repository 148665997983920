import { getCompanyConfig } from '@api/companyConfig';
import { AppDispatch } from '@hooks/useAppDispatch';
import {
  TALK_TO_ADVISOR_COMPLETE_DEFAULT_MESSAGE,
  TALK_TO_ADVISOR_DEFAULT_MESSAGE,
} from 'custom.d';
import { companyConfigActions } from 'store/Slices/CompanyConfigSlice';

export const getCompanyConfigurations = () => async (dispatch: AppDispatch) => {
  dispatch(companyConfigActions.setLoading({ loading: true }));

  try {
    const data = await getCompanyConfig();
    if (data) {
      dispatch(
        companyConfigActions.updateConfigurationData({
          showInstantHelp: data.showInstantHelp,
          instantHelpDescription: data.instantHelpDescription,
          showSpeakUp: data.showSpeakUp,
          welcomeMessageText:
            data.welcomeMessageText &&
            data.welcomeMessageText.length > 0 &&
            data.welcomeMessageText !== 'null'
              ? data.welcomeMessageText
              : null,
          expertsEnabled: data.expertsEnabled,
          talkToAdvisor: data.talkToAdvisor,
          talkToAdvisorMessage:
            data.talkToAdvisor &&
            data.talkToAdvisorMessage !== null &&
            data.talkToAdvisorMessage !== 'null' &&
            data.talkToAdvisorMessage !== undefined &&
            typeof data.talkToAdvisorMessage === 'string' &&
            data.talkToAdvisorMessage !== ' ' &&
            data.talkToAdvisorMessage !== ''
              ? data.talkToAdvisorMessage
              : TALK_TO_ADVISOR_DEFAULT_MESSAGE,
          talkToAdvisorCompleteMessage:
            data.talkToAdvisor &&
            data.talkToAdvisorCompleteMessage !== null &&
            data.talkToAdvisorCompleteMessage !== 'null' &&
            data.talkToAdvisorCompleteMessage !== undefined &&
            typeof data.talkToAdvisorCompleteMessage === 'string' &&
            data.talkToAdvisorCompleteMessage !== ' ' &&
            data.talkToAdvisorCompleteMessage !== ''
              ? data.talkToAdvisorCompleteMessage
              : TALK_TO_ADVISOR_COMPLETE_DEFAULT_MESSAGE,
        }),
      );
    } else {
      dispatch(companyConfigActions.setError({ error: 'Loading company configurations failed' }));
    }
  } catch (error) {
    dispatch(companyConfigActions.setError({ error: 'Loading company configurations failed' }));
  }
};
