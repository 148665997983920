import { ThoughtDataInterface } from 'custom.d';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getThoughts = async (questionnaireId = 1, assessmentId = 0) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/thoughts/${questionnaireId}/${assessmentId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: any = await response.json();

  return result;
};

export const getAllThoughts = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/allThoughts`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });
  const result: any = await response.json();

  return result;
};

export const getThoughtsByFilter = async (
  responded: boolean,
  questionnaireIds: any,
  assessmentId: number | null,
  order: string,
) => {
  const payload = { responded, questionnaireIds, assessmentId };
  const result = await fetch(`${process.env.REACT_APP_API_URL}/thoughts?order=${order}`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(payload),
  });

  const result1: ThoughtDataInterface[] = await result.json();

  return result1;
};
