import { getToken } from './config';
import { createHeaders, defaultHttpRequestHeader } from './httpHeaderConfig';

export const unsubscribeEmailAlert = async (data: any) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/email-unsubscribe`, {
    method: 'POST',
    headers: defaultHttpRequestHeader,
    body: JSON.stringify(data),
  });

  if (response.ok) {
    return { success: true };
  }

  return { success: false };
};

export const getEmailTypeDetails = async (typeId: number) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/email-type/${typeId}`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  const result: EmailSubscriptionTypeDetailsInterface = await response.json();

  return result;
};

interface EmailSubscriptionTypeDetailsInterface {
  id: number;
  emailType: string;
  subscriptionMessage: string;
  unsubscriptionMessage: string;
  createdAt: string;
  updatedAt: string;
}
