import { createSlice } from '@reduxjs/toolkit';
import { ShortStarQuestionsInterface } from 'custom.d';

export interface ShortStarQuestionsState {
  loading: boolean;
  isLoaded: boolean;
  error: string | null;
  data: ShortStarQuestionsInterface[];
}

const initialConfigState: ShortStarQuestionsState = {
  loading: false,
  isLoaded: false,
  error: null,
  data: [],
};

export interface UpdateShortStartQuestionsDataAction {
  type: string;
  payload: ShortStarQuestionsInterface[];
}

export interface SetLoadingAction {
  type: string;
  payload: {
    loading: boolean;
  };
}

export interface SetErrorAction {
  type: string;
  payload: {
    error: string;
  };
}

const shortStarQuestionsSlice = createSlice({
  name: 'shortStarQuestions',
  initialState: initialConfigState,
  reducers: {
    setLoading(state: ShortStarQuestionsState, action: SetLoadingAction): ShortStarQuestionsState {
      return {
        ...state,
        loading: action.payload.loading,
        isLoaded: false,
        error: null,
        data: [],
      };
    },
    setError(state: ShortStarQuestionsState, action: SetErrorAction): ShortStarQuestionsState {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload.error,
        data: [],
      };
    },
    updateShortStarQuestionsData(
      state: ShortStarQuestionsState,
      action: UpdateShortStartQuestionsDataAction,
    ): ShortStarQuestionsState {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        error: null,
        data: action.payload,
      };
    },
  },
});

export const shortStarActions = shortStarQuestionsSlice.actions;

export default shortStarQuestionsSlice;
