import React from 'react';

import { useHistory } from 'react-router';
import { SET_CURRENT_TAB, useGlobalState } from '@context/GlobalStore';
import Button from '@components/Button';
import globe from '@assets/images/icons/globe.svg';
import smile from '@assets/images/icons/smile.svg';
import checkmark from '@assets/images/icons/checkmark.svg';
import styles from './index.module.scss';

const FinishFirstStarMessage: React.FunctionComponent = () => {
  const [state, dispatch] = useGlobalState();
  const history = useHistory();

  return (
    <div className={styles.FirstStarMessageContent}>
      <p>From here, the process is simple:</p>
      <ul>
        <li>
          <div>
            <p>Take what you’ve learned into the real world&nbsp;</p>
            <img src={globe} alt="world" />
          </div>
        </li>
        <li>
          <div>
            <p>Tick off your actions and keep yourself accountable&nbsp;</p>
            <img src={checkmark} alt="tick" />
          </div>
        </li>
        <li>
          <div>
            <p>Come back and rescore when you’re ready&nbsp;</p>
            <img src={smile} alt="smile" />
          </div>
        </li>
      </ul>
      <p>
        If you want to stick around, why not take a deep dive in to your career and mental
        wellbeing! Check them out here:
      </p>
      <div className={styles.btnContainer}>
        <Button
          onClick={() => {
            dispatch({
              type: SET_CURRENT_TAB,
              payload: { id: 2, name: 'Career' },
            });
            history.push('/create-a-star');
          }}
          type="primary"
          isButton
        >
          Set career wellbeing score
        </Button>
        <Button
          onClick={() => {
            dispatch({
              type: SET_CURRENT_TAB,
              payload: { id: 3, name: 'Mental' },
            });
            history.push('/create-a-star');
          }}
          type="primary"
          isButton
        >
          Set mental wellbeing score
        </Button>
      </div>
    </div>
  );
};

export default FinishFirstStarMessage;
