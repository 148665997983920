/* eslint-disable prettier/prettier */
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const sendContactUs = async (data: any) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/contact-us`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });
  const { status } = result;

  return status === 200;
};
