import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { MultiValue, SingleValue } from 'react-select';
import { SelectableOptionType } from 'custom';
import SelectComponent from '@components/Atoms/SelectComponent';
import { LabelPositions } from '@enums/LabelPositions';
import Tooltip from '@components/Tooltip';
import styles from './index.module.scss';

const LabelSelect: FunctionComponent<LabelSelectProps> = (props) => {
  const {
    id,
    name,
    options,
    label,
    value,
    className,
    isError,
    placeholder,
    labelPosition,
    isSearchable,
    isClearable,
    isLoading,
    isDisabled,
    isMulti,
    onChange,
    onBlur,
    tip,
    customStyle,
    ...rest
  } = props;

  const selectClasses = classNames([
    className,
    labelPosition === LabelPositions.top && styles.positionTop,
    labelPosition === LabelPositions.left && styles.positionLeft,
  ]);

  return (
    <div className={selectClasses}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.selectBlock}>
        {tip && tip.value && (
          <div className={styles.tooltipContainer}>
            <p>{tip.label}</p>
            <Tooltip tip={tip.value} />
          </div>
        )}
        <SelectComponent
          id={id}
          name={name}
          isError={isError}
          value={value}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isSearchable={isSearchable}
          onChange={onChange}
          onBlur={onBlur}
          isMulti={isMulti}
          customStyle={customStyle}
          {...rest}
        />
      </div>
    </div>
  );
};

LabelSelect.displayName = 'LabelSelect';

LabelSelect.defaultProps = {
  value: undefined,
  className: styles.selectContainer,
  isError: false,
  placeholder: '',
  labelPosition: LabelPositions.top,
  isSearchable: true,
  isClearable: true,
  isLoading: false,
  isDisabled: false,
  isMulti: false,
  onBlur: undefined,
  tip: undefined,
  customStyle: {},
};

interface LabelSelectProps {
  id: string;
  name: string;
  options: any;
  label: string;
  onChange: (value: MultiValue<SelectableOptionType> | SingleValue<SelectableOptionType>) => void;
  value?: SelectableOptionType | null | undefined;
  className?: string;
  isError?: boolean;
  placeholder?: string;
  labelPosition?: LabelPositions.top | LabelPositions.left;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  tip?: { value: string; label?: string };
  customStyle?: React.CSSProperties;
}

export default LabelSelect;
