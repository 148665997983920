import React, { ReactNode } from 'react';
import styles from './index.module.scss';

const MobileToggler: React.FunctionComponent<MobileTogglerProps> = (props) => {
  const { name, isOpen, openHandler } = props;

  return (
    <div
      className={`${styles.hsMobileToggler} ${isOpen && styles.open}`}
      onClick={() => openHandler(!isOpen)}
    >
      {name}
      <i className="icon icon-chevron-down" />{' '}
    </div>
  );
};

interface MobileTogglerProps {
  name: ReactNode | string;
  isOpen: boolean;
  openHandler: (toggle: boolean) => void;
}

export default MobileToggler;
