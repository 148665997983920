import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Radar from '@components/Charts/Radar';
import CircleScoreCard from '@components/CircleScoreCard';
import ScoreFilter from '@components/ScoreFilter';
import ScoreCheckbox from '@components/ScoreCheckbox';
import { CreateStarCategoryInterface } from '@interfaces/index';
import { CreateStarContext } from '@context/CreateStarContext';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import useWindowDimensions from '@hooks/useWindowDimensions';
import reviewIcon from '@assets/images/review-Icons.svg';
import { COMPARE_DATA, useGlobalState } from '@context/GlobalStore';
import styles from './index.module.scss';

const ScoresPageContent: React.FunctionComponent<ScoresPageContentProps> = (props) => {
  const { isEditable, data, showButton } = props;
  const { setCategoryQuestion } = useContext(CreateStarContext);
  const [currentData, setCurrentData] = useState<CreateStarCategoryInterface[]>(data);
  const { isMobile } = useWindowDimensions();
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [state, dispatch] = useGlobalState();
  const [diffData, setDiffData] = useState<any>([]);

  useEffect(() => {
    setCurrentData(data);
  }, [data, activeSubNav.id, state.diffCompareData]);

  const handleSelectFocusAreas = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const [categoryId, questionId] = e.currentTarget.id.split('-');
    const newData = [...currentData];
    let selectedFocusAreas = 0;
    newData.forEach((category) => {
      selectedFocusAreas += category.questions.filter((qs) => qs.isSelected).length;
    });
    if (selectedFocusAreas < 5 || !checked) {
      const currentCategory = newData.find((category) => category.id === Number(categoryId));
      const currentQuestion = currentCategory?.questions.find(
        (question) => question.id === Number(questionId),
      );
      if (currentQuestion) {
        currentQuestion.isSelected = checked;
      }

      setCategoryQuestion(newData);
      setCurrentData(newData);
    }
  };

  useEffect(() => () => dispatch({ type: COMPARE_DATA, payload: [] }), []);

  useEffect(() => {
    if (state?.diffCompareData?.oneByOneCompare?.length < 22) {
      setDiffData([
        state?.diffCompareData?.oneByOneCompare?.slice(0, 1),
        state?.diffCompareData?.oneByOneCompare?.slice(1, 5),
        state?.diffCompareData?.oneByOneCompare?.slice(5, 9),
        state?.diffCompareData?.oneByOneCompare?.slice(9, 13),
        state?.diffCompareData?.oneByOneCompare?.slice(13, 17),
        state?.diffCompareData?.oneByOneCompare?.slice(17, 21),
      ]);
    } else {
      setDiffData([
        state?.diffCompareData?.oneByOneCompare?.slice(0, 4),
        state?.diffCompareData?.oneByOneCompare?.slice(4, 8),
        state?.diffCompareData?.oneByOneCompare?.slice(8, 12),
        state?.diffCompareData?.oneByOneCompare?.slice(12, 16),
        state?.diffCompareData?.oneByOneCompare?.slice(16, 20),
        state?.diffCompareData?.oneByOneCompare?.slice(20, 24),
      ]);
    }
  }, [state?.diffCompareData?.oneByOneCompare]);

  return (
    <div>
      <div className="container-fluid">
        {isEditable && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              position: 'relative',
              marginBottom: 80,
            }}
          >
            <div>
              <h2
                style={{
                  fontSize: '40px',
                  fontWeight: '600',
                  color: '#505050',
                  marginBottom: '28px',
                }}
              >
                Select your focus areas
              </h2>
              <p style={{ fontSize: '18px', color: '#505050' }}>Scoring is only the start!</p>
              <p style={{ width: isMobile ? '100%' : 849, color: '#505050', fontSize: '18px' }}>
                Check out your scores and select up to 5 focus areas to take action on.
              </p>
            </div>
            {!isMobile && (
              <img
                src={reviewIcon}
                alt="review icons"
                style={{
                  position: 'absolute',
                  top: -60,
                  right: 0,
                  zIndex: 999,
                }}
              />
            )}
          </div>
        )}
        <div className="col-12">
          <ScoreFilter />
        </div>
        <div className="row">
          {currentData.map((radar, index) => (
            <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4">
              <Radar {...radar} showOnScorePagea compareData={diffData[index]} />
              <div className={styles.radarBox}>
                {!isEditable &&
                  radar.questions.map((score, scoreIndex) => (
                    <CircleScoreCard
                      index={score.id}
                      key={scoreIndex}
                      text={score.name}
                      color={score.color}
                      score={score.score}
                      isSelected={score.isSelected}
                      scoreDifference={score.scoreDifference}
                    />
                  ))}
                {isEditable &&
                  radar.questions.map((score, scoreIndex) => (
                    <ScoreCheckbox
                      index={score.id}
                      key={scoreIndex}
                      id={`${radar.id}-${score.id}`}
                      text={score.name}
                      color={score.color}
                      score={score.score}
                      isSelected={score.isSelected}
                      scoreDifference={score.scoreDifference}
                      onChange={handleSelectFocusAreas}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface ScoresPageContentProps {
  data: CreateStarCategoryInterface[];
  isEditable?: boolean;
  showButton?: boolean;
}

ScoresPageContent.defaultProps = {
  isEditable: false,
  showButton: false,
};

export default ScoresPageContent;
