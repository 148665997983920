import React from 'react';
import { Link } from 'react-router-dom';
import Img from '@components/Atoms/Img';
import styles from './index.module.scss';

const BlogPostCard: React.FunctionComponent<BlogPostCardProps> = ({
  postId,
  keyValue,
  backgroundImage,
  title,
  className,
  desktopRowItems = 3,
}) => (
  <Link
    to={`${postId === -999 ? '/posts' : `/post/${postId}`}`}
    key={Number(keyValue) + Number(postId)}
    className={`${className} ${desktopRowItems >= 4 ? styles.smallCard : ''}`}
  >
    <Img src={backgroundImage} alt={`${title} Image`} className={styles.cardImg} />
    <div className={styles.title}>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
    </div>

    <div className={styles.arrowButton}>
      <Link to={`${postId === -999 ? '/posts' : `/post/${postId}`}`}>
        <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 72">
          <polygon
            className={styles.blogArrowBg}
            id="Path"
            points="86.96 1.33 1.15 21.53 31.69 71.92 86.96 71.97 86.96 1.33"
          />
          <g id="Arrow">
            <g id="Group-2">
              <polygon points="50.66 49.89 48.96 48.19 61.18 35.98 48.96 23.76 50.66 22.06 64.57 35.98 50.66 49.89" />
              <rect x="36.75" y="34.78" width="26.13" height="2.4" />
            </g>
          </g>
        </svg>
      </Link>
    </div>
  </Link>
);

BlogPostCard.defaultProps = {
  className: `${styles.card} ${styles.cardItem}`,
  desktopRowItems: 3,
};

interface BlogPostCardProps extends React.HTMLAttributes<HTMLDivElement> {
  postId: number;
  keyValue: number;
  backgroundImage: string;
  title: string;
  className?: string;
  desktopRowItems?: number;
}

export default BlogPostCard;
