import { getToken } from '@api/config';
import { createHeaders } from '@api/httpHeaderConfig';

export const logoutAuthenticatedUser = async () => {
  const formattedReq = {
    token: getToken(),
  };
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    window.location.href = '/login?action=logout';
    await localStorage.clear();
    await localStorage.removeItem('redirectPath');
  }

  return { isSuccessful: true };
};
