import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { id, name, onChange, checked, disabled, className, isError } = props;

  return (
    <input
      id={id}
      name={name}
      type="checkbox"
      className={classNames([className, isError && styles.error])}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  isError: false,
  className: styles.checkbox,
  disabled: false,
};

interface CheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  isError?: boolean;
}

export default Checkbox;
