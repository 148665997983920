import DeepDiveSlider from '@components/DeepDiveQuestionContainer/Components/DeepDiveSlider';
import PopUpModal from '@components/PopUpModal';
import ShareThoughts from '@components/ShareThoughts';
import { useGlobalState } from '@context/GlobalStore';
import { getCompanyDetails } from '@helpers/auth.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import classNames from 'classnames';
import React, { Suspense, useEffect, useState } from 'react';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { RootState } from 'store';
import { Category } from 'store/Slices/DeepDiveWizardSlice';
import styles from './index.module.scss';

interface DeepDiveScoringStepProps {
  activeCategoryIndex: number;
  categoriesWithQuestions: Category[];
  sliderValues: SliderValues;
  handleSliderChange: (
    event: Event,
    newValue: number,
    itemId: number,
    questionText: string,
  ) => void;
  reduxDispatch: any;
  deepDiveWizardActions: any;
  isIOS: boolean;
}

const DeepDiveScoringStep: React.FC<DeepDiveScoringStepProps> = ({
  activeCategoryIndex,
  categoriesWithQuestions,
  sliderValues,
  handleSliderChange,
  reduxDispatch,
  deepDiveWizardActions,
  isIOS,
}) => {
  const activeCategory = categoriesWithQuestions[activeCategoryIndex];
  const [state] = useGlobalState();
  const { isMobile } = useWindowDimensions();

  const maxProgressBars = categoriesWithQuestions.length / 2;
  const totalCategories = categoriesWithQuestions.length;
  const categoriesPerSet = Math.ceil(totalCategories / maxProgressBars);
  const currentSet = Math.floor(activeCategoryIndex / categoriesPerSet);
  const indexInSet = activeCategoryIndex % categoriesPerSet;
  const totalProgressBars = maxProgressBars;
  const filledProgressBars = currentSet + (indexInSet >= categoriesPerSet - 1 ? 1 : 0);
  const { collapsed, collapseSidebar } = useProSidebar();
  const [open, setOpen] = useState<boolean>(true);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number>(0);
  const [thoughtQuestionData, setThoughtQuestionData] = useState<any>({});
  const deepDiveWizard = useAppSelector((st: RootState) => st.deepDiveWizard);
  const [currentCompanyName, setCurrentCompanyName] = useState('');

  useEffect(() => {
    if (selectedQuestionId > 0) {
      const filteredData = getQuestionDetails(
        selectedQuestionId,
        deepDiveWizard.categoriesWithQuestions,
      );
      setThoughtQuestionData(filteredData);
    }
  }, [selectedQuestionId, collapsed]);

  useEffect(() => {
    const getCurrentCompanyName = async () => {
      const comDetails = await getCompanyDetails();
      if (comDetails) {
        setCurrentCompanyName(comDetails.company_name);
      }
    };

    getCurrentCompanyName();
  }, []);

  const shareThoughts = (data: any) => {
    const { shareWith, thoughtsText } = data;

    const newCategories = deepDiveWizard.parsedCategory.map((category: any) => {
      const currentCategory = { ...category };
      const questions = currentCategory.questions.map((question: any) => {
        const currentQuestion = {
          ...question,
        };
        if (currentQuestion.id === selectedQuestionId) {
          currentQuestion.shareWith = shareWith;
          currentQuestion.thoughtsText = thoughtsText;
        }

        return currentQuestion;
      });
      currentCategory.questions = questions;

      return currentCategory;
    });

    reduxDispatch(
      deepDiveWizardActions.updateThoughtDetails({
        thoughts: thoughtsText,
        shareWith: shareWith || 'mySelf',
        questionId: selectedQuestionId,
      }),
    );

    reduxDispatch(deepDiveWizardActions.parseCategoryDetails(newCategories));
  };

  function getQuestionDetails(qId: number, data: Category[]) {
    const category = data.find((cat) => cat.questions.some((q) => q.questionId === qId));

    if (category) {
      return category.questions.find((q) => q.questionId === qId);
    }

    return null;
  }

  const sidebarClasses = classNames([
    styles.hsQuestionsContainerSidebar,
    collapsed && styles.Collapsed,
  ]);

  return (
    <>
      <div className={styles.progressBarContainer}>
        {Array.from({ length: categoriesWithQuestions.length }).map((_, index) => (
          <div
            className={classNames(styles.progressBar, {
              [styles.active]: index <= activeCategoryIndex,
              [styles.inactive]: index > activeCategoryIndex,
            })}
            key={index}
          />
        ))}
      </div>

      <div className={styles.categoryContainer}>
        {categoriesWithQuestions.length > 0 && (
          <Suspense fallback={<div>Loading...</div>}>
            <h1>{activeCategory.name}</h1>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderWrapper}>
                {activeCategory.questions.map((queItem, index) => (
                  <DeepDiveSlider
                    key={queItem.questionId}
                    questionDetails={queItem}
                    sliderTopic={queItem.name}
                    defaultValue={7}
                    step={1}
                    marks
                    minValue={1}
                    maxValue={10}
                    sliderValue={sliderValues[queItem.questionId]?.score || 7}
                    handleSliderChange={(event: Event, newValue: number) => {
                      if (isIOS && event.type === 'mousedown') {
                        return;
                      }

                      reduxDispatch(
                        deepDiveWizardActions.updateDeepDiveAnsweredScore({
                          categoryId: activeCategory.id,
                          questionId: queItem.questionId,
                          answeredScore: newValue,
                        }),
                      );
                      handleSliderChange(event, newValue, queItem.questionId, queItem.name);
                    }}
                    valueLabel="auto"
                    questionId={queItem.questionId}
                    answeredScore={queItem.answeredScore || 0}
                    colorCode={queItem.color}
                    backgroundColor={queItem.color}
                    handleQuestionId={setSelectedQuestionId}
                    indexValue={index}
                  />
                ))}
              </div>
            </div>
          </Suspense>
        )}
      </div>

      {!collapsed && state?.isUserOnBoarded && (
        <PopUpModal
          top="150px"
          right="700px"
          open={open}
          setOpen={setOpen}
          description={`Any thoughts shared with ${currentCompanyName} are anonymous. This is your chance to be open and be heard.`}
          title="Sharing your thoughts"
        />
      )}

      <Sidebar
        className={sidebarClasses}
        defaultCollapsed
        backgroundColor="white"
        collapsedWidth="0px"
        width={isMobile ? '100%' : '390px'}
      >
        {!collapsed && (
          <ShareThoughts
            id={thoughtQuestionData.questionId}
            score={thoughtQuestionData.answeredScore || 7}
            color={thoughtQuestionData.color}
            question={thoughtQuestionData.name}
            shareThoughts={(data) => shareThoughts(data)}
          />
        )}
      </Sidebar>
      <div className={styles.hsQuestionsContainerSidebarOverlay} />
    </>
  );
};

interface SliderValues {
  [key: number]: {
    questionId: number;
    questionText: string;
    score: number;
  };
}

export default DeepDiveScoringStep;
