import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getCompanyDetailsByUrl } from '@api/auth/company';
import CheckYourInbox from '@components/Templates/CheckYourInbox';

const LoginPage: React.FunctionComponent = () => {
  const { companyURL, email } = useParams<UrlParamsType>();
  const [companyDetails, setCompanyDetails] = useState<any>(null);
  let organizationLogo: string | undefined;
  let organizationAltText: string | undefined;

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetailsByUrl(companyURL);
      setCompanyDetails(data);
    };

    if (companyURL) {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null) {
    organizationLogo = `${process.env.PUBLIC_URL}/images/logos/${companyDetails.company_logo}`;
    organizationAltText = `${companyDetails.company_name} logo`;
  }

  return (
    <CheckYourInbox
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      companyURL={companyURL}
      email={email}
    />
  );
};

type UrlParamsType = {
  email: string;
  companyURL: string;
};

export default LoginPage;
