import React from 'react';
import Modal from '@components/Atoms/Modal';
import DefaultButton from '@components/Atoms/DefaultButton';
import { SelectedUser } from '../../../custom';
import styles from './index.module.scss';

const DeleteEmployee: React.FunctionComponent<DeleteEmployeeProps> = (props) => {
  const { title, isOpen, onClose, onSave, selectedUser } = props;

  const handleClose = () => {
    onClose();
  };

  if (!selectedUser) {
    return null;
  }

  const fullName = `${selectedUser.forename} ${selectedUser.surname}`;
  const currentUser = localStorage.getItem('user') as any;
  const currentUserId = currentUser ? JSON.parse(currentUser).id : undefined;

  if (currentUserId === selectedUser.id) {
    onClose();
  }

  return (
    <div className={styles.deleteModal}>
      <Modal open={isOpen} setOpen={handleClose} overlay onTapBackgroundClose>
        <h4 className={styles.title}>{title}</h4>
        <hr />
        <div className="container">
          <div>
            <h4 className={styles.bodyMessage}>
              Are you sure you wish to delete the employee? This action cannot be reversed.
            </h4>

            {selectedUser.forename && selectedUser.surname ? (
              <h5 className={styles.name}>{fullName}</h5>
            ) : (
              <h5 className={styles.name}>Unknown</h5>
            )}
          </div>
        </div>
        <hr />

        <div className={styles.actionButtons}>
          <DefaultButton type="button" onClick={onClose} className={styles.cancelBtn}>
            Cancel
          </DefaultButton>
          <DefaultButton type="submit" onClick={onSave} className={styles.confirmBtn}>
            Confirm
          </DefaultButton>
        </div>
      </Modal>
    </div>
  );
};

interface DeleteEmployeeProps {
  title: string;
  isOpen: boolean;
  onClose: () => void | undefined;
  onSave: () => Promise<void>;
  selectedUser: SelectedUser;
}

export default DeleteEmployee;
