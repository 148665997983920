import { saveReflection } from '@api/shortStar';
import AwesomeOutlined from '@assets/images/starSteps/awesome-outlined.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { isEmptyValidation } from '@helpers/validation.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import useInput, { getInputValue } from '@hooks/useInput';
import TextField from '@mui/material/TextField';
import { ShortStatContentTypes } from 'custom';
import React, { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import styles from './index.module.scss';

const ReflectionSection = ({
  handleStep,
  helpAvailability,
  handleGetHelp,
}: ReflectionSectionProps) => {
  const wizardData = useAppSelector((state: RootState) => state.shortStarWizard);
  const {
    value: reflectionText,
    isValid: reflectionTextIsValid,
    hasError: reflectionTextHasError,
    valueChangeHandler: reflectionTextChangeHandler,
    inputBlurHandler: reflectionTextBlurHandler,
    reset: reflectionTextReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (reflectionTextIsValid) {
      try {
        if (wizardData.questionnaireResponse) {
          const response = await saveReflection({
            assessmentId: wizardData.questionnaireResponse.assessment_id,
            reflection: reflectionText,
          });
          if (response.success) {
            reflectionTextReset();
            if (wizardData?.actionPlans?.length > 0) {
              await handleStep('IMPROVE_SECTION');
            } else {
              await handleStep('FOCUS_SECTION');
            }
          } else {
            toast.error('Save reflection unsuccessful');
          }
        } else {
          toast.error('Save reflection unsuccessful');
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error(`Error: ${error.message}` || 'Save reflection unsuccessful');
        } else {
          toast.error('Save reflection unsuccessful');
        }
      }
    }
  };

  return (
    <>
      <div className={styles.reflectionContainer}>
        <h3 className={styles.considerText}>Write it down</h3>
        <Img src={AwesomeOutlined} alt="AwesomeOutlined" className={styles.awesomeOutlined} />
        <p className={styles.description}>
          Just writing down your thoughts or feelings can significantly improve your wellbeing,
          offering a sense of release and clarity. This simple act of expression helps organize your
          thoughts, making it easier to navigate challenges and find peace of mind.
        </p>
        <div className={styles.inputContainer}>
          <TextField
            id="outlined-multiline-static"
            label="Your reflection"
            multiline
            rows={4}
            fullWidth
            onChange={reflectionTextChangeHandler}
            onBlur={reflectionTextBlurHandler}
          />
          <p className={styles.hsCharCounter}>{reflectionText?.length || 0}/2000 characters</p>
        </div>
        <div className={styles.continueBtn}>
          <DefaultButton
            type="button"
            onClick={handleSubmit}
            variant="secondary"
            className={styles.hsPrimaryButton}
            disabled={reflectionTextHasError || reflectionText?.length === 0 || !reflectionText}
          >
            Continue
          </DefaultButton>
        </div>
        <div
          className={styles.skipBtn}
          onClick={() => {
            if (wizardData?.actionPlans?.length > 0) {
              handleStep('IMPROVE_SECTION');
            } else {
              handleStep('FOCUS_SECTION');
            }
          }}
        >
          skip
        </div>
        {/* {helpAvailability && (
          <>
            {' '}
            <p className={styles.helpText}>
              If you’re struggling a need support, help is one click away
            </p>
            <div className={styles.helpBtn}>
              <DefaultButton
                type="button"
                onClick={() => handleGetHelp(true)}
                className={styles.hsHelpButton}
              >
                GET URGENT HELP
              </DefaultButton>
            </div>
          </>
        )} */}
      </div>
    </>
  );
};

ReflectionSection.defaultProps = {};

interface ReflectionSectionProps {
  handleStep: Dispatch<SetStateAction<ShortStatContentTypes>>;
  helpAvailability: boolean;
  handleGetHelp: (arg1: boolean) => void;
}

export default ReflectionSection;
