import { getCompanyDetailsByUrl } from '@api/auth/company';
import { getEmailTypeDetails, unsubscribeEmailAlert } from '@api/emailSubscribe';
import Card from '@components/Atoms/Card';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import { Skeleton } from '@mui/material';
import { Parser } from 'html-to-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import styles from './index.module.scss';

const EmailSubscription: React.FunctionComponent = () => {
  const location = useLocation();
  const { companyURL } = useParams<UrlParamsType>();
  const [companyDetails, setCompanyDetails] = useState<any>(null);
  let content: JSX.Element | string | undefined;
  let organizationLogo: string | undefined;
  let organizationAltText: string | undefined;
  let organizationId = 0;

  const [emailTypeDetails, setEmailTypeDetails] =
    useState<EmailSubscriptionTypeDetailsInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const queryParams = new URLSearchParams(location.search);

  const subscription = queryParams.get('subscription');
  const uId = queryParams.get('uId');
  const type = queryParams.get('type');

  useEffect(() => {
    switch (subscription) {
      case 'unsubscribe':
        unsubscribe();
        break;
      default:
        break;
    }
  }, [subscription]);

  const unsubscribe = async () => {
    try {
      setLoading(true);
      const req = await unsubscribeEmailAlert({ userUUID: uId, emailTypeId: Number(type) });
      if (req?.success) {
        const typeDetails = await getEmailTypeDetails(Number(type));
        setEmailTypeDetails(typeDetails);
      }
    } catch (error) {
      setEmailTypeDetails(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetailsByUrl(companyURL);
      setCompanyDetails(data);
    };

    if (companyURL) {
      getCompanyData();
    }
  }, [companyURL]);

  if (companyDetails !== null) {
    content = Parser().parse(companyDetails.login_text);
    organizationLogo = `${process.env.PUBLIC_URL}/images/logos/${companyDetails.company_logo}`;
    organizationAltText = `${companyDetails.company_name} logo`;
    organizationId = companyDetails.id;
  }

  return (
    <AuthenticationLayout
      title="Email Subscription"
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
    >
      <div className={styles.cardContainer}>
        {loading ? (
          <div className={styles.contentContainer}>
            <Skeleton variant="rounded" height={300} className={styles.loader} />
          </div>
        ) : (
          <Card>
            <div className={styles.contentContainer}>
              <div className={styles.content}>
                <h1>
                  {emailTypeDetails?.emailType === 'actionReminder'
                    ? 'Unsubscribed from Action Reminders'
                    : 'Unsubscribed from Email Reminders'}
                </h1>
                {(() => {
                  switch (subscription) {
                    case 'unsubscribe':
                      return (
                        <>
                          {emailTypeDetails && (
                            <div>
                              <p className={styles.message}>
                                {emailTypeDetails?.unsubscriptionMessage}
                              </p>
                              <div className={styles.checkBoxWrapper}>
                                {' '}
                                <svg
                                  className={styles.checkmark}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 52 52"
                                >
                                  {' '}
                                  <circle
                                    className={styles.checkmarkCircle}
                                    cx="26"
                                    cy="26"
                                    r="25"
                                    fill="none"
                                  />{' '}
                                  <path
                                    className={styles.checkmarkCheck}
                                    fill="none"
                                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
          </Card>
        )}
      </div>
    </AuthenticationLayout>
  );
};

type UrlParamsType = {
  companyURL: string;
};

interface EmailSubscriptionTypeDetailsInterface {
  id: number;
  emailType: string;
  subscriptionMessage: string;
  unsubscriptionMessage: string;
  createdAt: string;
  updatedAt: string;
}

export default EmailSubscription;
