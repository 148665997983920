import React from 'react';
import classNames from 'classnames';
import Modal from '@components/Atoms/Modal';
import DefaultButton from '@components/Atoms/DefaultButton';
import { Parser } from 'html-to-react';
import styles from './index.module.scss';

const ViewCommentModal: React.FunctionComponent<ViewCommentModalProps> = (props) => {
  const { title, isOpen, onClose, children } = props;

  return (
    <div className={styles.root}>
      <Modal
        open={isOpen}
        setOpen={onClose}
        overlay
        onTapBackgroundClose
        className={styles.modalRoot}
      >
        <DefaultButton color="primary" type="button" className={styles.btnClose} onClick={onClose}>
          <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
        </DefaultButton>

        <div className={styles.modalBody}>
          <div className={styles.modalTitle}>{title}</div>
          <hr />

          <div>{Parser().parse(children)}</div>
        </div>
      </Modal>
    </div>
  );
};

interface ViewCommentModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void | undefined;
  children: React.ReactNode;
}

export default ViewCommentModal;
