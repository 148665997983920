import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const logDashboardMusic = async (audioName: string) => {
  const formattedReq = {
    audioName,
  };
  const result = await fetch(`${process.env.REACT_APP_API_URL}/log-audio-played`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (result.ok) {
    const responseData = await result.json();

    return { success: true, data: responseData };
  }

  const errorData = await result.json();

  return { success: false, error: errorData };
};
