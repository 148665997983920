import React from 'react';
import classNames from 'classnames';
import LinkButton from '@components/LinkButton';
import FormButton from '@components/FormButton';
import styles from './index.module.scss';

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const { isButton, path, type, isSubmit, onClick, children, isDisabled = false, width } = props;

  const buttonClasses = classNames([
    styles.hsButton,
    type === 'primary' && styles.hsPrimary,
    type === 'secondary' && styles.hsSecondary,
    type === 'tertiary' && styles.hsTertiary,
    type === 'icon' && styles.hsIconButton,
    type === 'more' && styles.hsShowMoreButton,
    type === 'small' && styles.hsSmall,
    type === 'close' && styles.hsClose,
    type === 'darken' && styles.hsDarken,
  ]);

  if (isButton && onClick) {
    return (
      <FormButton
        isSubmit={isSubmit}
        isDisabled={isDisabled}
        className={buttonClasses}
        width={width}
        onClick={onClick}
      >
        {children}
      </FormButton>
    );
  }

  if (path) {
    return (
      <LinkButton path={path} className={buttonClasses}>
        {children}
      </LinkButton>
    );
  }

  return null;
};

interface ButtonProps {
  isButton?: boolean;
  path?: string | undefined;
  isSubmit?: boolean;
  isDisabled?: boolean;
  width?: string;
  onClick?: () => void;
  type: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'more' | 'small' | 'close' | 'darken';
  children: React.ReactNode;
}

Button.defaultProps = {
  isButton: false,
  width: '',
  path: undefined,
  isSubmit: false,
  isDisabled: false,
  onClick: undefined,
};

export default Button;
