import DefaultButton from '@components/Atoms/DefaultButton';
import { useAppSelector } from '@hooks/useAppSelector';
import { ShortStatContentTypes } from 'custom.d';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RootState } from 'store';
import styles from './index.module.scss';

const ScoreDifferenceSection = ({ handleStep }: ScoreDifferenceSectionProps) => {
  const wizardData = useAppSelector((state: RootState) => state.shortStarWizard);
  const [scoreDiff, setScoreDiff] = useState<ComparisonData | null>(null);
  const [questionData, setQuestionData] = useState<{
    low: { questionId: number; questionText: string; score: number }[];
    high: { questionId: number; questionText: string; score: number }[];
  }>({ low: [], high: [] });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let generalHappinessQuestionWithoutImagePath = {};
    if (wizardData.generalHappinessQuestion) {
      generalHappinessQuestionWithoutImagePath = {
        questionId: wizardData.generalHappinessQuestion.questionId,
        questionText: wizardData.generalHappinessQuestion.questionText,
        score: wizardData.generalHappinessQuestion.score,
      };
    }

    const modifiedQuestionData = [
      generalHappinessQuestionWithoutImagePath,
      ...Object.values(wizardData.sliderStates),
    ];

    const filteredLow = modifiedQuestionData.filter((item) => item.score < 7);
    const filteredHigh = modifiedQuestionData.filter((item) => item.score >= 7);
    setQuestionData({ low: filteredLow, high: filteredHigh });
  }, [wizardData]);

  return (
    <div className={styles.step3Container}>
      <div className={styles.confetti}>
        <div className={styles.titleContainer}>
          <h3>Congratulations!</h3>
          <h5>You have successfully finished your assessment</h5>
        </div>
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
        <div className={styles.confettiPiece} />
      </div>

      {loading ? (
        <div className={styles.checkBoxWrapper}>
          {' '}
          <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            {' '}
            <circle className={styles.checkmarkCircle} cx="26" cy="26" r="25" fill="none" />{' '}
            <path className={styles.checkmarkCheck} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>
      ) : (
        <div className={styles.predictSection}>
          {questionData?.low?.length > 0 ? (
            <>
              <h5>
                You scored low for{' '}
                <span>{questionData?.low?.length.toString().padStart(2, '0')}</span> questions, but
                it&apos;s ok, happiness is a journey.
              </h5>

              <div className={styles.questionsCardSection}>
                {questionData.low.map((item, index) => (
                  <div key={index} className={`${styles.card} ${styles.cardItem}`}>
                    <div className={styles.title}>
                      <h2>{item.questionText}</h2>
                    </div>
                    <div className={styles.circledScore}>
                      <span className={styles.score}>{item.score.toString().padStart(2, '0')}</span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <h5>You scored well for all the questions</h5>
          )}
        </div>
      )}

      <div className={styles.continueBtn}>
        <DefaultButton
          type="button"
          onClick={() => handleStep('REFLECTION_SECTION')}
          variant="secondary"
          className={styles.hsPrimaryButton}
          disabled={loading}
        >
          Continue
        </DefaultButton>
      </div>
    </div>
  );
};

ScoreDifferenceSection.defaultProps = {};

interface ScoreDifferenceSectionProps {
  handleStep: Dispatch<SetStateAction<ShortStatContentTypes>>;
}

interface ComparisonData {
  latestAverage: number;
  previousAverage: number;
  scoreDifference: number;
  oneByOneCompare: QuestionComparison[];
}

interface QuestionComparison {
  id: number;
  questionText: string;
  scoreDifference: number;
}

export default ScoreDifferenceSection;
