import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface shortStarModalOpenAssessmentConfig {
  assessmentId: number;
  assessmentScore: number;
  createdAt: string;
  questionnaireId: number;
  updatedAt: string;
  userId: number;
}

interface ConfigurationState {
  shortStarModalOpenAssessment: shortStarModalOpenAssessmentConfig | null;
  daysSinceAssessmentCreated: number | null;
  shortStarModalForce: boolean;
}

const initialState: ConfigurationState = {
  shortStarModalOpenAssessment: null,
  daysSinceAssessmentCreated: null,
  shortStarModalForce: false,
};

const configurationSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    addLatestAssessmentScore: (
      state,
      action: PayloadAction<shortStarModalOpenAssessmentConfig>,
    ) => ({
      ...state,
      shortStarModalOpenAssessment: action.payload,
      daysSinceAssessmentCreated: calculateDaysSince(action.payload.createdAt), // Calculate days difference
    }),
    forceShortStarModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      shortStarModalForce: action.payload,
    }),
    resetConfigurations: () => initialState,
  },
});

const calculateDaysSince = (createdAt: string): number => {
  const createdAtDate = new Date(createdAt);
  const today = new Date();
  const differenceInTime = today.getTime() - createdAtDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

export const configurationSliceActions = configurationSlice.actions;

export default configurationSlice;
