export const emailValidation = (value: string) => {
  const emailValidationRegex = new RegExp(/^\w+([\-\.\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/); // eslint-disable-line

  return emailValidationRegex.test(value);
};

export const isEmptyValidation = (value: string) => value.trim() !== '';

export const isNullValidation = (value: any) => value !== null;

export const noValidation = (value: any) => true;
