import React, { useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import FormInputText from '@components/FormInputText';
import FormInputSelect from '@components/FormInputSelect';
import { FieldErrors, Controller, Control } from 'react-hook-form';
import { SelectableOptionType } from '../../custom';
import styles from './index.module.scss';

const FormFieldsUser: React.FunctionComponent<FormFieldsUserProps> = (props) => {
  const {
    errors,
    register,
    control,
    teams,
    locations,
    jobTitles,
    jobTypes,
    onAddTeam,
    loading,
    loadingTeams,
    loadingLocations,
    loadingJobTitles,
    loadingJobTypes,
    onAddLocation,
    onAddJobTitle,
    onAddJobType,
    isEdit,
    getValues,
    setValue,
  } = props;

  useEffect(() => {
    if (!getValues('isAdmin')) {
      setValue('speakupAlertEligibility', false);
    }
  }, [getValues('isAdmin')]);

  const genderOptions = [
    { label: '-', value: 'non_specified' },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Non-binary', value: 'non_binary' },
  ];

  return (
    <div className={styles.flexContainer}>
      <div>
        <FormInputText
          autoFocus
          placeholder="Forename"
          title="Forename"
          disabled={loading}
          isError={Boolean(errors.forename)}
          {...register('forename')}
        />
      </div>

      <div>
        <FormInputText
          placeholder="Surname"
          title="Surname"
          disabled={loading}
          isError={Boolean(errors.surname)}
          {...register('surname')}
        />
      </div>

      {!isEdit && (
        <div>
          <FormInputText
            placeholder="Email"
            title="Email"
            type="email"
            disabled={loading}
            isError={Boolean(errors.email)}
            {...register('email')}
          />
        </div>
      )}

      <div>
        <div className={styles.formLabel}>Job Type</div>
        <Controller
          control={control}
          name="jobType"
          render={({ field }) => (
            <CreatableSelect
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                  borderWidth: 2,
                  borderRadius: 10,
                  minHeight: 55,
                }),
                multiValue: (st) => ({
                  ...st,
                  backgroundColor: '#3FB9FC',
                  color: '#FFF',
                }),
                multiValueLabel: (st) => ({
                  ...st,
                  color: '#FFF',
                }),
              }}
              placeholder="Enter or select"
              allowCreateWhileLoading={false}
              isLoading={loadingJobTypes}
              isSearchable
              isClearable
              options={jobTypes}
              onCreateOption={onAddJobType}
              {...field}
            />
          )}
        />
      </div>

      <div>
        <div className={styles.formLabel}>Team</div>
        <Controller
          control={control}
          name="team"
          render={({ field }) => (
            <CreatableSelect
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                  borderWidth: 2,
                  borderRadius: 10,
                  minHeight: 55,
                }),
                multiValue: (st) => ({
                  ...st,
                  backgroundColor: '#3FB9FC',
                  color: '#FFF',
                }),
                multiValueLabel: (st) => ({
                  ...st,
                  color: '#FFF',
                }),
              }}
              placeholder="Enter or select"
              allowCreateWhileLoading={false}
              isLoading={loadingTeams}
              isSearchable
              isClearable
              options={teams}
              onCreateOption={onAddTeam}
              {...field}
            />
          )}
        />
      </div>

      <div>
        <div className={styles.formLabel}>Location</div>
        <Controller
          control={control}
          name="location"
          render={({ field }) => (
            <CreatableSelect
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                  borderWidth: 2,
                  borderRadius: 10,
                  minHeight: 55,
                }),
                multiValue: (st) => ({
                  ...st,
                  backgroundColor: '#3FB9FC',
                  color: '#FFF',
                }),
                multiValueLabel: (st) => ({
                  ...st,
                  color: '#FFF',
                }),
              }}
              placeholder="Enter or select"
              allowCreateWhileLoading={false}
              isLoading={loadingLocations}
              isSearchable
              isClearable
              options={locations}
              onCreateOption={onAddLocation}
              {...field}
            />
          )}
        />
      </div>

      <div>
        <div className={styles.formLabel}>Job Title</div>
        <Controller
          control={control}
          name="jobTitle"
          render={({ field }) => (
            <CreatableSelect
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#3FB9FC' : '#ECE7DC',
                  borderWidth: 2,
                  borderRadius: 10,
                  minHeight: 55,
                }),
                multiValue: (st) => ({
                  ...st,
                  backgroundColor: '#3FB9FC',
                  color: '#FFF',
                }),
                multiValueLabel: (st) => ({
                  ...st,
                  color: '#FFF',
                }),
              }}
              placeholder="Enter or select"
              allowCreateWhileLoading={false}
              isLoading={loadingJobTitles}
              isSearchable
              isClearable
              options={jobTitles}
              onCreateOption={onAddJobTitle}
              {...field}
            />
          )}
        />
      </div>

      <div>
        <FormInputSelect
          isError={Boolean(errors.sex)}
          title="Gender"
          variant="secondary"
          {...register('sex')}
        >
          <option value="non_specified">-</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="non_binary">Non-binary</option>
        </FormInputSelect>
      </div>

      <div>
        <FormInputSelect
          isError={Boolean(errors.seniority)}
          title="Seniority"
          variant="secondary"
          {...register('seniority')}
        >
          <option value="non_specified">-</option>
          <option value="junior">Junior</option>
          <option value="mid_level">Mid-level</option>
          <option value="senior">Senior</option>
          <option value="c_level">C-level</option>
        </FormInputSelect>
      </div>

      <div>
        <FormInputText
          isError={Boolean(errors.payLevel)}
          disabled={loading}
          title="Pay Level"
          placeholder="£"
          type="number"
          {...register('payLevel')}
        />
      </div>

      <div>
        <div className={styles.formLabel}>People Lead</div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="peopleLeadSwitch"
            {...register('peopleLead')}
          />
        </div>
      </div>

      <div>
        <div className={styles.formLabel}>Admin</div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="isAdminSwitch"
            {...register('isAdmin')}
          />
        </div>
      </div>

      {getValues('isAdmin') && (
        <div>
          <div className={styles.formLabel}>Receive Speak Up Alerts</div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="speakupAlertEligibility"
              disabled={!getValues('isAdmin')}
              {...register('speakupAlertEligibility')}
            />
          </div>
        </div>
      )}

      <div>
        <FormInputText
          isError={Boolean(errors.dob)}
          disabled={loading}
          title="D.O.B"
          type="date"
          {...register('dob')}
        />
      </div>

      <div>
        <FormInputText
          isError={Boolean(errors.joined)}
          disabled={loading}
          title="Joined"
          type="date"
          {...register('joined')}
        />
      </div>
    </div>
  );
};

interface FormFieldsUserProps {
  isEdit?: boolean;
  control: Control<any>;
  register: any;
  errors: FieldErrors;
  teams: Array<SelectableOptionType>;
  locations: Array<SelectableOptionType>;
  jobTitles: Array<SelectableOptionType>;
  jobTypes: Array<SelectableOptionType>;
  onAddTeam: (name: string) => void;
  onAddLocation: (name: string) => void;
  onAddJobTitle: (name: string) => void;
  onAddJobType: (name: string) => void;
  loadingTeams: boolean;
  loadingLocations: boolean;
  loadingJobTitles: boolean;
  loadingJobTypes: boolean;
  loading: boolean;
  getValues: any;
  setValue: any;
}

FormFieldsUser.defaultProps = {
  isEdit: false,
};

export default FormFieldsUser;
