import MainLayout from '@layouts/MainLayout/indexNew';
import React, { useEffect, useState } from 'react';
import InstantHelp from '@components/Organisms/InstantHelp';
import { getInstantHelpData } from 'store/Actions/InstantHelpAction';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { RootState } from 'store';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import { useLocation } from 'react-router-dom';
import { getIpAddress } from '@api/auth/login';
import { LogTypes } from 'custom.d';
import { logInstantHelpStarDetails } from '@api/instantHelp';
import useGoNative from '@hooks/useGoNative';
import { Skeleton } from '@mui/material';
import styles from './InstantHelpPage.module.scss';

const InstantHelpPage: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isMobileApp = useGoNative();
  const instantHelpData = useAppSelector((state: RootState) => state.instantHelp);
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const [loading, setLoading] = useState(false);

  const logStarInstantHelp = async () => {
    const payload = {
      logType: LogTypes.INSTANT_HELP_NAVIGATED,
      ipAddress: await getIpAddress(),
      isNative: isMobileApp,
      os: navigator.userAgentData?.platform || '',
    };

    await logInstantHelpStarDetails(
      payload.logType,
      payload.ipAddress,
      payload.os,
      payload.isNative,
    );
  };

  useEffect(() => {
    if (location.pathname.includes('wellbeing')) {
      logStarInstantHelp();
    }
  }, [location]);

  useEffect(() => {
    async function getInstantData() {
      await setLoading(true);
      await dispatch(getInstantHelpData());

      setLoading(false);
    }

    if (!instantHelpData.isLoaded) {
      getInstantData();
    }
  }, [dispatch, instantHelpData.isLoaded]);

  return (
    <MainLayout title="Hub" secondaryNavEnabled>
      {instantHelpData.error && !loading && <p>Unable to fetch wellbeing data</p>}

      {loading && (
        <div className="container">
          <div className={styles.loadingContainer}>
            <Skeleton variant="rounded" width="50%" height={50} />

            <div className={styles.loadingDesc}>
              <Skeleton variant="rounded" width="100%" height={20} />
              <Skeleton variant="rounded" width="100%" height={20} />
              <Skeleton variant="rounded" width="100%" height={20} />
            </div>
            <Skeleton variant="rounded" width="100%" height="100vh" />
          </div>
        </div>
      )}

      {instantHelpData.data.length > 0 && !instantHelpData.loading && !loading && (
        <InstantHelp
          title="Wellbeing Hub"
          subText={companyConfigData?.data?.instantHelpDescription || ''}
          instantData={instantHelpData.data}
          loading={instantHelpData.loading}
        />
      )}

      {instantHelpData.data.length === 0 && !instantHelpData.loading && !loading && (
        <div className={styles.noDataContainer}>
          <h3>No data found</h3>
        </div>
      )}
    </MainLayout>
  );
};

export default InstantHelpPage;
