import React, { useState, useMemo } from 'react';
import InstantBorderedBox from '@components/Molecules/InstantBorderedBox';
import { logInstantHelpActions } from '@api/instantHelp';
import { InstantHelpData, InstantHelpSection } from 'custom';
import TabButton from '@components/Atoms/TabButton';
import MobileToggler from '@layouts/MainLayout/SecondaryNavigation/components/MobileToggler';
import { Skeleton } from '@mui/material';
import styles from './index.module.scss';

const InstantHelp: React.FunctionComponent<InstantHelpProps> = ({
  title,
  subText,
  instantData,
  loading,
}) => {
  const [selectedTab, setSelectedTab] = useState<{ id: number; label: string; labelId: number }>({
    id: 0,
    label: '',
    labelId: 1,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [actionsLoading, setActionsLoading] = useState(false);

  const logInstantActionClick = async (sectionId: number, actionId: number) => {
    await logInstantHelpActions(actionId, sectionId);
  };

  function filterSectionsByTag(data: InstantHelpData[], targetTagId: number): InstantHelpSection[] {
    setActionsLoading(true);

    setTimeout(() => {
      setActionsLoading(false);
    }, 1000);

    return data.filter((item) => item.id === targetTagId).flatMap((item) => item.sections);
  }

  const filteredSections = useMemo(() => {
    if (instantData) {
      return filterSectionsByTag(instantData, selectedTab.labelId);
    }

    return [];
  }, [instantData, selectedTab.labelId]);

  const tabButtons =
    instantData?.map((data: InstantHelpData) => ({
      title: data?.tag?.length > 0 ? data?.tag : 'Default' || 'Default',
      id: data?.id,
    })) || [];

  const getListItemClassName = (tag: string) =>
    tag === (selectedTab.label ? selectedTab.label : instantData ? instantData[0]?.tag : 'Default')
      ? styles.active
      : '';

  return (
    <div className={styles.instantHelpContainer}>
      <div className={styles.instantHelpTopBox}>
        <div className="container">
          <div className={styles.content}>
            <h1 className={styles.titleText}>{title}</h1>
            <p className={styles.middleText}>{subText}</p>
          </div>
        </div>
        <div className={styles.abstractImg} />
      </div>

      {instantData && instantData?.length > 0 && (
        <div className="container">
          {loading ? (
            <div className={styles.loadingContainer}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only" />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.tabSection}>
                <TabButton
                  selectedTab={selectedTab}
                  handleSelect={(tab) => setSelectedTab(tab)}
                  tabs={tabButtons}
                />
              </div>

              <div className={styles.hsSubNavigation}>
                <MobileToggler
                  openHandler={setIsOpen}
                  isOpen={isOpen}
                  name={
                    selectedTab.label
                      ? selectedTab.label
                      : instantData
                      ? instantData[0]?.tag
                      : 'Default'
                  }
                />
                {isOpen && (
                  <ul>
                    {instantData?.map((dt: InstantHelpData, index: number) => (
                      <li key={index} className={getListItemClassName(dt.tag)}>
                        <a
                          onClick={() => {
                            setSelectedTab({ id: dt.id, label: dt.tag, labelId: dt.id });
                            setIsOpen(false);
                          }}
                        >
                          {dt.tag}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className={styles.box}>
                <div className={styles.borderBoxContainer}>
                  {actionsLoading ? (
                    <>
                      {[0, 1, 2].map((item, index) => (
                        <Skeleton
                          key={index}
                          variant="rounded"
                          className={styles.skeletonActionCard}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {filteredSections.map((section: InstantHelpSection, index: number) => (
                        <InstantBorderedBox
                          key={`${section.id}-${index}`}
                          data={section}
                          keyIndex={index}
                          actions={section.instantHelpActions}
                          handleOnClick={logInstantActionClick}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

InstantHelp.defaultProps = {
  title: '',
  subText: '',
  instantData: [],
  loading: false,
};

interface InstantHelpProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  title?: string;
  subText?: string;
  instantData?: InstantHelpData[];
  loading?: boolean;
}

export default InstantHelp;
