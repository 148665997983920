export interface Category {
  categoryId: number;
  name: string;
  color: string;
}

export const CategoryColorNames: Category[] = [
  { categoryId: 1, name: 'General happiness', color: 'skyBlue' },
  { categoryId: 2, name: 'Wellness', color: 'calmLilac' },
  { categoryId: 3, name: 'Relationships', color: 'sunYellow' },
  { categoryId: 4, name: 'Higher thoughts', color: 'zingyGreen' },
  { categoryId: 5, name: 'Activity', color: 'aquaGreen' },
  { categoryId: 6, name: 'Lifestyle', color: 'warmOrange' },
  { categoryId: 7, name: 'Happiness in work', color: 'skyBlue' },
  { categoryId: 8, name: 'Team', color: 'calmLilac' },
  { categoryId: 9, name: 'Wellness in work', color: 'sunYellow' },
  { categoryId: 10, name: 'Job', color: 'zingyGreen' },
  { categoryId: 11, name: 'Organisation', color: 'aquaGreen' },
  { categoryId: 12, name: 'Overall career', color: 'warmOrange' },
  { categoryId: 13, name: 'Emotional wellbeing', color: 'skyBlue' },
  { categoryId: 14, name: 'Daily Thoughts', color: 'calmLilac' },
  { categoryId: 15, name: 'My Inner Self', color: 'sunYellow' },
  { categoryId: 16, name: 'Coping with Stress', color: 'zingyGreen' },
  { categoryId: 17, name: 'Mental Health Support', color: 'aquaGreen' },
  { categoryId: 18, name: 'Growth and Resilience', color: 'warmOrange' },
  { categoryId: 19, name: 'Default Short Star', color: 'warmOrange' },
  { categoryId: 20, name: 'Sleep', color: 'skyBlue' },
  { categoryId: 21, name: 'Fitness', color: 'calmLilac' },
  { categoryId: 22, name: 'Diet and Nutrition', color: 'sunYellow' },
  { categoryId: 23, name: 'Physical Appearance', color: 'zingyGreen' },
  { categoryId: 24, name: 'Health Management', color: 'aquaGreen' },
  { categoryId: 25, name: 'Health Maintenance', color: 'warmOrange' },
  { categoryId: 26, name: 'Income', color: 'skyBlue' },
  { categoryId: 27, name: 'Spending', color: 'calmLilac' },
  { categoryId: 28, name: 'Savings', color: 'sunYellow' },
  { categoryId: 29, name: 'Money Management', color: 'zingyGreen' },
  { categoryId: 30, name: 'Planning', color: 'aquaGreen' },
  { categoryId: 31, name: 'Knowledge', color: 'warmOrange' },
  { categoryId: 32, name: 'Friendships', color: 'skyBlue' },
  { categoryId: 33, name: 'Family', color: 'calmLilac' },
  { categoryId: 34, name: 'Relationships', color: 'aquaGreen' },
  { categoryId: 35, name: 'Interests', color: 'warmOrange' },
  { categoryId: 36, name: 'Work Life', color: 'warmOrange' },
  { categoryId: 37, name: 'Digital Life', color: 'warmOrange' },
];
