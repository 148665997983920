import React from 'react';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import useWindowDimensions from '@hooks/useWindowDimensions';
import SignUp from '@components/Organisms/SignUp';
import styles from './index.module.scss';

const Registration: React.FunctionComponent<RegistrationProps> = (props) => {
  const { content, organizationLogo, organizationAltText, organizationId, companyURL } = props;
  const { isMobile } = useWindowDimensions();

  return (
    <AuthenticationLayout
      title="SignUp"
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
    >
      <div className={styles.cardContainer}>
        <Card>
          <div className={styles.registrationContentContainer}>
            <div className={styles.registrationContent}>
              {organizationLogo && isMobile && (
                <div className={styles.organizationLogoHolder}>
                  <Img
                    alt={organizationAltText || 'Organization logo'}
                    src={organizationLogo}
                    className={styles.img}
                  />
                </div>
              )}
              <h1>Sign-up to Hapstar</h1>
              <div className={styles.registrationDetails}>
                <SignUp content={content} organizationId={organizationId} companyURL={companyURL} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </AuthenticationLayout>
  );
};

Registration.displayName = 'Registration';

Registration.defaultProps = {
  organizationLogo: undefined,
  organizationAltText: undefined,
  organizationId: undefined,
  companyURL: undefined,
};

interface RegistrationProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  content: JSX.Element | string | undefined;
  organizationLogo?: string | undefined;
  organizationAltText?: string | undefined;
  organizationId?: number;
  companyURL?: string;
}

export default Registration;
