import React, { ReactNode, useEffect } from 'react';
import styles from './index.module.scss';

const Modal = ({
  className,
  open,
  setOpen,
  onTapBackgroundClose,
  overlay,
  children,
}: ModalProps) => {
  useEffect(() => {
    const handleBodyScroll = () => {
      document.body.classList.toggle('modal-open', open);
      document.body.style.overflow = open ? 'hidden' : 'auto';
    };

    handleBodyScroll();

    return () => {
      document.body.classList.remove('modal-open');
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <>
      {open && (
        <div>
          {overlay && (
            <div
              className={styles.overlay}
              onClick={() => onTapBackgroundClose && setOpen(false)}
              style={{ height: window.innerHeight + 550 }}
            />
          )}
          <div className={`${className} ${styles.modal}`}>{children}</div>
        </div>
      )}
    </>
  );
};

Modal.defaultProps = {
  className: styles.root,
  overlay: false,
  children: null,
  onTapBackgroundClose: false,
};

interface ModalProps {
  className?: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  onTapBackgroundClose?: boolean;
  overlay?: boolean;
  children?: ReactNode;
}

export default Modal;
