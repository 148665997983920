import { CompanyConfigData } from 'custom';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getCompanyConfig = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/company-config`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch company config data: ${response?.statusText}`);
  }

  const result: CompanyConfigData = await response.json();

  return result;
};
