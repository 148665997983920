import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const logActionAlertEmail = async (
  logType: string,
  ipAddress: string,
  os: string,
  isNative: boolean,
) => {
  const formattedReq = {
    logType,
    ipAddress,
    os,
    isNative,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/log-action-alert-click`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(`Failed to log action alert email click: ${response.statusText}`);
    throw error;
  }

  return response.json();
};
