import DefaultButton from '@components/Atoms/DefaultButton';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { ShortStatContentTypes } from 'custom.d';
import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { shortStarWizardActions } from 'store/Slices/ShortStarWizardSlice';
import styles from './index.module.scss';

const ImproveSection = ({ handleStep }: ImproveSectionProps) => {
  const wizardData = useAppSelector((state: RootState) => state.shortStarWizard);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const improvePlans = wizardData.actionPlans.map((plan) => plan?.question?.questionText);

  const handleContinue = async () => {
    if (improvePlans.length === 1) {
      history.push(
        `/improve-plans/?improveAreaId=${wizardData.actionPlans[0]?.id || null}&improvePlan=${
          improvePlans[0]
        }`,
      );
    } else {
      history.push('/improve-plans');
    }

    dispatch(shortStarWizardActions.resetWizard());
    handleStep('COMPLETE_SHORT_STAR');
  };

  const formattedImprovementPlans =
    improvePlans.length > 1 ? improvePlans.join(', ') : improvePlans[0];

  return (
    <div className={styles.focusSelectionSection}>
      <div className={styles.headingContainer}>
        <h4 className={styles.title}>Thank you for scoring again, great job 🙌</h4>
      </div>

      <div className={styles.continueContainer}>
        <h4 className={styles.title}>
          Remember, you have <span>{formattedImprovementPlans}</span>
          {improvePlans.length > 1 ? ' plans' : ' plan'}. Click continue.
        </h4>

        <div className={styles.continueBtnContainer}>
          <DefaultButton
            variant="primary"
            type="button"
            className={styles.continueBtnPrimary}
            onClick={() => {
              handleContinue();
            }}
          >
            CONTINUE
          </DefaultButton>
        </div>
      </div>

      <div className={styles.differentAreaContainer}>
        <h4 className={styles.title}>Or, select a different focus area</h4>
        <div className={styles.secondaryBtnContainer}>
          <DefaultButton
            variant="primary"
            type="button"
            className={styles.secondaryBtn}
            onClick={() => handleStep('FOCUS_SECTION')}
          >
            Choose Different Area
          </DefaultButton>
        </div>
      </div>

      <div className={styles.returnToPlatformContainer}>
        <h4 className={styles.title}>Not for now, close and take me to the platform.</h4>
        <div className={styles.secondaryBtnContainer}>
          <DefaultButton
            variant="primary"
            type="button"
            className={styles.secondaryBtn}
            onClick={() => {
              handleContinue();
            }}
            path="/"
          >
            Return To Platform
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

ImproveSection.defaultProps = {};

interface ImproveSectionProps {
  handleStep: Dispatch<SetStateAction<ShortStatContentTypes>>;
}

export default ImproveSection;
