import CustomSlider from '@components/Atoms/DefaultSlider';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import { useGlobalState } from '@context/GlobalStore';
import { useAppSelector } from '@hooks/useAppSelector';
import React, { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { RootState } from 'store';
import { Question } from 'store/Slices/DeepDiveWizardSlice';
import styles from './index.module.scss';

const DeepDiveSlider: React.FunctionComponent<DeepDiveSliderProps> = (props) => {
  const {
    sliderTopic,
    defaultValue,
    step,
    marks,
    minValue,
    maxValue,
    sliderValue,
    handleSliderChange,
    valueLabel,
    colorCode,
    backgroundColor,
    questionDetails,
    questionId,
    answeredScore,
    handleQuestionId,
    indexValue,
  } = props;
  const [state] = useGlobalState();

  const { collapsed, collapseSidebar } = useProSidebar();
  const deepDiveWizard = useAppSelector((st: RootState) => st.deepDiveWizard);

  useEffect(() => {
    if (!collapsed) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [collapsed]);

  const handleClick = (queId: number) => {
    handleQuestionId(queId);
  };

  const isButtonsDisabled = () => {
    let isDisabled = true;
    if (questionDetails && questionDetails.answeredScore && questionDetails.answeredScore > 0) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>
          {sliderTopic}&nbsp;{' '}
          <Tooltip
            tip={questionDetails?.questionInfo || ''}
            placement={indexValue === 0 ? 'bottom' : 'auto'}
          />
        </div>
        {deepDiveWizard.thoughtsSharing !== undefined && !deepDiveWizard.thoughtsSharing ? (
          <></>
        ) : (
          <div className={styles.thoughtButtonContainer}>
            <Button
              type="small"
              isButton
              onClick={() => {
                handleClick(questionId);
                collapseSidebar(false);
              }}
            >
              Share thoughts
            </Button>
          </div>
        )}
        <div
          className={`${styles.sliderContainer} ${
            deepDiveWizard.thoughtsSharing !== undefined && !deepDiveWizard.thoughtsSharing
              ? styles.sliderContainerNull
              : ''
          }`}
        >
          <div className={styles.sliderWrapper}>
            <CustomSlider
              aria-label="Small steps"
              defaultValue={defaultValue}
              step={step}
              marks={marks}
              minValue={minValue}
              maxValue={maxValue}
              sliderValue={typeof sliderValue === 'number' ? sliderValue : 0}
              handleSliderChange={handleSliderChange}
              valueLabel={valueLabel}
              colorCode={colorCode}
              backgroundColor={backgroundColor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DeepDiveSlider.defaultProps = {
  minValue: 0,
  maxValue: 10,
  sliderValue: 7,
  step: 1,
  defaultValue: 7,
  valueLabel: 'on',
  questionDetails: null,
  indexValue: 0,
};

type DeepDiveSliderProps = {
  sliderTopic: string;
  sliderValue?: number;
  handleSliderChange: any;
  defaultValue?: number;
  step?: number;
  marks: boolean;
  minValue?: number;
  maxValue?: number;
  valueLabel?: 'on' | 'off' | 'auto';
  colorCode: string;
  backgroundColor: string;
  questionDetails?: Question | null;
  questionId: number;
  answeredScore: number;
  handleQuestionId: (questionId: number) => void;
  indexValue?: number;
};

export default DeepDiveSlider;
