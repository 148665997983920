import { HelpLimitData, SpeakUpResponseInterface } from 'custom';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getInstantHelpPageData = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/instant-help`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    throw new Error(`Failed to fetch instant help page data: ${response.statusText}`);
  }

  return response.json();
};

export const logInstantHelpActions = async (
  instantHelpActionsId: any,
  instantHelpSectionsForCompaniesId: any,
) => {
  const formattedReq = {
    instant_help_actions_id: instantHelpActionsId,
    instant_help_sections_for_companies_id: instantHelpSectionsForCompaniesId,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/log-instant-help-actions`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(`Failed to log instant help actions: ${response.statusText}`);
    throw error;
  }

  return response.json();
};

export const getInstantHelpLimits = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/instant-help-limits`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch instant limits: ${response?.statusText}`);
  }

  const result: HelpLimitData[] = await response.json();

  return result;
};

export const shareSpeakupThoughts = async (
  assessmentId: number,
  message: string,
  shareMyIdentity: boolean,
) => {
  const formattedReq = {
    assessment_id: assessmentId,
    message,
    share_my_identity: shareMyIdentity,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/instant-help-speak_up`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(`Failed to log instant help actions: ${response.statusText}`);
    throw error;
  }

  return response.json();
};

export const logInstantHelpStarDetails = async (
  logType: string,
  ipAddress: string,
  os: string,
  isNative: boolean,
  triggers?: { question_id: number; limit: number; assessment_id: number }[],
) => {
  const formattedReq = {
    logType,
    ipAddress,
    os,
    isNative,
    triggers,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/log-instant-help-start-details`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(`Failed to log instant help start details: ${response.statusText}`);
    throw error;
  }

  return response.json();
};

export const getHelpResponses = async (
  assessmentId: number | null,
  questionnaireId: number | null,
  responded: boolean | null,
) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/instant-help-speak-ups`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify({ assessmentId, questionnaireId, responded }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to speak responses: ${response?.statusText}`);
  }

  const result: SpeakUpResponseInterface[] = await response.json();

  return result;
};

export const updateSpeakupResponse = async (seen: boolean, speakUpResponseId: number) => {
  const formattedReq = {
    seen,
    speakUpResponseId,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/instant-help-speak-up-response-update`,
    {
      method: 'PUT',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(`Failed to update response details: ${response.statusText}`);
    throw error;
  }

  return response.json();
};
