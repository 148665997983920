/* eslint-disable react/jsx-key */
import React, { useCallback } from 'react';
import { Column, usePagination, useTable } from 'react-table';
import currency from 'currency.js';
import TableActionsMenu from '@components/TableActionsMenu/TableActionsMenu';
import {
  getUserGenderLabel,
  getUserRoleLabel,
  getUserSeniorityLabel,
} from '@helpers/general.helpers';
import { format } from 'date-fns';
import { User } from '../../custom';
import styles from './index.module.scss';

const UserTable: React.FunctionComponent<UserTableProps> = (props) => {
  const { users, onSelectAction } = props;

  const actionsMenu = useCallback(
    (rowProps: any) => <TableActionsMenu onSelect={onSelectAction} values={rowProps.row.values} />,
    [onSelectAction],
  );

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'row' as keyof User,
      },
      {
        Header: 'ID',
        accessor: 'id' as keyof User,
        show: false,
      },
      {
        Header: 'Surname',
        accessor: 'surname' as keyof User,
      },
      {
        Header: 'Forename',
        accessor: 'forename' as keyof User,
      },
      {
        Header: 'Email',
        accessor: 'email' as keyof User,
      },
      {
        Header: 'Role',
        accessor: 'role' as keyof User,
      },
      {
        Header: 'Organisation',
        accessor: 'company' as keyof User,
        show: false,
      },
      {
        Header: 'Job Type',
        accessor: 'jobType' as keyof User,
      },
      {
        Header: 'Job Title',
        accessor: 'jobTitle' as keyof User,
      },
      {
        Header: 'Team',
        accessor: 'team' as keyof User,
      },
      {
        Header: 'Seniority',
        accessor: 'seniority' as keyof User,
      },
      {
        Header: 'Gender',
        accessor: 'sex' as keyof User,
      },
      {
        Header: 'Pay Level',
        accessor: 'payLevel' as keyof User,
      },
      {
        Header: 'D.O.B',
        accessor: 'dob' as keyof User,
      },
      {
        Header: 'Location',
        accessor: 'location' as keyof User,
      },
      {
        Header: 'Joined',
        accessor: 'joined' as keyof User,
      },
      {
        Header: 'People Lead',
        accessor: 'peopleLead' as keyof User,
      },
      {
        Header: 'Speak up',
        accessor: 'speakupAlertEligibility' as keyof User,
      },
      {
        Header: 'Actions',
        accessor: 'actions' as any,
        Cell: actionsMenu,
      },
    ],
    [actionsMenu],
  );

  const data = React.useMemo(
    () =>
      users.map((user: User, index: number) => ({
        row: index + 1,
        id: user.id,
        email: user.email,
        role: getUserRoleLabel(user.role),
        company: user.company,
        surname: user.surname,
        forename: user.forename,
        jobType: user.jobType,
        jobTitle: user.jobTitle,
        team: user.team,
        seniority: getUserSeniorityLabel(user.seniority),
        sex: getUserGenderLabel(user.sex),
        payLevel: user.payLevel ? currency(user.payLevel, { symbol: '£' }).format() : '-',
        location: user.location,
        dob: user.dob ? format(new Date(user.dob), 'dd/MM/yyyy') : '',
        joined: user.joined ? format(new Date(user.joined), 'dd/MM/yyyy') : '',
        peopleLead: user.peopleLead ? 'Yes' : 'No',
        speakupAlertEligibility: user.speakupAlertEligibility ? 'Yes' : 'No',
        actions: 1,
      })),
    [users],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ['id'],
        pageSize: 5,
        pageIndex: 0,
      },
    },
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = tableInstance;

  if (!rows || rows.length < 1) {
    return <h2 className="mb-5 mt-5">No results</h2>;
  }

  return (
    <div className={styles.tableContainer}>
      <table className={styles.tableRoot} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()} className={styles.tableBodyRow}>
                {row.cells.map((cell: any) => (
                  <td className="align-middle" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

interface UserTableProps {
  users: User[];
  onSelectAction: (eventKey: string | null, user: User) => void;
}

export default UserTable;
