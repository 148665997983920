import React from 'react';
import NoPreviewImage from '@assets/images/no-preview.png';
import classNames from 'classnames';
import styles from './index.module.scss';

const Img: React.FunctionComponent<ImgProps> = (props) => {
  const { alt, src, className, onError, ...rest } = props;

  return (
    <img alt={alt} src={src} className={classNames([className])} {...rest} onError={onError} />
  );
};

Img.defaultProps = {
  className: styles.image,
  onError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => null,
};

interface ImgProps {
  alt: string;
  src: string;
  className?: string;
  onError?: (arg1: any) => void;
}

export default Img;
