import { RhombusPosition } from 'custom.d';

export const rhombusCoordinates = [
  {
    p1: { x: 185.9, y: 1 },
    p2: { x: 246.9, y: 107.4 },
    p3: { x: 185.9, y: 213 },
    p4: { x: 124.9, y: 107.4 },
    position: RhombusPosition.Top,
    inner: {
      p1: { x: 185.86, y: 41.96 },
      p2: { x: 223.28, y: 107.26 },
      p3: { x: 185.86, y: 172.04 },
      p4: { x: 148.43, y: 107.26 },
    },
  },
  {
    p1: { x: 247.6, y: 107.4 },
    p2: { x: 370.3, y: 107.4 },
    p3: { x: 308.6, y: 213.4 },
    p4: { x: 186.7, y: 213.4 },
    position: RhombusPosition.RightTop,
    inner: {
      p1: { x: 259.52, y: 128.17 },
      p2: { x: 334.79, y: 127.93 },
      p3: { x: 296.94, y: 192.98 },
      p4: { x: 222.12, y: 192.96 },
    },
  },
  {
    p1: { x: 186.8, y: 214.4 },
    p2: { x: 308.7, y: 214.4 },
    p3: { x: 370.4, y: 320.4 },
    p4: { x: 247.7, y: 320.4 },
    position: RhombusPosition.RightBottom,
    inner: {
      p1: { x: 221.84, y: 234.65 },
      p2: { x: 296.66, y: 234.64 },
      p3: { x: 334.51, y: 299.7 },
      p4: { x: 259.24, y: 299.45 },
    },
  },
  {
    p1: { x: 185.9, y: 214.8 },
    p2: { x: 246.9, y: 320.4 },
    p3: { x: 185.9, y: 426.8 },
    p4: { x: 124.9, y: 320.4 },
    position: RhombusPosition.Bottom,
    inner: {
      p1: { x: 185.93, y: 255.83 },
      p2: { x: 223.35, y: 320.61 },
      p3: { x: 185.93, y: 385.91 },
      p4: { x: 148.51, y: 320.61 },
    },
  },
  {
    p1: { x: 63.2, y: 214.4 },
    p2: { x: 185.1, y: 214.4 },
    p3: { x: 124.2, y: 320.4 },
    p4: { x: 1.5, y: 320.4 },
    position: RhombusPosition.LeftBottom,
    inner: {
      p1: { x: 74.84, y: 234.89 },
      p2: { x: 149.66, y: 234.91 },
      p3: { x: 112.27, y: 299.7 },
      p4: { x: 37, y: 299.95 },
    },
  },
  {
    p1: { x: 1.4, y: 107.5 },
    p2: { x: 124.2, y: 107.9 },
    p3: { x: 185.9, y: 214.4 },
    p4: { x: 63.1, y: 213.5 },
    position: RhombusPosition.LeftTop,
    inner: {
      p1: { x: 36.91, y: 127.95 },
      p2: { x: 112.19, y: 128.19 },
      p3: { x: 149.58, y: 192.98 },
      p4: { x: 74.76, y: 193 },
    },
  },
];

export const innerRhombusConfig = {
  top: [
    {
      xPlus: true,
      yPlus: false,
      isSideRhombus: false,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: true,
      isSideRhombus: true,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
    {
      xPlus: false,
      yPlus: true,
      isSideRhombus: false,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
    {
      xPlus: false,
      yPlus: false,
      isSideRhombus: true,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
  ],
  rightTop: [
    {
      xPlus: true,
      yPlus: true,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: false,
      yPlus: true,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: false,
      yPlus: false,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: false,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
  ],
  rightBottom: [
    {
      xPlus: false,
      yPlus: true,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: false,
      yPlus: false,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: false,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: true,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
  ],
  bottom: [
    {
      xPlus: false,
      yPlus: true,
      isSideRhombus: false,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
    {
      xPlus: false,
      yPlus: false,
      isSideRhombus: true,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: false,
      isSideRhombus: false,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: true,
      isSideRhombus: true,
      walkXOnRotated: false,
      walkYOnRotated: false,
    },
  ],
  leftBottom: [
    {
      xPlus: false,
      yPlus: false,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: false,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: true,
      yPlus: true,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
    {
      xPlus: false,
      yPlus: true,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: false,
    },
  ],
  leftTop: [
    {
      xPlus: true,
      yPlus: false,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: true,
    },
    {
      xPlus: false,
      yPlus: false,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: true,
    },
    {
      xPlus: false,
      yPlus: true,
      isSideRhombus: false,
      walkXOnRotated: true,
      walkYOnRotated: true,
    },
    {
      xPlus: true,
      yPlus: true,
      isSideRhombus: true,
      walkXOnRotated: true,
      walkYOnRotated: true,
    },
  ],
};
