import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import DefaultSwiper from '@components/Molecules/DefaultSwiper';
import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { Autoplay, Navigation, Parallax } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import DefaultButton from '@components/Atoms/DefaultButton';
import styles from './index.module.scss';
import iosBack from '../../../../assets/images/ios-back.svg';
import iosNext from '../../../../assets/images/ios-next.svg';

const QuoteSection: React.FunctionComponent<QuoteSectionProps> = ({ loading, quoteIcon, data }) => {
  const [startIndex, setStartIndex] = useState(0);

  let randomItemIndex = 0;
  const today = new Date();
  const dayOfMonth = today.getDate();
  randomItemIndex = Math.min(dayOfMonth - 1, data.length - 1);
  const randomItem = data[randomItemIndex];

  const handleNext = () => {
    if (startIndex < data.length - 1) {
      setStartIndex(startIndex + 1);
    }
  };

  const handleBack = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  return (
    <Card className={styles.preMiddleWhiteBox1}>
      <div className={styles.cardContent}>
        {loading ? (
          <div className={styles.load}>
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="75%" height={30} />
          </div>
        ) : (
          <>
            <DefaultSwiper modes={[]}>
              {/* Display the quote based on randomItemIndex (day of the month) */}
              {randomItem && (
                <SwiperSlide key="random">
                  <div className={styles.sliderCont}>
                    <Img src={quoteIcon} alt="Quote Left" className={styles.quoteLeft} />
                    <p>{randomItem.quote}</p>
                    <Img src={quoteIcon} alt="Quote Right" className={styles.quoteRight} />
                  </div>

                  <div className={styles.attribution}>
                    {loading ? (
                      <Skeleton variant="text" width="20%" height={30} />
                    ) : (
                      <p>{randomItem.author}</p>
                    )}
                  </div>
                </SwiperSlide>
              )}
            </DefaultSwiper>
          </>
        )}
      </div>
    </Card>
  );
};

QuoteSection.defaultProps = {
  loading: false,
};

interface QuoteSectionProps {
  loading?: boolean;
  quoteIcon: string;
  data: any;
}

export default QuoteSection;
