import styles from '@components/LoadingPage/index.module.scss';
import React from 'react';

const LoadingPage: React.FunctionComponent = () => (
  <div className={styles.hsRoot}>
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only" />
    </div>
  </div>
);

export default LoadingPage;
