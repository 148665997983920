import Card from '@components/Atoms/Card';
import useWindowDimensions from '@hooks/useWindowDimensions';
import React, { useState } from 'react';
import EmptyPlans from '@assets/images/empty-plans.svg';
import Img from '@components/Atoms/Img';
import DefaultButton from '@components/Atoms/DefaultButton';
import ShortStarModal from '@components/Organisms/ShortStarModal';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';

const NoPlansSection: React.FunctionComponent<NoPlansSectionProps> = (props) => {
  const { loading } = props;
  const [shortStarModalOpen, setShortStarModalOpen] = useState<boolean>(false);

  return (
    <>
      {shortStarModalOpen &&
        ReactDOM.createPortal(
          <ShortStarModal
            modalOpen={shortStarModalOpen}
            onClose={() => window.location.reload()}
          />,
          document.getElementById('short-star-modal')!,
        )}
      <Card className={styles.card}>
        <div className={styles.body}>
          <Img src={EmptyPlans} alt="Empty Plans" className={styles.emptyPlansImg} />
          <div className={styles.texts}>
            <p>
              Looks like you haven&apos;t kicked off a plan yet! Don&apos;t worry, it&apos;s never
              too late to begin. Hapstar has you covered with tailored plans designed to align with
              your goals and aspirations.
            </p>

            <p>
              Having a plan is crucial because it provides us with a roadmap to follow, especially
              during those moments when motivation wanes. Even when we&apos;re feeling less than
              enthusiastic, a well-thought-out plan can keep us focused and on track towards our
              goals.
            </p>

            <p>Simply select one that resonates with you, and let&apos;s dive in together.</p>

            <div className={styles.button}>
              <DefaultButton variant="primary" onClick={() => setShortStarModalOpen(true)}>
                START NOW
              </DefaultButton>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

NoPlansSection.defaultProps = {
  loading: false,
};

type NoPlansSectionProps = {
  loading?: boolean;
};

export default NoPlansSection;
