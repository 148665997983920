import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CreateStarCategoryInterface } from '@interfaces/index';
import { TOKEN } from '@api/config';
import { getCategories } from '@api/categories';
import { SecondaryNavigationContext } from './SecondaryNavigationContext';

export const CreateStarContext = createContext<ICreateStarContext>({
  currentStep: 200,
  setCurrentStep(currentStep) {
    this.currentStep = currentStep;
  },
  categoryQuestion: [],
  setCategoryQuestion(currentCategoryQuestion) {
    this.categoryQuestion = currentCategoryQuestion;
  },
});

const CreateStarContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [categoryQuestion, setCategoryQuestion] = useState<CreateStarCategoryInterface[]>([]);

  // const token = (key: string) =>
  //   new Promise((resolve, reject) => {
  //     try {
  //       const item = localStorage.getItem(key);
  //       if (item) {
  //         resolve(item);
  //       } else {
  //         reject(new Error('missing token'));
  //       }
  //     } catch (error) {
  //       reject(error);
  //     }
  //   }) as any;

  const token = localStorage.getItem('token');

  useEffect(() => {
    const getCategoriesData = () => {
      getCategories(token, activeSubNav.id).then((categories: CreateStarCategoryInterface[]) => {
        setCategoryQuestion(categories);
      });
    };

    if (token) {
      getCategoriesData();
    }
  }, [activeSubNav.id, token]);

  const value = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      categoryQuestion,
      setCategoryQuestion,
    }),
    [currentStep, categoryQuestion],
  );

  return <CreateStarContext.Provider value={value}>{children}</CreateStarContext.Provider>;
};

interface ICreateStarContext {
  currentStep: number;
  setCurrentStep: (a: number) => void;
  categoryQuestion: CreateStarCategoryInterface[];
  setCategoryQuestion: (a: CreateStarCategoryInterface[]) => void;
}

export default CreateStarContextProvider;
