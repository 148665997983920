import useWindowDimensions from '@hooks/useWindowDimensions';
import React from 'react';
import styles from './index.module.scss';

const ScoreButtons: React.FunctionComponent<ScoreButtonsProps> = (props) => {
  const { name, questionId, scoreRange = 10, currentScore, onChange } = props;
  const { isMobile } = useWindowDimensions();

  const renderScoreButtons = () =>
    Array.from({ length: scoreRange }, (_, i) => i + 1).map((score, index) => (
      <div key={index}>
        <input
          id={`${name}-${index}`}
          type="radio"
          name={name}
          value={score}
          checked={score === currentScore}
          onChange={() => onChange(score, questionId)}
        />
        <label htmlFor={`${name}-${index}`}>{score}</label>
      </div>
    ));

  return <div className={styles.hsScoreButtons}>{renderScoreButtons()}</div>;
};

interface ScoreButtonsProps {
  name: string;
  questionId: number;
  scoreRange?: number;
  currentScore: number;
  onChange: (score: number, position: number) => void;
}

ScoreButtons.defaultProps = {
  scoreRange: 10,
};

export default ScoreButtons;
