import React, { useEffect, useState } from 'react';
import { generateStar } from '@utils/star.util';
import styles from './index.module.scss';

const Star: React.FunctionComponent<StarProps> = (props) => {
  const { wingDetails } = props;
  const [image, setImage] = useState<string>();

  useEffect(() => {
    const base64 = generateStar(372, 428, wingDetails);
    setImage(base64);
  }, [wingDetails]);

  return (
    <div className={styles.hsStar}>
      <img src={image} alt="Star" />
    </div>
  );
};

interface StarProps {
  wingDetails: WingDetails[];
}

export type WingDetails = {
  name: string;
  color: string;
  value: number[];
};

export default Star;
