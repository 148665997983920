import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './index.module.scss';

const LinkText: React.FunctionComponent<LinkTextProps> = (props) => {
  const { path, className, children, onClick, disable, target, rel } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!disable && onClick) {
      onClick();
    }
  };

  if (disable) {
    return (
      <Link to="" onClick={handleOnClick} className={styles.disableLink}>
        {children}
      </Link>
    );
  }

  if (onClick) {
    return (
      <Link to="" onClick={handleOnClick} className={classNames([className])}>
        {children}
      </Link>
    );
  }

  if (path && target) {
    return (
      <a href={path} className={classNames([className])} target={target} rel={rel}>
        {children}
      </a>
    );
  }

  if (path) {
    return (
      <Link to={path} className={classNames([className])} target={target} rel={rel}>
        {children}
      </Link>
    );
  }

  return null;
};

LinkText.defaultProps = {
  className: styles.link,
  path: undefined,
  onClick: undefined,
  disable: false,
  target: undefined,
  rel: undefined,
};

type LinkTextProps = {
  children: React.ReactNode;
  path?: string | undefined;
  className?: string;
  onClick?: () => void;
  disable?: boolean;
  target?: string | undefined;
  rel?: string | undefined;
};

export default LinkText;
