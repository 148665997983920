import React from 'react';
import styles from './index.module.scss';

const Card: React.FunctionComponent<CardProps> = (props) => {
  const { children, className } = props;

  return <div className={className}>{children}</div>;
};

Card.defaultProps = {
  className: styles.card,
};

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export default Card;
