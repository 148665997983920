import React from 'react';
import styles from './index.module.scss';

const FilterContainer: React.FunctionComponent<FilterContainerProps> = (props) => {
  const { children } = props;

  return <div className={styles.root}>{children}</div>;
};

interface FilterContainerProps {
  children: React.ReactNode;
}

export default FilterContainer;
