import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FunctionComponent = () => (
  <div className="not-found">
    <div>
      <h1>404</h1>
      <h2>Sorry :((((</h2>
      <p>The page cannot be found....</p>
      <Link to="/">Back to homepage...</Link>
    </div>
  </div>
);

export default NotFound;
