import { getHistoricalEmployerGraphData } from '@api/EmployerNew';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import EmployerArea from '@components/Charts/EmployerArea';
import Checkbox from '@components/Checkbox';
import { Skeleton } from '@mui/material';
import { ShortStarQuestionsInterface } from 'custom';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

type ThemeColors = {
  [key: string]: string;
  sunYellow: string;
  aquaGreen: string;
  calmLilac: string;
  warmOrange: string;
  zingyGreen: string;
  skyBlue: string;
  default: string;
};

const themeColors: ThemeColors = {
  sunYellow: '#FCD300',
  aquaGreen: '#06BF97',
  calmLilac: '#BEAAF3',
  warmOrange: '#FF8A4A',
  zingyGreen: '#8EEA36',
  skyBlue: '#019DF2',
  default: '#ECE7DC',
};

const ChartSection: React.FunctionComponent<ChartSectionProps> = ({
  loading,
  starQuestions,
  starQuestionsLoading,
  queColors,
  checkedQues,
}) => {
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [months, setMonths] = useState<number>(1);
  const [graphData, setGraphData] = useState<
    { id: number; data: { date: string; value: number; month: string; totalResponses: number }[] }[]
  >([]);
  const [filteredGraphData, setFilteredGraphData] = useState<
    { id: number; data: { date: string; value: number; month: string; totalResponses: number }[] }[]
  >([]);
  const [checkedQuestions, setCheckedQuestions] = useState<number[]>([]);

  useEffect(() => {
    setCheckedQuestions(checkedQues);
  }, [checkedQues]);

  useEffect(() => {
    getHistoricalEmployerGraphData({
      months,
    }).then((data) => {
      setGraphData(data);
    });
  }, [months]);

  useEffect(() => {
    const filteredData = graphData.filter((item: { id: number }) =>
      checkedQuestions.includes(item.id),
    );
    setFilteredGraphData(filteredData);
  }, [graphData, checkedQuestions]);

  const handleCheckboxChange = (questionId: number) => {
    const isChecked = checkedQuestions.includes(questionId);

    if (isChecked) {
      setCheckedQuestions(checkedQuestions.filter((id) => id !== questionId));
    } else {
      setCheckedQuestions([...checkedQuestions, questionId]);
    }
  };

  return (
    <Card className={styles.chartSection}>
      {starQuestionsLoading ? (
        <Skeleton variant="rounded" width="100%" height={500} />
      ) : (
        <>
          <div className={styles.questionsContainer}>
            {starQuestions &&
              starQuestions.map((question, index) => (
                <div key={index} className={styles.questionItem}>
                  <Checkbox
                    id={question.questionId.toString()}
                    text={question.questionText}
                    color={
                      themeColors[question.colorTheme as keyof ThemeColors] || themeColors.default
                    }
                    onChange={() => handleCheckboxChange(question.questionId)}
                    checked={checkedQuestions.includes(question.questionId)}
                  />
                </div>
              ))}
          </div>

          <div className={styles.timeFrames}>
            {['1 month', '6 month', '1 year'].map((i: any, key: any) => {
              const selected = key === selectedItem;

              return (
                <DefaultButton
                  type="button"
                  key={JSON.stringify(i + key)}
                  onClick={() => {
                    setSelectedItem(key);
                    if (key === 0) {
                      setMonths(1);
                    }

                    if (key === 1) {
                      setMonths(6);
                    }

                    if (key === 2) {
                      setMonths(12);
                    }
                  }}
                  className={`${styles.timeFrameButton} ${
                    selected && styles.timeFrameButtonSelected
                  }`}
                  style={{
                    marginRight:
                      key !== ['1 month', '6 month', '1 year'].length - 1 ? 10 : undefined,
                  }}
                >
                  <p className={`${styles.btnText} ${selected && styles.selectedText}`}>{i}</p>
                </DefaultButton>
              );
            })}
          </div>

          <EmployerArea
            values={filteredGraphData}
            selectedIndex={0}
            stars={[]}
            months={months}
            selectedTimeframeKey={selectedItem}
            selectedTabs={checkedQuestions}
            queColors={queColors}
          />
        </>
      )}
    </Card>
  );
};

ChartSection.defaultProps = {
  loading: false,
};
interface ChartSectionProps {
  loading?: boolean;
  starQuestions: ShortStarQuestionsInterface[];
  starQuestionsLoading: boolean;
  queColors: { qId: number; qName: string; color: string }[];
  checkedQues: number[];
}

export default ChartSection;
