import { AppDispatch } from '@hooks/useAppDispatch';
import { helpAndSpeakupActions } from 'store/Slices/HelpAndSpeakupSlice';

export interface HelpAnSpeakUpData {
  isHelpAvailable: boolean;
}

export const updateHelpAvailability = (availability: boolean) => async (dispatch: AppDispatch) => {
  dispatch(helpAndSpeakupActions.setLoading({ loading: true }));

  try {
    dispatch(helpAndSpeakupActions.updateHelpAndSpeakupData({ isHelpAvailable: availability }));
  } catch (error) {
    dispatch(helpAndSpeakupActions.setError({ error: 'Loading help availability failed' }));
  }
};
