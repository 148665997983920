import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const AdvisorScorePalate: React.FunctionComponent<AdvisorScorePalateProps> = (props) => {
  const { category, score, color } = props;

  const styleClasses = classNames([
    styles.hsScoreCard,
    color === '#BEAAF3' && styles.calmLilac,
    color === '#019DF2' && styles.skyBlue,
    color === '#FCD300' && styles.sunYellow,
    color === '#8EEA36' && styles.zingyGreen,
    color === '#06BF97' && styles.aquaGreen,
    color === '#FF8A4A' && styles.warmOrange,
  ]);

  return (
    <div className={styleClasses}>
      <p>{category}</p>
      <p>
        Score <span className={styles.scoreSpan}>{score}</span>
      </p>
    </div>
  );
};

type AdvisorScorePalateProps = {
  category: string;
  score: number;
  color: string;
};

export default AdvisorScorePalate;
