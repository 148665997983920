import DefaultButton from '@components/Atoms/DefaultButton';
import FormInputText from '@components/FormInputText';
import LabelSelect from '@components/Molecules/LabelSelect';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { use } from 'echarts';
import styles from './index.module.scss';

const SliceAndDiceFilters: React.FunctionComponent<SliceAndDiceFiltersProps> = (props) => {
  const {
    locations,
    jobTitles,
    jobTypes,
    teams,
    isFiltered,
    onFilter,
    onReset,
    methods,
    handleFilteringEnable,
    handleFilterClick,
    isFilterClicked,
  } = props;
  const sDate = methods.getValues('startDate');
  const eDate = methods.getValues('endDate');

  const [startDateSelected, setStartDateSelected] = useState<boolean>(false);
  const [endDateSelected, setEndDateSelected] = useState<boolean>(false);

  useEffect(() => {
    if ((sDate && !eDate) || (sDate && eDate === '')) {
      toast.info('Please select end date');
    } else if ((!sDate && eDate) || (eDate && sDate === '')) {
      toast.info('Please select start date');
    } else if (sDate && eDate) {
      if (new Date(sDate).getTime() > new Date(eDate).getTime()) {
        toast.error('Start date cannot be greater than end date');
      }
    }
  }, [sDate, eDate]);

  const genders = [
    { value: 'non_specified', label: 'Non-Specified' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'non_binary', label: 'Non-binary' },
  ];

  const peopleLeadValues = [
    { value: '', label: 'All' },
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
  ];

  const seniorityLevels = [
    { value: 'non_specified', label: 'Non-Specified' },
    { value: 'junior', label: 'Junior' },
    { value: 'mid_level', label: 'Mid-level' },
    { value: 'senior', label: 'Senior' },
    { value: 'c_level', label: 'C-level' },
  ];

  const userStatuses = [
    { value: 'true', label: 'Active Users' },
    { value: '', label: 'All Users' },
    { value: 'false', label: 'Deleted Users' },
  ];

  const selectDropDownStyles = {
    color: '#019df2',
  };

  const {
    formState: { errors },
    handleSubmit,
    formState,
  } = methods;

  const submit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (isFiltered && handleFilteringEnable) {
      await handleFilteringEnable(false);
    }

    if (handleFilteringEnable) {
      await handleFilteringEnable(true);
    }

    await handleSubmit(onFilter);

    if (handleFilterClick) {
      handleFilterClick(true);
    }
  };

  const clear = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    await onReset();

    if (handleFilteringEnable) {
      await handleFilteringEnable(false);
    }

    if (handleFilterClick) {
      await handleFilterClick(false);
    }

    await setStartDateSelected(false);
    await setEndDateSelected(false);
    await methods.setValue('startDate', null);
    await methods.setValue('endDate', null);
  };

  return (
    <div className={styles.root}>
      <form onSubmit={submit}>
        <div className={styles.sliceAndDiceSection}>
          <div className={styles.sliceAndDiceFlex}>
            <div>
              <Controller
                name="locations"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="locations"
                    name="locations"
                    label="Location"
                    options={[
                      { value: '', label: 'All' },
                      ...locations.map((location) => ({
                        value: location.id.toString(),
                        label: location.name,
                      })),
                    ]}
                    value={field.value || { label: 'All', value: '' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.locations)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="teams"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="teams"
                    name="teams"
                    label="Team"
                    options={[
                      { value: '', label: 'All' },
                      ...teams.map((team) => ({
                        value: team.id.toString(),
                        label: team.name,
                      })),
                    ]}
                    value={field.value || { label: 'All', value: '' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.teams)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="gender"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="gender"
                    name="gender"
                    label="Gender"
                    options={[
                      { value: '', label: 'All' },
                      ...genders.map((gender) => ({
                        value: gender.value.toString(),
                        label: gender.label,
                      })),
                    ]}
                    value={field.value || { label: 'All', value: '' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.gender)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="seniority"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="seniority"
                    name="seniority"
                    label="Seniority"
                    options={[
                      { value: '', label: 'All' },
                      ...seniorityLevels.map((slevel) => ({
                        value: slevel.value.toString(),
                        label: slevel.label,
                      })),
                    ]}
                    value={field.value || { label: 'All', value: '' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.seniority)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="jobTypes"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="jobTypes"
                    name="jobTypes"
                    label="Job Type"
                    options={[
                      { value: '', label: 'All' },
                      ...jobTypes.map((jobtype) => ({
                        value: jobtype.id.toString(),
                        label: jobtype.name,
                      })),
                    ]}
                    value={field.value || { label: 'All', value: '' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.jobTypes)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="jobTitles"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="jobTitles"
                    name="jobTitles"
                    label="Job Title"
                    options={[
                      { value: '', label: 'All' },
                      ...jobTitles.map((jobTitle) => ({
                        value: jobTitle.id.toString(),
                        label: jobTitle.name,
                      })),
                    ]}
                    value={field.value || { label: 'All', value: '' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.jobTitles)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="peopleLead"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="peopleLead"
                    name="peopleLead"
                    label="People Lead"
                    options={peopleLeadValues}
                    value={field.value || { label: 'All', value: '' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.peopleLead)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="active"
                control={methods.control}
                render={({ field }) => (
                  <LabelSelect
                    id="active"
                    name="active"
                    label="User Status"
                    options={userStatuses}
                    value={field.value || { label: 'Active Users', value: 'true' }}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    onBlur={field.onBlur}
                    isError={Boolean(errors.active)}
                    isClearable={false}
                    isSearchable={false}
                    className={styles.formTextInputStyles}
                    customStyle={selectDropDownStyles}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="startDate"
                control={methods.control}
                render={({ field }) => (
                  <div>
                    <div className={styles.tooltipContainer}>
                      <p>Start Date</p>
                    </div>
                    <FormInputText
                      name="Start Date"
                      type="date"
                      value={field.value === null ? '' : field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        if (value.target.value) {
                          setStartDateSelected(true);
                        } else {
                          setStartDateSelected(false);
                        }
                      }}
                      isSliceAndDice
                      required={endDateSelected}
                    />
                  </div>
                )}
              />
            </div>

            <div>
              <Controller
                name="endDate"
                control={methods.control}
                render={({ field }) => (
                  <div>
                    <div className={styles.tooltipContainer}>
                      <p>End Date</p>
                    </div>
                    <FormInputText
                      name="End Date"
                      type="date"
                      value={field.value === null ? '' : field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        if (value.target.value) {
                          setEndDateSelected(true);
                        } else {
                          setEndDateSelected(false);
                        }
                      }}
                      isSliceAndDice
                      min={methods.getValues('startDate')}
                      required={startDateSelected}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.buttonGroup}>
              <DefaultButton disabled={!formState.isDirty} type="submit" color="primary">
                Apply
              </DefaultButton>

              <DefaultButton
                disabled={!isFilterClicked}
                type="button"
                onClick={clear}
                color="primary"
              >
                Clear Filter
              </DefaultButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

SliceAndDiceFilters.defaultProps = {
  handleFilteringEnable: undefined,
  handleFilterClick: undefined,
  isFilterClicked: false,
};

interface SliceAndDiceFiltersProps {
  locations: Array<any>;
  teams: Array<any>;
  jobTypes: Array<any>;
  jobTitles: Array<any>;
  methods: UseFormReturn;
  isFiltered: boolean;
  onFilter: (data: any) => void;
  onReset: () => void;
  handleFilteringEnable?: (arg1: boolean) => void;
  handleFilterClick?: (arg1: boolean) => void;
  isFilterClicked?: boolean;
}

export default SliceAndDiceFilters;
