import {
  getFilteredShortStarQuestionScoreHps,
  getFilteredWellbeingScoreHps,
  getShortStarQuestionScoreHps,
  getWellbeingScoreHps,
} from '@api/EmployerNew';
import EmployerScoreGauge from '@components/Charts/Gauge/EmployerScore';
import { Grid, Skeleton } from '@mui/material';
import { ShortStarQuestionsInterface } from 'custom';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';

const ScoresSection: React.FunctionComponent<ScoresSectionProps> = ({
  loading,
  lightMode = false,
  filters,
  isFiltered = false,
  starQuestions,
}) => {
  const [wellbeingHps, setWellbeingHps] = useState({
    wellbeingScore: 0,
    totalUsers: 0,
  });
  const [wellbeingFilteredHps, setWellbeingFilteredHps] = useState({
    wellbeingScore: 0,
    totalUsers: 0,
  });
  const [shortStarHps, setShortStarHps] = useState<
    { questionId: number; question: string; scoreAverage: number }[] | []
  >([]);
  const [shortStarFilteredHps, setShortStarFilteredHps] = useState<
    { questionId: number; question: string; scoreAverage: number }[] | []
  >([]);
  const [wellbeingHpsLoading, setWellbeingHpsLoading] = useState(true);
  const [shortStarHpsLoading, setShortStarHpsLoading] = useState(true);
  const [wellbeingFilteredHpsLoading, setWellbeingFilteredHpsLoading] = useState(true);
  const [shortStarFilteredHpsLoading, setShortStarFilteredHpsLoading] = useState(true);

  useEffect(() => {
    if (starQuestions) {
      fetchHpsData(lightMode);
    }
  }, [starQuestions, lightMode, isFiltered]);

  const fetchHpsData = useCallback(
    async (isLightMode: boolean) => {
      if (!Array.isArray(starQuestions) || starQuestions.length === 0) {
        return;
      }

      const res1 = isLightMode
        ? await getFilteredWellbeingScoreHps(filters)
        : await getWellbeingScoreHps(filters);

      try {
        if (isLightMode) {
          setWellbeingFilteredHpsLoading(true);
          setShortStarFilteredHpsLoading(true);
        } else {
          setWellbeingHpsLoading(true);
          setShortStarHpsLoading(true);
        }

        if (isLightMode) {
          setWellbeingFilteredHps(res1);

          const filteredRes = await getFilteredShortStarQuestionScoreHps(filters);

          setShortStarFilteredHps(filteredRes);
        } else {
          setWellbeingHps(res1);
          const res2 = await getShortStarQuestionScoreHps({});
          setShortStarHps(res2);
        }
      } catch (error) {
        if (isLightMode) {
          setWellbeingFilteredHps({ wellbeingScore: 0, totalUsers: 0 });
          setShortStarFilteredHps([]);
        } else {
          setWellbeingHps({ wellbeingScore: 0, totalUsers: 0 });
          setShortStarHps([]);
        }
      } finally {
        if (isLightMode) {
          await setWellbeingFilteredHpsLoading(false);
          await setShortStarFilteredHpsLoading(false);
        } else {
          await setWellbeingHpsLoading(false);
          await setShortStarHpsLoading(false);
        }
      }
    },
    [starQuestions, filters, lightMode],
  );

  return (
    <>
      {(() => {
        switch (lightMode) {
          case true:
            return (
              <div className={`${styles.scoresSection} ${styles.lightMode}`}>
                <Grid container>
                  {wellbeingFilteredHpsLoading ? (
                    <Grid
                      item
                      xs={12}
                      md={2}
                      className={`${styles.wellbeingScoreContainerLoading} ${
                        lightMode ? styles.lightMode : ''
                      }`}
                    >
                      <Skeleton variant="text" height={30} width="50%" />
                      <Skeleton variant="circular" width={150} height={150} />
                      <Skeleton variant="text" height={20} width="50%" />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      md={2}
                      className={`${styles.wellbeingScoreContainer} ${styles.lightMode}`}
                    >
                      <h5>Wellbeing score</h5>
                      <EmployerScoreGauge
                        score={
                          !lightMode
                            ? wellbeingHps?.wellbeingScore
                            : wellbeingFilteredHps?.wellbeingScore
                        }
                        height={200}
                        fontSize="30px"
                        color="dark"
                      />
                    </Grid>
                  )}

                  {shortStarFilteredHpsLoading ? (
                    <Grid item xs={12} md={10}>
                      <Grid
                        container
                        className={`${styles.otherScoresContainerLoading} ${
                          lightMode ? styles.lightMode : ''
                        }`}
                      >
                        {[0, 1, 2, 3, 4, 5].map((index) => (
                          <Grid key={index} item xs={12} md={2} className={styles.scoreContainer}>
                            <Skeleton variant="text" height={30} width="70%" />
                            <Skeleton variant="circular" width={120} height={120} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={10}>
                      <Grid
                        container
                        className={`${styles.otherScoresContainer} ${
                          lightMode ? styles.lightMode : ''
                        }`}
                      >
                        {shortStarFilteredHps?.map((item, index) => (
                          <Grid key={index} item xs={12} md={2} className={styles.scoreContainer}>
                            <h5>{item?.question?.toLowerCase()}</h5>
                            <EmployerScoreGauge
                              score={item?.scoreAverage || 0}
                              height={180}
                              fontSize="22px"
                              color="dark"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
            );
          default:
            return (
              <div className={`${styles.scoresSection}`}>
                <Grid container>
                  {wellbeingHpsLoading ? (
                    <Grid
                      item
                      xs={12}
                      md={2}
                      className={`${styles.wellbeingScoreContainerLoading} ${
                        lightMode ? styles.lightMode : ''
                      }`}
                    >
                      <Skeleton variant="text" height={40} width="60%" />
                      <Skeleton variant="circular" width={150} height={150} />
                      <Skeleton variant="text" height={20} width="50%" />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={2} className={`${styles.wellbeingScoreContainer}`}>
                      <h5>Wellbeing score</h5>
                      <EmployerScoreGauge
                        score={
                          !lightMode
                            ? wellbeingHps?.wellbeingScore
                            : wellbeingFilteredHps?.wellbeingScore
                        }
                        height={200}
                        fontSize="30px"
                        color="light"
                      />

                      <p>{`${wellbeingHps?.totalUsers} users`}</p>
                    </Grid>
                  )}

                  {shortStarHpsLoading ? (
                    <Grid item xs={12} md={10}>
                      <Grid
                        container
                        className={`${styles.otherScoresContainerLoading} ${
                          lightMode ? styles.lightMode : ''
                        }`}
                      >
                        {[0, 1, 2, 3, 4, 5].map((index) => (
                          <Grid key={index} item xs={12} md={2} className={styles.scoreContainer}>
                            <Skeleton variant="text" height={30} width="50%" />
                            <Skeleton variant="circular" width={120} height={120} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={10}>
                      <Grid container className={`${styles.otherScoresContainer} `}>
                        {shortStarHps?.map((item, index) => (
                          <Grid key={index} item xs={12} md={2} className={styles.scoreContainer}>
                            <h5>{item?.question?.toLowerCase()}</h5>
                            <EmployerScoreGauge
                              score={item?.scoreAverage || 0}
                              height={180}
                              fontSize="22px"
                              color="dark"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
            );
        }
      })()}
    </>
  );
};

ScoresSection.defaultProps = {
  loading: false,
  lightMode: false,
  filters: {},
  isFiltered: false,
  starQuestions: [],
};

interface ScoresSectionProps {
  loading?: boolean;
  lightMode?: boolean;
  filters?: any;
  isFiltered?: boolean;
  starQuestions?: ShortStarQuestionsInterface[] | [];
}

export default ScoresSection;

// import EmployerScoreGauge from '@components/Charts/Gauge/EmployerScore';
// import { Grid, Skeleton } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { getShortStarQuestionScoreHps, getWellbeingScoreHps } from '@api/EmployerNew';
// import styles from './index.module.scss';

// const ScoresSection: React.FunctionComponent<ScoresSectionProps> = ({
//   loading,
//   lightMode,
//   filters,
//   isFiltered,
// }) => {
//   const [wellbeingHps, setWellbeingHps] = useState({
//     wellbeingScore: 0,
//     totalUsers: 0,
//   });
//   const [wellbeingFilteredHps, setWellbeingFilteredHps] = useState({
//     wellbeingScore: 0,
//     totalUsers: 0,
//   });

//   const [shortStarHps, setShortStarHps] = useState<
//     { questionId: number; question: string; scoreAverage: number }[] | []
//   >([]);
//   const [shortStarFilteredHps, setShortStarFilteredHps] = useState<
//     { questionId: number; question: string; scoreAverage: number }[] | []
//   >([]);

//   const [wellbeingHpsLoading, setWellbeingHpsLoading] = useState(true);
//   const [shortStarHpsLoading, setShortStarHpsLoading] = useState(true);

//   const [wellbeingFilteredHpsLoading, setWellbeingFilteredHpsLoading] = useState(true);
//   const [shortStarFilteredHpsLoading, setShortStarFilteredHpsLoading] = useState(true);

//   useEffect(() => {
//     getHps();
//   }, []);

//   useEffect(() => {
//     getFilteredHps();
//   }, [isFiltered, lightMode]);

//   const getHps = async () => {
//     try {
//       setWellbeingHpsLoading(true);
//       setShortStarHpsLoading(true);
//       const res1 = await getWellbeingScoreHps({});
//       const res2 = await getShortStarQuestionScoreHps({});

//       setWellbeingHps(res1);
//       setShortStarHps(res2);
//     } catch (e) {
//       setWellbeingHps({
//         wellbeingScore: 0,
//         totalUsers: 0,
//       });
//     } finally {
//       setWellbeingHpsLoading(false);
//       setShortStarHpsLoading(false);
//     }
//   };

//   const getFilteredHps = async () => {
//     try {
//       if (lightMode) {
//         setWellbeingFilteredHpsLoading(true);
//         setShortStarFilteredHpsLoading(true);
//         const res1 = await getWellbeingScoreHps(filters);
//         const res2 = await getShortStarQuestionScoreHps(filters);

//         setWellbeingFilteredHps(res1);
//         setShortStarFilteredHps(res2);
//       }
//     } catch (e) {
//       setWellbeingHps({
//         wellbeingScore: 0,
//         totalUsers: 0,
//       });
//     } finally {
//       setWellbeingFilteredHpsLoading(false);
//       setShortStarFilteredHpsLoading(false);
//     }
//   };

//   const defaultHpsLoading = wellbeingHpsLoading || shortStarHpsLoading;
//   const filteredHpsLoading = wellbeingFilteredHpsLoading || shortStarFilteredHpsLoading;

//   return (
//     <div className={`${styles.scoresSection} ${lightMode ? styles.lightMode : ''}`}>
//       {defaultHpsLoading || filteredHpsLoading ? (
//         <Grid container spacing={2}>
//           <Grid
//             item
//             xs={12}
//             md={2}
//             className={`${styles.wellbeingScoreContainerLoading} ${
//               lightMode ? styles.lightMode : ''
//             }`}
//           >
//             <Skeleton variant="text" height={30} width="50%" />
//             <Skeleton variant="circular" width={150} height={150} />
//             <Skeleton variant="text" height={20} width="50%" />
//           </Grid>
//           <Grid item xs={12} md={10}>
//             <Grid
//               container
//               spacing={2}
//               className={`${styles.otherScoresContainerLoading} ${
//                 lightMode ? styles.lightMode : ''
//               }`}
//             >
//               {[0, 1, 2, 3, 4, 5].map((index) => (
//                 <Grid key={index} item xs={12} md={2} className={styles.scoreContainer}>
//                   <Skeleton variant="text" height={30} width="50%" />
//                   <Skeleton variant="circular" width={150} height={150} />
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>
//       ) : (
//         <Grid container>
//           <Grid
//             item
//             xs={12}
//             md={2}
//             className={`${styles.wellbeingScoreContainer} ${lightMode ? styles.lightMode : ''}`}
//           >
//             <h5>Wellbeing score</h5>
//             <EmployerScoreGauge
//               score={
//                 !lightMode ? wellbeingHps?.wellbeingScore : wellbeingFilteredHps?.wellbeingScore
//               }
//               height={200}
//               fontSize="30px"
//               color={lightMode ? 'dark' : 'light'}
//             />

//             <p>{!lightMode && `${wellbeingHps?.totalUsers} users`} </p>
//           </Grid>
//           <Grid item xs={12} md={10}>
//             <Grid
//               container
//               className={`${styles.otherScoresContainer} ${lightMode ? styles.lightMode : ''}`}
//             >
//               {!lightMode ? (
//                 <>
//                   {shortStarHps?.map((item, index) => (
//                     <Grid key={index} item xs={12} md={2} className={styles.scoreContainer}>
//                       <h5>{item?.question?.toLowerCase()}</h5>
//                       <EmployerScoreGauge
//                         score={item?.scoreAverage || 0}
//                         height={180}
//                         fontSize="22px"
//                         color="dark"
//                       />
//                     </Grid>
//                   ))}
//                 </>
//               ) : (
//                 <>
//                   {shortStarFilteredHps?.map((item, index) => (
//                     <Grid key={index} item xs={12} md={2} className={styles.scoreContainer}>
//                       <h5>{item?.question?.toLowerCase()}</h5>
//                       <EmployerScoreGauge
//                         score={item?.scoreAverage || 0}
//                         height={180}
//                         fontSize="22px"
//                         color="dark"
//                       />
//                     </Grid>
//                   ))}
//                 </>
//               )}
//             </Grid>
//           </Grid>
//         </Grid>
//       )}
//     </div>
//   );
// };

// ScoresSection.defaultProps = {
//   loading: false,
//   lightMode: false,
//   filters: {},
//   isFiltered: false,
// };

// interface ScoresSectionProps {
//   loading?: boolean;
//   lightMode?: boolean;
//   filters?: any;
//   isFiltered?: boolean;
// }

// export default ScoresSection;
