import { WingDetails } from '@components/Star';
import {
  CategoryInterface,
  CategoryScores,
  CreateStarCategoryInterface,
  CreateStarQuestionInterface,
  QuestionnaireInterface,
  ResponseInterface,
  SaveAssessmentQuestion,
} from '@interfaces/index';
import { CategoryColorNames } from '@enums/ColorNames';
import { Colors } from '@enums/Colors';
import { LightColors } from '@enums/LightColors';
import { DarkColors } from '@enums/DarkColors';
import { forEach } from 'lodash';

export const parseScoresData = (responses: ResponseInterface[]): number[] => {
  let scores = responses.map((questionResponse) => questionResponse.score);
  scores = scores.length === 1 ? new Array(4).fill(scores[0]) : scores;

  return scores;
};

export const parseStarDetails = (
  name: string,
  color: string,
  scores: number[],
  isDummy = false,
): WingDetails => {
  const values = isDummy ? new Array(4).fill(10) : scores;

  return {
    name,
    color,
    value: values,
  };
};

export const parseAssessmentData = (data: QuestionnaireInterface) => {
  const allScores: number[] = [];
  const starDetails: WingDetails[] = [];
  const catScores: CategoryScores[] = [];
  const creationDate: number = Math.floor(new Date(data.date).getTime() / 1000);

  const categoryIdMap: { [key: string]: number } = {};
  CategoryColorNames.forEach((category) => {
    categoryIdMap[category.categoryId] = category.categoryId;
  });

  data.categories.forEach((item, index) => {
    const { id, name, questions }: CategoryInterface = item;
    const scores: number[] = parseScoresData(questions);

    allScores.push(...scores);

    const { color } = CategoryColorNames[index];

    starDetails.push(parseStarDetails(name, Colors[color as keyof typeof Colors], scores));

    let scoreTotal = 0;
    const scoresArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const SCORE_FACTORS_EMPLOYEE = [
      0, -2.0833, -1.82, -1.56, -1.3, -1.04, -0.5, 0, 1.46, 1.87, 2.0833,
    ];

    const scoreCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    scores.forEach((scoreData) => {
      const score = scoreData - 1;
      scoreCounts[score] += 1;
    });

    scoresArray.forEach((score) => {
      scoreTotal += SCORE_FACTORS_EMPLOYEE[score] * scoreCounts[score - 1];
    });

    const hpsScore = Math.round(scoreTotal * 6) + 50;

    const categoryId = categoryIdMap[id];

    catScores.push({
      id: categoryId,
      category: name,
      score: hpsScore,
      color: item.color,
    });
  });

  const avgScore = allScores.reduce((a, b) => a + b, 0) / allScores.length;

  return { avgScore, starDetails, catScores, creationDate };
};

export const parseScores = (data: CategoryInterface[]) =>
  data.map((category) => {
    const colorCategory = CategoryColorNames.find((c) => c.categoryId === category.id);

    if (colorCategory) {
      const { color } = colorCategory;

      const categoryQuestions: CreateStarQuestionInterface[] = category.questions.map(
        (categoryResponse) => ({
          id: categoryResponse.questionId,
          name: categoryResponse.name,
          hint: categoryResponse.questionInfo,
          score: categoryResponse.score,
          shareWith: categoryResponse.shareWith ? categoryResponse.shareWith : 'mySelf',
          thoughtsText: categoryResponse.thoughtsText ? categoryResponse.thoughtsText : '',
          scoreDifference: 0,
          color: categoryResponse.color,
          specialWeighting: categoryResponse.specialWeighting,
          isSelected: false,
        }),
      );

      let scoresData = categoryQuestions.map((q) => q.score);
      scoresData = scoresData.length === 1 ? new Array(4).fill(scoresData[0]) : scoresData;

      return {
        id: category.id,
        name: colorCategory.name,
        color: category.color,
        lightColor: LightColors[color as keyof typeof LightColors],
        darkColor: DarkColors[color as keyof typeof DarkColors],
        data: scoresData,
        compareData: [],
        questions: categoryQuestions,
      };
    }

    throw new Error(
      `Category object with categoryId ${category.id} not found in CategoryColorNames.`,
    );
  });

export const parseSaveAssessmentData = (data: CreateStarCategoryInterface[]) => {
  const questions: SaveAssessmentQuestion[] = [];
  data.forEach((category) => {
    const parsedQuestions = category.questions.map((question) => {
      const { id: questionId, score, shareWith, thoughtsText } = question;

      if (shareWith !== '' && thoughtsText !== '') {
        return { questionId, score, shareWith, thoughtsText };
      }

      return { questionId, score };
    });
    questions.push(...parsedQuestions);
  });

  return questions;
};

export const calculateAverageScoreForAssessment = (assessmentScoresData: any) => {
  let scoreTotal = 0;
  const data = assessmentScoresData.flatMap((i: any) =>
    i.questions.map((k: any) => ({
      specialWeighting: k.specialWeighting,
      score: k.score,
    })),
  );

  let divisor = data.length;
  data.forEach((scoreData: any) => {
    if (scoreData.specialWeighting) {
      scoreTotal += scoreData.score * 4;
      divisor += 3;
    } else {
      scoreTotal += scoreData.score;
    }
  });

  return Number(scoreTotal / divisor).toFixed(0);
};
