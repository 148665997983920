import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const FormInputText = React.forwardRef<HTMLInputElement, FormInputTextProps>((props, ref) => {
  const { name, isError, title, placeholder, isSliceAndDice, ...rest } = props;

  return (
    <fieldset className={styles.root}>
      <div className={styles.row}>
        <label htmlFor={name}>{title}</label>
      </div>

      <input
        id={name}
        className={classNames([
          styles.input,
          isError && styles.error,
          isSliceAndDice && styles.sliceAndDice,
        ])}
        name={name}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    </fieldset>
  );
});

FormInputText.displayName = 'FormInputText';

FormInputText.defaultProps = {
  isError: false,
  isSliceAndDice: false,
};

interface FormInputTextProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  isError?: boolean;
  isSliceAndDice?: boolean;
}

export default FormInputText;
