import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import chartBG from '@assets/images/chart-bg.svg';

const NewHomeGauge: React.FunctionComponent<GaugeProps> = (props) => {
  const { score } = props;
  const series = [Math.round(score)];

  const options: ApexOptions = {
    chart: {
      height: 250,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -360,
        endAngle: 0,
        hollow: {
          margin: 0,
          size: '62%',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: false,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#F4F2EC',
          strokeWidth: '100%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: 30,
            show: false,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter(val) {
              return `${String(val.toFixed(0))}`;
            },
            color: 'white',
            fontSize: '34px',
            fontFamily: 'Titillium Web',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'image',
      image: {
        src: [chartBG],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="radialBar" height={250} />
    </div>
  );
};

interface GaugeProps {
  score: number;
}

export default NewHomeGauge;
