import { getCurrentUserStatus, updateCurrentUserStatus } from '@api/auth/login';
import { AppDispatch } from '@hooks/useAppDispatch';
import { updateUserStatusRequest } from 'custom';
import { userStatusActions } from 'store/Slices/UserStatusSlice';

export const getUserStatusData = () => async (dispatch: AppDispatch) => {
  dispatch(userStatusActions.setLoading({ loading: true }));

  try {
    const data = await getCurrentUserStatus();
    const user = localStorage.getItem('user');

    if (data && user) {
      dispatch(
        userStatusActions.updateUserStatus({
          isRegistered: data.is_registered,
          isJoined: data.is_joined,
          isAgreed: JSON.parse(user).isAgree,
          isFirstShortStarCompleted: data.is_first_short_star_completed,
        }),
      );
    } else {
      dispatch(userStatusActions.setError({ error: 'Loading user status failed' }));
    }
  } catch (error) {
    dispatch(userStatusActions.setError({ error: 'Loading user status failed' }));
  }
};

export const updateUserStatusData =
  (data: updateUserStatusRequest) => async (dispatch: AppDispatch) => {
    dispatch(userStatusActions.setLoading({ loading: true }));

    try {
      const user = localStorage.getItem('user');
      const userId = user && JSON.parse(user).id;

      if (user && userId) {
        const userStatus = await updateCurrentUserStatus(userId, data);
        dispatch(
          userStatusActions.updateUserStatus({
            isRegistered: userStatus.is_registered,
            isJoined: userStatus.is_joined,
            isAgreed: JSON.parse(user).isAgree,
            isFirstShortStarCompleted: userStatus.is_first_short_star_completed,
          }),
        );
      } else {
        dispatch(userStatusActions.setError({ error: 'Update user status failed' }));
      }
    } catch (error) {
      dispatch(userStatusActions.setError({ error: 'Update user status failed' }));
    }
  };
