import React from 'react';
import DefaultButton from '@components/Atoms/DefaultButton';
import { ImportedRowType } from '../../custom';
import styles from './index.module.scss';

const ImportUserResults: React.FunctionComponent<ImportUserResults> = (props) => {
  const { results, onRestart } = props;

  const failedRows = results.failed;
  const successRows = results.successful;

  return (
    <div className={styles.root}>
      <div className="container">
        <div>
          <h1 className={styles.title}>Import Results</h1>

          {successRows.length > 0 && (
            <div>
              <h4 className="mb-2">Successfully Added</h4>

              <p className="mb-4">Invitations have been sent via email to the following users:</p>

              {successRows.map((row: ImportedRowType, index: number) => (
                <div className={styles.successAlertContainer} key={row.forename + index}>
                  {row.forename} {row.surname} ({row.email})
                </div>
              ))}
            </div>
          )}

          {failedRows.length > 0 && (
            <div className="mb-5">
              <h4>Failed</h4>
              {failedRows.map((row: ImportedRowType, index: number) => (
                <div className={styles.errorAlertContainer} key={row.forename + index}>
                  {row.forename} {row.surname} ({row.email})
                  <br />
                  Reason - {row.reason}
                </div>
              ))}
            </div>
          )}

          <footer className={styles.btnFooter}>
            <DefaultButton variant="primary" type="button" onClick={onRestart}>
              Start Again
            </DefaultButton>
          </footer>
        </div>
      </div>
    </div>
  );
};

type Results = {
  failed: ImportedRowType[];
  successful: ImportedRowType[];
};

interface ImportUserResults {
  results: Results;
  onRestart: () => void;
}

export default ImportUserResults;
