import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './index.module.scss';

const NotificationSection: React.FunctionComponent<NotificationSectionProps> = ({
  loading,
  icon,
  button,
  handleClickAction,
  improvePlan,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={12}>
      <div className={`${styles.actionNotificationContainer}`}>
        <div className={styles.iconContainer}>
          {loading ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Img src={icon} alt="Notification Active" className={styles.notificationIcon} />
          )}
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.notificationMobile}>
            {loading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <Img src={icon} alt="Notification Active" className={styles.notificationIcon} />
            )}
          </div>
          <div
            className={`${styles.text} ${
              improvePlan?.details?.actionType === 'USER_ACTION' && styles.noBtn
            }`}
          >
            {loading ? (
              <Skeleton width="80%" />
            ) : (
              `Your next action: ${
                improvePlan?.details?.actionTitle
                  ? improvePlan?.details?.actionTitle
                  : 'Set a focus area and create your personalised plan'
              }`
            )}
          </div>
          <div className={styles.button}>
            <div>
              {loading ? (
                <Skeleton variant="rounded" width="20%" className={styles.loadBtn} />
              ) : improvePlan?.details?.actionType !== 'USER_ACTION' || !improvePlan ? (
                <DefaultButton
                  variant="primary"
                  className={styles.hsPrimaryBtn}
                  onClick={handleClickAction}
                >
                  {button}
                </DefaultButton>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  </Grid>
);

NotificationSection.defaultProps = {
  loading: false,
  button: 'Start Now',
};

interface NotificationSectionProps {
  loading?: boolean;
  icon: string;
  button?: string;
  improvePlan: Action;
  handleClickAction: () => void;
}

interface Action {
  completedAt: string | null;
  done: boolean | null;
  dueDate: string | null;
  improvePlanActionDueDays: number | null;
  improvePlanId: number | null;
  removed: string | null;
  startAt: string | null;
  details: ActionDetails;
}

interface ActionDetails {
  actionId: number;
  actionType: string;
  actionTitle: string;
  actionDescription: string | null;
  actionImage: string | null;
}

export default NotificationSection;
