import Card from '@components/Atoms/Card';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ImprovePlanAction, UserImprovePlanActions } from 'custom.d';
import React, { useState } from 'react';
import Img from '@components/Atoms/Img';
import { formatDateToDateMonthYear } from '@utils/date.util';
import DefaultButton from '@components/Atoms/DefaultButton';
import { getUserImprovePlanActionsById } from '@api/actionPlan';
import BorderedProgressBar from '@components/Atoms/BorderLinearProgressBar';
import { set } from 'lodash';
import styles from './index.module.scss';
import ImproveActionCard from '../ImproveActionCard';

interface ColorMap {
  [key: string]: { barColor: string; backgroundColor: string };
}

const colorMap: ColorMap = {
  sunYellow: { barColor: '#FCD300', backgroundColor: '#ffe665' },
  aquaGreen: { barColor: '#06BF97', backgroundColor: '#3be1ce' },
  calmLilac: { barColor: '#BEAAF3', backgroundColor: '#d4c6f9' },
  warmOrange: { barColor: '#FF8A4A', backgroundColor: '#ffaf83' },
  zingyGreen: { barColor: '#8EEA36', backgroundColor: '#bbf484' },
  skyBlue: { barColor: '#019DF2', backgroundColor: '#3fb9fc' },
};

const defaultColors = { barColor: '#f4f2ec', backgroundColor: '#fcfbf7' };

const FinishedImprovePlans: React.FunctionComponent<FinishedImprovePlansSectionProps> = (props) => {
  const { loading, plans } = props;
  const { isDesktop } = useWindowDimensions();
  const [viewCompletedAction, setViewCompletedAction] = useState<{ show: boolean; data: any }>({
    show: false,
    data: null,
  });
  const [userImprovePlanActions, setUserImprovePlanActions] = useState<ImprovePlanAction[]>([]);
  const [filterActiveActions, setFilterActiveActions] = useState(true);

  const imageUrl = `../../../images/improve-actions/default-${
    isDesktop ? 'desktop' : 'mobile'
  }.png`;

  const getActions = async (id: number): Promise<ImprovePlanAction[]> => {
    try {
      let res: ImprovePlanAction[] = [];
      const response = await getUserImprovePlanActionsById(id);
      res = response.improvePlanActions.filter((dt) => dt.improvePlanActionDone);

      return res;
    } catch (e) {
      // handle err
      return [];
    }
  };

  return (
    <Card className={styles.card}>
      {!viewCompletedAction.show ? (
        <>
          <h3>Your completed plans</h3>

          <div className={styles.actionSection}>
            {plans.map((dt, index) => (
              <div
                key={index}
                className={`${styles.actionCard} ${
                  dt?.question?.color ? styles[dt?.question?.color] : ''
                }`}
              >
                <div className={styles.container}>
                  <div
                    className={styles.cardActionImageCont}
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                    }}
                  />

                  <div className={styles.planName}>{dt?.question?.questionText} plan</div>

                  <div className={styles.middleCont}>
                    <div className={styles.dates}>
                      started
                      <br />
                      {dt?.completeAt && formatDateToDateMonthYear(dt?.completeAt)}
                    </div>
                    <div className={styles.dates}>
                      finished
                      <br />
                      {dt?.completeAt && formatDateToDateMonthYear(dt?.completeAt)}
                    </div>
                    <div className={styles.dates}>
                      completed
                      <br />
                      {dt?.actionCount}
                    </div>
                  </div>

                  <div className={styles.btnCont}>
                    <DefaultButton
                      className={styles.hsPrimaryBtn}
                      onClick={async () => {
                        const actions = await getActions(dt.id);
                        setViewCompletedAction({
                          show: true,
                          data: { plan: dt, actions },
                        });
                      }}
                    >
                      View completed actions
                    </DefaultButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={styles.focusCardContainer}>
            <Card className={styles.focusCard}>
              <div className={styles.focusCardHeader}>
                <h2 className={styles.cardTitle}>
                  Your{' '}
                  {viewCompletedAction?.data?.plan?.question?.questionText?.toLowerCase() || ''}{' '}
                  plan
                </h2>
              </div>

              <div className={styles.progressSection}>
                <span>your progress</span>
                <span>{`${viewCompletedAction?.data?.plan?.progress}`}%</span>
              </div>

              <BorderedProgressBar
                value={viewCompletedAction?.data?.plan?.progress}
                className={styles.progressBar}
                barColor={
                  (viewCompletedAction?.data?.plan &&
                    colorMap[viewCompletedAction?.data?.plan?.question?.color]?.barColor) ||
                  defaultColors.barColor
                }
                backgroundColor={
                  (viewCompletedAction?.data?.plan &&
                    colorMap[viewCompletedAction?.data?.plan?.question?.color]?.backgroundColor) ||
                  defaultColors.backgroundColor
                }
              />

              {/* <div className={styles.focussingTextContainer}>
        <span>you are focusing on</span>
        {['Nutrition', 'Exercise'].map((item, index) => (
          <div
            key={index}
            className={`${styles.focusRoundedItem} ${
              selectedImprovePlan?.question?.color
                ? styles[selectedImprovePlan?.question?.color]
                : ''
            }`}
          >
            {item}
          </div>
        ))}
        </div> */}

              <div className={styles.completedDate}>{`completed on: ${formatDateToDateMonthYear(
                viewCompletedAction?.data?.plan?.completeAt,
              )}`}</div>

              <p className={styles.focusAreaDescription}>
                {viewCompletedAction?.data?.plan?.question?.questionInfo}
              </p>

              <div className={styles.actionSection}>
                <>
                  {viewCompletedAction?.data?.actions?.length > 0 ? (
                    viewCompletedAction?.data?.actions?.map(
                      (item: ImprovePlanAction, index: number) => (
                        <ImproveActionCard
                          key={index}
                          actionData={item}
                          className={`${styles.actionCard} ${
                            viewCompletedAction?.data?.plan?.question?.color
                              ? styles[viewCompletedAction?.data?.plan?.question?.color]
                              : ''
                          }`}
                          title={item.actionTitle}
                          description={item.actionDescription || ''}
                          dueDate={
                            item.improvePlanActionDueDays
                              ? item.improvePlanActionDueDays >= 0
                                ? `${Math.abs(item.improvePlanActionDueDays)} days to go`
                                : `${Math.abs(item.improvePlanActionDueDays)} days overdue`
                              : 'Set now'
                          }
                          completed={item.improvePlanActionCompletedAt !== null}
                          overdue={
                            item.improvePlanActionDueDays
                              ? !(item.improvePlanActionDueDays >= 0)
                              : null
                          }
                          isRecommendedSection={false}
                          theme={viewCompletedAction?.data?.plan?.question?.color}
                          actionButtonName={item.actionButton}
                          actionImage={item.actionImage}
                          handleRefetch={() => getActions(0)}
                          handleActions={setUserImprovePlanActions}
                          filterActiveActions={filterActiveActions}
                          handleProgress={null}
                          hideOptions
                        />
                      ),
                    )
                  ) : (
                    <h6 className={styles.noActionsText}>No actions to display here...</h6>
                  )}
                </>
              </div>

              <div
                className={styles.backBtn}
                onClick={() => setViewCompletedAction({ show: false, data: null })}
              >
                <DefaultButton>BACK TO COMPLETED PLANS</DefaultButton>
              </div>
            </Card>
          </div>
        </>
      )}
    </Card>
  );
};

FinishedImprovePlans.defaultProps = {
  loading: false,
};

type FinishedImprovePlansSectionProps = {
  loading?: boolean;
  plans: UserImprovePlanActions[];
};

export default FinishedImprovePlans;
