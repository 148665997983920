import {
  getEmployeeHighlights,
  getEmployerThoughts,
  getSpeakUpResponsesForEmployer,
  saveEmployerResponses,
  saveSpeakupResponse,
} from '@api/EmployerNew';
import { getQuestionnairesNames } from '@api/questionnaires';
import blueTray from '@assets/images/icons/blueTray.svg';
import infoRed from '@assets/images/icons/info-red.svg';
import nextIcon from '@assets/images/icons/next.svg';
import previousIcon from '@assets/images/icons/previous.svg';
import CommentsActionCard from '@components/ActionsCard/CommentsActionCard';
import ResponsesActionCard from '@components/ActionsCard/ResponsesActionCard';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import SelectComponent from '@components/Atoms/SelectComponent';
import TabButton from '@components/Atoms/TabButton';
import Checkbox from '@components/Checkbox';
import SpeakUpActionCard from '@components/Organisms/SpeakupActionCard/SpeakUpActionCard';
import { useAppSelector } from '@hooks/useAppSelector';
import MainLayout from '@layouts/MainLayout';
import { PaginationInterface, ThoughtDataInterface } from 'custom.d';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { RootState } from 'store';
import styles from './index.module.scss';

const EmployerCommentsPage = () => {
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const isSpeakupAvailable = companyConfigData?.data?.showSpeakUp;

  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [questionnaireNames, setQuestionnaireNames] = useState<QuestionnaireNamesInterface[]>([]);
  const [checkboxValue, setCheckboxValue] = useState<boolean>(true);
  const [checkboxValue1, setCheckboxValue1] = useState<boolean>(true);
  const [checkboxValue2, setCheckboxValue2] = useState<boolean>(true);
  const [checkboxValue3, setCheckboxValue3] = useState<boolean>(true);
  const [checkboxValue4, setCheckboxValue4] = useState<boolean>(true);
  const [checkboxValue5, setCheckboxValue5] = useState<boolean>(true);
  const [checkboxValueForDefaultStar, setCheckboxValueForDefaultStar] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [selectedTabDetails, setSelectedTabDetails] = useState<TabDetailsInterface>({
    id: 0,
    label: '',
    labelId: 1,
  });

  const [highlights, setHighlights] = useState<HighlightsInterface[]>([]);
  const [sortOrder] = useState<boolean>(false);
  const [rerender, setRerender] = useState<boolean>(false);
  const [speakupRerender, setSpeakupRerender] = useState<boolean>(false);
  const [expandSelectButton, setExpandSelectButton] = useState<boolean>(false);
  const [selectedSortItem, setSelectedSortItem] = useState<number>(0);
  const [months, setMonths] = useState<number>(1);
  const [speakupResponses, setSpeakupResponses] = useState<SpeakUpInterface>({
    data: [],
    pagination: initialSpeakupData().pagination,
  });
  const [speakupResponded, setSpeakupResponded] = useState<SpeakUpInterface>({
    data: [],
    pagination: initialSpeakupData().pagination,
  });

  const [thoughtsResponses, setThoughtsResponses] = useState<ThoughtsInterface>({
    data: [],
    pagination: initialThoughtsData().pagination,
  });
  const [thoughtsResponded, setThoughtsResponded] = useState<ThoughtsInterface>({
    data: [],
    pagination: initialThoughtsData().pagination,
  });
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(20);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const order: string = selectedSortItem === 1 ? 'ASC' : 'DESC';

  const orderComment: { orderType: string; orderBy: string } =
    selectedSortItem === 1
      ? { orderType: 'ASC', orderBy: 'CREATED_AT' }
      : selectedSortItem === 2
      ? { orderType: 'DESC', orderBy: 'CREATED_AT' }
      : selectedSortItem === 3
      ? { orderType: 'DESC', orderBy: 'SCORE' }
      : selectedSortItem === 4
      ? { orderType: 'ASC', orderBy: 'SCORE' }
      : { orderType: 'DESC', orderBy: 'CREATED_AT' };

  const questionnaireIds = useMemo(
    () =>
      [
        checkboxValue === true ? 1 : false,
        checkboxValue1 === true ? 2 : false,
        checkboxValue2 === true ? 3 : false,
        checkboxValue3 === true ? 5 : false,
        checkboxValue4 === true ? 6 : false,
        checkboxValue5 === true ? 7 : false,
        checkboxValueForDefaultStar === true ? 4 : false,
      ].filter((i: any) => i !== false),
    [
      checkboxValue,
      checkboxValue1,
      checkboxValue2,
      checkboxValue3,
      checkboxValue4,
      checkboxValue5,
      checkboxValueForDefaultStar,
    ],
  );

  useEffect(() => {
    if (isSpeakupAvailable) {
      setSelectedTab(1);
      setSelectedTabDetails({
        id: 0,
        label: '',
        labelId: 1,
      });
    } else {
      setSelectedTab(2);
      setSelectedTabDetails({
        id: 0,
        label: '',
        labelId: 2,
      });
    }
  }, [isSpeakupAvailable]);

  useEffect(() => {
    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
    }, 500);
  }, [selectedTab]);

  useEffect(() => {
    fetchThoughtsData(false);
    fetchThoughtsData(true);
  }, [rerender]);

  useEffect(() => {
    if (isSpeakupAvailable) {
      fetchSpeakupData(false, questionnaireIds as number[]);
      fetchSpeakupData(true, questionnaireIds as number[]);
    }
  }, [speakupRerender, isSpeakupAvailable]);

  useEffect(() => {
    if (selectedTab === 2) {
      fetchThoughtsData(false);
    } else if (selectedTab === 4) {
      fetchThoughtsData(true);
    }
  }, [page, selectedTab, questionnaireIds, orderComment.orderBy, orderComment.orderType]);

  useEffect(() => {
    if (isSpeakupAvailable) {
      if (selectedTab === 1) {
        fetchSpeakupData(false, questionnaireIds as number[]);
      } else if (selectedTab === 3) {
        fetchSpeakupData(true, questionnaireIds as number[]);
      }
    }
  }, [page, selectedTab, questionnaireIds, order, isSpeakupAvailable]);

  const fetchSpeakupData = async (isResponded: boolean, qIds: number[]) => {
    try {
      const data = await getSpeakUpResponsesForEmployer(isResponded, qIds, page, perPage, order);

      if (isResponded) {
        setSpeakupResponded(data);
      } else {
        setSpeakupResponses(data);
      }
    } catch (error) {
      setSpeakupResponded({
        data: [],
        pagination: initialSpeakupData().pagination,
      });
      setSpeakupResponses({
        data: [],
        pagination: initialSpeakupData().pagination,
      });
    }
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    getQuestionnairesNames().then((data) => {
      if (Array.isArray(data)) {
        // Define the new object you want to add
        const newObject = { id: 4, name: 'Check-in' };

        // Add the new object to the beginning of the array using unshift()
        data.unshift(newObject);

        // Set the updated array
        setQuestionnaireNames(data);
      } else {
        setQuestionnaireNames([]);
      }
    });
  }, []);

  const handleCompanyChange = (e: ReactNode, index: number) => {
    if (index === 1) {
      setCheckboxValue((prev: boolean) => !prev);
    } else if (index === 2) {
      setCheckboxValue1((prev: boolean) => !prev);
    } else if (index === 3) {
      setCheckboxValue2((prev: boolean) => !prev);
    } else if (index === 4) {
      setCheckboxValue3((prev: boolean) => !prev);
    } else if (index === 5) {
      setCheckboxValue4((prev: boolean) => !prev);
    } else if (index === 6) {
      setCheckboxValue5((prev: boolean) => !prev);
    } else {
      setCheckboxValueForDefaultStar((prev: boolean) => !prev);
    }
  };

  const fetchThoughtsData = async (isResponded: boolean) => {
    try {
      const data = await getEmployerThoughts(
        isResponded,
        questionnaireIds,
        page,
        perPage,
        orderComment.orderType,
        orderComment.orderBy,
      );

      if (isResponded) {
        setThoughtsResponded(data);
      } else {
        setThoughtsResponses(data);
      }
    } catch (error) {
      setThoughtsResponded({
        data: [],
        pagination: initialSpeakupData().pagination,
      });
      setThoughtsResponses({
        data: [],
        pagination: initialSpeakupData().pagination,
      });
    }
  };

  useEffect(() => {
    const getEmployerHighlightsData = async () => {
      const employeeHighlights = await getEmployeeHighlights(months);

      setHighlights(employeeHighlights);
    };

    getEmployerHighlightsData();
  }, [months]);

  const saveResponse = async ({
    thoughtId,
    thoughtResponseText,
  }: {
    thoughtId: number;
    thoughtResponseText: string;
  }) => {
    try {
      if (thoughtResponseText?.length > 0) {
        await saveEmployerResponses({
          thoughtId,
          thoughtResponseText,
        });
        setRerender((prev: boolean) => !prev);
      }

      await toast.success('Response saved successfully');
    } catch (error) {
      toast.error('Error saving response');
    }
  };

  const saveSpeakup = async (speakupId: number, message: string) => {
    try {
      if (message?.length > 0) {
        await saveSpeakupResponse(speakupId, message);
        setSpeakupRerender((prev: boolean) => !prev);
      }

      await toast.success('Speakup response saved successfully');
    } catch (error) {
      toast.error('Error saving Speakup response');
    }
  };

  const renderSpeakUpCards = (
    data: SpeakupUpDataInterface[],
    showResponseButton: boolean,
    responsesEnabled: boolean,
  ) =>
    data.map((item: SpeakupUpDataInterface, index: number) => (
      <div key={index} className={styles.commentCard}>
        <SpeakUpActionCard
          categoryName={item.shareIdentity ? `${item.firstName} ${item.lastName}` : 'Anonymous'}
          saveResponseProps={(responseText: string) => saveSpeakup(item.speakUpId, responseText)}
          key={index}
          addToCompleted={() => null}
          focusArea={
            item.shareIdentity ? `${item.firstName} ${item.lastName} - ${item.email}` : 'Anonymous'
          }
          focusAreaColor="#019DF2"
          action={item.speakUpMessage}
          scores={item.scores}
          createdDate={item.speakUpCreatedDate}
          completed
          showResponseButton={showResponseButton}
          responsesEnabled={responsesEnabled}
          response={item.response}
        />
      </div>
    ));

  const handleCommentChange = (tab: { id: number; label: string; labelId: number }) => {
    setSelectedTabDetails(tab);
    setSelectedTab(tab.labelId);
    setPage(1);
    setSelectedSortItem(0);
  };

  const tabButtons = [
    {
      id: 1,
      title: `Speak Up - Pending (${speakupResponses.pagination.total || 0})`,
      icon: infoRed,
    },
    {
      id: 2,
      title: `Comments - Pending (${thoughtsResponses?.pagination?.total})`,
      icon: infoRed,
    },
    {
      id: 3,
      title: `Speak Up - Responded (${speakupResponded.pagination.total || 0})`,
    },
    { id: 4, title: `Comments - Responded (${thoughtsResponded?.pagination?.total})` },
  ];

  const filteredTabButtons = !isSpeakupAvailable
    ? tabButtons.filter((button) => button.id !== 1 && button.id !== 3)
    : tabButtons;

  const tabButtonsCombined = filteredTabButtons.map((button) => ({
    ...button,
    value: button.id,
    label: button.title,
  }));

  const thoughtsResponsesLength = thoughtsResponses?.data?.length || 0;
  const thoughtsRespondedLength = thoughtsResponded?.data?.length || 1;

  return (
    <MainLayout title="Comments" useEmployerNav isEmployerPage secondaryNavEnabled>
      <ToastContainer hideProgressBar theme="dark" />
      <div className={styles.container}>
        <h2 className={styles.mainTitle}>Comments</h2>
        {/* <div className={styles.timeframes}>
          {['1 month', '6 months', '1 year'].map((i: string, key: number) => {
            const selected = key === selectedItem;

            return (
              <div
                key={key}
                onClick={() => {
                  setSelectedItem(key);
                  if (key === 0) {
                    setMonths(1);
                  }

                  if (key === 1) {
                    setMonths(6);
                  }

                  if (key === 2) {
                    setMonths(12);
                  }
                }}
                className={`${styles.timeframeItem} ${selected ? styles.active : ''}`}
              >
                {i}
              </div>
            );
          })}
        </div>
        <div className={styles.highlightsContainer}>
          {highlights.length > 0 &&
            highlights.map((i: HighlightsInterface, key: number) => (
              <HighlightsCard
                key={key}
                index={key}
                title={i.title}
                icon={i.icon}
                value={i.value}
                percentage={
                  Number.isNaN(
                    (thoughtsResponses.data.filter((o: ThoughtDataInterface) => !o.responded)
                      .length /
                      thoughtsResponses.data.length) *
                      100,
                  )
                    ? 0
                    : (thoughtsResponsesLength / thoughtsRespondedLength) * 100
                }
              />
            ))}
        </div> */}

        <div className={styles.selectComponent}>
          <SelectComponent
            id="commentsDropdownSelection"
            name="commentsDropdownSelection"
            placeholder={
              selectedTabDetails?.label?.length > 0
                ? selectedTabDetails?.label
                : `Speak Up - Pending (${speakupResponses.data.length || 0})`
            }
            onChange={(tab: any) => {
              setSelectedTab(tab?.value);
              setSelectedTabDetails(tab);
            }}
            options={tabButtonsCombined}
          />
        </div>

        <div className={styles.tabsContainer}>
          <TabButton
            selectedTab={selectedTabDetails}
            handleSelect={handleCommentChange}
            tabs={tabButtonsCombined}
            isMobileWrapEnabled
          />
        </div>

        <div className={styles.questionairesContainer}>
          <div className={styles.fullWidthContainer}>
            <div className={styles.rightContainer}>
              {questionnaireNames &&
                questionnaireNames.map((i: QuestionnaireNamesInterface, key: number) => (
                  <div
                    key={key}
                    className={styles.questionaireItem}
                    style={{
                      marginRight:
                        key === questionnaireNames.length - 1 && expandSelectButton
                          ? '225px'
                          : undefined,
                    }}
                  >
                    <Checkbox
                      id={i.name}
                      text={i.name}
                      color={
                        key === 1
                          ? '#FCD300'
                          : key === 2
                          ? '#019DF2'
                          : key === 3
                          ? '#06BF97'
                          : key === 4
                          ? '#BEAAF3'
                          : key === 5
                          ? '#FF8A4A'
                          : key === 6
                          ? '#8EEA36'
                          : '#505050'
                      }
                      onChange={(e: ReactNode) => handleCompanyChange(e, key)}
                      checked={
                        key === 1
                          ? checkboxValue
                          : key === 2
                          ? checkboxValue1
                          : key === 3
                          ? checkboxValue2
                          : key === 4
                          ? checkboxValue3
                          : key === 5
                          ? checkboxValue4
                          : key === 6
                          ? checkboxValue5
                          : checkboxValueForDefaultStar
                      }
                    />
                  </div>
                ))}
              {expandSelectButton && (
                <div className={styles.expandSelectedBtnSection}>
                  <DefaultButton
                    className={styles.sortButton}
                    type="button"
                    onClick={() => setExpandSelectButton((prev: boolean) => !prev)}
                  >
                    <p>Sort by</p>
                    <i className={!sortOrder ? 'icon icon-chevron-down' : 'icon icon-chevron-up'} />
                  </DefaultButton>

                  <div className={styles.expandBtnContainer}>
                    <DefaultButton
                      className={`${styles.expandButtonDefault} ${
                        selectedSortItem === 1 ? styles.selectedSortItem : ''
                      }`}
                      onClick={() => {
                        setSelectedSortItem(1);
                        setExpandSelectButton((prev: boolean) => !prev);
                        setPage(1);
                      }}
                    >
                      Sort By Date - old to new
                    </DefaultButton>
                    <DefaultButton
                      className={`${styles.expandButtonDefault} ${
                        selectedSortItem === 2 ? styles.selectedSortItem : ''
                      }`}
                      onClick={() => {
                        setSelectedSortItem(2);
                        setExpandSelectButton((prev: boolean) => !prev);
                        setPage(1);
                      }}
                    >
                      Sort By Date - new to old
                    </DefaultButton>
                    {selectedTab !== 1 && selectedTab !== 3 && (
                      <>
                        <DefaultButton
                          className={`${styles.expandButtonDefault} ${
                            selectedSortItem === 3 ? styles.selectedSortItem : ''
                          }`}
                          onClick={() => {
                            setSelectedSortItem(3);
                            setExpandSelectButton((prev: boolean) => !prev);
                            setPage(1);
                          }}
                        >
                          Score - high to low
                        </DefaultButton>
                        <DefaultButton
                          className={`${styles.expandButtonDefault} ${
                            selectedSortItem === 4 ? styles.selectedSortItem : ''
                          }`}
                          onClick={() => {
                            setSelectedSortItem(4);
                            setExpandSelectButton((prev: boolean) => !prev);
                            setPage(1);
                          }}
                        >
                          Score - low to high
                        </DefaultButton>
                      </>
                    )}
                  </div>
                </div>
              )}
              {!expandSelectButton && (
                <DefaultButton
                  className={styles.sortButton1}
                  type="button"
                  onClick={() => {
                    setExpandSelectButton((prev: boolean) => !prev);
                    setPage(1);
                  }}
                >
                  <p className={styles.spanButton1}>Sort by</p>
                  <i className={!sortOrder ? 'icon icon-chevron-down' : 'icon icon-chevron-up'} />
                </DefaultButton>
              )}
            </div>
            {loadingData ? (
              <div className={styles.scrollContainerSpinner}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only" />
                </div>
              </div>
            ) : (
              <div className={styles.scrollContainer}>
                <div
                  className={styles.fullWidth}
                  style={{ width: selectedTab !== 4 ? '100%' : '50%' }}
                >
                  {selectedTab !== 1 && selectedTab !== 3 && (
                    <h2 className={styles.commentsTab}>Comments</h2>
                  )}

                  <div className={styles.thoughtsContainer}>
                    <div className={styles.awaitingResContent}>
                      <>
                        {selectedTab === 1 &&
                          speakupResponses.data.length > 0 &&
                          renderSpeakUpCards(speakupResponses.data, false, false)}

                        {selectedTab === 3 &&
                          speakupResponded.data.length > 0 &&
                          renderSpeakUpCards(speakupResponded.data, true, true)}

                        {selectedTab === 1 && speakupResponses.data.length < 1 && (
                          <div className={styles.thoughtsTab1}>
                            <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                            <p>There are no pending Speak Up thoughts awaiting a response.</p>
                          </div>
                        )}

                        {selectedTab === 3 && speakupResponded.data.length < 1 && (
                          <div className={styles.thoughtsTab1}>
                            <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                            <p>No Speak Up comments or responses found.</p>
                          </div>
                        )}
                      </>
                    </div>

                    <div className={styles.awaitingResContent}>
                      {thoughtsResponses?.data?.length > 0 &&
                        selectedTab === 2 &&
                        thoughtsResponses?.data
                          .filter((o: ThoughtDataInterface) => o.responded === false)
                          .map((i: ThoughtDataInterface, key: number) => (
                            <div key={key} className={styles.commentCard}>
                              <CommentsActionCard
                                categoryName={i.categoryName}
                                saveResponseProps={(thoughtResponseText: string) =>
                                  saveResponse({ thoughtId: i.thoughtId, thoughtResponseText })
                                }
                                key={key}
                                addToCompleted={() => null}
                                focusArea={i.title}
                                focusAreaColor={i.color}
                                action={i?.thoughtsText}
                                score={i.score}
                                createdDate={i.createdAt}
                                completed
                                showResponseButton={i.responded}
                              />
                            </div>
                          ))}
                    </div>
                    {thoughtsResponses?.data?.length < 1 && selectedTab === 2 && (
                      <div className={styles.thoughtsTab1}>
                        <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                        <p>You have no comments waiting for a response.</p>
                      </div>
                    )}
                    {thoughtsResponded?.data?.length < 1 && selectedTab === 4 && (
                      <div className={styles.thoughtsTab2}>
                        <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                        <p>You have no comments yet</p>
                      </div>
                    )}
                    {thoughtsResponded?.data?.length > 0 &&
                      selectedTab === 4 &&
                      thoughtsResponded?.data
                        .filter((o: ThoughtDataInterface) => o.responded)
                        .map((i: ThoughtDataInterface, key: number) => (
                          <CommentsActionCard
                            categoryName={i.categoryName}
                            saveResponseProps={(thoughtResponseText: string) =>
                              saveResponse({ thoughtId: i.thoughtId, thoughtResponseText })
                            }
                            key={key}
                            addToCompleted={() => null}
                            focusArea={i.title}
                            focusAreaColor={i.color}
                            action={i.thoughtsText}
                            score={i.score}
                            createdDate={i.createdAt}
                            completed
                            showResponseButton={i.responded}
                          />
                        ))}
                  </div>
                </div>
                {selectedTab === 4 && (
                  <div className={styles.halfWidth}>
                    <h2 className={styles.responsesTitle}>Responses</h2>
                    <div className={styles.responsesContainer}>
                      {thoughtsResponded?.data?.length > 0 &&
                        thoughtsResponded?.data?.map((i: ThoughtDataInterface, key: number) => (
                          <ResponsesActionCard
                            categoryName={i.categoryName}
                            key={key}
                            addToCompleted={() => null}
                            focusArea={i.title}
                            focusAreaColor={i.color}
                            questionnaireName={i.questionnaireName}
                            action={i?.response?.responseMessage || ''}
                            score={i.score}
                            createdDate={i?.response?.responseCreated || i.createdAt}
                            completed
                          />
                        ))}
                      {thoughtsResponded?.data?.length < 1 && (
                        <div className={styles.responsesList}>
                          <Img src={blueTray} className={styles.blueTrayImg} alt="blue tray" />
                          <p>You have no responses yet</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {!loadingData && (selectedTab === 1 || selectedTab === 3) && (
              <div className={styles.paginationBtnContainer}>
                <DefaultButton
                  variant="icon"
                  type="button"
                  onClick={handlePrevPage}
                  disabled={
                    selectedTab === 1
                      ? speakupResponses?.pagination?.currentPage === 1 ||
                        speakupResponses?.pagination?.prevPage === null
                      : speakupResponded?.pagination?.currentPage === 1 ||
                        speakupResponded?.pagination?.prevPage === null
                  }
                  iconSrc={previousIcon}
                  iconPosition="left"
                >
                  {' '}
                </DefaultButton>
                {selectedTab === 1 ? (
                  <span>{`${speakupResponses?.pagination?.to} of ${speakupResponses?.pagination?.total}`}</span>
                ) : (
                  <span>{`${speakupResponded?.pagination?.to} of ${speakupResponded?.pagination?.total}`}</span>
                )}
                <DefaultButton
                  variant="icon"
                  type="button"
                  onClick={handleNextPage}
                  iconSrc={nextIcon}
                  iconPosition="right"
                  disabled={
                    selectedTab === 1
                      ? Number(speakupResponses?.pagination?.currentPage) ===
                          speakupResponses?.pagination?.lastPage ||
                        speakupResponses?.pagination?.nextPage === null
                      : Number(speakupResponded?.pagination?.currentPage) ===
                          speakupResponded?.pagination?.lastPage ||
                        speakupResponded?.pagination?.nextPage === null
                  }
                >
                  {' '}
                </DefaultButton>
              </div>
            )}

            {(selectedTab === 2 || selectedTab === 4) && (
              <div className={styles.paginationBtnContainer}>
                <DefaultButton
                  variant="icon"
                  type="button"
                  onClick={handlePrevPage}
                  disabled={
                    selectedTab === 2
                      ? thoughtsResponses?.pagination?.currentPage === 1 ||
                        thoughtsResponses?.pagination?.prevPage === null
                      : thoughtsResponded?.pagination?.currentPage === 1 ||
                        thoughtsResponded?.pagination?.prevPage === null
                  }
                  iconSrc={previousIcon}
                  iconPosition="left"
                >
                  {' '}
                </DefaultButton>
                {selectedTab === 2 ? (
                  <span>{`${thoughtsResponses?.pagination?.to} of ${thoughtsResponses?.pagination?.total}`}</span>
                ) : (
                  <span>{`${thoughtsResponded?.pagination?.to} of ${thoughtsResponded?.pagination?.total}`}</span>
                )}
                <DefaultButton
                  variant="icon"
                  type="button"
                  onClick={handleNextPage}
                  iconSrc={nextIcon}
                  iconPosition="right"
                  disabled={
                    selectedTab === 2
                      ? Number(thoughtsResponses?.pagination?.currentPage) ===
                          thoughtsResponses?.pagination?.lastPage ||
                        thoughtsResponses?.pagination?.nextPage === null
                      : Number(thoughtsResponded?.pagination?.currentPage) ===
                          thoughtsResponded?.pagination?.lastPage ||
                        thoughtsResponded?.pagination?.nextPage === null
                  }
                >
                  {' '}
                </DefaultButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

interface SpeakUpInterface {
  data: SpeakupUpDataInterface[];
  pagination: PaginationInterface;
}

interface ThoughtsInterface {
  data: ThoughtDataInterface[];
  pagination: PaginationInterface;
}

interface SpeakupUpDataInterface {
  questionnaireId: number;
  questionnaire: string;
  speakUpId: number;
  speakUpMessage: string;
  shareIdentity: false;
  speakUpCreatedDate: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  scores: {
    instantHelpTriggerId: number;
    assessment_id: number;
    questionId: number;
    questionText: string;
    score: number;
  }[];
  response?: {
    responderFirstName: string;
    responderLastName: string;
    responseCreated: string;
    responseId: number;
    responseMessage: string;
    responseSeen: boolean;
  };
}

interface HighlightsInterface {
  icon: string;
  title: string;
  value: Array<number>;
}

interface QuestionnaireNamesInterface {
  id: number;
  name: string;
}

interface TabDetailsInterface {
  id: number;
  label: string;
  labelId: number;
}
const initialSpeakupData = (): SpeakUpInterface => ({
  data: [],
  pagination: {
    total: 0,
    lastPage: 0,
    prevPage: 0,
    nextPage: 0,
    perPage: '',
    currentPage: 0,
    from: 0,
    to: 0,
  },
});

const initialThoughtsData = (): ThoughtsInterface => ({
  data: [],
  pagination: {
    total: 0,
    lastPage: 0,
    prevPage: 0,
    nextPage: 0,
    perPage: '',
    currentPage: 0,
    from: 0,
    to: 0,
  },
});

export default EmployerCommentsPage;
