import LoadingPage from '@components/LoadingPage/LoadingPage';
import CompleteRegistration from '@components/Templates/CompleteRegistration';
import { getCompanyDetails } from '@helpers/auth.helper';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import React, { useEffect, useState } from 'react';

const CompleteRegistrationPage: React.FunctionComponent = () => {
  const currentUser = localStorage.getItem('user') as any;
  const [companyDetails, setCompanyDetails] = useState<any>(null);
  let organizationLogo;
  let organizationAltText;
  let organizationId = 0;
  const [companyDataLoading, setCompanyDataLoading] = useState(true);

  useEffect(() => {
    getCompanyData();
  }, []);

  const getCompanyData = async () => {
    try {
      const data = await getCompanyDetails();
      if (data) {
        setCompanyDetails(data);
      }
    } catch {
      setCompanyDetails(null);
    } finally {
      setCompanyDataLoading(false);
    }
  };

  if (companyDetails !== null) {
    organizationLogo =
      companyDetails.company_logo &&
      `${process.env.PUBLIC_URL}/images/logos/${companyDetails.company_logo}`;
    organizationAltText = companyDetails.company_name && `${companyDetails.company_name} logo`;
    organizationId = companyDetails.id;
  }

  return (
    <>
      {companyDataLoading ? (
        <AuthenticationLayout
          title="SignUp-Complete"
          organizationLogo={organizationLogo}
          organizationAltText={organizationAltText}
        >
          <LoadingPage />
        </AuthenticationLayout>
      ) : (
        <CompleteRegistration
          organizationLogo={organizationLogo}
          organizationAltText={organizationAltText}
          organizationId={organizationId}
          user={JSON.parse(currentUser)}
        />
      )}
    </>
  );
};

export default CompleteRegistrationPage;
