import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import menuItems from '@config/main-menu.config';
import moreActive from '@assets/images/icons/more-menu-active.svg';
import moreInactive from '@assets/images/icons/more-menu-inactive.svg';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { useProSidebar } from 'react-pro-sidebar';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppSelector } from '@hooks/useAppSelector';
import { RootState } from 'store';
import styles from './index.module.scss';

const MobileFooter: React.FunctionComponent = () => {
  const { isMobile, isDesktop } = useWindowDimensions();
  const { toggled, toggleSidebar } = useProSidebar();
  const { pathname } = useLocation();
  const { toggleSubNav } = useContext(SecondaryNavigationContext);
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const defaultSubNav = { id: 1, name: 'Overall' };

  const handleClick = () => {
    toggleSidebar(!toggled);
  };

  return (
    <div className={styles.hsMobileFooter}>
      <ul>
        {menuItems
          .filter((item) => {
            if (!companyConfigData.data.showInstantHelp && item.name === 'Hub') {
              return false;
            }

            if (!companyConfigData.data.expertsEnabled && item.name === 'Experts') {
              return false;
            }

            return true;
          })
          .map((item, index) => (
            <div
              key={index}
              onClick={() => {
                if (item.name === 'Scores')
                  toggleSubNav(
                    JSON.parse(sessionStorage.getItem('subNav') as any) || defaultSubNav,
                  );
              }}
            >
              <li key={index}>
                <NavLink to={item.path} {...(toggled && { onClick: handleClick })}>
                  <img
                    src={
                      pathname === item.path
                        ? item.activeIcon
                        : (pathname?.includes('/post') && item.name === 'Learn') ||
                          (pathname.includes('/expert') && item.name === 'Experts')
                        ? item.activeIcon
                        : item.icon
                    }
                    alt={item.name}
                  />
                  {item.mobileName}
                </NavLink>
              </li>
            </div>
          ))}
        <li className={styles.moreMenuIcon}>
          <a onClick={handleClick}>
            <img src={!toggled ? moreInactive : moreActive} alt="Menu" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MobileFooter;
