import { getActions, saveAction } from '@api/actions';
import { getQuestions } from '@api/assessment';
import { getFocusAreaData } from '@api/focusArea';
import blueTray from '@assets/images/icons/blueTray.svg';
import PageTitle from '@components//PageTitle';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import Button from '@components/Button';
import ColoredBox from '@components/ColoredBox';
import AdvisorScorePalate from '@components/DeepDiveQuestionContainer/Advisor/AdvisorScorePalate';
import SelectableArea from '@components/DeepDiveQuestionContainer/Advisor/SelectableArea';
import ModalContent from '@components/ModalContent';
import AddPlanFocusArea from '@components/ModalContent/AddFocusArea';
import CreateEditAction from '@components/ModalContent/CreateEditAction';
import CreateImprovePlan from '@components/ModalContent/CreateImprovePlan';
import { CreateStarContext } from '@context/CreateStarContext';
import { ADD_ACTIONS, SET_ACTIONS, SET_FOCUS_AREAS, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { smoothScrollTo } from '@helpers/general.helpers';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CreateStarCategoryInterface } from '@interfaces/index';
import { TALK_TO_ADVISOR_AREAS_DISCUSS_MESSAGE } from 'custom.d';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useProSidebar } from 'react-pro-sidebar';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { updateHelpAvailability } from 'store/Actions/HelpAndSpeakupAction';
import { deepDiveWizardActions } from 'store/Slices/DeepDiveWizardSlice';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const TalkToAdviserStep: React.FunctionComponent<ActionsPageContentProps> = (props) => {
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);
  const { data, isActionPage, isWizard, nextStep, step, newDeepDiveFLow } = props;
  const [takeActionModal, setTakeActionModal] = useState(false);
  const [focusAreas, setFocusAreas] = useState<any>([]);
  const [state, dispatch] = useGlobalState();
  const { categoryQuestion } = useContext(CreateStarContext);
  const { isMobile } = useWindowDimensions();
  const [editFocusArea, setEditFocusArea] = useState(false);
  const [isAddingAction, setIsAddingAction] = useState(false);
  const [actionData, setActionData] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const [setActionErrorMessage] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<any>(false);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const { collapseSidebar } = useProSidebar();
  const reduxDispatch = useAppDispatch();
  const history = useHistory();

  const [createActionModal, setCreateActionModal] = useState<{ open: boolean; data: any }>({
    open: false,
    data: null,
  });

  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);
  const [selectedFocusIds, setSelectedFocusIds] = useState<number[]>([]);
  const [noSelection, setNoSelection] = useState(true);

  useEffect(() => {
    reduxDispatch(deepDiveWizardActions.updateDiscussionButtonContState(noSelection));
  }, [noSelection]);

  useEffect(() => {
    const modAreas = reduxStarScores?.selectedFocusAreas.filter(
      (item) => item.isSelected && selectedFocusIds.includes(item.id),
    );

    reduxDispatch(starScoresSliceActions.setAdvisorSelectedAreas(modAreas));
  }, [selectedFocusIds, reduxStarScores?.selectedFocusAreas]);

  useEffect(() => {
    const getFocusArea = async () => {
      const focusData = await getFocusAreaData(activeSubNav.id, state?.graphData?.assesmentId);
      const newFocusData = focusData.map((i: any) => ({
        ...i,
        name: i.focus_area,
        color: i.color,
      }));

      dispatch({ type: SET_FOCUS_AREAS, payload: newFocusData });
    };

    getFocusArea();
  }, [activeSubNav.id, state?.graphData?.assesmentId, isEditing, categoryQuestion]);

  useEffect(() => {
    const getActions1 = async () => {
      const focusData = await getActions(activeSubNav.id, state?.graphData?.assesmentId);

      if (!focusData.message) {
        dispatch({ type: SET_ACTIONS, payload: focusData });
      }
    };

    getActions1();
  }, [activeSubNav.id, state?.graphData?.assesmentId, isEditing, isAddingAction]);

  const handleEditFocusArea = () => {
    setEditFocusArea(!editFocusArea);
  };

  const editFocusAreaData = async (data2: any, questionnaireIds: any) => {
    dispatch({ type: SET_FOCUS_AREAS, payload: data2 });
    setIsEditing((prev: any) => !prev);
  };

  const sendActionData = async (item: any) => {
    const focusAreasData1 = data.flatMap((k) => k.questions).find((l) => l.name === item.name);

    const response = await saveAction(
      {
        focusAreaId: item.id,
        actionText: item.action_text,
      },
      activeSubNav.id,
      state?.graphData?.assesmentId,
    );

    if (response === 400) {
      setActionErrorMessage('You cannot add more than 10 actions');
    }

    if (response !== 400) {
      dispatch({
        type: ADD_ACTIONS,
        payload: {
          title: focusAreasData1?.name,
          score: focusAreasData1?.score,
          id: item?.id,
          action_text: item.action_text,
        },
      });
    }

    setIsAddingAction(!isAddingAction);
  };

  useEffect(() => {
    const getAllQuestions = async () => {
      const questionsData = await getQuestions(activeSubNav.id, state?.graphData?.assesmentId);

      setQuestions(questionsData);
      setFocusAreas(questionsData);
    };

    getAllQuestions();
  }, [activeSubNav.id, state?.graphData?.assesmentId]);

  useEffect(() => {
    if (state.focusAreas.length > 0) {
      questions.flatMap((i: any) =>
        state.focusAreas.flatMap((k: any) => {
          if (i.name === k.name) {
            setFocusAreas((prev: any) =>
              prev.map((l: any) => ({
                ...l,
                isSelected: l.name === i.name ? !l.isSelected : l.isSelected,
                questionId: i.id,
              })),
            );
          }

          return [];
        }),
      );
    }
  }, [state.focusAreas, questions]);

  const handleNavigateToPlan = async () => {
    collapseSidebar(true);
    reduxDispatch(updateHelpAvailability(false));

    if (reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas') {
      reduxDispatch(starScoresSliceActions.resetStarScores());
      reduxDispatch(deepDiveWizardActions.resetWizard());
      history.push(`/stars`);
    } else if (reduxStarScores.createdImprovementPlan) {
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.createdImprovementPlan.id}&improvePlan=${undefined}`,
      );
      reduxDispatch(starScoresSliceActions.resetStarScores());
      reduxDispatch(deepDiveWizardActions.resetWizard());
    } else if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans?.length > 0
    ) {
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.deepDiveQuestionWithPlans[0].question.improvePlans[0].id}&improvePlan=${reduxStarScores.deepDiveQuestionWithPlans[0].question?.questionText}`,
      );
      reduxDispatch(starScoresSliceActions.resetStarScores());
      reduxDispatch(deepDiveWizardActions.resetWizard());
    }
  };

  const handleSelectFocus = (id: number) => {
    setSelectedFocusIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        const newSelectedIds = prevSelectedIds.filter((selectedId) => selectedId !== id);
        setNoSelection(newSelectedIds.length === 0);

        return newSelectedIds;
      }

      if (prevSelectedIds.length < 3) {
        const newSelectedIds = [...prevSelectedIds, id];
        setNoSelection(false);

        return newSelectedIds;
      }

      return prevSelectedIds;
    });
  };

  return (
    <>
      {(() => {
        switch (step) {
          case 'talkToAdvisorMessage':
            smoothScrollTo(0, 0);

            return (
              <div className="container-fluid">
                <div className={styles.hsRoot}>
                  <PageTitle isWizzard={isWizard} title="Actions" subtitle=" " />
                </div>

                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    {companyConfig.talkToAdvisor && (
                      <Card>
                        {companyConfig.talkToAdvisorMessage && (
                          <div
                            dangerouslySetInnerHTML={{ __html: companyConfig.talkToAdvisorMessage }}
                          />
                        )}

                        <div className={styles.navigateBtnContainer}>
                          <DefaultButton
                            className={styles.secondaryBtn}
                            onClick={handleNavigateToPlan}
                          >
                            No, take me to my plan
                          </DefaultButton>
                          <DefaultButton
                            className={styles.primaryBtn}
                            onClick={nextStep}
                            disabled={reduxStarScores?.selectedFocusAreas?.length < 1}
                          >
                            Yes, I&apos;d like to discuss
                          </DefaultButton>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div className={styles.coloredBoxContainer}>
                    <div className={styles.coloredBox}>
                      <ColoredBox
                        title="My Focus Areas"
                        color="blue"
                        isEditable
                        setEditFocusArea={setEditFocusArea}
                      >
                        <div className={styles.focusContainer}>
                          {reduxStarScores?.selectedFocusAreas?.length > 0 &&
                            reduxStarScores?.selectedFocusAreas?.map((focusArea: any) => (
                              <div key={focusArea.id} className={styles.hsCategoryContainer}>
                                <AdvisorScorePalate
                                  category={focusArea.name}
                                  score={focusArea.score}
                                  color={focusArea.color}
                                />
                              </div>
                            ))}
                          {reduxStarScores?.selectedFocusAreas?.length < 1 && (
                            <div className={styles.noFocusContainer}>
                              <img src={blueTray} className={styles.blueTray} alt="blue tray" />
                              <p className={styles.noChosenAreaText}>
                                You haven’t chosen any focus areas
                              </p>
                              <Button type="more" isButton onClick={() => setEditFocusArea(true)}>
                                Add a focus area
                                <i className="icon icon-plus-circle" />
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className={`${styles.footerImage} ${styles.footerContainer}`} />
                      </ColoredBox>
                    </div>
                  </div>
                </div>
              </div>
            );

          case 'talkToAdvisorDiscussion':
            return (
              <div className="container-fluid">
                <div className={styles.hsRoot}>
                  <PageTitle isWizzard={isWizard} title="Actions" subtitle=" " />
                </div>

                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    {companyConfig.talkToAdvisor && (
                      <Card>
                        {TALK_TO_ADVISOR_AREAS_DISCUSS_MESSAGE && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: TALK_TO_ADVISOR_AREAS_DISCUSS_MESSAGE,
                            }}
                          />
                        )}
                      </Card>
                    )}
                  </div>
                  <div className={styles.coloredBoxContainer}>
                    <div className={styles.coloredBox}>
                      <ColoredBox
                        title="Select the areas you would like to discuss"
                        isTitleCentered
                        color="blue"
                        isEditable={false}
                        setEditFocusArea={setEditFocusArea}
                      >
                        <div className={styles.focusContainer}>
                          {reduxStarScores?.selectedFocusAreas?.length > 0 &&
                            reduxStarScores?.selectedFocusAreas?.map((focusArea: any) => (
                              <div key={focusArea.id} className={styles.hsCategoryContainer}>
                                <SelectableArea
                                  key={focusArea.id}
                                  id={focusArea.id}
                                  name={focusArea.name}
                                  color={focusArea.color}
                                  score={focusArea.score}
                                  isSelected={selectedFocusIds.includes(focusArea.id)}
                                  onSelect={() => handleSelectFocus(focusArea.id)}
                                />
                              </div>
                            ))}
                          {reduxStarScores?.selectedFocusAreas?.length < 1 && (
                            <div className={styles.noFocusContainer}>
                              <img src={blueTray} className={styles.blueTray} alt="blue tray" />
                              <p className={styles.noChosenAreaText}>
                                You haven’t chosen any focus areas
                              </p>
                              <Button type="more" isButton onClick={() => setEditFocusArea(true)}>
                                Add a focus area
                                <i className="icon icon-plus-circle" />
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className={`${styles.footerImage} ${styles.footerContainer}`} />
                      </ColoredBox>
                    </div>
                  </div>
                </div>
              </div>
            );

          case 'talkToAdvisorCompletion':
            smoothScrollTo(0, 0);

            return (
              <div className="container-fluid">
                <div className={styles.hsRoot}>
                  <PageTitle isWizzard={isWizard} title="Actions" subtitle=" " />
                </div>

                <div className={styles.actionContainer}>
                  <div className={styles.cardContainer}>
                    {companyConfig.talkToAdvisor && companyConfig.talkToAdvisorCompleteMessage && (
                      <Card>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: companyConfig.talkToAdvisorCompleteMessage,
                          }}
                        />

                        <div className={styles.centeredButton}>
                          <DefaultButton
                            className={styles.primaryBtn}
                            onClick={handleNavigateToPlan}
                          >
                            Take me to my plan
                          </DefaultButton>
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            );

          default:
            return null;
        }
      })()}

      <Modal
        isOpen={createActionModal.open}
        onRequestClose={() => setCreateActionModal({ open: false, data: null })}
        contentLabel="Improve Action Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '95%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() => setCreateActionModal({ open: false, data: null })}
          hasBackground
          title="Create your action"
        >
          <CreateImprovePlan
            closeModal={() => setCreateActionModal({ open: false, data: null })}
            improvePlan={reduxStarScores?.deepDiveQuestionWithPlans?.[0]?.question?.questionText}
            id={
              reduxStarScores.subNavigationStep === 'planCreatedWithFocusAreas' &&
              reduxStarScores?.createdImprovementPlan
                ? reduxStarScores?.createdImprovementPlan?.id
                : reduxStarScores?.deepDiveQuestionWithPlans?.[0]?.question?.improvePlans?.[0]
                    ?.id || 0
            }
            handleRefetch={() => null}
            actionData={createActionModal.data}
            focusAreaSuggestionsEnabled
          />
        </ModalContent>
      </Modal>

      <Modal
        isOpen={takeActionModal}
        onRequestClose={() => setTakeActionModal(false)}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() => setTakeActionModal(false)}
          hasBackground
          title="Create your action"
        >
          <CreateEditAction
            sendActionData={(item: any) => sendActionData(item)}
            closeModal={() => setTakeActionModal(false)}
            focusArea={actionData?.name}
            score={actionData?.score}
            id={actionData?.id}
            color={actionData?.color}
            suggestions={[]}
          />
        </ModalContent>
      </Modal>

      <Modal
        isOpen={editFocusArea}
        onRequestClose={handleEditFocusArea}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: isMobile ? '90%' : undefined,
          },
        }}
      >
        <ModalContent
          onClose={() => setEditFocusArea(false)}
          hasBackground
          title="Edit a focus area"
        >
          <AddPlanFocusArea
            focusAreas={focusAreas && focusAreas}
            onClose={() => {
              setEditFocusArea(false);
            }}
            setFocusAreas={setFocusAreas}
            sendFocusData={(data5, questionnaireIds) => editFocusAreaData(data5, questionnaireIds)}
            isActionPage={isActionPage}
            maxSelectCount={3}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

TalkToAdviserStep.defaultProps = {
  isActionPage: false,
  isWizard: false,
  step: 'talkToAdvisorMessage',
  newDeepDiveFLow: false,
};

interface ActionsPageContentProps {
  data: CreateStarCategoryInterface[];
  isActionPage?: boolean;
  isWizard?: boolean;
  nextStep: () => void;
  step?: 'talkToAdvisorMessage' | 'talkToAdvisorDiscussion' | 'talkToAdvisorCompletion';
  newDeepDiveFLow?: boolean;
}

export default TalkToAdviserStep;
