import { getAllEmployees } from '@api/EmployerNew';
import { getIpAddress } from '@api/auth/login';
import { TOKEN } from '@api/config';
import { createLog } from '@api/log';
import { deleteUser, insertUser, updateUser } from '@api/users';
import Button from '@components/Button';
import ButtonNew from '@components/ButtonNew/ButtonNew';
import FilterContainer from '@components/FilterContainer';
import ImportUserResults from '@components/ImportUserResults';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import AddEmployeeModal from '@components/ModalContent/AddEmployee';
import DeleteEmployeeModal from '@components/ModalContent/DeleteEmployee';
import EditEmployeeModal from '@components/ModalContent/EditEmployee';
import ImportUserModal from '@components/ModalContent/ImportUserModal';
import UserFilters from '@components/UserFilters';
import UserTable from '@components/UserTable/UserTable';
import { logoutUser } from '@helpers/auth.helper';
import {
  getUserGenderLabel,
  getUserRoleLabel,
  getUserSeniorityLabel,
} from '@helpers/general.helpers';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useGoNative from '@hooks/useGoNative';
import MainLayout from '@layouts/MainLayout';
import { Backdrop, CircularProgress } from '@mui/material';
import currency from 'currency.js';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as yup from 'yup';
import {
  ImportResults,
  LogTypes,
  SelectedUser,
  User,
  UserRoles,
  UsersFilterData,
} from '../../../custom.d';
import styles from './index.module.scss';

const filterValidationSchema = yup.object({
  location: yup.string(),
  team: yup.string(),
  gender: yup.string(),
  seniority: yup.string(),
  jobType: yup.string(),
  jobTitle: yup.string(),
  payLevel: yup.number(),
  peopleLead: yup.string(),
  speakupAlertEligibility: yup.string(),
});

const generateCsvHeaders = () => [
  { label: 'Surname', key: 'surname' },
  { label: 'Forename', key: 'forename' },
  { label: 'Email', key: 'email' },
  { label: 'Role', key: 'role' },
  { label: 'Organisation', key: 'company' },
  { label: 'Job Type', key: 'jobType' },
  { label: 'Job Title', key: 'jobTitle' },
  { label: 'Team', key: 'team' },
  { label: 'Seniority', key: 'seniority' },
  { label: 'Gender', key: 'gender' },
  { label: 'Pay Level', key: 'payLevel' },
  { label: 'D.O.B', key: 'dob' },
  { label: 'Location', key: 'location' },
  { label: 'Joined', key: 'joined' },
  { label: 'People Lead', key: 'peopleLead' },
  { label: 'Speak Up', key: 'speakUp' },
];

const formatUserData = (user: User): CsvData => ({
  surname: user?.surname || '',
  forename: user?.forename || '',
  email: user?.email || '',
  role: getUserRoleLabel(user?.role) || '',
  company: user?.company || '',
  jobType: user?.jobType || '',
  jobTitle: user?.jobTitle || '',
  team: user?.team || '',
  seniority: getUserSeniorityLabel(user?.seniority) || '',
  gender: getUserGenderLabel(user?.sex) || '',
  payLevel: user?.payLevel ? currency(user.payLevel, { symbol: '£' }).format() : '-',
  dob: user.dob ? format(new Date(user.dob), 'dd/MM/yyyy') : '',
  location: user?.location || '',
  joined: user?.joined ? format(new Date(user?.joined), 'dd/MM/yyyy') : '',
  peopleLead: user?.peopleLead ? 'Yes' : 'No',
  speakUp: user?.speakupAlertEligibility ? 'Yes' : 'No',
});

const EmployerUsersPage: React.FunctionComponent = () => {
  const history = useHistory();
  const isMobileApp = useGoNative();
  const [users, setUsers] = useState([]) as any;
  const [usersDisplayed, setUsersDisplayed] = useState([]) as any;
  const [importComplete, setImportComplete] = useState(false);
  const [importResultsData, setImportResultsData] = useState<ImportResults>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SelectedUser>(undefined);
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [openImportUsersModal, setOpenImportUsersModal] = useState(false);
  const [openEditEmployeeModal, setOpenEditEmployeeModal] = useState(false);
  const [openDeleteEmployeeModal, setOpenDeleteEmployeeModal] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const [csvHeaders, setCsvHeaders] = useState<{ label: string; key: string }[]>([]);
  const [csvData, setCsvData] = useState<CsvData[]>([]);
  const [csvGenerating, setCsvGenerating] = useState(false);

  useEffect(() => {
    setCsvHeaders(generateCsvHeaders());
    setCsvData(users.map(formatUserData));
  }, [users]);

  const filterMethods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(filterValidationSchema),
  });

  const importUserMethods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  /**
   * Filter the users by the selected filter values
   */
  const handleFilter = useCallback(
    (filterOptions: UsersFilterData) => {
      const getFilteredResults = () => {
        let allUsers = users;

        if (filterOptions.team) {
          allUsers = allUsers.filter((u: User) => u.team === filterOptions.team);
        }

        if (filterOptions.location) {
          allUsers = allUsers.filter((u: User) => u.location === filterOptions.location);
        }

        if (filterOptions.gender) {
          allUsers = allUsers.filter((u: User) => u.sex === filterOptions.gender);
        }

        if (filterOptions.seniority) {
          allUsers = allUsers.filter((u: User) => u.seniority === filterOptions.seniority);
        }

        if (filterOptions.jobType) {
          allUsers = allUsers.filter((u: User) => u.jobType === filterOptions.jobType);
        }

        if (filterOptions.jobTitle) {
          allUsers = allUsers.filter((u: User) => u.jobTitle === filterOptions.jobTitle);
        }

        if (filterOptions.payLevel) {
          allUsers = allUsers.filter((u: User) => {
            if (filterOptions.payLevel === 10000) {
              return u.payLevel >= 10000 && u.payLevel <= 20000;
            }

            if (filterOptions.payLevel === 20000) {
              return u.payLevel >= 20000 && u.payLevel <= 30000;
            }

            if (filterOptions.payLevel === 30000) {
              return u.payLevel >= 30000 && u.payLevel <= 40000;
            }

            if (filterOptions.payLevel === 40000) {
              return u.payLevel >= 40000 && u.payLevel <= 50000;
            }

            if (filterOptions.payLevel === 50000) {
              return u.payLevel >= 50000 && u.payLevel <= 60000;
            }

            if (filterOptions.payLevel === 70000) {
              return u.payLevel >= 70000 && u.payLevel <= 80000;
            }

            if (filterOptions.payLevel === 80000) {
              return u.payLevel >= 80000 && u.payLevel <= 90000;
            }

            if (filterOptions.payLevel === 90000) {
              return u.payLevel >= 90000 && u.payLevel <= 100000;
            }

            if (filterOptions.payLevel === 100000) {
              return u.payLevel >= 100000;
            }

            return u.payLevel >= 0;
          });
        }

        if (filterOptions.peopleLead) {
          allUsers = allUsers.filter((u: User) =>
            filterOptions.peopleLead === 'yes' ? u.peopleLead : !u.peopleLead,
          );
        }

        if (filterOptions.speakupAlertEligibility) {
          allUsers = allUsers.filter(
            (u: User) =>
              (filterOptions.speakupAlertEligibility === 'yes'
                ? u.speakupAlertEligibility
                : !u.speakupAlertEligibility) &&
              (u.role === UserRoles.ADMIN || u.role === UserRoles.SUPER_ADMIN),
          );
        }

        return allUsers;
      };

      setFiltered(true);
      setUsersDisplayed(getFilteredResults);
    },
    [users],
  );

  /**
   * Reset the filter values
   */
  const handleResetFilter = useCallback(() => {
    setFiltered(false);

    // Reset the users and  filter form values
    filterMethods.reset();
    setFiltered(false);
    setUsersDisplayed(users);
  }, [filterMethods, users]);

  const handleOpenDeleteEmployeeModal = useCallback(() => {
    setOpenDeleteEmployeeModal(true);
  }, []);

  const handleCloseDeleteEmployeeModal = useCallback(() => {
    setOpenDeleteEmployeeModal(false);
  }, []);

  const handleOpenEmployeeModal = useCallback(() => {
    setOpenAddEmployeeModal(true);
  }, []);

  const handleCloseAddEmployeeModal = useCallback(() => {
    setOpenAddEmployeeModal(false);
    setSelectedUser(undefined);
  }, []);

  const handleOpenEditEmployeeModal = useCallback(() => {
    setOpenEditEmployeeModal(true);
  }, []);

  const handleCloseEditEmployeeModal = useCallback(() => {
    setOpenEditEmployeeModal(false);
    setSelectedUser(undefined);
  }, []);

  const handleOpenCSVModal = useCallback(() => {
    setOpenImportUsersModal(true);
  }, []);

  const handleCloseImportUsersModal = useCallback(() => {
    setOpenImportUsersModal(false);
    importUserMethods.reset();
  }, [importUserMethods]);

  /**
   * Determine which modal to open based on the action
   */
  const handleSelectAction = useCallback(
    (eventKey: string | null, user: User) => {
      if (!eventKey) {
        return;
      }

      setSelectedUser(user);

      if (eventKey === 'edit') {
        handleOpenEditEmployeeModal();
      } else if (eventKey === 'delete') {
        handleOpenDeleteEmployeeModal();
      }
    },
    [handleOpenDeleteEmployeeModal, handleOpenEditEmployeeModal],
  );

  /**
   * Fetch users and populate the user table
   */
  const getUsers = useCallback(async () => {
    const data = await getAllEmployees();
    setLoading(true);

    if (data === 401) {
      logoutUser();
      history.push('/login?action=logout');

      return;
    }

    // Retain initial data for reset, set filtered data as the same initially
    setUsers(data);
    setUsersDisplayed(data);
    setLoading(false);
  }, [history]);

  /**
   * Add a new employee user
   */
  const handleAddEmployee = useCallback(
    async (data: any, methods: any) => {
      try {
        setLoading(true);

        const payload = data;

        // Only send the value of the special inputs in the payload
        payload.team = data.team ? data.team.value : data.team;
        payload.location = data.location ? data.location.value : data.team;
        payload.jobTitle = data.jobTitle ? data.jobTitle.value : data.team;
        payload.jobType = data.jobType ? data.jobType.value : data.team;

        const response = await insertUser(payload);

        if (!response.ok) {
          throw new Error('Unable to add user');
        }

        // Re-fetch the updated user list
        methods.reset();
        toast('Added employee successfully');
        setLoading(false);
        handleResetFilter();
        handleCloseAddEmployeeModal();

        // Re-fetch and populate the user table
        setTimeout(() => {
          getUsers();
        }, 1000);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    },
    [getUsers, handleCloseAddEmployeeModal],
  );

  /**
   * Update an employee
   */
  const handleUpdateEmployee = useCallback(
    async (data: any) => {
      try {
        const userId = data.id;

        setLoading(true);

        const payload = data;

        // Only send the value of the special inputs in the payload
        payload.team = data.team ? data.team.value : data.team;
        payload.location = data.location ? data.location.value : data.team;
        payload.jobTitle = data.jobTitle ? data.jobTitle.value : data.team;
        payload.jobType = data.jobType ? data.jobType.value : data.team;

        const response = await updateUser(userId, payload);

        if (!response.ok) {
          throw new Error('Unable to update');
        }

        toast('Updated employee successfully');
        setLoading(false);
        handleResetFilter();
        handleCloseEditEmployeeModal();

        // Re-fetch and populate the user table
        setTimeout(() => {
          getUsers();
        }, 1000);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    },
    [getUsers, handleCloseEditEmployeeModal],
  );

  /**
   * Delete an employee
   */
  const handleDeleteEmployee = useCallback(async () => {
    setLoading(true);

    try {
      if (!selectedUser) {
        throw new Error('Missing user ID');
      }

      const response = await deleteUser(selectedUser.id);

      if (!response.ok) {
        throw new Error('Unable to update');
      }

      toast('Deleted employee successfully');
      setLoading(false);
      setSelectedUser(undefined);
      handleCloseDeleteEmployeeModal();

      // Re-fetch and populate the user table
      setTimeout(() => {
        getUsers();
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  }, [getUsers, handleCloseDeleteEmployeeModal, selectedUser]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  /**
   * Submit the form data and add the user
   */
  const handleImportUsers = useCallback(
    async (data: any) => {
      try {
        const { files } = data;

        if (!files || files.length === 0) {
          throw new Error('Missing file');
        }

        const importFile = files[0];

        setLoading(true);
        setOpenImportUsersModal(false);

        const formData = new FormData();
        formData.append('file', importFile, 'import.csv');
        importUserMethods.reset();

        const response = await fetch(`${process.env.REACT_APP_API_URL}/import-users`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        });

        if (!response.ok) {
          const responseData = await response.json();
          throw new Error(responseData.message);
        } else {
          const responseData = await response.json();
          handleCreateImportLog();
          setLoading(false);
          setImportComplete(true);
          setImportResultsData(responseData);

          // Re-fetch and populate the user table
          setTimeout(() => {
            getUsers();
          }, 1000);
        }

        // If an unexpected error occurs
      } catch (err) {
        setLoading(false);
        setImportComplete(false);
        setImportResultsData(undefined);
        toast(`Import error. Please verify the integrity of the import file data - ${err}`, {
          autoClose: false,
          type: 'error',
        });
      }
    },
    [importUserMethods, getUsers],
  );

  const handleRestartImport = useCallback(() => {
    setImportResultsData(undefined);
    setImportComplete(false);
  }, []);

  const handleCreateImportLog = async () => {
    const ipAddress = await getIpAddress();
    const payload = {
      type: LogTypes.EMPLOYER_DASHBOARD_USER_IMPORT,
      ipAddress,
      os: navigator.userAgentData?.platform || '',
      isNative: isMobileApp,
    };

    await createLog(payload);
  };

  const handleCreateExportLog = async () => {
    const ipAddress = await getIpAddress();
    const payload = {
      type: LogTypes.EMPLOYER_DASHBOARD_USER_EXPORT,
      ipAddress,
      os: navigator.userAgentData?.platform || '',
      isNative: isMobileApp,
    };

    await createLog(payload);
  };

  return (
    <MainLayout title="Employer Portal" secondaryNavEnabled useEmployerNav isEmployerPage>
      <ToastContainer hideProgressBar draggable={false} theme="dark" />

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={csvGenerating}
        onClick={() => setCsvGenerating(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {loading && !error && <LoadingPage />}

      {error && !loading && (
        <p className={styles.error}>Unexpected error. Please contact support.</p>
      )}

      {!loading && !error && importComplete && importResultsData && (
        <div className={styles.userResultSection}>
          <ImportUserResults results={importResultsData} onRestart={handleRestartImport} />
        </div>
      )}

      {!loading && !error && !importComplete && (
        <>
          <div className={styles.titleRoot}>
            <div className={styles.titleRootInner}>
              <div>
                <h2 className={styles.title}>Employees</h2>
              </div>

              <div className={styles.btnGroup}>
                <ButtonNew color="primary" onClick={handleOpenCSVModal}>
                  Import
                </ButtonNew>

                <Link
                  to="/templates/users/example.csv"
                  target="_blank"
                  className={styles.downloadTemplate}
                >
                  <ButtonNew color="primary">Download Template</ButtonNew>
                </Link>

                {users?.length > 0 && (
                  <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    filename="users.csv"
                    className={styles.hsPrimaryBtn}
                    asyncOnClick
                    onClick={(event, done) => {
                      setCsvGenerating(true);
                      handleCreateExportLog();

                      setTimeout(() => {
                        setCsvGenerating(false);
                        done(true);
                      }, 1000);
                    }}
                  >
                    Export
                  </CSVLink>
                )}
              </div>
            </div>

            <div className={styles.addEmpBtn}>
              <Button isButton type="primary" onClick={handleOpenEmployeeModal}>
                Add Employee
              </Button>
            </div>

            <div className={styles.mb25}>
              <div className={styles.fullWidth}>
                <FilterContainer>
                  <UserFilters
                    methods={filterMethods}
                    users={users}
                    isFiltered={filtered}
                    onFilter={handleFilter}
                    onReset={handleResetFilter}
                  />
                </FilterContainer>
              </div>
            </div>

            <div className={styles.fullWidth}>
              <UserTable users={usersDisplayed} onSelectAction={handleSelectAction} />
            </div>
          </div>

          {openAddEmployeeModal && (
            <AddEmployeeModal
              isOpen={openAddEmployeeModal}
              title="Add Employee"
              onClose={handleCloseAddEmployeeModal}
              onSave={handleAddEmployee}
            />
          )}

          {selectedUser && openEditEmployeeModal && (
            <EditEmployeeModal
              title="Edit Employee"
              userId={selectedUser.id}
              isOpen={openEditEmployeeModal}
              onClose={handleCloseEditEmployeeModal}
              onSave={handleUpdateEmployee}
            />
          )}

          {selectedUser && (
            <DeleteEmployeeModal
              title="Delete Employee"
              isOpen={openDeleteEmployeeModal}
              onClose={handleCloseDeleteEmployeeModal}
              onSave={handleDeleteEmployee}
              selectedUser={selectedUser}
            />
          )}

          <ImportUserModal
            methods={importUserMethods}
            isOpen={openImportUsersModal}
            title="Upload Users"
            onClose={handleCloseImportUsersModal}
            onSubmit={handleImportUsers}
          />
        </>
      )}
    </MainLayout>
  );
};

interface CsvData {
  surname: string;
  forename: string;
  email: string;
  role: string;
  company: string;
  jobType: string;
  jobTitle: string;
  team: string;
  seniority: string;
  gender: string;
  payLevel: number | string;
  dob: string;
  location: string;
  joined: string;
  peopleLead: string;
  speakUp: string | boolean;
}

export default EmployerUsersPage;
