import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import menuItems from '@config/employer-main-menu.config';
import moreActive from '@assets/images/icons/more-active.svg';
import moreInactive from '@assets/images/icons/more-inactive.svg';
import { useProSidebar } from 'react-pro-sidebar';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import styles from './index.module.scss';

const MobileFooterEmployer: React.FunctionComponent = () => {
  const { toggled, toggleSidebar } = useProSidebar();
  const { pathname } = useLocation();
  const { toggleSubNav } = useContext(SecondaryNavigationContext);

  const handleClick = () => {
    toggleSidebar(!toggled);
  };

  return (
    <div className={styles.hsMobileFooter}>
      <ul>
        {menuItems.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              toggleSubNav({ id: 1, name: 'Overall' });
            }}
          >
            <li>
              <NavLink to={item.path} {...(toggled && { onClick: handleClick })}>
                <img src={pathname === item.path ? item.activeIcon : item.icon} alt={item.name} />
                {item.name}
              </NavLink>
            </li>
          </div>
        ))}
        <li>
          <a onClick={handleClick}>
            <img src={!toggled ? moreActive : moreInactive} alt="Menu" />
            Menu
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MobileFooterEmployer;
