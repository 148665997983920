import { Skeleton } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import useWindowDimensions from '@hooks/useWindowDimensions';
import styles from './index.module.scss';

const ExploreSectionAreaChart: FunctionComponent<ExploreSectionAreaChartProps> = (props) => {
  const { loading, data, filterData } = props;
  const { isDesktop } = useWindowDimensions();
  const [dates, setDates] = useState<string[]>([]);
  const [chartData, setChartData] = useState<DataItem[] | []>([]);
  const [dateRange, setDateRange] = useState<{ min: Date | any; max: Date | any }>({
    min: '',
    max: '',
  });
  const [chartLoading, setChartLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data?.length > 0) {
      filter();
    }

    async function filter() {
      setChartLoading(true);
      if (filterData && filterData?.length > 0 && !loading) {
        const filteredData = await data.filter((dt: DataItem) => filterData.includes(dt.name));

        const datesArray = await filteredData.map((item: DataItem) => item.createdAt);
        const flattenedDatesArray: string[] = datesArray.flat();

        if (flattenedDatesArray && flattenedDatesArray?.length > 0) {
          const lastIndex = flattenedDatesArray.length - 1;

          const lastElement = flattenedDatesArray[lastIndex];

          setDateRange({
            min: new Date(flattenedDatesArray[0]).getTime(),
            max: new Date(lastElement).getTime(),
          });
        }

        await setChartData(filteredData);
        await setDates(flattenedDatesArray);
      } else if (filterData?.length !== 0) {
        const datesArray = await data.map((item: DataItem) => item.createdAt);
        const flattenedDatesArray: string[] = datesArray.flat();

        if (flattenedDatesArray && flattenedDatesArray?.length > 0) {
          const lastIndex = flattenedDatesArray.length - 1;

          const lastElement = flattenedDatesArray[lastIndex];

          setDateRange({
            min: new Date(flattenedDatesArray[0]).getTime(),
            max: new Date(lastElement).getTime(),
          });
        }

        await setChartData(data);
        await setDates(flattenedDatesArray);
      } else {
        await setChartData([]);
        await setDates([]);
        setDateRange({ min: '', max: '' });
      }

      await setChartLoading(false);
    }
  }, [data, filterData, loading]);

  const chartOpts: ApexOptions = {
    chart: {
      events: {},
      animations: {
        enabled: true,
        speed: 2500,
        animateGradually: {
          enabled: true,
        },
      },
      height: 350,
      type: 'area',
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: isDesktop,
          zoomin: true,
          zoomout: true,
          pan: isDesktop,
          reset: true,
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: chartData?.every(
        (item: { createdAt: string | any[] }) => item?.createdAt?.length === 1,
      ),
    },
    stroke: {
      curve: 'smooth',
      width: 4,
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.3,
        opacityTo: 0.2,
      },
    },
    // labels: dates,
    markers: {
      size: chartData?.every((item: { createdAt: string | any[] }) => item?.createdAt?.length === 1)
        ? 10
        : 0,
      hover: {
        size: 10,
        sizeOffset: 3,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: dates,
      labels: {
        datetimeFormatter: {
          day: "MMM 'dd",
        },
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: '#b6b6b6',
          width: 2,
          dashArray: 0,
        },
      },
      tickAmount: 'dataPoints',
      tickPlacement: 'between',
    },

    yaxis: {
      labels: {
        show: true,
        formatter(val) {
          return val?.toFixed(0);
        },
      },
      tickAmount: 5,
      min: 0,
      max: 10,
    },
    tooltip: {
      followCursor: true,
      x: {
        format: "MMM 'dd",
      },
    },
    grid: {
      borderColor: 'transparent',
    },
    legend: {
      show: !isDesktop,
      position: isDesktop ? 'top' : 'bottom',
      horizontalAlign: isDesktop ? 'left' : 'center',
      tooltipHoverFormatter(val, opts) {
        return `${val} - <strong>${
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
        }</strong>`;
      },
      onItemClick: {
        toggleDataSeries: !isDesktop,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.75,
        },
      },
    },
  };

  return (
    <div>
      {loading || chartLoading ? (
        <Skeleton variant="rounded" width="100%" className={styles.loadChart} />
      ) : (
        <ReactApexChart options={chartOpts} series={chartData} type="area" height={350} />
      )}
    </div>
  );
};

ExploreSectionAreaChart.defaultProps = {
  loading: true,
  filterData: null,
};

interface DataItem {
  name: string;
  data: number[];
  color: string;
  createdAt: string[];
}

interface ExploreSectionAreaChartProps {
  loading?: boolean;
  data: DataItem[];
  filterData?: string[] | null;
}

export default ExploreSectionAreaChart;
