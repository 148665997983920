import GraphExample from '@assets/images/graph-example.svg';
import TipsIcon from '@assets/images/tips-icon.svg';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import ExploreSectionAreaChart from '@components/Molecules/ExploreSectionAreaChart';
import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const DesktopExploreSection: React.FunctionComponent<DesktopExploreSectionProps> = ({
  loading,
  data,
  assessmentScores,
  handleStarModal,
}) => {
  const hasEmptyData = assessmentScores.some((item) => item.data.length === 0);
  const [selectedQue, setSelectedQue] = React.useState<any[]>([]);
  const [scoreFilterLoading, setScoreFilterLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data?.length > 0 && !loading) {
      filterData();
    }

    async function filterData() {
      setScoreFilterLoading(true);
      const names = await data.map((item) => item.questionText);
      await setSelectedQue(names);
      await setScoreFilterLoading(false);
    }
  }, [assessmentScores, loading, hasEmptyData, data]);

  const handleButtonClick = (questionText: number) => {
    if (selectedQue.includes(questionText)) {
      setSelectedQue(selectedQue.filter((que) => que !== questionText));
    } else {
      setSelectedQue([...selectedQue, questionText]);
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <Card className={styles.exploreSectionDesktop}>
        <Grid container spacing={2} className={styles.exploreBody}>
          <Grid item xs={12} md={3}>
            <div className={styles.navSection}>
              {loading || scoreFilterLoading ? (
                <Skeleton variant="text" width="90%" height={40} />
              ) : (
                <h5>Explore your wellbeing score</h5>
              )}

              <div className={styles.navButtons}>
                {loading || scoreFilterLoading ? (
                  <>
                    {[0, 1, 2, 3, 4].map((index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        width="100%"
                        height={60}
                        className={styles.loadingPilarBtn}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {data?.map((item: any, index: number) => (
                      <DefaultButton
                        key={index}
                        className={`${styles.hsSecondaryWhite} ${styles[item?.colorTheme]} ${
                          selectedQue.includes(item?.questionText) ? styles.selected : ''
                        }`}
                        onClick={() => handleButtonClick(item?.questionText)}
                      >
                        <div className={styles.navButtonText}>
                          <div className={styles.btnText}>{item?.questionText}</div>
                          <div
                            className={`${styles.score} ${styles[item?.colorTheme]} ${
                              selectedQue.includes(item?.questionText) ? styles.selected : ''
                            }`}
                          >
                            <span>{item?.latestQuestionScore || 0}</span>
                          </div>
                        </div>
                      </DefaultButton>
                    ))}
                  </>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className={styles.chartSection}>
              <div className={styles.top}>
                {loading || scoreFilterLoading ? (
                  <Skeleton variant="text" width="60%" height={30} />
                ) : (
                  <>
                    <Img src={TipsIcon} alt="Tips" className={styles.tipsIcon} />
                    <p>Track your progress across 6 key wellbeing pillars</p>
                  </>
                )}
              </div>

              {hasEmptyData || assessmentScores.length === 0 ? (
                <>
                  {loading || scoreFilterLoading ? (
                    <Skeleton variant="rounded" width="100%" className={styles.loadChart} />
                  ) : (
                    <div
                      className={styles.areaChartContainerEmpty}
                      onClick={() => handleStarModal(true)}
                    >
                      <div className={styles.emptyChart}>
                        <h3>
                          Let&apos;s start building your well-being journey! <br />
                          <span onClick={() => handleStarModal(true)}>Check in</span> and start
                          building your score
                        </h3>
                        <Img
                          src={GraphExample}
                          alt="HS graph example"
                          className={styles.graphImage}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.areaChartContainer}>
                  <ExploreSectionAreaChart
                    loading={loading || scoreFilterLoading}
                    data={assessmentScores}
                    filterData={selectedQue}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

DesktopExploreSection.defaultProps = {
  loading: true,
};

interface AssessmentScores {
  name: string;
  data: number[];
  color: string;
  createdAt: string[];
}

interface DesktopExploreSectionProps {
  loading?: boolean;
  data: ShortStarScoreInterface[];
  assessmentScores: AssessmentScores[];
  handleStarModal: (arg0: boolean) => void;
}

interface ShortStarScoreInterface {
  questionId: number;
  questionText: string;
  colorTheme: string;
  latestAssessmentScore: number;
  icon: string;
}

export default DesktopExploreSection;
