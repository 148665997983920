import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getJobTypes = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/jobtypes`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};
