import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
  const { id, text, color, onChange, checked, list = -1, isSelected = true, disabled } = props;

  const classStyles = classNames([
    styles.hsCustomCheckbox,
    disabled && styles.disabled,
    color === '#BEAAF3' && styles.calmLilac,
    color === '#019DF2' && styles.skyBlue,
    color === '#FCD300' && styles.sunYellow,
    color === '#8EEA36' && styles.zingyGreen,
    color === '#06BF97' && styles.aquaGreen,
    color === '#FF8A4A' && styles.warmOrange,
    color === '#505050' && styles.grey,
  ]);

  const onChangeHandler = (e: any) => {
    onChange(e);
  };

  return (
    <label htmlFor={id} className={classStyles}>
      <span>{text}</span>
      <div className={styles.round}>
        {list > -1 ? (
          <input
            type="checkbox"
            id={id}
            onChange={onChangeHandler}
            checked={isSelected}
            disabled={disabled}
          />
        ) : (
          <input
            type="checkbox"
            checked={checked}
            id={id}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        <label htmlFor={id} />
      </div>
    </label>
  );
};

Checkbox.defaultProps = {
  list: -1,
  isSelected: true,
  disabled: false,
};

interface CheckboxProps {
  id: string;
  text: string;
  color: string;
  onChange: (checked: any) => void;
  checked: boolean;
  list?: number;
  isSelected?: boolean;
  disabled?: boolean;
}

export default Checkbox;
