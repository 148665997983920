import { getUserImprovePlansByDeepDive } from '@api/actionPlan';
import TalkToAdviserStep from '@components/DeepDiveQuestionContainer/Steps/TalkToAdviser/TalkToAdviserStep';
import NewScorePageContent from '@components/NewScorePageContent';
import NewScorePlanSection from '@components/NewScorePlanSection';
import PopUpModal from '@components/PopUpModal';
import QuestionItem from '@components/QuestionItem';
import ShareThoughts from '@components/ShareThoughts';
import StepWizardFinish from '@components/StepWizardFinish';
import StepWizardFooter from '@components/StepWizardFooter';
import StepWizardHeader from '@components/StepWizardHeader';
import StepWizardStar from '@components/StepWizardStar';
import { CreateStarContext } from '@context/CreateStarContext';
import { useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { getCompanyDetails } from '@helpers/auth.helper';
import { smoothScrollTo } from '@helpers/general.helpers';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { CreateStarCategoryInterface } from '@interfaces/index';
import HelpAndSpeakup from '@pages/EarlyWarning/HelpAndSpeakup';
import classNames from 'classnames';
import { DeepDiveQuestionnairePlan, ImprovePlanFocusAreas } from 'custom';
import React, { useContext, useEffect, useState } from 'react';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { ToastContainer } from 'react-toastify';
import { Wizard } from 'react-use-wizard';
import { RootState } from 'store';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const QuestionItemContainer: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const reduxStarScores = useAppSelector((state: RootState) => state.starScores);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig);
  const { categoryQuestion, setCategoryQuestion } = useContext(CreateStarContext);
  const { collapsed } = useProSidebar();
  const [allCategories, setAllCategories] = useState<CreateStarCategoryInterface[] | any>([]);
  const [sidebarScore, setSidebarScore] = useState<number>(0);
  const [sidebarColor, setSidebarColor] = useState<string>('');
  const [open, setOpen] = useState<boolean>(true);
  const [sidebarQuestion, setSidebarQuestion] = useState<string>('');
  const [sidebarId, setSidebarId] = useState<any>('');
  const [state] = useGlobalState();
  const [currentCompanyName, setCurrentCompanyName] = useState('');
  const helpConfig = useAppSelector((st: RootState) => st.helpConfig.data);
  const { isHelpAvailable } = helpConfig;
  const [assesmentDataForHelp, setAssessmentDataForHelp] = useState<AssessmentData>({
    assessmentId: 0,
    questionnaireId: 0,
  });
  const [previewInstantHelp, setPreviewInstantHelp] = useState<boolean>(false);
  const [skipToAnyStep, setSkipToAnyStep] = useState<number>(0);
  const [customButtonName, setCustomButtonName] = useState<string>('');
  const [userActiveImprovePlans, setUserActiveImprovePlans] = useState<
    DeepDiveQuestionnairePlan[] | []
  >([]);

  const { activeSubNav } = useContext(SecondaryNavigationContext);

  useEffect(() => {
    if (!collapsed) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    smoothScrollTo(0, 0);

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [collapsed]);

  useEffect(() => {
    const getCurrentCompanyName = async () => {
      const comDetails = await getCompanyDetails();
      if (comDetails) {
        setCurrentCompanyName(comDetails.company_name);
      }
    };

    getCurrentCompanyName();
    dispatch(starScoresSliceActions.setUserSkippedFocusSelection(false));
  }, []);

  const sidebarClasses = classNames([
    styles.hsQuestionsContainerSidebar,
    collapsed && styles.Collapsed,
  ]);

  useEffect(() => {
    setAllCategories(categoryQuestion);
  }, [categoryQuestion]);

  useEffect(() => {
    getActiveImprovePlans();
  }, []);

  const getActiveImprovePlans = async () => {
    type StepType =
      | 'NO_ACTIVE_PLANS'
      | 'HAS_PLANS_NO_FOCUS_AREAS'
      | 'HAS_BOTH_PLANS_AND_FOCUS_AREAS';
    const uPlans: any = await getUserImprovePlansByDeepDive(activeSubNav.id, true);

    dispatch(starScoresSliceActions.addDeepDiveQuestionWithPlan(uPlans));

    const modUplan = uPlans[0]?.question?.improvePlans;

    let step: StepType = 'NO_ACTIVE_PLANS';
    let focusAreas: ImprovePlanFocusAreas[] = [];

    if (modUplan) {
      if (modUplan?.length === 0) {
        step = 'NO_ACTIVE_PLANS';
      } else if (
        modUplan?.length > 0 &&
        modUplan[0]?.improvePlansFocusAreas.filter((dt: { active: boolean }) => dt.active)
          ?.length === 0
      ) {
        step = 'HAS_PLANS_NO_FOCUS_AREAS';
      } else if (
        modUplan?.length > 0 &&
        modUplan[0]?.improvePlansFocusAreas.filter((dt: { active: boolean }) => dt.active)?.length >
          0
      ) {
        step = 'HAS_BOTH_PLANS_AND_FOCUS_AREAS';
        focusAreas = modUplan[0]?.improvePlansFocusAreas;
      }
    }

    dispatch(starScoresSliceActions.updateCurrentStep({ step, focusAreas }));
    setUserActiveImprovePlans(uPlans);
  };

  const handleChange = (score: number, questionId: number) => {
    const newCategories = allCategories.map((category: any) => {
      const currentCategory = { ...category };
      const questions = currentCategory.questions.map((question: any) => {
        const currentQuestion = {
          ...question,
        };

        if (currentQuestion.id === questionId) {
          currentQuestion.score = score;
        }

        return currentQuestion;
      });
      const scores = questions.map((q: any) => q.score);
      currentCategory.questions = questions;
      currentCategory.data = scores.length === 1 ? new Array(4).fill(scores[0]) : scores;

      return currentCategory;
    });

    setAllCategories(newCategories);
    setCategoryQuestion(newCategories);
  };

  const handleSidebarOpen = (score: number, color: string, question: string, id: number) => {
    setSidebarScore(score);
    setSidebarColor(color);
    setSidebarQuestion(question);
    setSidebarId(id);
  };

  const shareThoughts = (data: any) => {
    const { shareWith, thoughtsText } = data;

    const newCategories = allCategories.map((category: any) => {
      const currentCategory = { ...category };
      const questions = currentCategory.questions.map((question: any) => {
        const currentQuestion = {
          ...question,
        };
        if (currentQuestion.id === sidebarId) {
          currentQuestion.shareWith = shareWith;
          currentQuestion.thoughtsText = thoughtsText;
        }

        return currentQuestion;
      });
      currentCategory.questions = questions;

      return currentCategory;
    });

    setAllCategories(newCategories);
    setCategoryQuestion(newCategories);
  };

  return (
    <div className={styles.hsQuestionsContainer}>
      <ToastContainer hideProgressBar draggable={false} theme="dark" />
      <Wizard
        header={
          <StepWizardHeader
            categoryNames={allCategories.map((category: any) => category.name)}
            handleAssessmentData={setAssessmentDataForHelp}
            customButtonName={customButtonName}
          />
        }
        footer={
          <StepWizardFooter
            handleAssessmentData={setAssessmentDataForHelp}
            assesmentData={assesmentDataForHelp}
            skipToAnyStep={skipToAnyStep}
            handleStarSkip={setSkipToAnyStep}
            customButtonName={customButtonName}
          />
        }
      >
        <StepWizardStar />
        <QuestionItem
          showPopUp
          collapsed={collapsed}
          categoryQuestions={allCategories[0]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItem
          categoryQuestions={allCategories[1]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItem
          categoryQuestions={allCategories[2]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItem
          categoryQuestions={allCategories[3]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItem
          categoryQuestions={allCategories[4]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItem
          categoryQuestions={allCategories[5]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        {/* <StepWizardStar isEdit /> */}
        <StepWizardFinish />
        {isHelpAvailable && (
          <HelpAndSpeakup
            assesmentDataForHelp={assesmentDataForHelp}
            handleAssessmentData={setAssessmentDataForHelp}
            previewInstantHelp={previewInstantHelp}
            handleInstantPreview={setPreviewInstantHelp}
            skipStarStep={setSkipToAnyStep}
            handleSkipButton={setCustomButtonName}
          />
        )}

        <NewScorePageContent isEditable data={allCategories} />

        {reduxStarScores.subNavigationStep !== 'noPlanAndFocusAreas' &&
          reduxStarScores.subNavigationStep !== 'hasPlanNoFocusAreas' &&
          reduxStarScores.subNavigationStep !== 'hasPlanAndRemovedFocusAreas' &&
          companyConfig.data &&
          !reduxStarScores.isUserSkippedFocusAreas &&
          companyConfig.data.talkToAdvisor && (
            <TalkToAdviserStep
              data={allCategories}
              isWizard
              nextStep={() => setSkipToAnyStep(isHelpAvailable ? 11 : 10)}
              step="talkToAdvisorMessage"
            />
          )}

        {reduxStarScores.subNavigationStep !== 'noPlanAndFocusAreas' &&
          reduxStarScores.subNavigationStep !== 'hasPlanNoFocusAreas' &&
          reduxStarScores.subNavigationStep !== 'hasPlanAndRemovedFocusAreas' &&
          companyConfig.data &&
          !reduxStarScores.isUserSkippedFocusAreas &&
          companyConfig.data.talkToAdvisor && (
            <TalkToAdviserStep
              data={allCategories}
              isWizard
              nextStep={() => setSkipToAnyStep(isHelpAvailable ? 12 : 11)}
              step="talkToAdvisorDiscussion"
            />
          )}

        {reduxStarScores.subNavigationStep !== 'noPlanAndFocusAreas' &&
          reduxStarScores.subNavigationStep !== 'hasPlanNoFocusAreas' &&
          reduxStarScores.subNavigationStep !== 'hasPlanAndRemovedFocusAreas' &&
          companyConfig.data &&
          !reduxStarScores.isUserSkippedFocusAreas &&
          companyConfig.data.talkToAdvisor && (
            <TalkToAdviserStep
              data={allCategories}
              isWizard
              nextStep={() => setSkipToAnyStep(isHelpAvailable ? 13 : 12)}
              step="talkToAdvisorCompletion"
            />
          )}

        {companyConfig.data.talkToAdvisor && reduxStarScores.isUserSkippedFocusAreas && (
          <NewScorePlanSection data={allCategories} isWizard />
        )}

        {companyConfig.data.talkToAdvisor &&
          reduxStarScores.subNavigationStep !== 'noPlanAndFocusAreas' && (
            <NewScorePlanSection data={allCategories} isWizard />
          )}

        {companyConfig.data.talkToAdvisor &&
          reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' && (
            <NewScorePlanSection data={allCategories} isWizard />
          )}

        {companyConfig.data.talkToAdvisor &&
          reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' && (
            <NewScorePlanSection data={allCategories} isWizard />
          )}

        {(!companyConfig.data.talkToAdvisor ||
          companyConfig.data.talkToAdvisor === undefined ||
          companyConfig.data.talkToAdvisor === null) && (
          <NewScorePlanSection data={allCategories} isWizard />
        )}
      </Wizard>
      {!collapsed && state?.isUserOnBoarded && (
        <PopUpModal
          top="150px"
          right="700px"
          open={open}
          setOpen={setOpen}
          description={`Any thoughts shared with ${currentCompanyName} are anonymous. This is your chance to be open and be heard.`}
          title="Sharing your thoughts"
        />
      )}
      <Sidebar
        className={sidebarClasses}
        defaultCollapsed
        backgroundColor="white"
        collapsedWidth="0px"
        width="420px"
      >
        {!collapsed && (
          <ShareThoughts
            id={sidebarId}
            score={sidebarScore}
            color={sidebarColor}
            question={sidebarQuestion}
            shareThoughts={(data) => shareThoughts(data)}
          />
        )}
      </Sidebar>

      <div className={styles.hsQuestionsContainerSidebarOverlay} />
    </div>
  );
};

interface AssessmentData {
  assessmentId: number;
  questionnaireId: number;
}

export default QuestionItemContainer;
