import React, { FunctionComponent, useRef } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import classNames from 'classnames';
import { SelectableOptionType } from 'custom';
import styles from './index.module.scss';

const SelectComponent: FunctionComponent<SelectComponentProps> = (props) => {
  const {
    id,
    name,
    options,
    onChange,
    value,
    isError,
    className,
    isSearchable,
    isClearable,
    isLoading,
    isDisabled,
    isMulti,
    placeholder,
    onBlur,
    customStyle,
    ...rest
  } = props;

  return (
    <Select
      id={id}
      name={name}
      options={options}
      className={classNames([className, isError && styles.error])}
      value={value}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      onChange={onChange}
      onBlur={onBlur}
      isMulti={isMulti}
      placeholder={placeholder}
      styles={{
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: customStyle?.color || '#ECE7DC',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: customStyle?.color || 'black',
        }),
      }}
      {...rest}
    />
  );
};

SelectComponent.displayName = 'SelectComponent';

SelectComponent.defaultProps = {
  value: undefined,
  isError: false,
  className: styles[`react-select-container`],
  isSearchable: false,
  isClearable: false,
  isLoading: false,
  isDisabled: false,
  isMulti: false,
  placeholder: '',
  onBlur: undefined,
  customStyle: {},
};

interface SelectComponentProps {
  id: string;
  name: string;
  options: SelectableOptionType[];
  onChange: (value: MultiValue<SelectableOptionType> | SingleValue<SelectableOptionType>) => void;
  value?: SelectableOptionType | null | undefined;
  isError?: boolean;
  className?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  customStyle?: React.CSSProperties;
}

export default SelectComponent;
