import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { User } from '../../custom';
import styles from './index.module.scss';

const TableActionsMenu: React.FunctionComponent<TableActionsMenuProps> = (props) => {
  const { onSelect, values } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (eventKey: string) => {
    onSelect(eventKey, values);
    handleClose();
  };

  return (
    <div className={styles.root}>
      <IconButton
        size="small"
        aria-controls="table-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={styles.btnAction}
      >
        <MoreVertIcon className={styles.icon} fontSize="small" />
      </IconButton>
      <Menu id="table-actions-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick('edit')}>Edit</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('delete')}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

interface TableActionsMenuProps {
  onSelect: (eventKey: string | null, user: User) => void;
  values: User;
}

export default TableActionsMenu;
