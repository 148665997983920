const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
  'X-Requested-With': 'XMLHttpRequest', //To prevent CSRF attacks by distinguishing between AJAX and non-AJAX requests.
});

export const createHeaders = (additionalHeaders = {}) => {
  const defaultHeaders = getDefaultHeaders();

  return { ...defaultHeaders, ...additionalHeaders };
};

export const defaultHttpRequestHeader = createHeaders();
