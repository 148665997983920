import React from 'react';

const FormButton: React.FunctionComponent<FormButtonProps> = (props) => {
  const { isSubmit, isDisabled, className, onClick, children, width, ...rest } = props;

  return (
    <button
      disabled={isDisabled}
      type={isSubmit ? 'submit' : 'button'}
      className={className}
      onClick={onClick}
      style={{ width }}
      {...rest}
    >
      {children}
    </button>
  );
};

type FormButtonProps = {
  isSubmit?: boolean;
  isDisabled?: boolean;
  className: string;
  width?: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
};

FormButton.defaultProps = {
  isSubmit: false,
  isDisabled: false,
  width: '',
};

export default FormButton;
