import { Skeleton } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React, { FunctionComponent, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Img from '@components/Atoms/Img';
import GraphExample from '@assets/images/graph-example.svg';
import useWindowDimensions from '@hooks/useWindowDimensions';
import styles from './index.module.scss';

const DashboardAreaChart: FunctionComponent<DashboardAreaChartProps> = (props) => {
  const { className, loading, scoreChartData, handleStarModal } = props;
  const { isDesktop } = useWindowDimensions();
  const [dateRange, setDateRange] = useState<{ min: Date | any; max: Date | any }>({
    min: '',
    max: '',
  });

  const series = [
    {
      name: 'Score',
      data: scoreChartData.scores,
    },
  ];

  const chartOpts: ApexOptions = {
    chart: {
      animations: {
        enabled: true,
        speed: 2500,
        animateGradually: {
          enabled: true,
        },
      },
      height: 350,
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: isDesktop,
          zoomin: true,
          zoomout: true,
          pan: isDesktop,
          reset: true,
        },
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
    },
    markers: {
      size: scoreChartData?.dates?.length === 1 ? 10 : 0,
      hover: {
        size: 10,
        sizeOffset: 3,
      },
    },
    dataLabels: {
      enabled: scoreChartData?.dates?.length === 1,
    },
    stroke: {
      curve: 'smooth',
      width: [4],
    },
    colors: ['#FCD300'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#019DF2'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      categories: scoreChartData.dates,
      labels: {
        datetimeFormatter: {
          day: "MMM 'dd",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter(val) {
          return val.toFixed(0);
        },
      },
      tickAmount: 5,
      min: 0,
      max: 100,
    },
    tooltip: {
      followCursor: true,
      x: {
        format: "MMM 'dd",
      },
    },
    grid: {
      borderColor: 'transparent',
    },
    legend: {
      show: true,
      tooltipHoverFormatter(val, opts) {
        return `${val} - <strong>${
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
        }</strong>`;
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.75,
        },
      },
    },
  };

  return (
    <div>
      {loading ? (
        <Skeleton variant="rounded" width="100%" className={styles.loadSpiline} />
      ) : scoreChartData.scores.length === 0 ? (
        <div className={styles.emptyChart} onClick={() => handleStarModal(true)}>
          <h3>
            Let&apos;s start building your well-being journey! <br />
            <span onClick={() => handleStarModal(true)}>Check in</span> and start building your
            score
          </h3>

          <Img src={GraphExample} alt="HS graph example" className={styles.graphImage} />
        </div>
      ) : (
        <ReactApexChart options={chartOpts} series={series} type="line" height={380} />
      )}
    </div>
  );
};

DashboardAreaChart.defaultProps = {
  className: '',
  loading: false,
};

interface DashboardAreaChartProps {
  className?: string;
  loading?: boolean;
  scoreChartData: { scores: number[]; dates: string[] };
  handleStarModal: (arg0: boolean) => void;
}

export default DashboardAreaChart;
