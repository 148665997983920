import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './index.module.scss';

const RoundedSquareButton: FunctionComponent<RoundedSquareButtonInterface> = (props) => {
  const {
    id,
    colorTheme,
    selected,
    onClick,
    score,
    title,
    icon,
    progressSectionEnabled,
    progress,
  } = props;

  const buttonClass = classNames(styles.roundedSqureButton, styles[colorTheme], {
    [styles.selected]: selected,
  });

  return (
    <DefaultButton
      className={buttonClass}
      onClick={() => {
        if (id) {
          onClick(id);
        }
      }}
    >
      <div className={styles.topSection}>
        <p>{title}</p>
        <Img src={`../../../images/shortStar/${icon}.svg`} alt={title} className={styles.icon} />
      </div>
      {progressSectionEnabled ? (
        <CircularProgress
          className={styles.circularProgress}
          variant="determinate"
          value={progress}
          size={50}
        />
      ) : (
        <span className={styles.scoreSection}>{score}</span>
      )}
    </DefaultButton>
  );
};

RoundedSquareButton.defaultProps = {
  icon: '',
  progressSectionEnabled: false,
  progress: 0,
};

interface RoundedSquareButtonInterface {
  id: number;
  colorTheme: string;
  selected: any;
  onClick: (id: number) => void;
  score: number;
  title: string;
  icon?: ShortStarIconTypes | string;
  progressSectionEnabled?: boolean;
  progress?: number;
}

type ShortStarIconTypes =
  | 'sunnyIcon'
  | 'physchologyIcon'
  | 'peopleIcon'
  | 'selfImprovementIcon'
  | 'briefCaseIcon'
  | 'houseIcon'
  | '';

export default RoundedSquareButton;
