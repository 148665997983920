import React, { FunctionComponent } from 'react';
import Checkbox from '@components/Atoms/Checkbox';
import classNames from 'classnames';
import { LabelPositions } from '@enums/LabelPositions';
import styles from './index.module.scss';

const LabelCheckbox: FunctionComponent<LabelCheckboxProps> = (props) => {
  const { id, name, checked, label, onChange, disabled, className, isError, labelPosition } = props;

  const checkboxClasses = classNames([
    className,
    labelPosition === LabelPositions.top && styles.positionTop,
    labelPosition === LabelPositions.bottom && styles.positionBottom,
    labelPosition === LabelPositions.left && styles.positionLeft,
    labelPosition === LabelPositions.right && styles.positionRight,
  ]);

  return (
    <div className={checkboxClasses}>
      <label htmlFor={name}>{label}</label>
      <Checkbox
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        isError={isError}
      />
    </div>
  );
};

LabelCheckbox.displayName = 'LabelCheckbox';

LabelCheckbox.defaultProps = {
  disabled: false,
  className: styles.checkboxContainer,
  isError: false,
  labelPosition: LabelPositions.top,
};

interface LabelCheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string;
  name: string;
  checked: boolean;
  label: JSX.Element | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  isError?: boolean;
  labelPosition?: LabelPositions;
}

export default LabelCheckbox;
