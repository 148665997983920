import React from 'react';
import Img from '@components/Atoms/Img';
import LinkText from '@components/Atoms/Link';
import useWindowDimensions from '@hooks/useWindowDimensions';
import useGoNative from '@hooks/useGoNative';
import { useParams } from 'react-router';
import logo from '../../../assets/images/logo.svg';

import styles from './index.module.scss';

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { organizationAltText, organizationLogo } = props;
  const { companyURL } = useParams<UrlParamsType>();
  const { isMobile } = useWindowDimensions();
  const isMobileApp = useGoNative();

  return (
    <div className={styles.hsHeader}>
      <nav className={styles.hsNav}>
        <div>
          <LinkText path="/">
            <Img alt="Hapstar logo" src={logo} className={styles.img} />
          </LinkText>
        </div>
        {organizationLogo && !isMobile && (
          <div>
            <Img
              alt={organizationAltText || 'Organization logo'}
              src={organizationLogo}
              className={styles.img}
            />
          </div>
        )}
      </nav>
    </div>
  );
};

Header.defaultProps = {
  organizationLogo: undefined,
  organizationAltText: undefined,
};

type HeaderProps = {
  organizationAltText?: string;
  organizationLogo?: string | undefined;
};

type UrlParamsType = {
  companyURL: string;
};

export default Header;
