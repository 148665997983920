import React, { FunctionComponent, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Img from '../Img';
import styles from './index.module.scss';

const DefaultButton: FunctionComponent<DefaultButtonProps> = ({
  className,
  iconSrc,
  iconPosition,
  disabled,
  variant,
  path,
  children,
  ...rest
}) => {
  const buttonClasses = classNames([
    className,
    variant === 'primary' && styles.hsPrimary,
    variant === 'secondary' && styles.hsSecondary,
    variant === 'tertiary' && styles.hsTertiary,
    variant === 'icon' && styles.hsIconButton,
    variant === 'more' && styles.hsShowMoreButton,
    variant === 'small' && styles.hsSmall,
    variant === 'close' && styles.hsClose,
    variant === 'darken' && styles.hsDarken,
    variant === 'red' && styles.hsRed,
  ]);

  return (
    <>
      {path?.length === 0 || path === undefined ? (
        <button
          className={classNames([
            buttonClasses,
            iconPosition === 'left' && styles.iconLeft,
            iconPosition === 'right' && styles.iconRight,
          ])}
          {...rest}
          disabled={disabled}
        >
          {iconSrc && iconPosition === 'left' && (
            <Img src={iconSrc} alt="Icon" className={styles.iconLeft} />
          )}
          {children}
          {iconSrc && iconPosition === 'right' && (
            <Img src={iconSrc} alt="Icon" className={styles.iconRight} />
          )}
        </button>
      ) : (
        <Link to={`${path}`}>
          <button
            className={classNames([
              buttonClasses,
              iconPosition === 'left' && styles.iconLeft,
              iconPosition === 'right' && styles.iconRight,
            ])}
            {...rest}
            disabled={disabled}
          >
            {iconSrc && iconPosition === 'left' && (
              <Img src={iconSrc} alt="Icon" className={styles.iconLeft} />
            )}
            {children}
            {iconSrc && iconPosition === 'right' && (
              <Img src={iconSrc} alt="Icon" className={styles.iconRight} />
            )}
          </button>
        </Link>
      )}
    </>
  );
};

DefaultButton.displayName = 'Button';

interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  iconSrc?: string;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'icon'
    | 'more'
    | 'small'
    | 'close'
    | 'darken'
    | 'red';
  children: React.ReactNode;
  path?: string;
}

DefaultButton.defaultProps = {
  className: styles.button,
  iconSrc: '',
  iconPosition: 'left',
  disabled: false,
  variant: 'primary',
  path: '',
};

export default DefaultButton;
