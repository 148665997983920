import { logDashboardMusic } from '@api/audio';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import iosBack from '../../../../assets/images/ios-back.svg';
import iosNext from '../../../../assets/images/ios-next.svg';
import PauseIcon from '../../../../assets/images/pause.svg';
import PlayIcon from '../../../../assets/images/play.svg';
import styles from './index.module.scss';

const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const MusicSection: React.FunctionComponent<MusicSectionProps> = ({ loading, musicData }) => {
  const [audioDuration, setAudioDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [audio, setAudio] = useState<any>();
  const audioRef = useRef(new Audio(musicData[0].src));
  const [startIndex, setStartIndex] = useState(0);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentProgress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
    setProgress(currentProgress);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    setProgress(0);
  };

  useEffect(() => {
    const updateProgress = () => {
      handleTimeUpdate();
    };

    if (isPlaying) {
      audioRef.current.play();
      audioRef.current.addEventListener('timeupdate', updateProgress);
      audioRef.current.addEventListener('ended', handleAudioEnded);
    } else {
      audioRef.current.pause();
      audioRef.current.removeEventListener('timeupdate', updateProgress);
      audioRef.current.removeEventListener('ended', handleAudioEnded);
    }

    return () => {
      audioRef.current.removeEventListener('timeupdate', updateProgress);
      audioRef.current.removeEventListener('ended', handleAudioEnded);
    };
  }, [isPlaying, startIndex]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setAudioDuration(audioRef.current.duration);
    };

    audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [startIndex]);

  const handleNext = () => {
    if (startIndex < musicData.length - 1) {
      setStartIndex(startIndex + 1);
      audioRef.current.src = musicData[startIndex + 1].src;
    }
  };

  const handleBack = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
      audioRef.current.src = musicData[startIndex - 1].src;
    }
  };

  const handleLogMusic = async (musicName: string) => {
    let fileName = musicName.split('/').pop();

    if (fileName) {
      const parts = fileName.split('.');

      const baseName = parts.slice(0, -2).join('.');

      const extension = parts[parts.length - 1];
      fileName = `${baseName}.${extension}`;

      await logDashboardMusic(fileName);
    }
  };

  return (
    <Card className={styles.preMiddleWhiteBox2}>
      {musicData.slice(startIndex, startIndex + 3).map((item: any, index: number) => (
        <div className={styles.cardContent} key={index}>
          {loading ? (
            <Skeleton
              className={styles.musicBgLoading}
              variant="rectangular"
              width="100%"
              height={200}
            />
          ) : (
            <div className={styles.musicBg} />
          )}

          <div>
            <div className={styles.top}>
              {loading ? <Skeleton variant="text" width={200} height={30} /> : <h5>{item.name}</h5>}
              <div className={styles.navigationButtons}>
                {!loading && (
                  <>
                    <DefaultButton
                      onClick={handleBack}
                      disabled={startIndex === 0}
                      className={styles.navigationBtn}
                    >
                      <Img src={iosBack} alt="Back" className={styles.navigationIcon} />
                    </DefaultButton>
                    <DefaultButton
                      onClick={handleNext}
                      disabled={startIndex + 1 >= musicData.length}
                      className={styles.navigationBtn}
                    >
                      <Img src={iosNext} alt="Next" className={styles.navigationIcon} />
                    </DefaultButton>
                  </>
                )}
              </div>
            </div>
            <div className={styles.middle}>
              {loading && (
                <>
                  <div>
                    <Skeleton variant="text" width={300} height={20} />
                    <Skeleton variant="text" width={300} height={20} />
                    <Skeleton variant="text" width={150} height={20} />
                  </div>
                  <Skeleton
                    variant="circular"
                    width={60}
                    height={60}
                    className={styles.loadCircle}
                  />
                </>
              )}
              {!loading && (
                <>
                  <p>{item.description}</p>
                  <div>
                    <div
                      className={styles.musicControl}
                      onClick={() => {
                        togglePlay();
                      }}
                    >
                      {isPlaying ? (
                        <Img src={PauseIcon} alt="Pause" className={styles.controlBtnPause} />
                      ) : (
                        <div onClick={() => handleLogMusic(item.src)}>
                          <Img src={PlayIcon} alt="Play" className={styles.controlBtnPlay} />
                        </div>
                      )}
                    </div>
                    {isPlaying ? (
                      <div className={styles.progressSec}>
                        <div className={styles.progressBar}>
                          <div className={styles.progressFill} style={{ width: `${progress}%` }} />
                        </div>
                      </div>
                    ) : (
                      <div className={styles.progressBarNone} />
                    )}

                    <p className={styles.audioDuration}>{formatDuration(audioDuration)} mins</p>
                  </div>
                </>
              )}
            </div>

            <div className={styles.navigationButtonsMobile}>
              {!loading && (
                <>
                  <DefaultButton
                    onClick={handleBack}
                    disabled={startIndex === 0}
                    className={styles.navigationBtn}
                  >
                    <Img src={iosBack} alt="Back" className={styles.navigationIcon} />
                  </DefaultButton>
                  <DefaultButton
                    onClick={handleNext}
                    disabled={startIndex + 1 >= musicData.length}
                    className={styles.navigationBtn}
                  >
                    <Img src={iosNext} alt="Next" className={styles.navigationIcon} />
                  </DefaultButton>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};

MusicSection.defaultProps = {
  loading: false,
};

interface MusicSectionProps {
  loading?: boolean;
  musicData: MusicItem[];
}

interface MusicItem {
  id: number;
  name: string;
  description: string;
  src: string;
}

export default MusicSection;
