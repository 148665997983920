import { getUserImprovePlans } from '@api/actionPlan';
import { getIpAddress } from '@api/auth/login';
import { getInstantHelpLimits, logInstantHelpStarDetails } from '@api/instantHelp';
import HsStar from '@assets/images/icons/home-active.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import { Skeleton } from '@mui/material';
import HelpAndSpeakup from '@pages/EarlyWarning/HelpAndSpeakup';
import classNames from 'classnames';
import {
  HelpLimitData,
  LogTypes,
  ShortStarQuestionsInterface,
  ShortStatContentTypes,
  shortStarContent,
  updateUserStatusRequest,
} from 'custom.d';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { getShortStarQuestions } from 'store/Actions/ShortStarAction';
import { updateUserStatusData } from 'store/Actions/UserStatusAction';
import { shortStarWizardActions } from 'store/Slices/ShortStarWizardSlice';
import FocusSection from '../../Molecules/ShortStar/Sections/FocusSection';
import GeneralHappinessQuestion from '../../Molecules/ShortStar/Sections/GeneralHappinessQuestion';
import ImproveSection from '../../Molecules/ShortStar/Sections/ImproveSection';
import Questions from '../../Molecules/ShortStar/Sections/Questions';
import ReflectionSection from '../../Molecules/ShortStar/Sections/ReflectionSection';
import ScoreDifferenceSection from '../../Molecules/ShortStar/Sections/ScoreDifferenceSection';
import styles from './index.module.scss';

export const ModalPortal = ({ children }: { children: React.ReactNode }) => {
  const portalRoot = document.getElementById('short-star-modal') as Element;

  return ReactDOM.createPortal(children, portalRoot);
};

const ShortStarModal = ({ modalOpen, onClose }: ShortStarModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobileApp = useGoNative();
  const shortStarQuestions = useAppSelector((state: RootState) => state.shortStarQuestions);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);
  const { showSpeakUp } = companyConfig;
  const wizardData = useAppSelector((state: RootState) => state.shortStarWizard);
  const [generalHappinessQueData, setGeneralHappinessQueData] = useState<
    ShortStarQuestionsInterface | null | undefined
  >(null);
  const userStatus = useAppSelector((st: RootState) => st.userStatus.data);
  const hsConfigurations = useAppSelector((state: RootState) => state.configurations);
  const [modalDataLoading, setModalDataLoading] = useState<boolean>(false);
  const [questionLimits, setQuestionLimits] = useState<HelpLimitData[]>([]);
  const [getHelpOpen, setGetHelpOpen] = useState<boolean>(false);
  const [assessmentDataForHelp, setAssessmentDataForHelp] = useState<AssessmentData>({
    assessmentId: 0,
    questionnaireId: 0,
  });
  const [previewInstantHelp, setPreviewInstantHelp] = useState<boolean>(false);
  const [skipToAnyStep, setSkipToAnyStep] = useState<number>(0);
  const [customButtonName, setCustomButtonName] = useState<string>('');

  const popupDataStr = localStorage.getItem('shortStarCacheHistory');

  useEffect(() => {
    if (wizardData.questionnaireResponse) {
      setAssessmentDataForHelp({
        assessmentId: wizardData.questionnaireResponse?.assessment_id,
        questionnaireId: wizardData.questionnaireResponse?.questionnaire_id,
      });
    }
  }, [wizardData]);

  const checkLimits = wizardData.questionsScores.some((question) => {
    const limit = questionLimits?.find((lm) => lm?.question_id === question?.questionId);

    return limit && limit?.limit >= question?.score;
  });

  useEffect(() => {
    const limitedQuestions = wizardData.questionsScores
      .filter((question: { questionId: number; score: number }) => {
        const limit = questionLimits?.find((lm) => lm?.question_id === question?.questionId);

        return limit && limit.limit >= question.score;
      })
      .map((question) => {
        const limit = questionLimits?.find((lm) => lm?.question_id === question?.questionId);

        return {
          question_id: question.questionId,
          limit: limit ? limit.limit : 0,
          assessment_id: assessmentDataForHelp.assessmentId,
        };
      });

    if (checkLimits && assessmentDataForHelp.assessmentId > 0) {
      setGetHelpOpen(true);
      logLimitedQuestions(limitedQuestions);
    }
  }, [assessmentDataForHelp.assessmentId, checkLimits, questionLimits, wizardData.questionsScores]);

  useEffect(() => {
    setModalDataLoading(true);
    async function getShortStarData() {
      await dispatch(getShortStarQuestions());
    }

    if (!shortStarQuestions.isLoaded) {
      getShortStarData();
    }

    setTimeout(() => {
      setModalDataLoading(false);
    }, 1500);
  }, [dispatch, shortStarQuestions.isLoaded]);

  useEffect(() => {
    getUserActiveImprovePlans();
  }, []);

  useEffect(() => {
    const modifiedData = shortStarQuestions.data.find(
      (question) => question.generalHappinessQuestion,
    );
    if (modifiedData) {
      setGeneralHappinessQueData(modifiedData);
      dispatch(
        shortStarWizardActions.updateWizardContent(
          shortStarContent.GENERAL_HAPPINESS_QUESTION_SECTION,
        ),
      );
    } else {
      dispatch(shortStarWizardActions.updateWizardContent(shortStarContent.QUESTIONS_SECTION));
    }
  }, [shortStarQuestions]);

  useEffect(() => {
    getInstantHelpLimits().then((response) => {
      setQuestionLimits(response);
    });
  }, []);

  useEffect(() => {
    if (wizardData.wizardContent === shortStarContent.COMPLETE_SHORT_STAR) {
      if (userStatus.isFirstShortStarCompleted) {
        onClose();
        dispatch(shortStarWizardActions.resetWizard());
      } else {
        onClose();
        dispatch(shortStarWizardActions.resetWizard());
        history.push('/');
      }
    }
  }, [wizardData.wizardContent, userStatus.isFirstShortStarCompleted]);

  const getUserActiveImprovePlans = async () => {
    try {
      const res = await getUserImprovePlans(true);
      if (res?.userImprovePlans?.length > 0) {
        await dispatch(shortStarWizardActions.addActionPlans(res?.userImprovePlans));
      } else {
        await dispatch(shortStarWizardActions.addActionPlans([]));
      }
    } catch (e) {
      await dispatch(shortStarWizardActions.addActionPlans([]));
    }
  };

  const logLimitedQuestions = async (
    limitedQuestions: {
      assessment_id: number;
      limit: number;
      question_id: number;
    }[],
  ) => {
    const payload = {
      logType:
        checkLimits && showSpeakUp ? LogTypes.SPEAKUP_DISPLAYED : LogTypes.INSTANT_HELP_DISPLAYED,
      ipAddress: await getIpAddress(),
      isNative: isMobileApp,
      os: navigator.userAgentData?.platform || '',
    };

    if (checkLimits && showSpeakUp) {
      logInstantHelpStarDetails(
        LogTypes.SPEAKUP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
      );

      logInstantHelpStarDetails(
        LogTypes.INSTANT_HELP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        limitedQuestions,
      );
    } else {
      logInstantHelpStarDetails(
        payload.logType,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        limitedQuestions,
      );
    }
  };

  const closeModal = () => {
    if (popupDataStr) {
      const shortStarCacheHistory = JSON.parse(popupDataStr);

      shortStarCacheHistory.popupClosedToday = true;

      const updatedPopupDataStr = JSON.stringify(shortStarCacheHistory);

      localStorage.setItem('shortStarCacheHistory', updatedPopupDataStr);
    }

    onClose();
  };

  const wizardWelcomeMessage =
    hsConfigurations.shortStarModalForce &&
    companyConfig?.welcomeMessageText &&
    companyConfig?.welcomeMessageText !== 'null' &&
    companyConfig?.welcomeMessageText?.length > 0;

  return (
    <ModalPortal>
      {getHelpOpen && (
        <Modal open={getHelpOpen} setOpen={setGetHelpOpen} className={styles.helpRoot}>
          <div className={styles.btnWrapper}>
            <div className={styles.btnClose}>
              <button type="button" onClick={() => setGetHelpOpen(false)}>
                Close&nbsp;
                <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
              </button>
            </div>
          </div>

          <div className={styles.helpAndSpeakUpModalBody}>
            <HelpAndSpeakup
              assesmentDataForHelp={assessmentDataForHelp}
              handleAssessmentData={setAssessmentDataForHelp}
              previewInstantHelp={previewInstantHelp}
              handleInstantPreview={setPreviewInstantHelp}
              skipStarStep={setSkipToAnyStep}
              handleSkipButton={setCustomButtonName}
              handleClose={() => setGetHelpOpen(false)}
              isShortStarModal
            />

            <div className={styles.skipButton}>
              <DefaultButton
                type="button"
                variant="secondary"
                onClick={() => setGetHelpOpen(false)}
              >
                Skip to continue
              </DefaultButton>
            </div>
          </div>
        </Modal>
      )}
      <div className={styles.overlay}>
        <Modal open={modalOpen} setOpen={closeModal} className={styles.modalRoot}>
          {modalDataLoading ? (
            <div className={styles.loadingCont}>
              <Skeleton variant="rounded" className={styles.loadingText} />
              <div className={styles.loadingFaces}>
                {[0, 1, 2, 3, 4].map((index) => (
                  <Skeleton key={index} className={styles.loadingFace} variant="circular" />
                ))}
              </div>
            </div>
          ) : (
            <>
              {!hsConfigurations.shortStarModalForce && (
                <div className={styles.btnWrapper}>
                  <div className={styles.btnClose}>
                    <button type="button" onClick={closeModal}>
                      Close&nbsp;
                      <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                    </button>
                  </div>
                </div>
              )}

              {hsConfigurations.daysSinceAssessmentCreated === null &&
                wizardData.wizardContent === shortStarContent.GENERAL_HAPPINESS_QUESTION_SECTION &&
                !companyConfig.welcomeMessageText && (
                  <div className={styles.starForceSection}>
                    <div>
                      <Img src={HsStar} alt="HS star" className={styles.hsStarImage} />
                      <h3>Welcome to Hapstar</h3>
                    </div>
                  </div>
                )}

              {(wizardData.wizardContent === shortStarContent.QUESTIONS_SECTION ||
                wizardData.wizardContent === shortStarContent.REFLECTION_SECTION ||
                wizardData.wizardContent === shortStarContent.FOCUS_SECTION ||
                wizardData.wizardContent === shortStarContent.IMPROVE_SECTION) &&
                wizardData.generalHappinessQuestion !== null && (
                  <div
                    className={`${styles.feelsImageContainer} 
                      ${hsConfigurations.shortStarModalForce && styles.mt20} 
                      ${
                        wizardData.wizardContent === shortStarContent.QUESTIONS_SECTION &&
                        styles.cursorEnabled
                      }
                    `}
                    onClick={() => {
                      if (wizardData.wizardContent === shortStarContent.QUESTIONS_SECTION) {
                        dispatch(
                          shortStarWizardActions.updateWizardContent(
                            shortStarContent.GENERAL_HAPPINESS_QUESTION_SECTION,
                          ),
                        );
                        dispatch(shortStarWizardActions.resetGeneralHappinessQuestion());
                      }
                    }}
                  >
                    <Img
                      src={wizardData.generalHappinessQuestion.imagePath}
                      alt={wizardData.generalHappinessQuestion.imagePath}
                      className={styles.feelsImage}
                    />
                  </div>
                )}

              <div className={styles.shortStarContainer}>
                {(() => {
                  switch (wizardData.wizardContent) {
                    case shortStarContent.GENERAL_HAPPINESS_QUESTION_SECTION:
                      return (
                        <GeneralHappinessQuestion
                          handleStep={(value: SetStateAction<ShortStatContentTypes>) =>
                            dispatch(shortStarWizardActions.updateWizardContent(value.toString()))
                          }
                          data={generalHappinessQueData}
                          isFirstShortStarCompleted={userStatus.isFirstShortStarCompleted}
                          closeModal={closeModal}
                        />
                      );
                    case shortStarContent.QUESTIONS_SECTION:
                      return (
                        <Questions
                          handleStep={(value: SetStateAction<ShortStatContentTypes>) =>
                            dispatch(shortStarWizardActions.updateWizardContent(value.toString()))
                          }
                          data={shortStarQuestions.data || []}
                        />
                      );
                    case shortStarContent.SCORE_DIFFERENCE_SECTION:
                      return (
                        <ScoreDifferenceSection
                          handleStep={(value: SetStateAction<ShortStatContentTypes>) =>
                            dispatch(shortStarWizardActions.updateWizardContent(value.toString()))
                          }
                        />
                      );
                    case shortStarContent.REFLECTION_SECTION:
                      return (
                        <ReflectionSection
                          handleStep={(value: SetStateAction<ShortStatContentTypes>) =>
                            dispatch(shortStarWizardActions.updateWizardContent(value.toString()))
                          }
                          helpAvailability={checkLimits}
                          handleGetHelp={setGetHelpOpen}
                        />
                      );
                    case shortStarContent.FOCUS_SECTION:
                      return (
                        <FocusSection
                          handleStep={(value: SetStateAction<ShortStatContentTypes>) =>
                            dispatch(shortStarWizardActions.updateWizardContent(value.toString()))
                          }
                        />
                      );
                    case shortStarContent.IMPROVE_SECTION:
                      return (
                        <ImproveSection
                          handleStep={(value: SetStateAction<ShortStatContentTypes>) =>
                            dispatch(shortStarWizardActions.updateWizardContent(value.toString()))
                          }
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
              <div
                className={
                  wizardData.wizardContent === 'GENERAL_HAPPINESS_QUESTION_SECTION'
                    ? `${styles.footerImageV1} ${
                        wizardWelcomeMessage && styles.footerImageV1Welcome
                      }`
                    : styles.footerImageV2
                }
              />
            </>
          )}
        </Modal>
      </div>
    </ModalPortal>
  );
};

ShortStarModal.defaultProps = {};

interface AssessmentData {
  assessmentId: number;
  questionnaireId: number;
}

interface ShortStarModalProps {
  modalOpen: boolean;
  onClose: () => void;
}

export default ShortStarModal;
