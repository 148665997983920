import React, { FunctionComponent } from 'react';
import Card from '@components/Atoms/Card';
import { InstantHelpAction } from 'custom';
import InstantRedirectActionButton from '../InstantRedirectActionButton';
import styles from './index.module.scss';

const InstantBorderedBox: FunctionComponent<InstantBorderedBoxProps> = (props) => {
  const { data, keyIndex, className, actions, handleOnClick } = props;

  return (
    <Card
      className={`${styles.borderedBox} ${styles[`borderColor${keyIndex % 9}`]}`}
      key={keyIndex}
    >
      <div className={styles.borderBoxContent}>
        <h2>{data.title}</h2>
        <p>{data.description_text}</p>
        <div className={styles.actions}>
          {actions.map((action: InstantHelpAction) => (
            <InstantRedirectActionButton
              key={action.id}
              action={action}
              icon={action.icon}
              iconPosition="left"
              handleClick={() => handleOnClick(data.id, action.id)}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

InstantBorderedBox.defaultProps = {
  className: styles.subText,
};

interface InstantBorderedBoxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  data: { id: number; title: string; description_text: string };
  className?: string;
  keyIndex: number;
  actions: InstantHelpAction[];
  handleOnClick: (dataId: number, actionId: number) => void;
}

export default InstantBorderedBox;
