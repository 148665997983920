import React, { useEffect, useState } from 'react';
import ScoreButtons from '@components/ScoreButtons';

import { CreateStarQuestionInterface } from '@interfaces/index';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import { useProSidebar } from 'react-pro-sidebar';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getCompanyDetails } from '@helpers/auth.helper';
import styles from './index.module.scss';

const QuestionItemContent: React.FunctionComponent<QuestionItemContentProps> = (props) => {
  const { question, onChange, onSidebarOpen } = props;
  const { collapseSidebar } = useProSidebar();
  const { isMobile } = useWindowDimensions();
  const [shareThoughts, setShareThoughts] = useState(true);

  useEffect(() => {
    const getCurrentCompanyDetails = async () => {
      const comDetails = await getCompanyDetails();
      if (comDetails) {
        setShareThoughts(comDetails.share_thoughts);
      }
    };

    getCurrentCompanyDetails();
  }, []);

  const handleClick = () => {
    collapseSidebar(false);
    onSidebarOpen(question.score, question.color, question.name, question.id);
  };

  const isButtonsDisabled = () => {
    let isDisabled = true;
    if (question.score > 0) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <div className={styles.hsSingleQuestion}>
      <div className={styles.hsSingleQuestionTitle}>
        {question.name} <Tooltip tip={question.hint} />
      </div>
      <ScoreButtons
        onChange={onChange}
        name={question.name}
        questionId={question.id}
        currentScore={question.score}
      />
      {shareThoughts !== undefined && !shareThoughts ? (
        <></>
      ) : (
        <Button type="small" isButton onClick={handleClick} isDisabled={isButtonsDisabled()}>
          Share thoughts
        </Button>
      )}
    </div>
  );
};

interface QuestionItemContentProps {
  question: CreateStarQuestionInterface;
  onChange: (score: number, position: number) => void;
  onSidebarOpen: (score: number, color: string, question: string, id: number) => void;
}

export default QuestionItemContent;
