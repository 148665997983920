import React from 'react';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import Card from '@components/Atoms/Card';
import Img from '@components/Atoms/Img';
import useWindowDimensions from '@hooks/useWindowDimensions';
import CompleteRegistrationForm from '@components/Organisms/CompleteRegistrationForm';
import LinkText from '@components/Atoms/Link';
import { useHistory } from 'react-router';
import { logoutUser } from '@helpers/auth.helper';
import styles from './index.module.scss';

const CompleteRegistration: React.FunctionComponent<CompleteRegistrationProps> = (props) => {
  const { organizationLogo, organizationAltText, organizationId, user } = props;

  const { isMobile } = useWindowDimensions();
  const history = useHistory();

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <AuthenticationLayout
      title="SignUp-Complete"
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
    >
      <div className={styles.cardContainer}>
        <Card>
          <div className={styles.registrationContentContainer}>
            <div className={styles.registrationContent}>
              {organizationLogo && isMobile && (
                <div className={styles.organizationLogoHolder}>
                  <Img
                    alt={organizationAltText || 'Organization logo'}
                    src={organizationLogo}
                    className={styles.img}
                  />
                </div>
              )}
              <h1>Complete your sign-up to Hapstar</h1>
              {organizationId !== 0 && (
                <p>
                  Additional data helps your organisation in providing the right wellbeing support.
                </p>
              )}
              <div className={styles.registrationDetails}>
                <CompleteRegistrationForm user={user} />
              </div>
              <div className={styles.backContainer}>
                <LinkText onClick={handleLogout}>Back</LinkText>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </AuthenticationLayout>
  );
};

CompleteRegistration.displayName = 'CompleteRegistration';

CompleteRegistration.defaultProps = {
  organizationLogo: undefined,
  organizationAltText: undefined,
  organizationId: 0,
};

interface CompleteRegistrationProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  organizationLogo?: string | undefined;
  organizationAltText?: string | undefined;
  organizationId?: number;
  user: any;
}

export default CompleteRegistration;
