import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './index.module.scss';

const DeepDownItem: FunctionComponent<DeepDownItemInterface> = (props) => {
  const {
    id,
    colorTheme,
    selected,
    onClick,
    score,
    title,
    icon,
    progressSectionEnabled,
    progress,
    deepDiveId,
    deepDiveName,
  } = props;

  const buttonClass = classNames(styles.roundedSqureButton, styles[colorTheme], {
    [styles.selected]: selected,
  });

  const radius = 30;
  const circumference = 2 * Math.PI * radius;

  const strokeDashoffset = progress ? circumference - (progress / 100) * circumference : 0;

  return (
    <DefaultButton
      className={buttonClass}
      onClick={() => {
        if (id) {
          onClick(id);
          const redirectUrl =
            progress === 0
              ? `/create-a-star?tabId=${deepDiveId}&tabName=${deepDiveName}`
              : `/stars?tabId=${deepDiveId}&tabName=${deepDiveName}&navigatedSource=deepDive`;
          window.location.href = redirectUrl;
        }
      }}
    >
      <div className={styles.topSection}>
        <p>{title}</p>
        <Img src={`../../../images/shortStar/${icon}.svg`} alt={title} className={styles.icon} />
      </div>
      {progressSectionEnabled ? (
        <div className={styles.progressCircle}>
          <svg className={styles.progressCircleSvg} width={radius * 2} height={radius * 2}>
            <circle className={styles.progressCircleBg} cx={radius} cy={radius} r={radius - 4} />
            <circle
              className={styles.progressCircleFg}
              cx={radius}
              cy={radius}
              r={radius - 4}
              style={{
                strokeDasharray: circumference,
                strokeDashoffset,
                stroke: progress === 0 ? 'white' : 'default-stroke-color',
              }}
            />

            <text
              className={styles.progress}
              x="50%"
              y="52%"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {progress === 0 ? <></> : `${progress}`}
            </text>
          </svg>
        </div>
      ) : (
        <span className={styles.scoreSection}>{score}</span>
      )}
      {progress === 0 && (
        <div className={styles.playContainer}>
          <PlayArrowIcon className={styles.playIcon} />
        </div>
      )}
    </DefaultButton>
  );
};

DeepDownItem.defaultProps = {
  icon: '',
  progressSectionEnabled: false,
  progress: 0,
};

interface DeepDownItemInterface {
  id: number;
  colorTheme: string;
  selected: any;
  onClick: (id: number) => void;
  score: number;
  title: string;
  icon?: ShortStarIconTypes | string;
  progressSectionEnabled?: boolean;
  progress?: number;
  deepDiveId: number;
  deepDiveName: string;
}

type ShortStarIconTypes =
  | 'sunnyIcon'
  | 'physchologyIcon'
  | 'peopleIcon'
  | 'selfImprovementIcon'
  | 'briefCaseIcon'
  | 'houseIcon'
  | '';

export default DeepDownItem;
