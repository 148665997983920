import Tracker from '@openreplay/tracker';

const tracker = new Tracker({
  projectKey: 'CGMAQknenb2wrWC1YhEQ',
  ingestPoint: 'https://analytics.hapstar.app/ingest',
  __DISABLE_SECURE_MODE: process.env.NODE_ENV === 'development',
});

Object.freeze(tracker);

export default tracker;
