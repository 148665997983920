import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getScore = async (questionaireId = 1, assesmentId = 0) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/assessment-average-score/${questionaireId}/${assesmentId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result = await response.json();

  return {
    data: result,
  };
};
