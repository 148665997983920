import useWindowDimensions from '@hooks/useWindowDimensions';
import React from 'react';
import styles from './index.module.scss';

const PageTitle: React.FunctionComponent<PageTitleProps> = (props) => {
  const { title, subtitle, isWizzard } = props;
  const { isMobile } = useWindowDimensions();

  return (
    <div className={styles.hsPageTitle}>
      <h2 style={{ textAlign: isMobile ? 'center' : undefined, fontSize: '1.9rem' }}>{title}</h2>
      {subtitle && <p style={{ width: isWizzard ? '65%' : '100%' }}>{subtitle}</p>}
    </div>
  );
};

PageTitle.defaultProps = {
  subtitle: undefined,
  isWizzard: false,
};

interface PageTitleProps {
  title: string;
  subtitle?: string | undefined;
  isWizzard?: boolean;
}

export default PageTitle;
