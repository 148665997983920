import React from 'react';
import { Chip } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

const DefaultChip: React.FunctionComponent<DefaultChipProps> = (props) => {
  const { label, className, theme } = props;

  return <Chip label={label} className={classNames(className, styles[theme])} />;
};

DefaultChip.defaultProps = {
  className: styles.chip,
};

interface DefaultChipProps {
  label: string;
  className?: string;
  theme: keyof typeof styles;
}

export default DefaultChip;
