import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExpertState {
  refetchSessions: boolean;
}

const initialState: ExpertState = {
  refetchSessions: false,
};

const expertSlice = createSlice({
  name: 'expertWizard',
  initialState,
  reducers: {
    updateRefetchSessionsState: (state, action: PayloadAction<boolean>) => ({
      ...state,
      refetchSessions: action.payload,
    }),
    resetExperts: () => initialState,
  },
});

export const expertActions = expertSlice.actions;

export default expertSlice;
