import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import LabelInput from '@components/Molecules/LabelInput';
import { isNullValidation, noValidation } from '@helpers/validation.helper';
import useInput, { getInputValue } from '@hooks/useInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';

import { uploadImage } from '@api/imageUploader';
import { configLearningTag, deleteTagById, getAllLearningTags } from '@api/learningTags';
import EditIcon from '@assets/images/icons/edit-blue.svg';
import DeleteIcon from '@assets/images/icons/remove-red.svg';
import Modal from '@components/Atoms/Modal';
import { LearningTagInterface } from '@interfaces/index';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import classNames from 'classnames';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#f4f2ec',
  },
}));

const DeleteCategoryModal: FunctionComponent<DeleteProps> = (props) => {
  const { isOpen, handleClose, title, bodyMessage, refetchCategories, catId } = props;

  const [error, setError] = useState('');
  const removeTag = async (id: number) => {
    const res = await deleteTagById(id);

    if (res.success) {
      await refetchCategories();
      toast.success('Successfully deleted the category');
      await handleClose();
    } else {
      setError(res.message || 'Error deleting the category');
      toast.error(res.message || 'Error deleting the category');
    }
  };

  return (
    <div className={styles.deleteModal}>
      <Modal open={isOpen} setOpen={handleClose} overlay onTapBackgroundClose>
        <h4 className={styles.title}>{title}</h4>
        <hr />
        <div className="container">
          <div>
            <h4 className={styles.bodyMessage}>{bodyMessage}</h4>

            {error && error?.length > 0 && (
              <p className={styles.error}>
                <span>Error: </span>
                {error}
              </p>
            )}
          </div>
        </div>
        <hr />

        <div className={styles.actionButtons}>
          <DefaultButton type="button" onClick={handleClose} className={styles.cancelBtn}>
            Cancel
          </DefaultButton>
          <DefaultButton
            type="submit"
            disabled={!catId || error?.length > 0}
            onClick={() => {
              if (catId) removeTag(catId);
            }}
            className={styles.confirmBtn}
          >
            Confirm
          </DefaultButton>
        </div>
      </Modal>
    </div>
  );
};

const ManageCategories: React.FunctionComponent<ManageCategoriesProps> = (props) => {
  const { onClose, refetchTags } = props;
  const [expanded, setExpanded] = useState(false);
  const [isEdit, setIsEdit] = useState<{ edit: boolean; tagId: number | null }>({
    edit: false,
    tagId: null,
  });
  const [tagCategories, setTagCategories] = useState<LearningTagInterface[] | []>([]);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [deleteCatOpen, setDeleteCatOpen] = useState<{ open: boolean; catId: number | null }>({
    open: false,
    catId: null,
  });

  const {
    value: categoryName,
    isValid: categoryNameIsValid,
    hasError: categoryNameHasError,
    valueChangeHandler: categoryNameChangeHandler,
    inputBlurHandler: categoryNameBlurHandler,
    reset: categoryNameReset,
  } = useInput(isNullValidation, getInputValue, '');

  const {
    value: thumbnailImageUrl,
    isValid: thumbnailImageUrlIsValid,
    hasError: thumbnailImageUrlHasError,
    valueChangeHandler: thumbnailImageUrlChangeHandler,
    inputBlurHandler: thumbnailImageUrlBlurHandler,
    reset: thumbnailImageUrlReset,
  } = useInput(isNullValidation, getInputValue, '');

  const {
    value: order,
    isValid: orderIsValid,
    hasError: orderHasError,
    valueChangeHandler: orderChangeHandler,
    inputBlurHandler: orderBlurHandler,
    reset: orderReset,
  } = useInput(isNullValidation, getInputValue, '');

  const {
    value: showInCarousel = 'true',
    isValid: showInCarouselIsValid,
    hasError: showInCarouselHasError,
    valueChangeHandler: showInCarouselChangeHandler,
    inputBlurHandler: showInCarouselBlurHandler,
    reset: showInCarouselReset,
  } = useInput(noValidation, getInputValue, '');

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    getTagCategories();
  }, []);

  const getTagCategories = async () => {
    try {
      setCategoryLoading(true);
      const res = await getAllLearningTags();
      setTagCategories(res);
      setCategoryLoading(false);
    } catch (err) {
      setTagCategories([]);
    } finally {
      setCategoryLoading(false);
    }
  };

  const handleAddCategory = async () => {
    if (categoryNameIsValid && thumbnailImageUrlIsValid && orderIsValid) {
      const payload = {
        id: isEdit && isEdit.tagId ? isEdit.tagId : null,
        tag: categoryName,
        thumbnailImageUrl,
        orderId: Number(order),
        displayInCarousel: Boolean(showInCarousel === 'true'),
      };
      const res = await configLearningTag(payload);

      if (res) {
        toast.success('Successfully saved');
        await getTagCategories();
        if (modalContentRef.current) {
          await modalContentRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }

      setIsEdit({ edit: false, tagId: null });
      await refetchTags();
      await resetFORM(true);
    }
  };

  const handleUpdateCategoryVisibility = async (
    data: { id: number; tag: string; thumbnailImageUrl: string; orderId: number },
    show: boolean,
  ) => {
    if (data) {
      const payload = {
        id: data.id,
        tag: data.tag,
        thumbnailImageUrl: data.thumbnailImageUrl,
        orderId: Number(data.orderId),
        displayInCarousel: show,
      };
      const res = await configLearningTag(payload);

      if (res) {
        await getTagCategories();
      }

      await resetFORM(false);
    }
  };

  const resetFORM = (close: boolean) => {
    categoryNameReset();
    thumbnailImageUrlReset();
    orderReset();
    showInCarouselReset();
    setExpanded(close);
    setIsEdit({ edit: false, tagId: null });
    showInCarouselChangeHandler({ target: { value: 'true' } });
    setFile(null);
    setUploading(false);
    setUploadStatus('Select image');
  };

  const renderEditData = async (data: {
    id: number;
    tag: string;
    thumbnailImageUrl: string;
    orderId: number;
    displayInCarousel: boolean;
  }) => {
    setIsEdit({ edit: true, tagId: data.id });
    await setExpanded(true);
    if (modalContentRef.current) {
      await modalContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    setFile(null);
    setUploading(false);
    setUploadStatus('Select image');

    categoryNameChangeHandler({ target: { value: data.tag } });
    thumbnailImageUrlChangeHandler({ target: { value: data.thumbnailImageUrl } });
    orderChangeHandler({ target: { value: data.orderId.toString() } });
    showInCarouselChangeHandler({ target: { value: data.displayInCarousel ? 'true' : 'false' } });
  };

  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('Select image');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload');

      return;
    }

    setUploading(true);
    setUploadStatus('Uploading...');

    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('bucketPath', 'learning');
      formData.append('bucketSubPath', 'categories');

      const response = await uploadImage(formData);

      if (response && response?.imageUrl) {
        setUploadStatus('Upload successful');
        thumbnailImageUrlChangeHandler({ target: { value: response?.imageUrl } });
      } else {
        setUploadStatus('Upload failed');
      }
    } catch (error) {
      setUploadStatus('Upload failed');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className={styles.container} ref={modalContentRef}>
      {deleteCatOpen.open && deleteCatOpen.catId && (
        <DeleteCategoryModal
          isOpen={deleteCatOpen.open}
          handleClose={() => setDeleteCatOpen({ open: false, catId: null })}
          title="Delete category"
          bodyMessage="Are you sure you want to delete this category?"
          refetchCategories={getTagCategories}
          catId={deleteCatOpen.catId}
        />
      )}

      <DefaultButton
        color="primary"
        type="button"
        className={styles.btnClose}
        onClick={() => {
          onClose();
          resetFORM(true);
        }}
      >
        <div className={styles.btnCloseLabel}>Close</div>
        <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
      </DefaultButton>
      <h2 className={styles.modalTitle}>Manage categories</h2>

      <Accordion expanded={expanded} onChange={handleExpansion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {isEdit.edit ? 'Edit category' : 'Add category'}
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.inputHolder}>
            <LabelInput
              id="categoryName"
              label="Category name *"
              type="text"
              name="categoryName"
              placeholder="Enter category name"
              onChange={categoryNameChangeHandler}
              onBlur={categoryNameBlurHandler}
              value={categoryName || ''}
              isError={categoryNameHasError}
            />
            {categoryNameHasError && <p className={styles.hsError}>Required</p>}
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div>
                <LabelInput
                  id="thumbnailImageUrl"
                  label="Thumbnail image url *"
                  type="text"
                  name="thumbnailImageUrl"
                  placeholder="Enter image url"
                  onChange={thumbnailImageUrlChangeHandler}
                  onBlur={thumbnailImageUrlBlurHandler}
                  value={thumbnailImageUrl || ''}
                  isError={thumbnailImageUrlHasError}
                />
                {thumbnailImageUrlHasError && <p className={styles.hsError}>Required</p>}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={styles.uploadImgContainer}>
            <Grid item xs={12} md={4}>
              <Stack direction="column" spacing={1} className={styles.imageUploadContainer}>
                <input type="file" accept="image/*" onChange={handleFileChange} />
                <DefaultButton
                  onClick={handleUpload}
                  disabled={!file || uploading}
                  variant="secondary"
                >
                  Upload
                </DefaultButton>

                {uploadStatus && <p>{uploadStatus}</p>}
              </Stack>
            </Grid>
            <Grid item xs={12} md={8}>
              <div className={styles.imagePreviewContainer}>
                {thumbnailImageUrl ? (
                  <Img src={thumbnailImageUrl} alt="thumbnail" className={styles.image} />
                ) : (
                  <>
                    <p>Image preview</p>
                  </>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LabelInput
                id="order"
                label="Order"
                type="number"
                name="order"
                placeholder="Order No."
                onChange={orderChangeHandler}
                onBlur={orderBlurHandler}
                value={order || ''}
                isError={orderHasError}
              />
              {orderHasError && <p className={styles.hsError}>Required</p>}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  className={styles.showCarouselTitle}
                >
                  Show in carousel
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={showInCarouselChangeHandler}
                  defaultChecked={showInCarousel}
                  defaultValue={showInCarousel}
                  value={showInCarousel}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <div className={styles.addBtn}>
            {isEdit.edit && (
              <DefaultButton
                variant="secondary"
                onClick={() => {
                  resetFORM(false);
                  setIsEdit({ edit: false, tagId: null });
                }}
              >
                Reset form
              </DefaultButton>
            )}
            <DefaultButton
              disabled={!categoryNameIsValid || !thumbnailImageUrlIsValid || !orderIsValid}
              onClick={handleAddCategory}
            >
              {isEdit.edit ? 'Update category' : 'Add category'}
            </DefaultButton>
          </div>
        </AccordionDetails>
      </Accordion>

      <div className={styles.categoryList}>
        <h5 className={styles.categoryListTitle}>Category list</h5>
        <TableContainer>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableCell}>#</TableCell>
                <TableCell align="left" className={styles.tableCell}>
                  Image
                </TableCell>
                <TableCell align="left" className={styles.tableCell}>
                  Name
                </TableCell>
                <TableCell align="left" className={styles.tableCell}>
                  Order
                </TableCell>
                <TableCell align="left" className={styles.tableCell}>
                  Carousel
                </TableCell>
                <TableCell align="left" className={styles.tableCell}>
                  Options
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoryLoading ? (
                <>
                  {[0, 1, 2, 3, 4, 5].map((index) => (
                    <CustomTableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {[0, 1, 2, 3, 4, 5].map((i) => (
                        <TableCell component="th" scope="row" key={i}>
                          <Skeleton variant="text" height={25} />
                        </TableCell>
                      ))}
                    </CustomTableRow>
                  ))}
                </>
              ) : (
                <>
                  {tagCategories.map((row) => (
                    <CustomTableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">
                        <Img
                          src={row.thumbnailImageUrl}
                          alt="thumbnail"
                          className={styles.thumbnailImage}
                        />
                      </TableCell>
                      <TableCell align="left">{row.tag}</TableCell>
                      <TableCell align="left">{row.orderId}</TableCell>
                      <TableCell align="left">
                        {row.displayInCarousel ? (
                          <VisibilityIcon
                            color="success"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleUpdateCategoryVisibility(row, false)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            color="error"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleUpdateCategoryVisibility(row, true)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row" spacing={2}>
                          <div onClick={() => renderEditData(row)}>
                            <Img src={EditIcon} alt="Edit" className={styles.optIcon} />
                          </div>
                          <div
                            onClick={() => {
                              if (modalContentRef.current) {
                                modalContentRef.current.scrollIntoView({ behavior: 'smooth' });
                              }

                              setDeleteCatOpen({ open: true, catId: row.id });
                            }}
                          >
                            <Img src={DeleteIcon} alt="Delete" className={styles.optIcon} />
                          </div>
                        </Stack>
                      </TableCell>
                    </CustomTableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {tagCategories.length === 0 && !categoryLoading && (
          <div className={styles.noData}>
            <p>No categories found</p>
            {!expanded && (
              <DefaultButton
                onClick={() => {
                  if (modalContentRef.current) {
                    modalContentRef.current.scrollIntoView({ behavior: 'smooth' });
                  }

                  setExpanded(true);
                }}
              >
                Add
              </DefaultButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ManageCategories.defaultProps = {};

interface DeleteProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  bodyMessage: string;
  refetchCategories: () => void;
  catId: number | null;
}

interface ManageCategoriesProps {
  onClose: () => void;
  refetchTags: () => void;
}

export default ManageCategories;
