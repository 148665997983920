import { Popper, PopperProps, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

export const CustomAutocompletePopper = (
  props: JSX.IntrinsicAttributes & PopperProps & React.RefAttributes<HTMLDivElement>,
) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return <Popper {...props} placement={matches ? 'bottom' : 'top-start'} sx={{ zIndex: 999999 }} />;
};

export const CustomPopper = styled(Popper)({
  zIndex: 999999,
});

export const AutocompleteGroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  color: 'white',
  backgroundColor: '#3fb9fc',
  fontWeight: 600,
}));

export const AutocompleteGroupItems = styled('ul')({
  padding: 0,
});

export const FLIP_POPPER_MODS = {
  popper: {
    modifiers: [
      {
        name: 'flip',
        options: {
          rootBoundary: 'document',
        },
      },
    ],
  },
};
