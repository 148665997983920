import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormButtonNew from '@components/FormButtonNew/FormButtonNew';
import { updateUserTerms } from '@api/users';
import { toast } from 'react-toastify';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
import Card from '@components/Atoms/Card';
import LabelCheckbox from '@components/Molecules/LabelCheckbox';
import { LabelPositions } from '@enums/LabelPositions';
import LinkText from '@components/Atoms/Link';
import { userStatusActions } from 'store/Slices/UserStatusSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import styles from './index.module.scss';

const WelcomePage: React.FunctionComponent = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleChangeTermsAccepted = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleAgreeToTermsUser = async () => {
    const currentUser = localStorage.getItem('user') as any;
    const currentUserObject = JSON.parse(currentUser);
    const currentUserId = currentUserObject.id;

    const response = await updateUserTerms(currentUserId, {
      isAgree: termsAccepted,
    });

    if (response.ok) {
      dispatch(
        userStatusActions.updateUserStatus({
          isAgreed: termsAccepted,
        }),
      );
      currentUserObject.isAgree = true;
      localStorage.removeItem('user');
      localStorage.setItem('user', JSON.stringify(currentUserObject));
      history.push('/introduction');
    } else {
      toast('Agree to terms and condition unsuccessful. Please try again!');
    }
  };

  return (
    <div className={styles.hsWelcomePageContainer}>
      <AuthenticationLayout title="Welcome">
        <div className={styles.hsWelcomePageSubContainer}>
          <Card>
            <div className={styles.hsWelcomeCardContainer}>
              <h1>Welcome to Hapstar</h1>
              <div>
                <label htmlFor="terms" className={styles.terms}>
                  <div>
                    <LabelCheckbox
                      id="welcomeTermsAndConditions"
                      name="welcomeTermsAndConditions"
                      onChange={handleChangeTermsAccepted}
                      checked={termsAccepted}
                      labelPosition={LabelPositions.right}
                      label="I agree to the Hapstar"
                    />
                  </div>
                  <div>
                    <LinkText
                      path="https://hapstar.app/terms/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & Conditions
                    </LinkText>{' '}
                  </div>
                </label>
              </div>

              <div className={styles.button}>
                <FormButtonNew
                  onClick={handleAgreeToTermsUser}
                  color="primary"
                  disabled={!termsAccepted}
                >
                  Continue
                </FormButtonNew>
              </div>
            </div>
          </Card>
        </div>
      </AuthenticationLayout>
    </div>
  );
};

export default WelcomePage;
