import { useEffect, useState } from 'react';
import useQuery from './useQuery';

export default function useGoNative() {
  const searchParams = useQuery();
  const checkIsMobileParam = searchParams.get('isMobileApp') === 'true';
  const [isMobileApp, setIsMobileApp] = useState(checkIsMobileParam);

  useEffect(() => {
    if (navigator.userAgent.indexOf('gonative') > -1) {
      setIsMobileApp(true);
    }
  }, []);

  return isMobileApp;
}
