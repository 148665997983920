import {
  getCompareScoreWithHighest,
  getCompareScoreWithLowest,
  getCompareScoreWithPrevious,
} from '@api/assessment';
import { COMPARE_DATA, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import React, { useContext, useState } from 'react';
import styles from './index.module.scss';

const ScoreFilter: React.FunctionComponent = () => {
  const [comparison, setComparison] = useState('none');
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [_, dispatch] = useGlobalState();

  const handleCompareChange = async (newValue: string) => {
    setComparison(newValue);
    if (newValue === 'highest') {
      const data = await getCompareScoreWithHighest(activeSubNav.id);
      dispatch({ type: COMPARE_DATA, payload: data });
    } else if (newValue === 'lowest') {
      const data = await getCompareScoreWithLowest(activeSubNav.id);
      dispatch({ type: COMPARE_DATA, payload: data });
    } else if (newValue === 'previous') {
      const data = await getCompareScoreWithPrevious(activeSubNav.id);
      dispatch({ type: COMPARE_DATA, payload: data });
    } else {
      dispatch({ type: COMPARE_DATA, payload: [] });
    }
  };

  const availableCompares = [
    { id: 0, name: 'None', value: 'none', checked: comparison === 'none' },
    {
      id: 1,
      name: 'Previous',
      value: 'previous',
      checked: comparison === 'previous',
    },
    {
      id: 2,
      name: 'Highest',
      value: 'highest',
      checked: comparison === 'highest',
    },
    {
      id: 3,
      name: 'Lowest',
      value: 'lowest',
      checked: comparison === 'lowest',
    },
  ];

  return (
    <div className={styles.hsScoreFilter}>
      <p>&nbsp;</p>
      <div>
        <span>Compare with:</span>
        <ul>
          {availableCompares.map((compare) => (
            <li key={compare.id}>
              <input
                type="radio"
                id={compare.value}
                name="filter"
                value={compare.value}
                checked={compare.checked}
                onChange={() => handleCompareChange(compare.value)}
              />
              <label htmlFor={compare.value}>{compare.name}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ScoreFilter;
