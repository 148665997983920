import DefaultButton from '@components/Atoms/DefaultButton';
import React, { useEffect, useState } from 'react';
import { updateImprovePlan } from '@api/actionPlan';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import ShortStarModal from '@components/Organisms/ShortStarModal';
import styles from './index.module.scss';

const CompletedPlansSection: React.FunctionComponent<CompletedPlansSectionProps> = (props) => {
  const { loading, planName, activeImprovePlanCount, completed, planId, refetchPlans } = props;
  const [markAsCompleted, setMarkAsCompleted] = useState(false);
  const [shortStarModalOpen, setShortStarModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (completed) {
      setMarkAsCompleted(true);
    }
  }, [completed]);

  const updatePlan = async () => {
    try {
      setMarkAsCompleted(true);
      const req = await updateImprovePlan({ planId, done: true });
      toast.success('Plan completed');
      // refetchPlans();
    } catch (err) {
      setMarkAsCompleted(false);
      // handle err
      if (err instanceof Error) {
        toast.error(err.message || `Error. Try again later`);
      } else {
        toast.error(`Error. Try again later`);
      }
    }
  };

  return (
    <>
      {shortStarModalOpen &&
        ReactDOM.createPortal(
          <ShortStarModal modalOpen={shortStarModalOpen} onClose={refetchPlans} />,
          document.getElementById('short-star-modal')!,
        )}
      <div className={styles.card}>
        <div className={styles.body}>
          <div className={styles.confetti}>
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
            <div className={styles.confettiPiece} />
          </div>
          {!markAsCompleted ? (
            <div className={styles.texts}>
              <p>
                Hey there, congratulations!
                <br />
                You&apos;ve really nailed your {planName} action plan. Take a moment to savor this
                awesome achievement and think about all the hard work you&apos;ve put into it.
                It&apos;s important we acknowledge our efforts because it helps us see how far
                we&apos;ve come and keeps us motivated for what&apos;s next. Well done!
              </p>

              <span>Mark this plan as complete</span>

              <div className={styles.button}>
                <DefaultButton variant="primary" onClick={updatePlan}>
                  MARK PLAN AS COMPLETE
                </DefaultButton>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.texts2}>
                <p>
                  Hey there, congratulations!
                  <br />
                  You&apos;ve really nailed your {planName} action plan. Take a moment to savor this
                  awesome achievement and think about all the hard work you&apos;ve put into it.
                  It&apos;s important we acknowledge our efforts because it helps us see how far
                  we&apos;ve come and keeps us motivated for what&apos;s next. Well done!
                </p>
                <div className={styles.checkBoxWrapper}>
                  {' '}
                  <svg
                    className={styles.checkmark}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    {' '}
                    <circle
                      className={styles.checkmarkCircle}
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />{' '}
                    <path
                      className={styles.checkmarkCheck}
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
                <span>
                  Your physical plan is now marked as completed. You can always access it on
                  Completed Plans section at the top.
                </span>
                {activeImprovePlanCount > 0 ? (
                  <span>Keep your momentum going! Switch to your next plan.</span>
                ) : (
                  <span>
                    The possibilities are endless! What will you achieve next in your wellness
                    journey?
                  </span>
                )}

                <div className={styles.checkInButton}>
                  <DefaultButton variant="primary" onClick={() => setShortStarModalOpen(true)}>
                    CHECK IN AGAIN
                  </DefaultButton>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

CompletedPlansSection.defaultProps = {
  loading: false,
};

type CompletedPlansSectionProps = {
  loading?: boolean;
  planName: string;
  activeImprovePlanCount: number;
  completed: boolean;
  planId: number;
  refetchPlans: () => void;
};

export default CompletedPlansSection;
