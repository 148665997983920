import React, { FunctionComponent, useState } from 'react';
import { InstantHelpAction } from 'custom';
import DefaultButton from '@components/Atoms/DefaultButton';
import useWindowDimensions from '@hooks/useWindowDimensions';
import Modal from '@components/Atoms/Modal';
import styles from './index.module.scss';

const InstantRedirectActionButton: FunctionComponent<InstantRedirectActionButtonProps> = (
  props,
) => {
  const { action, className, iconPosition, handleClick } = props;
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const { isDesktop } = useWindowDimensions();

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleClick(e);
    setPopupOpen(action.type === 'phone' || action.type === 'text');

    if ((action.type !== 'phone' && action.type !== 'text') || !isDesktop) {
      window.open(action.value, '_blank');
    }
  };

  return (
    <div>
      {isDesktop && (
        <Modal open={popupOpen} setOpen={setPopupOpen} overlay onTapBackgroundClose>
          <div className={styles.modalContainer}>
            <h3>{action.label}</h3>
            <p>
              {action.type === 'phone' && (
                <>
                  Call us now on <span>{action.value.split(':')[1]?.trim() || ''}</span>
                </>
              )}
              {action.type === 'text' && (
                <>
                  Text us now on <span>{action.value.split(':')[1]?.trim() || ''}</span>
                </>
              )}
            </p>

            <div className={styles.btnActionContainer} onClick={() => setPopupOpen(false)}>
              <div className={styles.btnAction}>Done</div>
            </div>
          </div>
        </Modal>
      )}

      <div key={action.id} className={className}>
        <DefaultButton
          iconSrc={action.icon}
          iconPosition={iconPosition}
          className={styles.actionButton}
          onClick={handleButtonClick}
        >
          {action.label}
        </DefaultButton>
      </div>
    </div>
  );
};

InstantRedirectActionButton.defaultProps = {
  className: styles.actionBtnContainer,
  iconPosition: 'left',
};

interface InstantRedirectActionButtonProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  action: InstantHelpAction;
  className?: string;
  icon: string;
  iconPosition?: 'left' | 'right';
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default InstantRedirectActionButton;
