import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import DefaultSwiper from '@components/Molecules/DefaultSwiper';
import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { formatDateToDateMonthYear, newFormatDate } from '@utils/date.util';
import styles from './index.module.scss';
import iosBack from '../../../../assets/images/ios-back.svg';
import iosNext from '../../../../assets/images/ios-next.svg';

const ReflectionSection: React.FunctionComponent<ReflectionSectionProps> = ({
  loading,
  questionMarkIcon,
  quoteIcon,
  userReflections,
  handleOpen,
  handleStarModal,
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const handleToggleText = (ref: UserReflection) => {
    setShowFullText(!showFullText);
    handleOpen({
      open: true,
      top: '150px',
      right: '10%',
      firstDescription: ref.reflection,
      description: '',
      title: `Your reflection on ${newFormatDate(ref.createdAt)}`,
    });
  };

  const truncateText = (text: string | any[], maxLength: number) => {
    if (text.length <= maxLength) return text;

    return `${text.slice(0, maxLength)}...`;
  };

  const handleNext = () => {
    if (Array.isArray(userReflections) && startIndex < userReflections.length - 1) {
      setStartIndex(startIndex + 1);
    }
  };

  const handleBack = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  return (
    <div className={styles.reflectionContainer}>
      <div className={styles.bodyContainer}>
        <div>
          <div className={styles.top}>
            {loading ? (
              <>
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="10%" height={40} />
              </>
            ) : (
              <>
                <h5 className={`${userReflections?.length === 0 && styles.titleLength}`}>
                  Your reflection
                </h5>
                <div className={styles.navigationButtons}>
                  {!loading && userReflections?.length > 0 && (
                    <>
                      <DefaultButton
                        onClick={handleBack}
                        disabled={startIndex === 0}
                        className={styles.navigationBtn}
                      >
                        <Img src={iosBack} alt="Back" className={styles.navigationIcon} />
                      </DefaultButton>
                      <DefaultButton
                        onClick={handleNext}
                        disabled={startIndex + 1 >= userReflections?.length}
                        className={styles.navigationBtn}
                      >
                        <Img src={iosNext} alt="Next" className={styles.navigationIcon} />
                      </DefaultButton>
                    </>
                  )}
                </div>
                <div
                  className={styles.questionmark}
                  onClick={() => {
                    handleOpen({
                      open: true,
                      top: '150px',
                      right: '10%',
                      firstDescription:
                        'Journal your thoughts to keep track of what impacts your mood. This works like magic for your wellbeing and gives you perspective over time.',
                      description: '',
                      title: 'Reflections',
                    });
                  }}
                >
                  <Img
                    src={questionMarkIcon}
                    alt="question mark icon"
                    className={styles.queMarkicon}
                  />
                </div>
              </>
            )}
          </div>

          {!loading && userReflections?.length === 0 && (
            <div>
              <div className={styles.body}>
                <div className={styles.reflectionBody}>
                  <p>
                    Just writing down your thoughts or feelings can significantly improve your
                    well-being, offering a sense of release and clarity. This simple act of
                    expression helps organize your thoughts, making it easier to navigate challenges
                    and find peace of mind.
                  </p>
                </div>
              </div>

              <div className={styles.buttons2}>
                <div className={styles.footerBtns}>
                  <DefaultButton
                    variant="primary"
                    className={styles.hsPrimaryBtn}
                    onClick={() => handleStarModal(true)}
                  >
                    Reflect
                  </DefaultButton>
                </div>
              </div>
            </div>
          )}

          <div className={styles.body}>
            {loading ? (
              <div className={styles.loadSkeletonText}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
              </div>
            ) : (
              <DefaultSwiper modes={[]}>
                {userReflections?.slice(startIndex, startIndex + 3)?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={styles.dateContainer}>
                      <h5>{formatDateToDateMonthYear(item.createdAt)}</h5>
                    </div>
                    <div className={styles.reflectionBody}>
                      <Img src={quoteIcon} alt="Quote Left" className={styles.quoteLeft} />
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {truncateText(item.reflection, 270)}
                        {item.reflection.length > 270 && (
                          <span
                            onClick={() => handleToggleText(item)}
                            className={styles.readMoreBtn}
                          >
                            &nbsp;&nbsp;Read more
                          </span>
                        )}
                      </p>
                      <Img src={quoteIcon} alt="Quote Right" className={styles.quoteRight} />
                    </div>
                  </SwiperSlide>
                ))}
              </DefaultSwiper>
            )}
          </div>
        </div>

        <div className={styles.buttons}>
          {loading ? (
            <>
              <Skeleton variant="rounded" width="20%" className={styles.loadBtn} />
              <Skeleton variant="text" width="50%" />
            </>
          ) : (
            <>
              {!loading && userReflections?.length > 0 && (
                <div className={styles.footerBtns}>
                  <DefaultButton
                    variant="primary"
                    className={styles.hsPrimaryBtn}
                    onClick={() => handleStarModal(true)}
                  >
                    Reflect
                  </DefaultButton>
                  {/* <div className={styles.historyBtn}>view history</div> */}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ReflectionSection.defaultProps = {
  loading: false,
};

interface ReflectionSectionProps {
  loading?: boolean;
  questionMarkIcon: string;
  quoteIcon: string;
  userReflections: UserReflection[];
  handleOpen: (arg1: any) => void;
  handleStarModal: (arg1: boolean) => void;
}

interface UserReflection {
  id: number;
  assessmentId: number;
  reflection: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
}

export default ReflectionSection;
