import React, { FunctionComponent, useState } from 'react';
import SelectComponent from '@components/Atoms/SelectComponent';
import { MultiValue, SingleValue } from 'react-select';
import { SelectableOptionType } from 'custom';
import Tooltip from '@components/Tooltip';
import styles from './index.module.scss';

const YearMonthSelect: FunctionComponent<YearMonthSelectProps> = (props) => {
  const {
    startYear,
    endYear,
    label,
    onMonthChange,
    onYearChange,
    isError,
    isSearchable,
    isClearable,
    isLoading,
    isDisabled,
    onMonthBlur,
    onYearBlur,
    tip,
  } = props;

  const arrayRange = (start: number, stop: number, step: number) =>
    Array.from({ length: (start - stop) / step + 1 }, (value, index) => start - index * step);

  const yearRange = arrayRange(endYear, startYear, 1);

  const years = yearRange.map((year) => ({ value: year.toString(), label: year.toString() }));

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  return (
    <div className={styles.selectContainer}>
      <div className={styles.labelContainer}>
        <label id="YearMonthSelect" htmlFor="YearMonthSelect">
          {label}
        </label>
        {tip && tip.value && (
          <div className={styles.tooltipContainer}>
            <p>{tip.label}</p>
            <Tooltip tip={tip.value} />
          </div>
        )}
      </div>
      <div className={styles.selectGroup}>
        <div className={styles.select}>
          <SelectComponent
            id="Month"
            name="Month"
            placeholder="Month"
            isError={isError}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            onChange={onMonthChange}
            onBlur={onMonthBlur}
            options={months}
          />
        </div>
        <div className={styles.select}>
          <SelectComponent
            id="Year"
            name="Year"
            placeholder="Year"
            isError={isError}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            onChange={onYearChange}
            onBlur={onYearBlur}
            options={years}
          />
        </div>
      </div>
    </div>
  );
};

YearMonthSelect.displayName = 'YearMonthSelect';

YearMonthSelect.defaultProps = {
  isSearchable: true,
  isClearable: true,
  isLoading: false,
  isDisabled: false,
  isError: false,
  onMonthBlur: undefined,
  onYearBlur: undefined,
  tip: undefined,
};

interface YearMonthSelectProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  startYear: number;
  endYear: number;
  label: string;
  onMonthChange: (
    value: MultiValue<SelectableOptionType> | SingleValue<SelectableOptionType>,
  ) => void;
  onYearChange: (
    value: MultiValue<SelectableOptionType> | SingleValue<SelectableOptionType>,
  ) => void;
  isError?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onMonthBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onYearBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  tip?: { value: string; label?: string };
}

export default YearMonthSelect;
