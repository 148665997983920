import { ShortStarQuestionsInterface } from 'custom.d';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getShortStarQuestionsData = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/short-questionnaires`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch short star data: ${response.statusText}`);
  }

  const result: ShortStarQuestionsInterface[] = await response.json();

  return result;
};

export const saveQuestions = async (
  data: { questionId: number; score: number }[],
  questionnaireId = 1,
) => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/save-short-questionnaire-responses/${questionnaireId}`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );

  if (result.ok) {
    const responseData = await result.json();

    return { success: true, data: responseData };
  }

  const errorData = await result.json();

  return { success: false, error: errorData };
};

export const saveReflection = async (data: { assessmentId: number; reflection: string }) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/reflections`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });

  if (result.ok) {
    const responseData = await result.json();

    return { success: true, data: responseData };
  }

  const errorData = await result.json();

  return { success: false, error: errorData };
};

export const getShortStarAssessmentScores = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/short-assessment-scores`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch short star assessment scores: ${response.statusText}`);
  }

  const result: any = await response.json();

  return result;
};

export const getShortStarAssessmentScoreByQuestionId = async (questionId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/short-question-score/${questionId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    throw new Error(
      `Failed to fetch short star assessment score by question id: ${response.statusText}`,
    );
  }

  const result: any = await response.json();

  return result;
};

export const getShortStarScores = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/short-question-latest-scores`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch short star score data: ${response}`);
  }

  const result: ScoreInterface[] = await response.json();

  return result;
};

interface ScoreInterface {
  questionId: number;
  questionText: string;
  colorTheme: string;
  latestAssessmentScore: number;
  icon: string;
}
