import Card from '@components/Atoms/Card';
import { Grid, Skeleton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getBoostersAndDrainers } from '@api/EmployerNew';
import styles from './index.module.scss';

const BoostersAndDrainersSection: React.FunctionComponent<BoostersAndDrainersSectionProps> = ({
  loading,
  filters,
  isFiltered,
}) => {
  const [boostersAndDrainers, setBoostersAndDrainers] = useState<BoostersAndDrainersInterfaceData>({
    topBoosters: [],
    topDrainers: [],
    topCareerBoosters: [],
    topCareerDrainers: [],
  });
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    const updatedFilters = { ...filters };
    if (initialLoad) {
      updatedFilters.active = true;
      setInitialLoad(false);
    }

    getBoostersAndDrainersData(updatedFilters);
  }, [isFiltered]);

  const getBoostersAndDrainersData = async (modFilters = filters) => {
    try {
      setDataLoading(true);
      const res = await getBoostersAndDrainers(modFilters);
      setBoostersAndDrainers(res);
    } catch (err) {
      setBoostersAndDrainers({
        topBoosters: [],
        topDrainers: [],
        topCareerBoosters: [],
        topCareerDrainers: [],
      });
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top boosters</h6>
            <p className={styles.subDescription}>Your people feel good about</p>
            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : boostersAndDrainers.topBoosters.length > 0 ? (
              <div className={styles.boostersList}>
                {boostersAndDrainers.topBoosters.map((item, index) => (
                  <p key={index}>{item.name}</p>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No boosters</div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top drainers</h6>
            <p className={styles.subDescription}>Your people don’t feel good about</p>

            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : boostersAndDrainers.topDrainers.length > 0 ? (
              <div className={styles.drainersList}>
                {boostersAndDrainers.topDrainers.map((item, index) => (
                  <p key={index}>{item.name}</p>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No drainers</div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top career boosters</h6>
            <p className={styles.subDescription}>Your people feel good about their career</p>

            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : boostersAndDrainers.topCareerBoosters.length > 0 ? (
              <div className={styles.careerBoostersList}>
                {boostersAndDrainers.topCareerBoosters.map((item, index) => (
                  <p key={index}>{item.name}</p>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No boosters</div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card className={styles.cardContainer}>
            <h6 className={styles.subTitle}>Top career drainers</h6>
            <p className={styles.subDescription}>Your people don’t feel good about their career</p>

            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={3} sx={{ marginTop: 5 }}>
                {[0, 1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rounded"
                    className={styles.loadingItem}
                    key={item}
                    width="100%"
                    height={44}
                  />
                ))}
              </Stack>
            ) : boostersAndDrainers.topCareerDrainers.length > 0 ? (
              <div className={styles.careerDrainersList}>
                {boostersAndDrainers.topCareerDrainers.map((item, index) => (
                  <p key={index}>{item.name}</p>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>No drainers</div>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

BoostersAndDrainersSection.defaultProps = {
  loading: false,
};

interface BoostersAndDrainersSectionProps {
  loading?: boolean;
  filters: any;
  isFiltered: boolean;
}

interface Booster {
  questionId: number;
  name: string;
  score: number;
}

interface BoostersAndDrainersInterfaceData {
  topBoosters: Booster[];
  topDrainers: Booster[];
  topCareerBoosters: Booster[];
  topCareerDrainers: Booster[];
}

export default BoostersAndDrainersSection;
