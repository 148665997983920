import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const ButtonNew: React.FunctionComponent<ButtonNewProps> = (props) => {
  const { children, type = 'button', color, ...rest } = props;

  const classes = classNames([
    styles.hsButton,
    color === 'primary' && styles.hsPrimary,
    color === 'secondary' && styles.hsSecondary,
    color === 'tertiary' && styles.hsTertiary,
    color === 'icon' && styles.hsIconButton,
    color === 'more' && styles.hsShowMoreButton,
    color === 'small' && styles.hsSmall,
    color === 'small_text' && styles.hsSmallText,
    color === 'close' && styles.hsClose,
  ]);

  return (
    <button className={classes} type={type} {...rest}>
      {children}
    </button>
  );
};

interface ButtonNewProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: string;
  type?: 'button' | 'reset' | 'submit' | undefined;
  color: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'more' | 'small' | 'small_text' | 'close';
}

ButtonNew.defaultProps = {
  type: 'button',
};

export default ButtonNew;
