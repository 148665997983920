import { saveAssessment } from '@api/assessment';
import { getIpAddress } from '@api/auth/login';
import { getInstantHelpLimits, logInstantHelpStarDetails } from '@api/instantHelp';
import DefaultButton from '@components/Atoms/DefaultButton';
import { CreateStarContext } from '@context/CreateStarContext';
import { SET_WIZZARD_STEP, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import { CreateStarCategoryInterface } from '@interfaces/index';
import { HelpLimitData, ImprovePlanTypes, LogTypes } from 'custom.d';
import React, { useContext, useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useHistory } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import { RootState } from 'store';
import { updateHelpAvailability } from 'store/Actions/HelpAndSpeakupAction';
// import mixpanel from 'mixpanel-browser';
import { completePilar, createImprovePlan, updateActionsByImprovePlanId } from '@api/actionPlan';
import { addAdvisorFocusAreas } from '@api/focusArea';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const StepWizardFooter: React.FunctionComponent<StepWizardFooterInterface> = ({
  handleAssessmentData,
  assesmentData,
  skipToAnyStep,
  handleStarSkip,
  customButtonName,
}) => {
  const isMobileApp = useGoNative();
  const reduxDispatch = useAppDispatch();
  const helpConfig = useAppSelector((state: RootState) => state.helpConfig.data);
  const companyConfig = useAppSelector((state: RootState) => state.companyConfig.data);
  const deepDive = useAppSelector((state: RootState) => state.deepDiveWizard);
  const { isHelpAvailable } = helpConfig;
  const { showSpeakUp } = companyConfig;
  const history = useHistory();
  const { categoryQuestion, setCategoryQuestion } = useContext(CreateStarContext);
  const [state, dispatch] = useGlobalState();
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const { previousStep, nextStep, isFirstStep, isLastStep, goToStep, activeStep, handleStep } =
    useWizard();
  const [data, setData] = useState<CreateStarCategoryInterface[]>([]);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [idsQuestionaires, setIdsquestionaires] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const [questionLimits, setQuestionLimits] = useState<HelpLimitData[]>([]);
  const { collapseSidebar, collapsed } = useProSidebar();
  const [failsQuestions, setFailsQuestions] = useState<FailsQuestionsInterface[]>([]);
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);
  const [completingPilar, setCompletingPilar] = useState<boolean>(false);

  useEffect(() => {
    getInstantHelpLimits().then((response) => {
      setQuestionLimits(response);
    });
  }, []);

  useEffect(() => {
    if (skipToAnyStep > 0) {
      goToStep(skipToAnyStep);
      handleStarSkip(0);
    }
  }, [skipToAnyStep]);

  useEffect(() => {
    if (isHelpAvailable && assesmentData.assessmentId > 0 && activeStep === 8) {
      logStarInstantHelp();
      setFailsQuestions([]);
    }
  }, [assesmentData, activeStep, isHelpAvailable]);

  useEffect(() => {
    setData(categoryQuestion);
    let selectedFocusAreas = 0;
    categoryQuestion.forEach((category) => {
      selectedFocusAreas += category.questions.filter((qs) => qs.isSelected).length;
    });
    setSelectedCount(selectedFocusAreas);
  }, [categoryQuestion]);

  useEffect(() => {
    const ids = categoryQuestion.flatMap((category) =>
      category.questions.filter((i) => i.isSelected).flatMap((o) => o.id),
    );
    setIdsquestionaires(ids);
    setQuestions(categoryQuestion);
  }, [categoryQuestion]);

  const isChangeRequested = () => {
    const changeRequested = localStorage.getItem('changeRequested') || 'false';

    return changeRequested === 'true';
  };

  function checkScoreFailsLimit() {
    const resultedQues: { question_id: number; limit: number }[] = [];

    questionLimits.forEach((limitItem) => {
      const questionItem = data.find((item) =>
        item.questions.some((q) => q.id === limitItem.question_id),
      );

      if (questionItem) {
        questionItem.questions.forEach((question) => {
          if (question.id === limitItem.question_id) {
            if (question.score <= limitItem.limit && question.score !== 0) {
              resultedQues.push({
                question_id: question.id,
                limit: limitItem.limit,
              });
            }
          }
        });
      }
    });

    const checkValidity = resultedQues?.length > 0;
    setFailsQuestions(resultedQues);
    reduxDispatch(updateHelpAvailability(checkValidity));
  }

  const logStarInstantHelp = async () => {
    const updatedQuestions = failsQuestions.map((failQue) => ({
      ...failQue,
      assessment_id: assesmentData.assessmentId,
    }));

    const payload = {
      logType:
        isHelpAvailable && showSpeakUp
          ? LogTypes.SPEAKUP_DISPLAYED
          : LogTypes.INSTANT_HELP_DISPLAYED,
      ipAddress: await getIpAddress(),
      isNative: isMobileApp,
      os: navigator.userAgentData?.platform || '',
    };

    if (isHelpAvailable && showSpeakUp) {
      await logInstantHelpStarDetails(
        LogTypes.SPEAKUP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
      );

      await logInstantHelpStarDetails(
        LogTypes.INSTANT_HELP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        updatedQuestions,
      );
    } else {
      await logInstantHelpStarDetails(
        payload.logType,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        updatedQuestions,
      );
    }
  };

  const handleNext = async () => {
    checkScoreFailsLimit();
    collapseSidebar(true);
    if (!isLastStep) {
      if (isChangeRequested()) {
        localStorage.removeItem('changeRequested');
        goToStep(7);
      } else {
        if (activeStep === 6) {
          const response = await saveAssessment(data, activeSubNav.id);
          if (isHelpAvailable && response.data) {
            await handleAssessmentData({
              assessmentId: response?.data?.assessment_id,
              questionnaireId: response?.data?.questionnaire_id,
            });
          }
        }

        await nextStep();
        dispatch({ type: SET_WIZZARD_STEP, payload: activeStep });
      }
    }
  };

  const handleFocusNavigation = async (type: string) => {
    if (type !== 'next') return;

    if (!((isHelpAvailable && activeStep === 9) || (!isHelpAvailable && activeStep === 8))) return;

    if (
      reduxStarScores.currentStep !== 'NO_ACTIVE_PLANS' &&
      reduxStarScores.selectedFocusAreas.length !== 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }

      await reduxDispatch(starScoresSliceActions.setCreatedImprovePlanDetails(res));
    }

    if (
      reduxStarScores.currentStep === 'NO_ACTIVE_PLANS' &&
      reduxStarScores.selectedFocusAreas.length > 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }

      await reduxDispatch(starScoresSliceActions.setCreatedImprovePlanDetails(res));
    }

    if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id &&
      reduxStarScores.selectedFocusAreas.length === 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }
    }

    switch (reduxStarScores.currentStep) {
      case 'NO_ACTIVE_PLANS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'noPlanAndFocusAreas'
              : 'planCreatedWithFocusAreas',
          ),
        );
        break;
      case 'HAS_PLANS_NO_FOCUS_AREAS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'hasPlanNoFocusAreas'
              : 'hasPlanAndUpdatedFocusAreas',
          ),
        );
        break;
      case 'HAS_BOTH_PLANS_AND_FOCUS_AREAS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'hasPlanAndRemovedFocusAreas'
              : 'hasPlanAndUpdatedFocusAreas',
          ),
        );
        break;
      default:
        break;
    }

    await nextStep();
    await dispatch({ type: SET_WIZZARD_STEP, payload: activeStep });
  };

  const handlePrevious = () => {
    collapseSidebar(true);
    if (!isFirstStep) {
      previousStep();
      localStorage.removeItem('changeRequested');
    }
  };

  const handleCancel = () => {
    collapseSidebar(true);
    history.push('/');
  };

  const handleComplete = async () => {
    collapseSidebar(true);
    reduxDispatch(updateHelpAvailability(false));
    reduxDispatch(starScoresSliceActions.resetStarScores());

    if (reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas') {
      history.push(`/stars`);
    } else if (reduxStarScores.createdImprovementPlan) {
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.createdImprovementPlan.id}&improvePlan=${undefined}`,
      );
    } else if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans?.length > 0
    ) {
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.deepDiveQuestionWithPlans[0].question.improvePlans[0].id}&improvePlan=${reduxStarScores.deepDiveQuestionWithPlans[0].question?.questionText}`,
      );
    }
  };

  const isButtonsDisabled = () => {
    let isDisabled = false;
    if (activeStep > 0 && activeStep < 7) {
      const category = data[activeStep - 1];
      const filtered = category.data.filter((score) => score === 0);
      if (filtered.length > 0) {
        isDisabled = true;
      }
    }

    return isDisabled;
  };

  const handleNextOnSkip = async () => {
    if (isHelpAvailable && activeStep === 8) {
      await handleNext();
      await handleAssessmentData({ assessmentId: 0, questionnaireId: 0 });
    } else {
      handleNext();
    }
  };

  const skipFocusArea = async () => {
    if (
      reduxStarScores.selectedFocusAreas.length > 0 &&
      reduxStarScores.currentStep !== 'NO_ACTIVE_PLANS'
    ) {
      if (
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id
      ) {
        await updateActionsByImprovePlanId(
          reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id,
          ImprovePlanTypes.DEEP_DIVE,
          true,
        );
      }

      await handleCompletePilar();
      reduxDispatch(starScoresSliceActions.setSubNavigationStep('hasPlanAndUpdatedFocusAreas'));

      await nextStep();
    } else {
      if (
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id
      ) {
        await updateActionsByImprovePlanId(
          reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id,
          ImprovePlanTypes.DEEP_DIVE,
          true,
        );
      }

      await handleCompletePilar();

      if (reduxStarScores.currentStep === 'HAS_PLANS_NO_FOCUS_AREAS') {
        reduxDispatch(starScoresSliceActions.setSubNavigationStep('hasPlanNoFocusAreas'));
      } else {
        reduxDispatch(starScoresSliceActions.setSubNavigationStep('noPlanAndFocusAreas'));
      }

      await nextStep();
    }
  };

  const handleCompletePilar = async () => {
    try {
      setCompletingPilar(true);
      const payload = {
        questionnaireId: activeSubNav?.id ?? null,
        improvePlanId:
          reduxStarScores?.deepDiveQuestionWithPlans?.[0]?.question?.improvePlans?.[0]?.id ?? null,
      };
      await completePilar(payload);
    } catch (e) {
      setCompletingPilar(false);
    } finally {
      setCompletingPilar(false);
    }
  };

  const talkToAdvisorNotEnabled =
    !companyConfig.talkToAdvisor ||
    companyConfig.talkToAdvisor === undefined ||
    companyConfig.talkToAdvisor === null;

  const handleSaveAdvisorAreas = async () => {
    try {
      const areaIds = reduxStarScores.advisorSelectedFocusAreas.map((area) => area.id);
      if (areaIds?.length > 0) {
        const req = await addAdvisorFocusAreas(areaIds);

        if (req?.success) {
          handleNextOnSkip();
        } else {
          const message = req?.responseObject?.[0]?.message || '';
          toast.error(message || 'Error occurred, try again!');
          handleNextOnSkip();
        }
      } else {
        toast.error('Please select at least one area');
      }
    } catch (e: any) {
      toast.error(e?.message || 'Error occurred, try again!');
    }
  };

  if (!isFirstStep) {
    return (
      <div className={styles.hsStepWizardFooter}>
        {isHelpAvailable && activeStep === 9 && (
          <div className={styles.hsStepWizardFooterSelectedFocusAreas}>
            <p>Select up to three areas that you’d like to improve on</p>
            <p>Selected: {selectedCount}</p>
          </div>
        )}
        {!isHelpAvailable && activeStep === 8 && (
          <div className={styles.hsStepWizardFooterSelectedFocusAreas}>
            <p>Select up to three areas that you’d like to improve on</p>
            <p>Selected: {selectedCount}</p>
          </div>
        )}
        {activeStep < 7 && (
          <DefaultButton
            variant="secondary"
            type="button"
            onClick={handlePrevious}
            disabled={isFirstStep}
          >
            Back
          </DefaultButton>
        )}

        {/* Talk to adviser true, instant help false and activeStep === 10 */}
        {companyConfig.talkToAdvisor && activeStep === 10 && !isHelpAvailable && (
          <DefaultButton
            variant="secondary"
            type="button"
            onClick={handlePrevious}
            disabled={isFirstStep}
          >
            Back
          </DefaultButton>
        )}

        {/* Talk to adviser true, instant help true and activeStep === 11 */}
        {companyConfig.talkToAdvisor && activeStep === 11 && isHelpAvailable && (
          <DefaultButton
            variant="secondary"
            type="button"
            onClick={handlePrevious}
            disabled={isFirstStep || completingPilar}
          >
            Back
          </DefaultButton>
        )}

        <div className={styles.hsStepWizardFooterInner}>
          {activeStep < 7 && (
            <DefaultButton variant="secondary" type="button" onClick={handleCancel}>
              Cancel
            </DefaultButton>
          )}
          {!isLastStep && activeStep < 7 && (
            <DefaultButton
              variant="primary"
              type="button"
              onClick={handleNext}
              disabled={isButtonsDisabled() || !collapsed}
            >
              {isChangeRequested() ? 'Finish' : 'Next'}
            </DefaultButton>
          )}
          {isHelpAvailable && activeStep === 7 && (
            <DefaultButton variant="primary" type="button" onClick={handleNext}>
              Continue
            </DefaultButton>
          )}
          {!isHelpAvailable && activeStep === 7 && (
            <DefaultButton variant="primary" type="button" onClick={handleNext}>
              Continue
            </DefaultButton>
          )}

          {/* STARTS HERE
              talkToAdvisor, help enabled */}
          {companyConfig.talkToAdvisor && isHelpAvailable && activeStep === 8 && (
            <DefaultButton variant="primary" type="button" onClick={handleNext}>
              {customButtonName}
            </DefaultButton>
          )}

          {companyConfig.talkToAdvisor && isHelpAvailable && activeStep === 9 && (
            <>
              <DefaultButton
                variant="secondary"
                type="button"
                onClick={() => {
                  skipFocusArea();
                  reduxDispatch(starScoresSliceActions.setUserSkippedFocusSelection(true));
                }}
                disabled={completingPilar}
              >
                Skip
              </DefaultButton>

              <DefaultButton
                variant="primary"
                type="button"
                onClick={async () => {
                  await handleCompletePilar();
                  await handleFocusNavigation('next');
                }}
                disabled={completingPilar}
              >
                {completingPilar ? <CircularProgress size={20} color="inherit" /> : 'Continue'}
              </DefaultButton>
            </>
          )}

          {companyConfig.talkToAdvisor && isHelpAvailable && activeStep === 11 && (
            <>
              <DefaultButton
                variant="primary"
                type="button"
                onClick={() => {
                  handleSaveAdvisorAreas();
                }}
                disabled={deepDive.isAdvisorDiscussionContinueDisabled}
              >
                Continue
              </DefaultButton>
            </>
          )}

          {companyConfig.talkToAdvisor && isHelpAvailable && activeStep === 12 && (
            <DefaultButton variant="primary" type="button" onClick={handleComplete}>
              {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                ? 'Finish'
                : 'Take me to my plan'}
            </DefaultButton>
          )}

          {/* ENDS HERE
          talkToAdvisor, help enabled */}

          {/* STARTS HERE
              talkToAdvisor, help not enabled */}
          {companyConfig.talkToAdvisor && !isHelpAvailable && activeStep === 8 && (
            <>
              <DefaultButton
                variant="secondary"
                type="button"
                onClick={() => {
                  skipFocusArea();
                  reduxDispatch(starScoresSliceActions.setUserSkippedFocusSelection(true));
                }}
                disabled={completingPilar}
              >
                Skip
              </DefaultButton>

              <DefaultButton
                variant="primary"
                type="button"
                onClick={async () => {
                  await handleCompletePilar();
                  await handleFocusNavigation('next');
                }}
                disabled={completingPilar}
              >
                {completingPilar ? <CircularProgress size={20} color="inherit" /> : 'Continue'}
              </DefaultButton>
            </>
          )}

          {companyConfig.talkToAdvisor && !isHelpAvailable && activeStep === 10 && (
            <>
              <DefaultButton
                variant="primary"
                type="button"
                onClick={() => {
                  handleSaveAdvisorAreas();
                }}
                disabled={deepDive.isAdvisorDiscussionContinueDisabled}
              >
                Continue
              </DefaultButton>
            </>
          )}

          {companyConfig.talkToAdvisor &&
            !isHelpAvailable &&
            activeStep === 9 &&
            reduxStarScores.isUserSkippedFocusAreas &&
            (reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' ||
              reduxStarScores.subNavigationStep === 'hasPlanAndUpdatedFocusAreas' ||
              reduxStarScores.subNavigationStep === 'hasPlanNoFocusAreas') && (
              <DefaultButton variant="primary" type="button" onClick={handleComplete}>
                Take me to my plan
              </DefaultButton>
            )}

          {companyConfig.talkToAdvisor &&
            !isHelpAvailable &&
            activeStep === 9 &&
            !reduxStarScores.isUserSkippedFocusAreas &&
            reduxStarScores.selectedFocusAreas.length === 0 &&
            (reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' ||
              reduxStarScores.subNavigationStep === 'hasPlanAndUpdatedFocusAreas' ||
              reduxStarScores.subNavigationStep === 'hasPlanNoFocusAreas') && (
              <DefaultButton variant="primary" type="button" onClick={handleComplete}>
                Take me to my plan
              </DefaultButton>
            )}

          {companyConfig.talkToAdvisor && !isHelpAvailable && activeStep === 11 && (
            <DefaultButton variant="primary" type="button" onClick={handleComplete}>
              {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                ? 'Finish'
                : 'Take me to my plan'}
            </DefaultButton>
          )}
          {/* ENDS HERE
          talkToAdvisor, help not enabled */}

          {/* STARTS HERE
              talkToAdvisor false, help enabled */}
          {talkToAdvisorNotEnabled && isHelpAvailable && activeStep === 8 && (
            <DefaultButton variant="primary" type="button" onClick={handleNext}>
              {customButtonName}
            </DefaultButton>
          )}

          {talkToAdvisorNotEnabled && isHelpAvailable && activeStep === 9 && (
            <>
              <DefaultButton
                variant="secondary"
                type="button"
                onClick={() => {
                  skipFocusArea();
                  reduxDispatch(starScoresSliceActions.setUserSkippedFocusSelection(true));
                }}
                disabled={completingPilar}
              >
                Skip
              </DefaultButton>

              <DefaultButton
                variant="primary"
                type="button"
                onClick={async () => {
                  await handleCompletePilar();
                  await handleFocusNavigation('next');
                }}
                disabled={completingPilar}
              >
                {completingPilar ? <CircularProgress size={20} color="inherit" /> : 'Continue'}
              </DefaultButton>
            </>
          )}

          {talkToAdvisorNotEnabled && isHelpAvailable && activeStep === 10 && (
            <DefaultButton variant="primary" type="button" onClick={handleComplete}>
              {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                ? 'Finish'
                : 'Take me to my plan'}
            </DefaultButton>
          )}

          {companyConfig.talkToAdvisor &&
            isHelpAvailable &&
            activeStep === 10 &&
            reduxStarScores.isUserSkippedFocusAreas &&
            (reduxStarScores.subNavigationStep === 'hasPlanAndRemovedFocusAreas' ||
              reduxStarScores.subNavigationStep === 'hasPlanAndUpdatedFocusAreas' ||
              reduxStarScores.subNavigationStep === 'hasPlanNoFocusAreas') && (
              <DefaultButton variant="primary" type="button" onClick={handleComplete}>
                Take me to my plan
              </DefaultButton>
            )}

          {!talkToAdvisorNotEnabled &&
            isHelpAvailable &&
            activeStep === 10 &&
            reduxStarScores.subNavigationStep !== 'planCreatedWithFocusAreas' &&
            reduxStarScores.subNavigationStep !== 'hasPlanAndUpdatedFocusAreas' && (
              <DefaultButton variant="primary" type="button" onClick={handleComplete}>
                {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                  ? 'Finish'
                  : 'Take me to my plan'}
              </DefaultButton>
            )}

          {!talkToAdvisorNotEnabled &&
            !isHelpAvailable &&
            activeStep === 9 &&
            reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas' && (
              <DefaultButton variant="primary" type="button" onClick={handleComplete}>
                {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                  ? 'Finish'
                  : 'Take me to my plan'}
              </DefaultButton>
            )}
          {/* ENDS HERE
          talkToAdvisor false, help enabled */}

          {/* STARTS HERE
              talkToAdvisor false, help not enabled */}
          {talkToAdvisorNotEnabled && !isHelpAvailable && activeStep === 8 && (
            <>
              <DefaultButton
                variant="secondary"
                type="button"
                onClick={() => {
                  skipFocusArea();
                  reduxDispatch(starScoresSliceActions.setUserSkippedFocusSelection(true));
                }}
                disabled={completingPilar}
              >
                Skip
              </DefaultButton>

              <DefaultButton
                variant="primary"
                type="button"
                onClick={async () => {
                  await handleCompletePilar();
                  await handleFocusNavigation('next');
                }}
                disabled={completingPilar}
              >
                {completingPilar ? <CircularProgress size={20} color="inherit" /> : 'Continue'}
              </DefaultButton>
            </>
          )}

          {talkToAdvisorNotEnabled && !isHelpAvailable && activeStep === 9 && (
            <DefaultButton variant="primary" type="button" onClick={handleComplete}>
              {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                ? 'Finish'
                : 'Take me to my plan'}
            </DefaultButton>
          )}
          {/* ENDS HERE
          talkToAdvisor false, help not enabled */}
        </div>
      </div>
    );
  }

  return <div className={styles.hsStepWizardStartFooter} />;
};

type SubNavType =
  | 'noPlanAndFocusAreas'
  | 'planCreatedWithFocusAreas'
  | 'hasPlanNoFocusAreas'
  | 'hasPlanAndUpdatedFocusAreas'
  | 'hasPlanAndRemovedFocusAreas';

interface FailsQuestionsInterface {
  question_id: number;
  limit: number;
}
interface StepWizardFooterInterface {
  handleAssessmentData: (data: { assessmentId: number; questionnaireId: number }) => void;
  assesmentData: { assessmentId: number; questionnaireId: number };
  skipToAnyStep: number;
  handleStarSkip: (arg0: number) => void;
  customButtonName: string;
}

export default StepWizardFooter;
