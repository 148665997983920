import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton: React.FunctionComponent<LinkButtonProps> = (props) => {
  const { path, className, children } = props;

  return (
    <Link to={`${path}`} className={className}>
      {children}
    </Link>
  );
};

type LinkButtonProps = {
  path: string;
  className: string;
  children: React.ReactNode;
};

export default LinkButton;
