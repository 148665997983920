import React from 'react';
import FormButtonNew from '@components/FormButtonNew/FormButtonNew';
import LabelInput from '@components/Molecules/LabelInput';
import useInput, { getInputValue } from '@hooks/useInput';
import { isEmptyValidation } from '@helpers/validation.helper';
import styles from './index.module.scss';

const GetCompanyCodeForm: React.FunctionComponent<GetCompanyCodeFormProps> = (props) => {
  const { onClick } = props;

  const {
    value: companyCodeValue,
    isValid: companyCodeIsValid,
    hasError: companyCodeHasError,
    valueChangeHandler: companyCodeChangeHandler,
    inputBlurHandler: companyCodeBlurHandler,
    reset: companyCodeReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const handleCreateUser = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (companyCodeIsValid) {
      onClick(companyCodeValue);
    }

    companyCodeReset();
  };

  return (
    <form onSubmit={handleCreateUser}>
      <div className={styles.inputHolder}>
        <LabelInput
          id="companyCode"
          label="Company Code *"
          type="text"
          name="companyCode"
          placeholder="Add your company code here..."
          onChange={companyCodeChangeHandler}
          onBlur={companyCodeBlurHandler}
          value={companyCodeValue || ''}
          isError={companyCodeHasError}
        />
        {companyCodeHasError && <p className={styles.hsError}>Please enter a valid company code</p>}
      </div>
      <div className={styles.buttonHolder}>
        <FormButtonNew
          onClick={handleCreateUser}
          disabled={!companyCodeIsValid}
          color="primary"
          type="submit"
        >
          Continue
        </FormButtonNew>
      </div>
    </form>
  );
};

GetCompanyCodeForm.displayName = 'GetCompanyCodeForm';

interface GetCompanyCodeFormProps {
  onClick: (companyCodeValue: string) => void;
}

export default GetCompanyCodeForm;
