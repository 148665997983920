import { HourBuffCategories } from 'custom.d';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import moment from 'moment-timezone';

const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

export const formatDate = (stringTimeStamp: string) => {
  const date = new Date(parseInt(stringTimeStamp, 10) * 1000);

  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '/',
  );
};

export const newFormatDate = (stringTimeStamp: string) => {
  const date = stringTimeStamp.slice(0, 10);
  const temp = date.split('-');
  const to = `${temp[2]}.${temp[1]}.${temp[0]}`;

  return to;
};

export const calculateDate = (date: string) => {
  dayjs.extend(relativeTime);

  return dayjs(date).fromNow();
};

export function formatDateStringSplitDots(inputDateString: string, locale = 'en-GB'): string {
  const date = new Date(inputDateString);

  const day = date.toLocaleDateString(locale, { day: '2-digit' });
  const month = date.toLocaleDateString(locale, { month: '2-digit' });
  const year = date.toLocaleDateString(locale, { year: 'numeric' });

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
}

export const formatDateToDateMonthYear = (dateString: string) => {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const date = new Date(dateString);

  return date.toLocaleDateString('en-GB', options as Intl.DateTimeFormatOptions);
};

export const formatDateToDateFullMonthYear = (dateString: string) => {
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const date = new Date(dateString);

  return date.toLocaleDateString('en-GB', options as Intl.DateTimeFormatOptions);
};

export const formatTimeToGMT = (dateString: string) => {
  const gmtTime = moment.tz(dateString, 'GMT');

  const localTime = gmtTime.clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const formattedTime = localTime.format('HH:mm');

  const offset = localTime.format('Z');

  return `${formattedTime} (${offset} GMT)`;
};

export const getHourBufferCategory = (offsetDiff: number) => {
  const hoursDiff = Math.abs(offsetDiff / 60);

  const categoryEntry = Object.entries(HourBuffCategories).find(([key, range]) => {
    const [min, max] = range.split('-').map(Number);

    return hoursDiff >= min && hoursDiff < max;
  });

  return categoryEntry ? parseInt(categoryEntry[0], 10) : 9;
};
