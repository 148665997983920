import { getFilteredResults, getFilters } from '@api/EmployerNew';
import TabButton from '@components/Atoms/TabButton';
import RadarEmployer from '@components/Charts/RadarEmployer';
import CircleScoreCard from '@components/CircleScoreCard';
import SliceAndDiceFilters from '@components/SliceAndDiceFilters';
import { yupResolver } from '@hookform/resolvers/yup';
import MobileToggler from '@layouts/MainLayout/SecondaryNavigation/components/MobileToggler';
import { Grid, Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ShortStarQuestionsInterface } from 'custom';
import BoostersAndDrainersSection from '../BoostersAndDrainersSection';
import ScoresSection from '../ScoresSection';
import styles from './index.module.scss';

const objectShape = yup
  .object()
  .shape({
    label: yup.string(),
    value: yup.string(),
  })
  .nullable();

const filterValidationSchema = yup.object().shape({
  locations: objectShape,
  teams: objectShape,
  gender: objectShape,
  seniority: objectShape,
  jobTypes: objectShape,
  jobTitles: objectShape,
  peopleLead: objectShape,
  active: objectShape,
  startDate: yup.string(),
  endDate: yup.string(),
});

const SliceAndDiceDeepDiveSection: React.FunctionComponent<SliceAndDiceDeepDiveSectionProps> = ({
  loading,
  questionnaireNames,
  starQuestions,
}) => {
  const [resultsData, setResultsData] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [selectedTabDetails, setSelectedTabDetails] = useState<TabDetailsInterface>({
    id: 0,
    label: '',
    labelId: 1,
    actualItemId: 1,
  });
  const [starDataLoading, setStarDataLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filteringEnabled, setFilteringEnabled] = useState<boolean>(false);
  const [isFilterClicked, setIsFilterClicked] = useState<boolean>(false);

  const filterMethods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(filterValidationSchema),
  });

  useEffect(() => {
    filterMethods.setValue('active', { label: 'Active Users', value: 'true' });
    filterMethods.setValue('startDate', null);
    filterMethods.setValue('endDate', null);
  }, []);

  const sDate = filterMethods.getValues('startDate');
  const eDate = filterMethods.getValues('endDate');

  const removeNullUndefined = (dat: any) =>
    Object.entries(dat)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  const getFilterValues = useCallback(() => {
    const values = filterMethods.getValues();

    const mapField = (field: string) => {
      const value = values?.[field]?.value;
      if (value === undefined) return null;

      if (
        field === 'locations' ||
        field === 'teams' ||
        field === 'jobTypes' ||
        field === 'jobTitles'
      ) {
        return parseInt(value, 10) || null;
      }

      if (field === 'gender' || field === 'seniority') {
        if (value === '') return null;
      }

      if (field === 'peopleLead' || field === 'active') {
        if (value === 'true') return true;
        if (value === 'false') return false;

        return null;
      }

      return value;
    };

    const mappedValues = {
      locations: mapField('locations'),
      teams: mapField('teams'),
      gender: mapField('gender'),
      seniority: mapField('seniority'),
      jobTypes: mapField('jobTypes'),
      jobTitles: mapField('jobTitles'),
      peopleLead: mapField('peopleLead'),
      active: mapField('active'),
      startDate: mapField('startDate'),
      endDate: mapField('endDate'),
    };

    let filterValues: any = removeNullUndefined(mappedValues);

    if (sDate || eDate) {
      filterValues = { ...filterValues };

      if (sDate) {
        filterValues.startDate = sDate;
      }

      if (eDate) {
        filterValues.endDate = eDate;
      }
    }

    return filterValues;
  }, [filterMethods, sDate, eDate]);

  useEffect(() => {
    if (questionnaireNames && questionnaireNames?.length > 0) {
      setSelectedTabDetails({
        id: 0,
        label: questionnaireNames[0]?.name,
        labelId: 1,
        actualItemId: questionnaireNames[0]?.id,
      });
    }
  }, [questionnaireNames]);

  useEffect(() => {
    const getFiltersData = async () => {
      const data = await getFilters();
      setFilters(data);
    };

    getFiltersData();
  }, []);

  const handleFilterResultsData = useCallback(() => {
    if (selectedTabDetails.actualItemId && getFilterValues()) {
      setStarDataLoading(true);
      const getFilteredResultsHighlightsData = async () => {
        const filteredResultsResponse = await getFilteredResults(
          selectedTabDetails.actualItemId || 1,
          getFilterValues(),
        );

        const { categories } = filteredResultsResponse;

        const scoresData = categories?.map((cat: any) => {
          if (cat.questions.length === 1) {
            return new Array(4).fill(cat.questions[0].score.toFixed(0));
          }

          return cat.questions.map((question: any) => question.score.toFixed(0));
        });

        const newStructure = {
          ...filteredResultsResponse,
          categories: filteredResultsResponse?.categories.map((i: any, key: any) => ({
            ...i,
            data: scoresData[key],
          })),
        };

        setResultsData(newStructure);
        setStarDataLoading(false);
      };

      getFilteredResultsHighlightsData();
    }
  }, [selectedTabDetails.actualItemId]);

  useEffect(() => {
    if (filteringEnabled) {
      handleFilterResultsData();
    }
  }, [filteringEnabled]);

  useEffect(() => {
    handleFilterResultsData();
  }, [handleFilterResultsData]);

  const handleApplyFilters = React.useCallback(async () => {
    await handleFilterResultsData();
  }, [handleFilterResultsData]);

  const handleResetFilter = useCallback(() => {
    filterMethods.reset();
    filterMethods.setValue('active', { label: 'Active Users', value: 'true' });
    filterMethods.setValue('startDate', null);
    filterMethods.setValue('endDate', null);
    handleFilterResultsData();
  }, [filterMethods, handleFilterResultsData]);

  const handleTabChange = async (tab: {
    id: number;
    label: string;
    labelId: number;
    actualItemId: number;
  }) => {
    await setSelectedTabDetails(tab);
  };

  const tabButtons =
    questionnaireNames &&
    questionnaireNames.map((que: { id: number; name: string }, index: number) => ({
      id: index + 1,
      title: que?.name,
      actualItemId: que.id || 0,
    }));

  const getListItemClassName = (tag: string) =>
    tag ===
    (selectedTabDetails.label
      ? selectedTabDetails.label
      : questionnaireNames && questionnaireNames[0]?.name)
      ? styles.active
      : '';

  return (
    <div className={styles.overallDetailsContainer}>
      {filters && filters.length > 0 && (
        <SliceAndDiceFilters
          methods={filterMethods}
          isFiltered={filteringEnabled}
          onFilter={handleApplyFilters}
          onReset={handleResetFilter}
          jobTitles={filters[5].jobTitles}
          jobTypes={filters[4].jobTypes}
          locations={filters[0].locations}
          teams={filters[1].teams}
          handleFilteringEnable={setFilteringEnabled}
          handleFilterClick={setIsFilterClicked}
          isFilterClicked={isFilterClicked}
        />
      )}

      <div className={styles.regUsersCount}>
        {Number(resultsData?.employeeCount) > 4 ? (
          <h6 className={styles.regUsersCountText}>
            {resultsData?.employeeCount} registered users
          </h6>
        ) : (
          <h6 className={styles.regUsersCountText}>
            {resultsData?.employeeCount} registered users - Minimum 5 registered users needed to
            view data
          </h6>
        )}
      </div>

      <ScoresSection
        loading={false}
        lightMode
        filters={getFilterValues()}
        isFiltered={filteringEnabled}
        starQuestions={starQuestions}
      />

      <BoostersAndDrainersSection filters={getFilterValues()} isFiltered={filteringEnabled} />

      {questionnaireNames && tabButtons && (
        <>
          <div className={styles.tabsContainer}>
            <TabButton
              selectedTab={selectedTabDetails}
              handleSelect={(tab: any) => {
                handleTabChange(tab);
              }}
              tabs={tabButtons}
              isMobileWrapEnabled
              themeSkyBlue
            />
          </div>

          <div className={styles.hsSubNavigation}>
            <MobileToggler
              openHandler={setIsOpen}
              isOpen={isOpen}
              name={selectedTabDetails.label}
            />
            {isOpen && (
              <ul>
                {questionnaireNames?.map((dt: { id: number; name: string }, index: number) => (
                  <li key={index} className={getListItemClassName(dt.name)}>
                    <a
                      onClick={() => {
                        setSelectedTabDetails({
                          id: index,
                          label: dt.name,
                          labelId: dt.id,
                          actualItemId: dt.id,
                        });
                        setIsOpen(false);
                      }}
                    >
                      {dt.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className={styles.deepDiveRadarContainer}>
            {starDataLoading ? (
              <div className={styles.starLoadingContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <div className={styles.starLoadingItem}>
                      <Skeleton variant="text" height={70} width="50%" />
                    </div>
                    <div className={styles.starLoadingSquareItem}>
                      <Skeleton variant="text" className={styles.radarChartSkeleton} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className={styles.starLoadingItem}>
                      <Skeleton variant="text" height={70} width="50%" />
                    </div>
                    <div className={styles.starLoadingSquareItem}>
                      <Skeleton variant="text" className={styles.radarChartSkeleton} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className={styles.starLoadingItem}>
                      <Skeleton variant="text" height={70} width="50%" />
                    </div>
                    <div className={styles.starLoadingSquareItem}>
                      <Skeleton variant="text" className={styles.radarChartSkeleton} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className="row">
                {resultsData &&
                  resultsData.categories?.map((radar: any, index: any) => (
                    <div
                      key={JSON.stringify(radar + index)}
                      className="col-12 col-sm-12 col-md-6 col-lg-4"
                    >
                      <RadarEmployer {...radar} showOnScorePagea />

                      <div className={styles.circleScoreCardContainer}>
                        {radar.questions.map((score: any, scoreIndex: any) => (
                          <CircleScoreCard
                            index={score.id}
                            key={JSON.stringify(scoreIndex + index)}
                            text={score.name}
                            color={score.color}
                            score={score.score}
                            isSelected={score.isSelected}
                            scoreDifference={score.scoreDifference}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                {resultsData?.categories?.length < 1 && (
                  <h1 className={styles.noDataCont}>NO DATA</h1>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

SliceAndDiceDeepDiveSection.defaultProps = {
  loading: false,
  questionnaireNames: [],
  starQuestions: [],
};

interface SliceAndDiceDeepDiveSectionProps {
  loading?: boolean;
  questionnaireNames?: { id: number; name: string }[] | [];
  starQuestions?: ShortStarQuestionsInterface[] | [];
}

interface TabDetailsInterface {
  id: number;
  label: string;
  labelId: number;
  actualItemId?: number;
}

export default SliceAndDiceDeepDiveSection;
