import { createSlice } from '@reduxjs/toolkit';
import { InstantHelpData } from 'custom';

export interface InstantHelpState {
  loading: boolean;
  isLoaded: boolean;
  error: string | null;
  data: InstantHelpData[];
}

const initialState: InstantHelpState = {
  loading: false,
  isLoaded: false,
  error: null,
  data: [],
};

export interface UpdateInstantHelpDataAction {
  type: string;
  payload: InstantHelpData[];
}

export interface SetLoadingAction {
  type: string;
  payload: {
    loading: boolean;
  };
}
export interface SetErrorAction {
  type: string;
  payload: {
    error: string;
  };
}

const instantHelpSlice = createSlice({
  name: 'instantHelp',
  initialState,
  reducers: {
    setLoading(state: InstantHelpState, action: SetLoadingAction): InstantHelpState {
      return {
        ...state,
        loading: action.payload.loading,
        isLoaded: false,
        error: null,
        data: [],
      };
    },
    setError(state: InstantHelpState, action: SetErrorAction): InstantHelpState {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload.error,
        data: [],
      };
    },
    updateInstantHelpData(
      state: InstantHelpState,
      action: UpdateInstantHelpDataAction,
    ): InstantHelpState {
      return {
        ...state,
        loading: false,
        isLoaded: Boolean(action.payload.length > 0),
        error: null,
        data: action.payload,
      };
    },
  },
});

export const instantHelpActions = instantHelpSlice.actions;

export default instantHelpSlice;
