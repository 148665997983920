import { useGlobalState } from '@context/GlobalStore';
import React from 'react';
import { useHistory } from 'react-router';
import styles from './index.module.scss';

const ColoredBox: React.FunctionComponent<ColoredBoxType> = (props) => {
  const {
    title,
    color,
    children,
    isEditable,
    isHomePage,
    setEditFocusArea,
    indexGraph,
    dataLength,
    showActionButton,
    isTitleCentered,
  } = props;
  const className = `${styles.hsColoredBox} ${styles[color]}`;
  const [state] = useGlobalState();
  const history = useHistory();

  return (
    <div className={className} style={{ height: isHomePage ? '700px' : undefined }}>
      <p
        className={styles.hsColoredBoxTitle}
        style={isTitleCentered ? { textAlign: 'center' } : {}}
      >
        {title}{' '}
        {/* {isHomePage &&
          `(${state?.graphData?.date && indexGraph ? state?.graphData?.date : 'Current'})`}{' '}
        {(dataLength && dataLength >= 0) || dataLength === 0 ? `(${dataLength})` : ''} */}
        {showActionButton && (
          <button
            type="button"
            onClick={() => history.push('/actions')}
            className={styles.hsActionButton}
          >
            Set a new action
          </button>
        )}
        {isEditable && (
          <a href="#" onClick={() => setEditFocusArea && setEditFocusArea(true)}>
            Edit
          </a>
        )}
      </p>
      {children}
    </div>
  );
};

ColoredBox.defaultProps = {
  isEditable: false,
  dataLength: -1,
  isHomePage: false,
  setEditFocusArea: () => null,
  indexGraph: false,
  showActionButton: false,
  isTitleCentered: false,
};

type ColoredBoxType = {
  title: string;
  color: string;
  dataLength?: number;
  children: React.ReactNode;
  isEditable?: boolean;
  showActionButton?: boolean;
  isHomePage?: boolean;
  indexGraph?: boolean;
  setEditFocusArea?: (focus: boolean) => void;
  isTitleCentered?: boolean;
};

export default ColoredBox;
