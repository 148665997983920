import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import Modal from '@components/Atoms/Modal';
import classNames from 'classnames';
import DefaultButton from '@components/Atoms/DefaultButton';
import styles from './index.module.scss';

const ImportUserModal: React.FunctionComponent<ImportUserModalProps> = (props) => {
  const { title, isOpen, onClose, onSubmit, methods } = props;

  const { register, handleSubmit, watch } = methods;

  const files = watch('files');
  const canSubmit = files && files.length > 0;

  return (
    <div>
      {isOpen && (
        <div className={styles.overlay}>
          <Modal open={isOpen} setOpen={onClose} onTapBackgroundClose className={styles.modalRoot}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.modalHeader}>
                <h4>{title}</h4>
                <button type="button" className={styles.btnClose} onClick={onClose}>
                  <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                </button>

                <hr />

                <div className="container">
                  <div className={styles.warningBox}>
                    Import users from a CSV file. The CSV file should be based on the provided
                    template. Please ensure the file matches the structure of the template file
                    before starting the import process.
                  </div>

                  <input type="file" accept=".csv" {...register('files')} />
                </div>

                <hr className={styles.hrLine} />
                <div className={styles.footerContainer}>
                  <DefaultButton variant="primary" type="submit" disabled={!canSubmit}>
                    Confirm
                  </DefaultButton>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      )}
    </div>
  );
};

interface ImportUserModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void | undefined;
  onSubmit: (data: any) => void;
  methods: UseFormReturn;
}

export default ImportUserModal;
