import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const FormButtonNew: React.FunctionComponent<FormButtonNewProps> = (props) => {
  const { children, color, ...rest } = props;

  const classes = classNames([
    styles.hsButton,
    color === 'primary' && styles.hsPrimary,
    color === 'secondary' && styles.hsSecondary,
    color === 'tertiary' && styles.hsTertiary,
    color === 'icon' && styles.hsIconButton,
    color === 'more' && styles.hsShowMoreButton,
    color === 'small' && styles.hsSmall,
    color === 'close' && styles.hsClose,
  ]);

  return (
    <button className={classes} type="button" {...rest}>
      {children}
    </button>
  );
};

interface FormButtonNewProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: string;
  color: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'more' | 'small' | 'close';
}

export default FormButtonNew;
