import React, { useEffect, useState } from 'react';
import QuestionItemContent from '@components/QuestionItemContent';
import Radar from '@components/Charts/Radar';
import { CreateStarCategoryInterface } from '@interfaces/index';
import PopUpModal from '@components/PopUpModal';
import { useGlobalState } from '@context/GlobalStore';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getCompanyDetails } from '@helpers/auth.helper';
import styles from './index.module.scss';

const QuestionItem: React.FunctionComponent<QuestionItemInterface> = (props) => {
  const { categoryQuestions, onChange, onSidebarOpen, showPopUp, collapsed } = props;
  const questionResponsesLength = categoryQuestions?.questions?.length;
  const [question1, question2, question3, question4] = categoryQuestions.questions;
  const [open, setOpen] = useState(true);
  const [openSecond, setOpenSecond] = useState(true);
  const [state] = useGlobalState();
  const [currentCompanyName, setCurrentCompanyName] = useState('');

  useEffect(() => {
    const getCurrentCompanyName = async () => {
      const comDetails = await getCompanyDetails();
      if (comDetails) {
        setCurrentCompanyName(comDetails.company_name);
      }
    };

    getCurrentCompanyName();
  }, []);

  if (questionResponsesLength > 1) {
    return (
      <div className={styles.hsQuestionItem}>
        <div>
          <QuestionItemContent
            question={question1}
            onChange={onChange}
            onSidebarOpen={onSidebarOpen}
          />
        </div>
        <div className={styles.hsQuestionItemRadarContainer}>
          <div>
            <QuestionItemContent
              question={question2}
              onChange={onChange}
              onSidebarOpen={onSidebarOpen}
            />
          </div>
          <div className={styles.hsQuestionItemRadar}>
            <Radar
              color={categoryQuestions.color}
              lightColor={categoryQuestions.lightColor}
              darkColor={categoryQuestions.darkColor}
              data={categoryQuestions.data}
            />
          </div>
          <div>
            <QuestionItemContent
              question={question4}
              onChange={onChange}
              onSidebarOpen={onSidebarOpen}
            />
          </div>
        </div>
        <div>
          <QuestionItemContent
            question={question3}
            onChange={onChange}
            onSidebarOpen={onSidebarOpen}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.hsQuestionRhombus}>
      {showPopUp && collapsed && state?.isUserOnBoarded && (
        <PopUpModal
          open={open}
          setOpen={setOpen}
          top="200px"
          right="50px"
          firstDescription='each driver is asking "how happy am I with…" with 10 being "Very happy" and 1 being "Very unhappy".'
          description="If you're unsure what the driver is asking, press the '?' for more information."
          title="Remember…"
        />
      )}
      {showPopUp && collapsed && !open && state?.isUserOnBoarded && (
        <PopUpModal
          top="200px"
          right="50px"
          open={openSecond}
          setOpen={setOpenSecond}
          firstDescription="Journaling is a powerful tool to expand on why you score a certain way."
          description={`You can keep your thoughts private as a personal journal, or share them anonymously with ${currentCompanyName}.`}
          title="Sharing your thoughts"
        />
      )}
      <>
        <div>
          <QuestionItemContent
            question={question1}
            onChange={onChange}
            onSidebarOpen={onSidebarOpen}
          />
        </div>
        <div className={styles.hsQuestionRhombusRadar}>
          <div className={styles.hsQuestionRhombusRadarContainer}>
            <Radar
              color={categoryQuestions.color}
              lightColor={categoryQuestions.lightColor}
              darkColor={categoryQuestions.darkColor}
              data={categoryQuestions.data}
            />
          </div>
        </div>
      </>
    </div>
  );
};

QuestionItem.defaultProps = {
  showPopUp: false,
  collapsed: false,
};

interface QuestionItemInterface {
  categoryQuestions: CreateStarCategoryInterface;
  showPopUp?: boolean;
  collapsed?: boolean;
  onChange: (score: number, questionId: number) => void;
  onSidebarOpen: (score: number, color: string, question: string, id: number) => void;
}

export default QuestionItem;
