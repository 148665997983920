import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const logLearning = async (
  logType: string,
  ipAddress: string,
  os: string,
  isNative: boolean,
  articleId: number,
  articleTitle: string,
) => {
  const formattedReq = {
    logType,
    ipAddress,
    os,
    isNative,
    articleId,
    articleTitle,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/log-learning`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(`Failed to log learning blog: ${errorMessage}`);
    throw error;
  }

  return response;
};

export const getRecommendedLearningByQuestion = async (questionIds: number[]) => {
  const formattedReq = {
    questionIds,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}/get-recommended-learning`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(formattedReq),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(errorMessage || `Failed to get recommended learning by question`);
    throw error;
  }

  return response;
};

export const getLearnings = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/get-learnings`, {
    method: 'GET',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data?.responseObject[0]?.message;
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    const error = new Error(errorMessage || `Failed to get learnings`);
    throw error;
  }

  return response;
};
