import React from 'react';
import CustomSlider from '@components/Atoms/DefaultSlider';
import styles from './index.module.scss';

const ShortStarSlider: React.FunctionComponent<ShortStarSliderProps> = (props) => {
  const {
    sliderTopic,
    defaultValue,
    step,
    marks,
    minValue,
    maxValue,
    sliderValue,
    handleSliderChange,
    valueLabel,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{sliderTopic}</div>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderWrapper}>
          <CustomSlider
            aria-label="Small steps"
            defaultValue={defaultValue}
            step={step}
            marks={marks}
            minValue={minValue}
            maxValue={maxValue}
            sliderValue={typeof sliderValue === 'number' ? sliderValue : 0}
            handleSliderChange={handleSliderChange}
            valueLabel={valueLabel}
          />
        </div>
      </div>
    </div>
  );
};

ShortStarSlider.defaultProps = {
  minValue: 0,
  maxValue: 10,
  sliderValue: 7,
  step: 1,
  defaultValue: 7,
  valueLabel: 'on',
};

type ShortStarSliderProps = {
  sliderTopic: string;
  sliderValue?: number;
  handleSliderChange: any;
  defaultValue?: number;
  step?: number;
  marks: boolean;
  minValue?: number;
  maxValue?: number;
  valueLabel?: 'on' | 'off' | 'auto';
};

export default ShortStarSlider;
