import React from 'react';
import classNames from 'classnames';
import useGoNative from '@hooks/useGoNative';

import styles from './index.module.scss';

const Footer: React.FunctionComponent = () => {
  const isMobileApp = useGoNative();

  return <footer className={classNames([styles.hsFooter, isMobileApp && styles.hsFooterMobile])} />;
};

export default Footer;
