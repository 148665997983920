import { getToken } from '@api/config';
import { createHeaders, defaultHttpRequestHeader } from '@api/httpHeaderConfig';
import { AuthResponse, updateUserStatusRequest, userStatusResponse } from 'custom';

export enum LoginTypes {
  'TOKEN' = 'token',
  'LINK' = 'link',
}

export const getIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');

    if (response.ok) {
      const ipAddress = await response.json();

      return ipAddress.ip;
    }
  } catch (error) {
    return null;
  }

  return null;
};

export const loginUser = async (body: any) => {
  const requestOptions = {
    method: 'POST',
    headers: defaultHttpRequestHeader,
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/auth/trigger-login-email`, requestOptions);
};

export const getCurrentUserStatus = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/user-status`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (!response.ok) {
    throw new Error('Failed to get user Status data');
  }

  const result: userStatusResponse = await response.json();

  return result;
};

export const updateCurrentUserStatus = async (userId: number, data: updateUserStatusRequest) => {
  const requestOptions = {
    method: 'PUT',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  };

  const userData = await fetch(
    `${process.env.REACT_APP_API_URL}/setUserStatus/${userId}`,
    requestOptions,
  );

  if (!userData.ok) {
    throw new Error('Failed to update user Status data');
  }

  return (await userData.json()) as userStatusResponse;
};

export const verifyInviteTokenRequest = async (body: any) => {
  const ipAddress = await getIpAddress();
  const os = navigator.userAgentData?.platform;

  const data = { ...body, ipAddress, os };

  const requestOptions = {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  };

  const userData = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, requestOptions);

  return (await userData.json()) as AuthResponse;
};

export const confirmUserLogin = async (body: any) => {
  const requestOptions = {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/auth/confirm-user-login`, requestOptions);
};
