import React from 'react';
import { motion } from 'framer-motion';

const AnimatedStarOpacity: React.FunctionComponent<AnimatedStarOpacityProps> = (props) => {
  const { type, categoryNames } = props;
  const variants = {
    inactive: {
      opacity: 0.3,
    },
    active: {
      opacity: 1,
    },
  };

  const strokeVariants = {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
    },
  };

  return (
    <svg
      width="217"
      height="245"
      viewBox="0 0 217 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        id="general-happiness"
        d="M108.481 16L139.391 68.556L108.481 122.071L77.5703 68.556L108.481 16Z"
        fill="#019DF2"
        initial="inactive"
        animate={
          type === categoryNames[0]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={variants}
      />

      <motion.path
        id="wellness"
        d="M201.504 68.3139L170.243 122.055L108.442 122.043L139.332 68.5162L201.504 68.3139Z"
        fill="#BEAAF3"
        initial="inactive"
        animate={
          type === categoryNames[1]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={variants}
      />
      <motion.path
        id="relationships"
        d="M201.545 175.754L170.284 122.013L108.483 122.024L139.373 175.552L201.545 175.754Z"
        fill="#FCD300"
        initial="inactive"
        animate={
          type === categoryNames[2]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={variants}
      />
      <motion.path
        id="higher-thoughts"
        d="M108.515 229.466L77.6047 175.523L108.515 122.007L139.426 175.523L108.515 229.466Z"
        fill="#8EEA36"
        initial="inactive"
        animate={
          type === categoryNames[3]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={variants}
      />
      <motion.path
        id="activity"
        d="M15.4978 175.763L46.7591 122.022L108.56 122.034L77.6697 175.561L15.4978 175.763Z"
        fill="#06BF97"
        initial="inactive"
        animate={
          type === categoryNames[4]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={variants}
      />
      <motion.path
        id="lifestyle"
        d="M15.4548 68.3241L46.7162 122.065L108.517 122.054L77.6267 68.5265L15.4548 68.3241Z"
        fill="#FF8A4A"
        initial="inactive"
        animate={
          type === categoryNames[5]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={variants}
      />

      <motion.path
        id="general-happiness-stroke"
        d="M69.4577 69.3086L108.483 1.20441L147.507 69.3086L108.483 136.872L69.4577 69.3086Z"
        stroke="#0072B0"
        initial="inactive"
        animate={
          type === categoryNames[0]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={strokeVariants}
      />

      <motion.path
        id="wellness-stroke"
        d="M135.221 61.3973L213.713 61.1418L174.245 128.99L96.2213 128.976L135.221 61.3973Z"
        stroke="#6048A1"
        initial="inactive"
        animate={
          type === categoryNames[1]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={strokeVariants}
      />
      <motion.path
        id="relationships-stroke"
        d="M174.034 114.758L213.502 182.606L135.01 182.351L96.0101 114.772L174.034 114.758Z"
        stroke="#D4B202"
        initial="inactive"
        animate={
          type === categoryNames[2]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={strokeVariants}
      />
      <motion.path
        id="higher-thoughts-stroke"
        d="M147.505 175.464L108.48 243.569L69.4556 175.464L108.48 107.901L147.505 175.464Z"
        stroke="#4C8F0C"
        initial="inactive"
        animate={
          type === categoryNames[3]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={strokeVariants}
      />
      <motion.path
        id="activity-stroke"
        d="M42.4902 115.362L120.983 115.106L81.515 182.955L3.49083 182.94L42.4902 115.362Z"
        stroke="#069B7B"
        initial="inactive"
        animate={
          type === categoryNames[4]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={strokeVariants}
      />
      <motion.path
        id="lifestyle-stroke"
        d="M81.5149 61.4534L120.982 129.302L42.4901 129.046L3.49055 61.4682L81.5149 61.4534Z"
        stroke="#D96525"
        initial="inactive"
        animate={
          type === categoryNames[5]?.toLowerCase().split(' ').join('-') ? 'active' : 'inactive'
        }
        variants={strokeVariants}
      />
    </svg>
  );
};

interface AnimatedStarOpacityProps {
  type: string;
  categoryNames: string[];
}

export default AnimatedStarOpacity;
