import DoneIcon from '@mui/icons-material/Done';
import { defaultDarkColor, getDarkColor } from 'custom.d';
import React from 'react';
import styles from './index.module.scss';

interface FocusAreaItemProps {
  id: number;
  name: string;
  isSelected: boolean;
  color?: string;
  score: number;
  onSelect: (id: number) => void;
}

const SelectableArea: React.FC<FocusAreaItemProps> = ({
  id,
  name,
  isSelected,
  color,
  score,
  onSelect,
}) => {
  const darkColorVariant = getDarkColor(color?.toLocaleLowerCase() || '#019df2');
  const disabledColor = '#828282';

  return (
    <div
      onClick={() => onSelect(id)}
      className={`${styles.focusAreaItem} ${isSelected ? styles.selected : styles.notSelected}`}
      style={{ borderColor: isSelected ? color : disabledColor }}
    >
      <div
        className={styles.leftCircle}
        style={{
          backgroundColor: isSelected ? darkColorVariant : 'white',
          borderColor: isSelected ? 'white' : disabledColor,
          color: isSelected ? 'white' : disabledColor,
        }}
      >
        {score || 0}
      </div>
      <span className={styles.itemName}>{name}</span>
      <div
        className={styles.checkCircle}
        style={{
          backgroundColor: isSelected ? darkColorVariant : 'transparent',
          borderColor: isSelected ? darkColorVariant : disabledColor,
        }}
      >
        {isSelected && (
          <span className={styles.checkmark}>
            <DoneIcon />
          </span>
        )}
      </div>
    </div>
  );
};

SelectableArea.defaultProps = {
  color: '#019df2',
};

export default SelectableArea;
