import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import { useGlobalState } from '@context/GlobalStore';
import styles from './index.module.scss';

const ScoreCheckbox: React.FunctionComponent<ScoreCheckboxProps> = (props) => {
  const { id, text, color, score, scoreDifference, isSelected = false, onChange, index } = props;

  const [state] = useGlobalState();
  const stateData = state?.diffCompareData?.oneByOneCompare
    ? state?.diffCompareData?.oneByOneCompare.find((i: any) => i?.id === index)?.scoreDifference
    : null;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, !isSelected);
  };

  const classStyles = classNames([
    styles.hsScoreCheckbox,
    isSelected && color === '#BEAAF3' && styles.calmLilac,
    isSelected && color === '#019DF2' && styles.skyBlue,
    isSelected && color === '#FCD300' && styles.sunYellow,
    isSelected && color === '#8EEA36' && styles.zingyGreen,
    isSelected && color === '#06BF97' && styles.aquaGreen,
    isSelected && color === '#FF8A4A' && styles.warmOrange,
    stateData < 0 ? styles.down : stateData > 0 ? styles.up : styles.right,
  ]);

  const icon =
    stateData < 0 ? (
      <i className="icon icon-arrow-down" />
    ) : stateData > 0 ? (
      <i className="icon icon-arrow-up" />
    ) : (
      <i className="icon icon-arrow-right" />
    );

  return (
    <div>
      <div className={classStyles}>
        <label className={styles.content} htmlFor={id}>
          {text}
          {stateData !== null && icon}
          <span>{stateData}</span>
        </label>
        <span className={styles.score}>{score}</span>
        <div className={styles.round}>
          <input type="checkbox" checked={isSelected} id={id} onChange={handleChange} />
          <label htmlFor={id} />
        </div>
      </div>
    </div>
  );
};

interface ScoreCheckboxProps {
  id: string;
  text: string;
  color: string;
  score: number;
  scoreDifference: number;
  isSelected?: boolean;
  index: number;
  onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

ScoreCheckbox.defaultProps = {
  isSelected: false,
};

export default ScoreCheckbox;
