import { createSlice } from '@reduxjs/toolkit';

export interface HelpAnSpeakUpData {
  isHelpAvailable: boolean;
}

export interface HelpAndSpeakupState {
  loading: boolean;
  isLoaded: boolean;
  error: string | null;
  data: HelpAnSpeakUpData;
}

const initialState: HelpAndSpeakupState = {
  loading: false,
  isLoaded: false,
  error: null,
  data: { isHelpAvailable: false },
};

export interface UpdateHelpDataAction {
  type: string;
  payload: HelpAnSpeakUpData;
}

export interface SetLoadingAction {
  type: string;
  payload: {
    loading: boolean;
  };
}
export interface SetErrorAction {
  type: string;
  payload: {
    error: string;
  };
}

const helpAndSpeakupSlice = createSlice({
  name: 'helpAndSpeakup',
  initialState,
  reducers: {
    setLoading(state: HelpAndSpeakupState, action: SetLoadingAction): HelpAndSpeakupState {
      return {
        ...state,
        loading: action.payload.loading,
        isLoaded: false,
        error: null,
        data: { isHelpAvailable: false },
      };
    },
    setError(state: HelpAndSpeakupState, action: SetErrorAction): HelpAndSpeakupState {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload.error,
        data: { isHelpAvailable: false },
      };
    },
    updateHelpAndSpeakupData(
      state: HelpAndSpeakupState,
      action: UpdateHelpDataAction,
    ): HelpAndSpeakupState {
      return {
        ...state,
        loading: false,
        isLoaded: Boolean(action.payload),
        error: null,
        data: { isHelpAvailable: action.payload.isHelpAvailable },
      };
    },
  },
});

export const helpAndSpeakupActions = helpAndSpeakupSlice.actions;

export default helpAndSpeakupSlice;
