import React, { ReactNode } from 'react';
import Img from '../Img';
import styles from './index.module.scss';

const TabButton: React.FunctionComponent<TabButtonProps> = (props) => {
  const { className, selectedTab, handleSelect, tabs, isMobileWrapEnabled, themeSkyBlue } = props;

  return (
    <div className={`${className} ${isMobileWrapEnabled && 'mobileWrapped'}`}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          type="button"
          onClick={() =>
            handleSelect({
              id: Number(index),
              label: tab.title,
              labelId: tab.id,
              actualItemId: tab.actualItemId,
            })
          }
          className={`${selectedTab.id === index ? styles.selectedTabItem : styles.tabItem} ${
            index === 0 ? styles.tabItem1 : styles.tabItem2
          }`}
        >
          {tab.icon ? (
            <>
              <div className={styles.tabIcon}>
                <Img src={tab.icon} alt="Tab Icon" />
              </div>
              {tab.title}
            </>
          ) : (
            <div className={styles.tabNameCombined}>
              {tab.title}
              {tab.rightContent && tab.rightContent !== undefined && <>{tab.rightContent}</>}
            </div>
          )}
          {selectedTab.id === index && <div className={styles.overlapElement} />}
          {index === 0 && tabs?.length > 1 ? (
            <div
              className={
                selectedTab.id === index
                  ? styles.overlapSelectedCircle
                  : `${styles.overlapCircle} ${themeSkyBlue ? styles.themeSkyBlue : ''}`
              }
            />
          ) : (
            <div className={styles.oneTabContainer}>
              <div
                className={`${styles.overlapCircle} ${themeSkyBlue ? styles.themeSkyBlue : ''}`}
              />
              <div className={styles.overlapSquare} />
            </div>
          )}
          {index !== 0 && (
            <div
              className={
                selectedTab.id === index
                  ? styles.selectedOverlapCircle2
                  : `${styles.overlapCircle2} ${themeSkyBlue ? styles.themeSkyBlue : ''}`
              }
            />
          )}
          {index !== 0 && (
            <div
              className={
                selectedTab.id === index ? styles.selectedOverlapSquare2 : styles.overlapSquare2
              }
            />
          )}
        </button>
      ))}
    </div>
  );
};

TabButton.defaultProps = {
  className: styles.tabsContainer,
  isMobileWrapEnabled: false,
  themeSkyBlue: false,
};

interface TabButtonProps {
  className?: string;
  selectedTab: { id: number; label: string; actualItemId?: number };
  handleSelect: (tab: {
    id: number;
    label: string;
    labelId: number;
    actualItemId?: number;
  }) => void;
  tabs: {
    title: string;
    id: number;
    icon?: string;
    rightContent?: ReactNode;
    actualItemId?: number;
  }[];
  isMobileWrapEnabled?: boolean;
  themeSkyBlue?: boolean;
}

export default TabButton;
