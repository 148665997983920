export enum Colors {
  'skyBlue' = '#019DF2',
  'calmLilac' = '#BEAAF3',
  'sunYellow' = '#FCD300',
  'zingyGreen' = '#8EEA36',
  'aquaGreen' = '#06BF97',
  'warmOrange' = '#FF8A4A',
}

export const ColorPalette: { [key: string]: string } = {
  '#019df2': '#0072b0',
  '#beaaf3': '#6048a1',
  '#fcd300': '#d4b202',
  '#8eea36': '#4c8f0c',
  '#06bf97': '#069b7b',
  '#ff8a4a': '#d96525',
};

export const DarkColorMap: Record<string, string> = {
  '#3fb9fc': '#0072b0', // sky-blue light -> dark
  '#019df2': '#0072b0', // sky-blue medium -> dark
  '#d4c6f9': '#6048a1', // calm-lilac light -> dark
  '#beaaf3': '#6048a1', // calm-lilac medium -> dark
  '#ffe665': '#d4b202', // sun-yellow light -> dark
  '#fcd300': '#d4b202', // sun-yellow medium -> dark
  '#bbf484': '#4c8f0c', // zingy-green light -> dark
  '#8eea36': '#4c8f0c', // zingy-green medium -> dark
  '#3be1ce': '#069b7b', // aqua-green light -> dark
  '#06bf97': '#069b7b', // aqua-green medium -> dark
  '#ffaf83': '#d96525', // warm-orange light -> dark
  '#ff8a4a': '#d96525', // warm-orange medium -> dark
  '#4ff1de': '#2fc2ab', // turquoise light -> dark
  '#47dcc5': '#2fc2ab', // turquoise medium -> dark
  '#fcfbf7': '#ece7dc', // putty light -> dark
  '#f4f2ec': '#ece7dc', // putty medium -> dark
};
