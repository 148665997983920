import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const newTheme = (theme: any) =>
  createTheme({
    ...theme,
    components: {
      MuiPickersToolbar: {
        styleOverrides: {
          root: { fontFamily: 'Titillium Web', display: 'none' },
          content: {
            color: 'white',
            backgroundColor: '#019df2',
            padding: '0 16px',
            fontFamily: 'Titillium Web',
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            color: '#019df2',
            backgroundColor: 'transparent',
            fontFamily: 'Titillium Web',
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            backgroundColor: '#fcfbf7',
          },
        },
      },
    },
  });

const StaticDatePickerX: React.FunctionComponent<StaticDatePickerXProps> = (props) => {
  const { selectedDate, handleChange, onClose, onAccept } = props;

  const handleDateChange = async (newDate: any | null) => {
    const date = dayjs(newDate).format('YYYY-MM-DD');
    await handleChange(date);
    await onAccept();
  };

  const parsedSelectedDate = selectedDate instanceof Date ? selectedDate : null;

  const formattedDate = parsedSelectedDate ? dayjs(parsedSelectedDate).format('YYYY-MM-DD') : null;

  const value = formattedDate ? new Date(formattedDate) : null;

  return (
    <ThemeProvider theme={newTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker<Date>
          localeText={{ toolbarTitle: 'Due Date' }}
          orientation="landscape"
          value={value}
          onChange={handleDateChange}
          autoFocus
          openTo="day"
          disablePast
          reduceAnimations={false}
          componentsProps={{ actionBar: { actions: [] } }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

StaticDatePickerX.defaultProps = {
  selectedDate: dayjs(new Date()).format('YYYY-MM-DD'),
};

interface StaticDatePickerXProps {
  selectedDate?: any;
  handleChange: (date: any | null) => void;
  onClose: () => void;
  onAccept: () => void;
}

export default StaticDatePickerX;
