import { getInstantHelpPageData } from '@api/instantHelp';
import { AppDispatch } from '@hooks/useAppDispatch';
import { instantHelpActions } from 'store/Slices/InstantHelpSlice';
import { InstantHelpData } from 'custom';
import externalLinkIcon from '../../assets/images/icons/external-link.svg';
import emailIcon from '../../assets/images/icons/email.svg';
import phoneIcon from '../../assets/images/icons/phone-call.svg';
import textIcon from '../../assets/images/icons/message-square.svg';

export const getInstantHelpData = () => async (dispatch: AppDispatch) => {
  dispatch(instantHelpActions.setLoading({ loading: true }));

  try {
    const data: InstantHelpData[] = await getInstantHelpPageData();
    if (data) {
      const formattedData = data.map((item, index) => ({
        ...item,
        id: index + 1,
        sections: item.sections
          .sort((a, b) => a.order_id - b.order_id)
          .map((section) => ({
            ...section,
            instantHelpActions: section.instantHelpActions.map((action) => ({
              ...action,
              icon:
                action.type === 'phone'
                  ? phoneIcon
                  : action.type === 'text'
                  ? textIcon
                  : action.type === 'email'
                  ? emailIcon
                  : action.type === 'url'
                  ? externalLinkIcon
                  : externalLinkIcon,
              value:
                action.type === 'phone'
                  ? `tel:${action.value}`
                  : action.type === 'text'
                  ? `sms:${action.value}`
                  : action.type === 'email'
                  ? `mailto:${action.value}`
                  : action.value,
            })),
          })),
      }));

      dispatch(instantHelpActions.updateInstantHelpData(formattedData));
    } else {
      dispatch(instantHelpActions.setError({ error: 'Loading instant help data failed' }));
    }
  } catch (error) {
    dispatch(instantHelpActions.setError({ error: 'Loading instant help data failed' }));
  }
};
