import React from 'react';
import classNames from 'classnames';
import { useGlobalState } from '@context/GlobalStore';
import styles from './index.module.scss';

const CircleScoreCard: React.FunctionComponent<CircleScoreCardType> = (props) => {
  const { text, color, score, scoreDifference, isSelected, index } = props;
  const [state] = useGlobalState();
  const stateData = state?.diffCompareData?.oneByOneCompare
    ? state?.diffCompareData?.oneByOneCompare.find((i: any) => i?.id === index)?.scoreDifference
    : null;

  const styleClasses = classNames([
    styles.hsCircleScoreCard,
    color === '#BEAAF3' && styles.calmLilac,
    color === '#019DF2' && styles.skyBlue,
    color === '#FCD300' && styles.sunYellow,
    color === '#8EEA36' && styles.zingyGreen,
    color === '#06BF97' && styles.aquaGreen,
    color === '#FF8A4A' && styles.warmOrange,
    stateData < 0 ? styles.down : stateData > 0 ? styles.up : styles.right,
  ]);

  const icon =
    stateData < 0 ? (
      <i className="icon icon-arrow-down" />
    ) : stateData > 0 ? (
      <i className="icon icon-arrow-up" />
    ) : (
      <i className="icon icon-arrow-right" />
    );

  return (
    <div className={styleClasses}>
      <div className={styles.content}>
        {text}
        {stateData !== null && icon}
        <span>{stateData}</span>
      </div>
      <span className={styles.score}>{score}</span>
    </div>
  );
};

type CircleScoreCardType = {
  text: string;
  score: number;
  scoreDifference: number;
  color: string;
  isSelected?: boolean;
  index: number;
};

CircleScoreCard.defaultProps = {
  isSelected: false,
};

export default CircleScoreCard;
