import { configureStore } from '@reduxjs/toolkit';
import CompanyConfigSlice from './Slices/CompanyConfigSlice';
import DeepDiveWizardSlice from './Slices/DeepDiveWizardSlice';
import ExpertSlice from './Slices/ExpertSlice';
import HelpAndSpeakupSlice from './Slices/HelpAndSpeakupSlice';
import InstantHelpSlice from './Slices/InstantHelpSlice';
import ShortStarQuestionsSlice from './Slices/ShortStarSlice';
import ShortStarWizardSlice from './Slices/ShortStarWizardSlice';
import StarScoresSlice from './Slices/StarScoreSlice';
import UserStatusSlice from './Slices/UserStatusSlice';
import ConfigurationSlice from './Slices/configurationsSlice';

const store = configureStore({
  reducer: {
    userStatus: UserStatusSlice.reducer,
    instantHelp: InstantHelpSlice.reducer,
    companyConfig: CompanyConfigSlice.reducer,
    helpConfig: HelpAndSpeakupSlice.reducer,
    shortStarQuestions: ShortStarQuestionsSlice.reducer,
    shortStarWizard: ShortStarWizardSlice.reducer,
    configurations: ConfigurationSlice.reducer,
    starScores: StarScoresSlice.reducer,
    deepDiveWizard: DeepDiveWizardSlice.reducer,
    experts: ExpertSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
