import { getAllEmployees } from '@api/EmployerNew';
import { getQuestionnairesNames } from '@api/questionnaires';
import { getShortStarQuestionsData } from '@api/shortStar';
import ChartSection from '@components/Molecules/Employer/ChartSection';
import OverallDetailsSection from '@components/Molecules/Employer/OverallDetailsSection';
import ScoresSection from '@components/Molecules/Employer/ScoresSection';
import SliceAndDiceDeepDiveSection from '@components/Molecules/Employer/SliceAndDiceDeepDiveSection';
import MainLayout from '@layouts/MainLayout';
import { Skeleton } from '@mui/material';
import { ShortStarQuestionsInterface } from 'custom';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import styles from './index.module.scss';

type ThemeColors = {
  [key: string]: string;
  sunYellow: string;
  aquaGreen: string;
  calmLilac: string;
  warmOrange: string;
  zingyGreen: string;
  skyBlue: string;
  default: string;
};

const themeColors: ThemeColors = {
  sunYellow: '#FCD300',
  aquaGreen: '#06BF97',
  calmLilac: '#BEAAF3',
  warmOrange: '#FF8A4A',
  zingyGreen: '#8EEA36',
  skyBlue: '#019DF2',
  default: '#ECE7DC',
};

const EmployerAnalytics: React.FunctionComponent = () => {
  const [questionnaireNames, setQuestionnaireNames] = useState<{ id: number; name: string }[] | []>(
    [],
  );
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersCount, setUsersCount] = useState<number>(0);

  const [starQuestions, setStarQuestions] = useState<ShortStarQuestionsInterface[] | []>([]);
  const [checkedQuestions, setCheckedQuestions] = useState<number[]>([]);
  const [starQuestionsLoading, setStarQuestionsLoading] = useState<boolean>(true);
  const [queColors, setQueColors] = useState<{ qId: number; qName: string; color: string }[] | []>(
    [],
  );

  useEffect(() => {
    getQueNames();
    getAllUsers();
    getStarQuestions();
  }, []);

  const getAllUsers = async () => {
    try {
      await setUsersLoading(true);
      const res = await getAllEmployees();
      setUsersCount(res?.length);
    } catch (error) {
      setUsersCount(0);
    } finally {
      await setUsersLoading(false);
    }
  };

  const getQueNames = async () => {
    const res = await getQuestionnairesNames();
    if (res === 401) {
      setQuestionnaireNames([]);
    } else {
      setQuestionnaireNames(res);
    }
  };

  const getStarQuestions = async () => {
    try {
      setStarQuestionsLoading(true);
      const questions = await getShortStarQuestionsData();
      setCheckedQuestions(
        questions.map((question: ShortStarQuestionsInterface) => question.questionId),
      );

      const colorCodes = questions.map((item) => ({
        qId: item.questionId,
        qName: item.questionText,
        color: themeColors[item.colorTheme] || themeColors.default,
      }));

      if (colorCodes) {
        setQueColors(colorCodes);
      }

      setStarQuestions(questions);
    } catch (error) {
      setStarQuestions([]);
    } finally {
      setStarQuestionsLoading(false);
    }
  };

  return (
    <MainLayout title="Employer Portal" useEmployerNav isEmployerPage secondaryNavEnabled>
      <ToastContainer hideProgressBar draggable={false} theme="dark" />
      <div className={styles.mainContainer}>
        <div className={styles.topSection}>
          {usersLoading ? (
            <>
              <Skeleton variant="text" height={60} width={280} />
              <Skeleton variant="text" height={30} width={100} />
            </>
          ) : (
            <>
              <h2 className={styles.mainTitle}>Organisation Snapshot</h2>
              <h6 className={styles.regUsersCountText}>{usersCount} registered users</h6>
            </>
          )}
        </div>

        <ScoresSection isFiltered={false} starQuestions={starQuestions} />
        <ChartSection
          starQuestions={starQuestions}
          starQuestionsLoading={starQuestionsLoading}
          queColors={queColors}
          checkedQues={checkedQuestions}
        />
        <OverallDetailsSection />
      </div>
      <div className={styles.blueContainer}>
        <div className={styles.mainContainer}>
          <h3>Slice and dice</h3>
          <SliceAndDiceDeepDiveSection
            questionnaireNames={questionnaireNames}
            starQuestions={starQuestions}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default EmployerAnalytics;
