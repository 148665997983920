import { LoginTypes, confirmUserLogin } from '@api/auth/login';
import { getCompanyDetails, getTokenVerifiedUser } from '@helpers/auth.helper';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useGoNative from '@hooks/useGoNative';
import AuthenticationLayout from '@layouts/AuthenticationLayout';
// import mixpanel from 'mixpanel-browser';
import { updateOneSignalDetails } from '@helpers/median.helper';
import tracker from 'OpenReplay';
import React, { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserStatusData } from 'store/Actions/UserStatusAction';
import styles from './VerifyToken.module.scss';

interface InitialState {
  authenticated: boolean;
  loading: boolean;
}

enum DispatchTypes {
  'SET_AUTHENTICATED',
}

interface Action {
  type: DispatchTypes;
  values: { authenticated: boolean; loading: boolean };
}

const initialState: InitialState = {
  authenticated: false,
  loading: false,
};

const verifyTokenReducer: Reducer<InitialState, Action> = (state, action) => {
  if (action.type === DispatchTypes.SET_AUTHENTICATED) {
    return {
      ...state,
      authenticated: action.values.authenticated,
      loading: action.values.loading,
    };
  }

  return initialState;
};

const VerifyToken: React.FunctionComponent = () => {
  const { userId, token } = useParams<UrlParamsType>();
  const history = useHistory();
  const location = useLocation();
  const [verifyTokenState, dispatchVerifyToken] = useReducer(verifyTokenReducer, initialState);
  const isMobileApp = useGoNative();
  const dispatch = useAppDispatch();

  const [hasVerifiedAuthToken, setHasVerifiedAuthToken] = useState(false);
  const redirectPath = localStorage.getItem('redirectPath');
  const isDirectLink = localStorage.getItem('isDirectLink') === 'true';

  const params = new URLSearchParams(location.search);
  const redirectedBy = params.get('redirectedBy');

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      tracker.start();
    }
  });

  const verifyAuthToken = useCallback(async () => {
    dispatchVerifyToken({
      type: DispatchTypes.SET_AUTHENTICATED,
      values: {
        authenticated: false,
        loading: true,
      },
    });

    try {
      const data = await getTokenVerifiedUser(
        token,
        LoginTypes.LINK,
        isMobileApp,
        undefined,
        userId,
        isDirectLink,
        redirectedBy,
      );

      dispatchVerifyToken({
        type: DispatchTypes.SET_AUTHENTICATED,
        values: {
          authenticated: true,
          loading: true,
        },
      });

      // mixpanel.identify(data.user.id);
      // mixpanel.track('Log In (Token)');

      await getCompanyDetails();

      dispatch(getUserStatusData());

      tracker.setUserID(data.user.id.toString());

      localStorage.removeItem('redirectPath');
      localStorage.removeItem('isDirectLink');
      history.replace(redirectPath || '/');

      dispatchVerifyToken({
        type: DispatchTypes.SET_AUTHENTICATED,
        values: {
          authenticated: true,
          loading: false,
        },
      });

      await updateOneSignalDetails();
      await confirmUserLogin({ type: LoginTypes.LINK });

      setHasVerifiedAuthToken(true);
    } catch (err) {
      dispatchVerifyToken({
        type: DispatchTypes.SET_AUTHENTICATED,
        values: {
          authenticated: false,
          loading: false,
        },
      });
      toast.error('Invalid authentication request', {
        autoClose: 400,
        onClose: async () => {
          history.push('/login?error=invalid');
        },
      });
    } finally {
      setHasVerifiedAuthToken(false);
    }
  }, [dispatch, dispatchVerifyToken, history, isMobileApp, token, userId]);

  useEffect(() => {
    if (!verifyTokenState.authenticated && !hasVerifiedAuthToken) {
      verifyAuthToken();
    }
  }, [hasVerifiedAuthToken, verifyAuthToken, verifyTokenState.authenticated]);

  if (!token || !userId) {
    toast.error('Invalid authentication request');
    history.push('/login');
  }

  return (
    <AuthenticationLayout title="Login">
      <div className={styles.hsRoot}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only" />
        </div>
      </div>
    </AuthenticationLayout>
  );
};

type UrlParamsType = {
  userId: string;
  token: string;
};

export default VerifyToken;
